import React, { useEffect, useState } from "react";
import styles from "./NewLinkedAccounts.module.sass";
import Icon from "../../../../components/Icon";
import Accounts from "./Accounts";
import Transactions from "../../../../components/Transactions";
import DeleteModal from "../../../../components/DeleteModal";
import Card from "../../../../components/Card";
import {
  dislinkAccount,
  getTransactions,
} from "../../../../utils/apiCallHanlder";
import { Router, useHistory } from "react-router";
import {
  toastConfiguration,
  transactionDateFormatter,
} from "../../../../utils/utils";
import Spinner from "../../../../utils/spinner";
import cn from "classnames";
import { toast } from "react-toastify";

const NewLinkedAccounts = ({
  setVisible,
  setAddLinkedAccounts,
  setShowLinkedAccounts,
  linkAccount,
  linkedAccountId,
  count,
  setCount,
}) => {
  const [colorName, setColorName] = useState("gray");
  const [ownerName, setOwnerName] = useState("");
  const [loading, setLoading] = useState(true);
  const [lastId, setLastId] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [isNextPageExist, setIsNextPageExist] = useState(false);
  const [accountDetailPayload, setAccountDetailPayload] = useState({});
  const [delinkLoading, setDelinkLoading] = useState(false);
  const [nextPageInfo, setNextPageInfo] = useState({});

  const history = useHistory();

  useEffect(async () => {
    const { data } = await getTransactions(
      {
        id: linkedAccountId,
        FromDate: transactionDateFormatter(
          new Date(new Date().setDate(new Date().getDate() - 30))
        ),
        ToDate: transactionDateFormatter(new Date()),
      },
      100,
      lastId ?? ""
    );

    if (data) {
      setLastId(data?.transactions?.at(-1)?.id);
      setTransactions(data.transactions);
      setIsNextPageExist(data?.isNextPageExist);
      setAccountDetailPayload(data.accountDetailPayload);
    }
    setLoading(false);
  }, []);

  const saveButtonHandler = () => {
    setVisible(true);
    setAddLinkedAccounts(false);
    setShowLinkedAccounts(true);
  };

  const unlinkAccountHandler = async () => {
    setDelinkLoading(true);
    const { data } = await dislinkAccount({
      id: linkedAccountId,
    });

    if (data) {
      history.push("/accountsandcards");
      setCount(count + 1);
    }
    setDelinkLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <h5
          className={styles.backBtn}
          onClick={() => history.push("/accountsandcards")}
        >
          Accounts &amp; Cards <Icon name='arrow-next' size='14' />
        </h5>
        <p>Linked Account</p>
      </div>
      <Accounts
        colorName={colorName}
        setColorName={setColorName}
        ownerName={ownerName}
        linkAccount={linkAccount}
        setOwnerName={setOwnerName}
      />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner loading={loading} size='44px' color='gray' />
        </div>
      ) : (
        <Transactions
          transactions={transactions}
          notShowDropdown={true}
          accountDetailPayload={accountDetailPayload}
          setAccountDetailPayload={setAccountDetailPayload}
          linkedAccountId={linkedAccountId}
          lastId={lastId}
          isNextPageExist={isNextPageExist}
          setLastId={setLastId}
          setTransactions={setTransactions}
          setIsNextPageExist={setIsNextPageExist}
          setNextPageInfo={setNextPageInfo}
        />
      )}
      <Card className={styles.actionCard}>
        <button
          onClick={() => history.push("/accountsandcards")}
          className={cn("button-stroke", styles.button)}
        >
          <Icon name='arrow-left' size='24' />
          <span>Cancel</span>
        </button>
        {accountDetailPayload?.type === "External" ? (
          <DeleteModal
            className={styles.deleteBtn}
            btnTxt='Unlink this account'
            deleteBtn={
              <button
                className='button'
                style={{ width: 150 }}
                onClick={unlinkAccountHandler}
              >
                {delinkLoading ? (
                  <Spinner size='24' color='white' loading={delinkLoading} />
                ) : (
                  "Yes I'm sure!"
                )}
              </button>
            }
          />
        ) : null}
      </Card>
    </div>
  );
};

export default NewLinkedAccounts;
