import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Send.module.sass";
import Spinner from "../../../../utils/spinner";

const Send = ({ setSelectedImages, selectedImages, newMessageSend, disabled }) => {
  const fileObj = [];
  const fileArray = [];
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSelectedImage = (e) => {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setSelectedImages([...selectedImages].concat(fileArray))

  };


  const handleSubmit = (event) => {
    event.preventDefault();
    //sendMessage(roomId, user, value);
    newMessageSend({ text: value });
    setValue('');
  };

  const handleEnter = (e) => {
    if (e.key === 13 && !e.shiftKey) {
      e.preventDefault();
      newMessageSend({ text: value });
      setValue('');
    }
  };


  return (
    <>
      <div className={styles.send}>
        {/* <div className={styles.file}>
          <input
            type="file"
            name="myImage"
            multiple="multiple"
            onChange={handleSelectedImage}
          />
          <Icon name="file-add" size="24" />
        </div> */}
        <div className={styles.form}>
          <form onSubmit={handleSubmit} className={styles.messageInputContainer}>
            <input
              className={styles.input}
              value={value}
              onChange={handleChange}
              type="text"
              name="message"
              onKeyDown={handleEnter}
              required
            />
            <button
              className={cn("button-small", styles.button)}
              style={{ backgroundColor: 'transparent' }}
              type="submit"
              disabled={(value < 1 || disabled)}>
              {
                !disabled ? <img src={value ? "/chatSendActive.png" : "/sendChat.png"} alt="send" /> : (
                  <>
                    <div className={styles.spinner}>
                      <Spinner loading={true} size="24" color="gray" />
                    </div>
                  </>
                )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Send;
