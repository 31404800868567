import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Icon from '../../../components/Icon';
import TextInput from '../../../components/TextInput';
import styles from './AddNew/addnew.module.sass';
import Spinner from '../../../utils/spinner';
import { formatStripeAmount, getCurrencyFromLocalStorage } from '../../../utils/utils';
import CheckboxWithText from './checkboxWithText';
import Amount from '../../../components/Amount';

let typingTimer;
let doneTypingInterval = 600;

const Lines = ({
    x,
    index,
    updateInvoicee,
    setLines,
    dropdownValue,
    lines,
    currencySymbol
}) => {
    const [qty, setQty] = useState(x.quantity);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [updateQuantityLoading, setUpdateQuantityLoading] = useState(false);
    const [customerAdjustValueCheckbox, setCustomerAdjustCheckbox] = useState(false);

    useEffect(() => { setQty(x.quantity) }, [x.quantity])
    useEffect(() => { setCustomerAdjustCheckbox(x.isCustomerAdjustQuantity) }, [x.isCustomerAdjustQuantity])


    const updateQuantity = async (event) => {
        if (qty !== "0" && qty !== "") {
            setUpdateQuantityLoading(true);
            x.quantity = qty;
            setUpdateQuantityLoading(false);
        }
        else {
            x.quantity = 1;
        }
    }

    const deleteButtonHandler = async () => {
        setDeleteLoading(true);
        const newLines = lines?.filter(item => item.lineId !== x.lineId)
        setLines(newLines);
        setDeleteLoading(false);
    }

    const customerAdjustOnChangeHandler = () => {
        setCustomerAdjustCheckbox(!customerAdjustValueCheckbox);
        x.isCustomerAdjustQuantity = !customerAdjustValueCheckbox;
    }

    let currency = getCurrencyFromLocalStorage();

    return (
        <div key={index} className={styles.productWrap}>
            {deleteLoading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, marginTop: 20 }}>
                    <Spinner loading={deleteLoading} size={"40"} color={"blue"} />
                </div>
                :
                <>
                    <div className={styles.head} style={{ alignItems: 'center' }}>
                        <div className={cn(styles.left, styles.name)}>{x.name}</div>
                        <div className={styles.right}>
                            <button
                                onClick={deleteButtonHandler}
                                className={styles.deleteBtn}
                            >
                                <Icon name="trash" size="20" />
                            </button>
                        </div>
                    </div>
                    {dropdownValue === "customer_choose" ?
                        null :
                        <>
                            <div className={styles.row}>
                                <div className={styles.left}>
                                    <div style={{ display: 'flex' }}>
                                        <span className={styles.title}>Price</span>
                                        {currency}
                                        <Amount
                                            amount={formatStripeAmount(x.priceValue, updateInvoicee?.currencySymbol)}
                                            subtitleEmValue={"0.8em"} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.row} style={{ justifyContent: 'space-between' }}>
                                <div className={styles.qtyWrap} style={{ width: 150 }}>
                                    <span className={styles.title}>Qty</span>
                                    <TextInput
                                        className={styles.field}
                                        name="description"
                                        value={qty}
                                        updateQuantityLoading={updateQuantityLoading}
                                        onChange={(event) => setQty(event.target.value)}
                                        placeholder="Quantity"
                                        type="text"
                                        onKeyUp={() => {
                                            clearTimeout(typingTimer);
                                            typingTimer = setTimeout(updateQuantity, doneTypingInterval);
                                        }}
                                        onKeyDown={() => {
                                            clearTimeout(typingTimer);
                                        }}
                                        required
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={styles.title}>Total</span>
                                    {currency}
                                    <Amount
                                        amount={formatStripeAmount(x.priceValue * x.quantity, updateInvoicee?.currencySymbol)}
                                        subtitleEmValue={"0.8em"} />
                                </div>
                            </div>
                            <CheckboxWithText
                                onChange={customerAdjustOnChangeHandler}
                                setValue={setCustomerAdjustCheckbox}
                                value={customerAdjustValueCheckbox}
                                text={"Let Customers Adjust Quantity"}
                                showTooltip={false}
                            />
                        </>
                    }
                </>
            }
        </div>
    )
}

export default Lines;