import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "../Overview.module.sass";
import Card from "../../../../components/Card";
import LargeNav from "../../../../components/LargeNav";
import CircleChart from "../CircleChart";
import { getRandomColor } from "../../../../utils/utils";
import List from "../List";


const filters = ["Chart", "List"]
const dateFilters = ["All Time", "This Quarter", "Previous Quarter", "This Year", "Previous Year"]
const dbValues = {
    "All Time": "amount",
    "This Quarter": "amountThisQuarter",
    "Previous Quarter": "amountPrevQuarter",
    "This Year": "amountThisYear",
    "Previous Year": "amountPrevYear"
}
const colors = ["#FF4900", "#E9E7E0", "#FFA96B", "#FFB7C3"]

const Income = ({
    className,
    data,
    categoryData,
    isDashbord
}) => {
    const [activeFilter, setActiveFilter] = useState(0);
    const [revenueWho, setRevenueWho] = useState([]);
    const [revenueCategory, setRevenueCategory] = useState([]);
    const [incomeFilter, setIncomeFilter] = useState(3);

    useEffect(() => {
        if (data && categoryData) {
            const fieldName = dbValues[dateFilters[incomeFilter]];
            let yearsData = [];
            let yearsCategoryData = [];

            Object.keys(data)?.map(item => {
                yearsData = [...yearsData, ...(data[item] || [])];
            });

            Object.keys(categoryData)?.map(item => {
                yearsCategoryData = [...yearsCategoryData, ...(categoryData[item] || [])];
            });

            const calculatedRevenueWho = valueSetter(yearsData, fieldName, "revenue");
            const calculatedRevenueCategory = valueSetter(yearsCategoryData, fieldName, "revenue");

            setRevenueWho(calculatedRevenueWho);
            setRevenueCategory(calculatedRevenueCategory);
        }
    }, [data, categoryData, incomeFilter])

    const valueSetter = (data, fieldName, condition) => {
        let finalArray = [];

        data?.map((item) => {
            item.amountValue = item[fieldName];
            if (item?.amountValue < 0) {
                item.amountValue = -item.amountValue;
                item.isNegative = true;
            }

            if (item?.type === condition) {
                finalArray.push(item);
            }
        })

        finalArray.map((item, index) => item.fill = colors[index] ? colors[index] : getRandomColor())
        return finalArray;
    }

    return (
        <div className={styles.container}>

            {/* Income Year */}
            <Card
                className={cn(styles.incomeCard, className)}
                title={
                    <div>
                        <span>
                            Income
                        </span>
                        <LargeNav
                            className={styles.filters}
                            tabOptions={dateFilters}
                            activeTab={incomeFilter}
                            onClick={(x, index) => {
                                setIncomeFilter(index);
                            }}
                        />
                        <LargeNav
                            tabOptions={filters}
                            activeTab={activeFilter}
                            onClick={(x, index) => {
                                setActiveFilter(index);
                            }}
                        />
                    </div>
                }
                classCardHead={styles.cardHead}
            >
                <div className={styles.noContent}>
                    {revenueWho?.length < 1 && revenueCategory?.length < 1 && (
                        <span className={styles.title}>No Data to show</span>
                    )}
                </div>
                <div className={styles.cicularCharts} style={isDashbord ? { paddingLeft: 0 } : {}}>
                    {activeFilter === 1 ?
                        <>
                            {revenueWho?.length > 0 &&
                                <List
                                    title={"Who paid you?"}
                                    data={revenueWho}
                                />
                            }
                            {revenueCategory?.length > 0 &&
                                <List
                                    title={"What services or products?"}
                                    data={revenueCategory}
                                />
                            }

                        </>
                        :
                        <>
                            {revenueWho?.length > 0 &&
                                <CircleChart
                                    title={"Who paid you?"}
                                    data={revenueWho}
                                />
                            }
                            {revenueCategory?.length > 0 &&
                                <CircleChart
                                    title={"What services or products?"}
                                    data={revenueCategory}
                                />
                            }
                        </>
                    }
                </div>
            </Card>
        </div>
    );
};

export default Income;
