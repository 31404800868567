import React, { useState, useEffect } from "react";
import styles from "./Pricing.module.sass";
import TextInput from "../../../../components/TextInput";
import cn from "classnames";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Checkbox from "../../../../components/Checkbox";
import Tooltip from "../../../../components/Tooltip";
import Card from "../../../../components/Card";
import Dropdown from "../../../../components/Dropdown_c";
import { getCurrencyFromLocalStorage } from "../../../../utils/utils";

const Pricing = ({
  className,
  productCurrency,
  selectCurrency,
  setSelectCurrency,
  durationInMonths,
  setDurationInMonths,
  selectDuration,
  setSelectDuration,
  couponType,
  setCouponType,
  userChoose,
  amountOff,
  isAdd,
  setAmountOff,
  percentOff,
  setPercentOff,
  setValueHandler,
  archived,
}) => {
  
  const [type, setType] = useState(couponType || "percentOff");
  return (
    <>
      <Card className={cn(styles.card, className)}>
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <div className={styles.fieldLabel} style={{ marginBottom: 0 }}>
            Type
          </div>
          <Checkbox
            content={"Percentage Discount"}
            value={type === "percentOff" ? true : false}
            onChange={() => {
              setValueHandler("percentOff", setCouponType);
              setType("percentOff");
            }}
          />
          <Checkbox
            content={"Fixed Amount Discount"}
            value={type === "amountOff" ? true : false}
            onChange={() => {
              setValueHandler("amountOff", setCouponType);
              setType("amountOff");
            }}
          />
        </div>

        {couponType === "percentOff" ? (
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.field} style={{ position: "relative" }}>
                <p
                  className={styles.fieldLabel}
                  style={{ marginTop: "15px", marginBottom: 8 }}
                >
                  Percentage Off
                </p>
                <TextInput
                  style={
                    percentOff === "" || percentOff === null
                      ? {
                          backgroundColor: "#FFBC9925",
                          borderColor: "#FFBC9925",
                        }
                      : {}
                  }
                  percentage={"percentage"}
                  name="percentOff"
                  onChange={(event) => {
                    if (isAdd !== undefined) {
                      setValueHandler(event?.target?.value, setPercentOff);
                    }
                  }}
                  value={percentOff}
                  type="number"
                  required
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {couponType === "amountOff" ? (
          <div
            className={styles.row}
            style={archived ? { pointerEvents: "none" } : null}
          >
            <div className={styles.col}>
              <div>
                <p
                  className={styles.fieldLabel}
                  style={{ marginTop: "15px", marginBottom: 8 }}
                >
                  Discount Amount
                </p>
                <div className={styles.priceGroup}>
                  <TextInput
                    style={
                      amountOff === "" || amountOff === null
                        ? {
                            backgroundColor: "#FFBC9925",
                            borderColor: "#FFBC9925",
                          }
                        : {}
                    }
                    name="amountOff"
                    value={amountOff}
                    onChange={(event) => {
                      if (isAdd !== undefined) {
                        setValueHandler(event?.target?.value, setAmountOff);
                      }
                    }}
                    required
                  />
                  <Dropdown
                    className={styles.dropdown}
                    classDropdownHead={styles.dropdownHead}
                    value={selectCurrency}
                    setValue={setSelectCurrency}
                    options={productCurrency}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Pricing;
