import React, { useEffect, useState } from "react";
import Dropdown from "../../../../../../components/Dropdown_c";
import Search from "../../SearchProduct";
import cn from "classnames";
import styles from "./DetailsCustomer.module.sass";
import TextInput from "../../../../../../components/TextInput";
import { NavLink } from "react-router-dom";
import CreateNewProduct from "../../Modals/CreateNewProduct";
import Icon from "../../../../../../components/Icon";
import Card from "../../../../../../components/Card";
import Line from "./Lines";
import {
  addLineToInvoice,
  updateInvoice,
  memoUpdate,
  updateInvoiceDiscount,
  updateInvoiceTaxRate,
} from "../../../../../../utils/apiCallHanlder";
import Spinner from "../../../../../../utils/spinner";
import BankCheckBox from "./bankCheckBox";
import Checkbox from "../../../../../../components/Checkbox";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../../../../utils/utils";
import Image from "../../../../../../components/Image";
import AlternativeAvatar from "../../../../../../components/AlternativeAvatar";
import Coupons from "./Coupons";
import TaxRates from "./TaxRates";

let typingTimer;
let doneTypingInterval = 600;

const DetailsCustomer = ({
  className,
  customerTaxExempt,
  dbTaxRates,
  setShowActionBtn,
  setShowDetails,
  setShowAccountInfo,
  setInvoiceObject,
  invoiceId,
  invoiceObject,
  searchNamesProducts,
  setUpdateInvoice,
  updateInvoicee,
  getProducts,
  setShowAccountInfos,
  selectedCustomerName,
  isManual,
  isThroughStripe,
  setIsManual,
  setIsThroughStripe,
  customerImage,
  setSearchNamesProducts,
  sendButton,
  selectedCustomerEmail,
  selectedCustomerPhone,
  connect,
  downloadButton
}) => {
  var getRemanningDays = function () {
    var date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    var days =
      time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    return days;
  };

  const intervals = [
    { text: "Now", value: 1, display: "Now" },
    { text: "10 days", value: 10, display: "10 days" },
    {
      text: "End of month",
      value: getRemanningDays(),
      display: "End of month",
    },
  ];

  const [durations, setDurations] = useState(
    invoiceObject?.daysUntilDue || invoiceObject?.daysUntilDue === 0
      ? invoiceObject?.daysUntilDue === 30
        ? intervals[2].value
        : invoiceObject?.daysUntilDue
      : intervals[0].value
  );


  useEffect(async () => {

    setDurations(invoiceObject?.daysUntilDue || invoiceObject?.daysUntilDue === 0
      ? invoiceObject?.daysUntilDue === 30
        ? intervals[2].value
        : invoiceObject?.daysUntilDue
      : intervals[0].value);

  }, [invoiceObject]);


  const [addAnotherProduct, setAddAnotherProduct] = useState(false);
  const [showAddAnotherProductBtn, setShowAddAnotherProductBtn] =
    useState(true);

  const [showNewProductModal, setShowNewProductModal] = useState(false);

  const [showProduct, setShowProduct] = useState(false);
  const [visible, setVisible] = useState(false);
  const [lines, setLines] = useState(invoiceObject?.lines);

  const [taxRate, setTaxRate] = useState(invoiceObject?.taxRate);
  const [taxRatesList, setTaxRatesList] = useState(null);

  const [discount, setdiscount] = useState(
    invoiceObject?.discount?.amountDiscountValue > 0
      ? invoiceObject?.discount
      : null
  );

  const [productName, setProductName] = useState("");
  const [addLoading, setAddLoading] = useState(false);
  const [cardPaymentArray, setCardPaymentArray] = useState(
    invoiceObject?.paymentTypes
  );

  const [searchString, setSearchString] = useState("");
  const [errorString, setErrorString] = useState("");
  const [invoiceDueLoading, setInvoiceDueLoading] = useState(false);
  const [manualLoading, setManualLoading] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [descriptionLoading, setDescriptionLoading] = useState(false);
  const [loading0, setloading0] = useState(false);
  const [selectedCashAccountId, setSelectedCashAccountId] = useState(null);
  const [memo, setMemo] = useState(invoiceObject?.description || "");

  useEffect(() => {
    setLines(invoiceObject?.lines);
  }, [invoiceObject?.lines]);

  if (invoiceObject?.lines?.length > 0) {
    setShowActionBtn(true);
  }

  const productSelectHandler = async (product) => {
    if (loading0) {
      return;
    }
    setloading0(true);
    setVisible(false);
    setAddLoading(true);
    setProductName(product?.name);
    setSearchString("");
    const addLinePayload = {
      customerId: invoiceObject?.customerId,
      invoiceId: invoiceId,
      productId: product?.id,
      description: product?.name,
      taxCode: product?.taxCode,
      quantity: 0,
      priceValue: product?.priceValue,
      imageUrl: product?.imageUrl,
      taxBehavior: "exclusive",
    };

    if (product?.isTaxIncluded) addLinePayload.taxBehavior = "inclusive";

    const { data, error } = await addLineToInvoice(addLinePayload);
    if (data) {
      setProductName("");
      setLines(data?.invoice?.lines);
      setShowProduct(true);
      setShowActionBtn(true);
      setAddAnotherProduct(false);
      setShowAddAnotherProductBtn(true);
      setInvoiceObject(data?.invoice);
      //setUpdateInvoice(!updateInvoicee);
      setloading0(false);
    } else {
      setloading0(false);
      toast.error(error, toastConfiguration);
    }
    setAddLoading(false);
  };

  const invoiceDueHandler = async (value) => {
    setInvoiceDueLoading(true);
    const payload = {
      invoiceId: invoiceId,
      changedField: "daysUntilDue",
      daysUntilDue: value,
    };
    const { data } = await updateInvoice(payload);
    if (data) {
      setInvoiceObject(data?.invoice);
      //setUpdateInvoice(!updateInvoicee);
    }
    setInvoiceDueLoading(false);
  };

  const setManualPayment = async (value, loader, selectedPaymentAccountId) => {
    if (loader === "manual") {
      setManualLoading(true);
    } else {
      setStripeLoading(true);
    }

    const payload = {
      invoiceId: invoiceId,
      changedField: "ispaymentmanual",
      selectedPaymentAccountId: selectedPaymentAccountId || "",
      isPaymentManual: value,
    };
    const { data, error } = await updateInvoice(payload);
    if (data) {
      setInvoiceObject(data?.invoice);
      toast.success("Information saved successfully", toastConfiguration);
    } else {
      toast.error(error, toastConfiguration);
    }

    if (loader === "manual") {
      setManualLoading(false);
    } else {
      setStripeLoading(false);
    }
  };

  const memoChangeHandler = (event) => {
    setMemo(event.target.value);
  };

  const setDescription = async () => {
    setDescriptionLoading(true);
    const payload = {
      invoiceId: invoiceId,
      changedField: "description",
      description: memo,
    };

    const data = await memoUpdate(payload);
    if (data) {
      setInvoiceObject(data?.data?.invoice);
      //setUpdateInvoice(!updateInvoicee);
    }
    setDescriptionLoading(false);
  };

  const saveDiscountHandler = async (id, x, removeDiscount) => {
    const payload = {
      invoiceId: invoiceId,
      couponId: id,
      removeDiscount: removeDiscount || false,
    };

    const { data, error, message } = await updateInvoiceDiscount(payload);
    if (data) {
      setInvoiceObject(data?.invoice);
      setdiscount(data?.invoice?.discount);
      message && toast.success(message, toastConfiguration);
    } else {
      toast.error(error, toastConfiguration);
    }
  };

  const saveTaxRateHandler = async (ids, x, removeTaxRate) => {
    const payload = {
      invoiceId: invoiceId,
      taxRateIds: ids,
      removeTaxRate: removeTaxRate || false,
    };

    const { data, error, message } = await updateInvoiceTaxRate(payload);
    if (data) {
      setInvoiceObject(data?.invoice);
      setTaxRate(data?.invoice?.taxRate);
      message && toast.success(message, toastConfiguration);
    } else {
      toast.error(error, toastConfiguration);
    }
  };

  return (
    <div className={styles.detailsWrap}>
      <Card
        border
        className={cn(styles.card, className)}
        title='Invoice Details'
      /* classTitle="title-blue" */
      >
        <div className={styles.customerHead} style={{ marginBottom: 32 }}>
          <label className={styles.customerLabel}>Customer</label>

          <div className={styles.infoWrapper}>
            <div className={styles.left}>
              {customerImage ? (
                <Image
                  src={customerImage || "/images/content/avatar.jpg"}
                  srcDark={customerImage || "/images/content/avatar.jpg"}
                  className={styles.pic}
                />
              ) : (
                <AlternativeAvatar
                  name={selectedCustomerName}
                  className={"customer-info"}
                />
              )}
              <button
                onClick={() => {
                  setShowAccountInfos(false);
                  setShowActionBtn(false);
                  setShowDetails();
                }}
                className={styles.deleteBtn}
              >
                <Icon name='trash' size='20' fill='#FF4900' />
              </button>
            </div>
            <div className={styles.infoWrap}>
              <div className={styles.info}>
                <p className={styles.label}>Name</p>
                <p className={styles.txt}>{selectedCustomerName}</p>
              </div>
              <div className={styles.info}>
                <p className={styles.label}>Email</p>
                <p className={styles.txt}>{selectedCustomerEmail}</p>
              </div>
              <div className={styles.info}>
                <p className={styles.label}>Mobile</p>
                <p className={styles.txt}>{selectedCustomerPhone}</p>
              </div>
            </div>
          </div>

          {/* <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: 'space-between',
            marginTop: 8
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
              <img
                src={customerImage || "/images/content/avatar.jpg"}
                alt="customer-image"
                className={styles.customerImage}
              />
              <h3 className={styles.name} style={{ marginLeft: 8 }}>{selectedCustomerName}</h3>
            </div>
            <button
              onClick={() => {
                setShowAccountInfos(false);
                setShowActionBtn(false);
                setShowDetails();
              }}
              className={styles.deleteBtn}
            >
              <Icon
                name="trash"
                size="20"
                fill="#FF4900"
              />
            </button>
          </div> */}
        </div>
        {lines?.length < 1 ? (
          <>
            <label className={styles.customerLabel}>Products</label>
            <Search
              className={styles.search}
              inputPlaceholder='Search or add new Product'
              addNewItem={() => setShowNewProductModal(true)}
              addNewBtnTxt='Create new Product'
              visible={visible}
              addLoading={addLoading}
              productName={productName}
              setVisible={setVisible}
              getProducts={getProducts}
              productSelectHandler={productSelectHandler}
              searchString={searchString}
              setSearchString={setSearchString}
              searchNames={searchNamesProducts}
              setSearchNames={setSearchNamesProducts}
              showDetails={() => {
                setShowProduct(true);
                setShowActionBtn(true);
                setUpdateInvoice(true);
              }}
            />
            <CreateNewProduct
              showNewProductModal={showNewProductModal}
              showOneTime={true}
              showSubscription={true}
              searchString={searchString}
              setShowNewProductModal={setShowNewProductModal}
              productSelectHandler={productSelectHandler}
              showProduct={() => {
                setShowProduct(true);
                setShowNewProductModal(false);
                setShowActionBtn(true);
                setUpdateInvoice(true);
              }}
            />
          </>
        ) : (
          <>
            <div className={styles.detailsTxt}>Products</div>
            {lines?.map((x, index) => (
              <div key={index}>
                <Line
                  x={x}
                  index={index}
                  setShowActionBtn={setShowActionBtn}
                  setShowProduct={setShowActionBtn}
                  setUpdateInvoice={setUpdateInvoice}
                  setInvoiceObject={setInvoiceObject}
                  updateInvoicee={updateInvoicee}
                  currencySymbol={invoiceObject?.currencySymbol}
                  invoiceId={invoiceId}
                  customerId={invoiceObject?.customerId}
                  setLines={setLines}
                />
              </div>
            ))}
            {showAddAnotherProductBtn ? (
              <button
                onClick={() => {
                  setAddAnotherProduct(true);
                  setShowAddAnotherProductBtn(false);
                }}
                className={styles.addProductBtn}
              >
                Add another product
              </button>
            ) : null}
            {addAnotherProduct ? (
              <>
                <Search
                  className={styles.search}
                  inputPlaceholder='Search or add new Product'
                  addNewItem={() => setShowNewProductModal(true)}
                  addNewBtnTxt='Create new Product'
                  visible={visible}
                  addLoading={addLoading}
                  setAddAnotherProduct={setAddAnotherProduct}
                  setShowAddAnotherProductBtn={setShowAddAnotherProductBtn}
                  productName={productName}
                  setVisible={setVisible}
                  getProducts={getProducts}
                  productSelectHandler={productSelectHandler}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  searchNames={searchNamesProducts}
                  showDetails={() => {
                    setShowProduct(true);
                    setShowActionBtn(true);
                    setUpdateInvoice(true);
                  }}
                />
                <CreateNewProduct
                  showNewProductModal={showNewProductModal}
                  showOneTime={true}
                  setShowNewProductModal={setShowNewProductModal}
                  productSelectHandler={productSelectHandler}
                  searchString={searchString}
                  showProduct={() => {
                    setShowNewProductModal(false);
                    setShowProduct(true);
                    setUpdateInvoice(true);
                    setShowActionBtn(true);
                    setAddAnotherProduct(false);
                    setShowAddAnotherProductBtn(true);
                  }}
                />
              </>
            ) : null}
          </>
        )}
        {lines?.length > 0 && (
          <>
            <div className={styles.getPaidActionWrap}>
              <div className={styles.getPaidSingleAction}>
                <div className={styles.wrap}>
                  <h4 className={styles.label}>Due</h4>
                  <Dropdown
                    className={styles.dropdownX}
                    classDropdownHead={styles.dropdownHead}
                    onChange={invoiceDueHandler}
                    value={durations}
                    setValue={setDurations}
                    options={intervals}
                    invoiceDueLoading={invoiceDueLoading}
                    small
                  />
                </div>
              </div>
              <div className={styles.getPaidSingleAction}>
                <Coupons
                  discount={discount}
                  setdiscount={setdiscount}
                  saveDiscountHandler={saveDiscountHandler}
                ></Coupons>
              </div>
              {/* <div className={styles.getPaidSingleAction}>

              </div> */}
            </div>
            {customerTaxExempt !== "exempt" && (
              <TaxRates
                taxRate={taxRate}
                dbTaxRates={dbTaxRates}
                saveTaxRateHandler={saveTaxRateHandler}
                taxRatesList={taxRatesList}
                setTaxRatesList={setTaxRatesList}
              ></TaxRates>
            )}
            <div className={styles.wrap}>
              {/* <h4 className={styles.label}>Payments Type</h4> */}
              <div style={{ paddingTop: 10 }}>
                <h2 className={styles.paymentTitle}>
                  Where do you want the money?
                </h2>
                {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }} >
                {manualLoading ?
                  <Spinner size="18" color="gray" loading={setManualLoading} />
                  :
                  <Checkbox
                    value={isManual}
                    onChange={() => {
                      setManualPayment(!isManual, "manual");
                      setIsManual(!isManual);
                      setIsThroughStripe(!isThroughStripe);
                    }}
                  />
                }
                <h2 className={styles.paymentSubtitle}>Send manually. Settle in my bank account</h2>
              </div> */}
                {connect === 1 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 10,
                    }}
                  >
                    {stripeLoading ? (
                      <Spinner
                        size='18'
                        color='gray'
                        loading={setManualLoading}
                      />
                    ) : (
                      <Checkbox
                        value={isThroughStripe}
                        onChange={() => {
                          setManualPayment(false, "stripe");
                          setIsManual(false);
                          setIsThroughStripe(true);
                        }}
                      />
                    )}
                    <h2 className={styles.paymentSubtitle}>Stripe Balance</h2>
                  </div>
                )}
              </div>

              {invoiceObject?.paymentAccounts?.length > 0 ? invoiceObject?.paymentAccounts?.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 10,
                    }}
                    key={index}
                  >
                    {manualLoading && item.id === selectedCashAccountId ? (
                      <Spinner
                        size='18'
                        color='gray'
                        loading={setManualLoading}
                      />
                    ) : (
                      <Checkbox
                        value={item?.isSelected}
                        onChange={() => {
                          setSelectedCashAccountId(item?.id);
                          setManualPayment(true, "manual", item?.id);
                          setIsManual(true);
                          setIsThroughStripe(false);
                        }}
                      />
                    )}
                    <h2 className={styles.paymentSubtitle}>{item?.name}</h2>
                  </div>
                );
              }) :
                <div className={styles.label} style={{ textAlign: 'center', marginTop: 8 }}>No bank account available</div>
              }

            </div>
            <div className={styles.wrap}>
              <div className={styles.label}>Memo</div>
              <div>
                <TextInput
                  onChange={memoChangeHandler}
                  value={memo}
                  descriptionLoading={descriptionLoading}
                  onKeyUp={() => {
                    clearTimeout(typingTimer);
                    typingTimer = setTimeout(
                      setDescription,
                      doneTypingInterval
                    );
                  }}
                  onKeyDown={() => {
                    clearTimeout(typingTimer);
                  }}
                />
              </div>
            </div>

            {sendButton && sendButton}
            {downloadButton && downloadButton}
          </>
        )}
      </Card>
    </div>
  );
};

export default DetailsCustomer;
