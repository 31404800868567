import React, { useEffect, useState } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Row from "./Row";
import DeleteModal from "../../../../components/DeleteModal";
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import { products } from "../../../../mocks/products";
import Checkbox from "../../../../components/Checkbox";
import {
  TriStateCheckbox,
  TriStateContext,
} from "react-tri-state-checkbox";
import "react-tri-state-checkbox/styles/checkbox.css";

// data

import { productStatus } from "../../../../mocks/productStatus";
import { removeProductsData, getProductsData } from "../../../../utils/apiCallHanlder";
import Spinner from '../../../../utils/spinner';
import { zhCN } from "date-fns/locale";
import NoProduct from '../../NoProduct';
import { useHistory } from 'react-router';
import LargeNav from "../../../../components/LargeNav";

let archivedLastId = "";
const tabContent = [
  "Contacts",
  "Products",
  "Templates",
  "Event",
  "Documents",
];

const Table = ({
  className,
  changeAvailableProduct,
  changeAllAvailableProduct,
  changeArchivedProduct,
  changeAllArchivedProduct,
  setArchivedCheckCount,
  archivedCheckCount,
  setActionArchivedProduct,
  editProduct,
  actionBtnHandle,
  availableCheckCount,
  setActionAvailableProduct,
  deleteIdArray,
  setDeleteIdArray,
  setAvailableCheckCount,
  setShowMainContainer,
  setId,
  onNoProductClickHandler,
  setCustomerVisible,
  activeIndex,
  getProductData,
  setIsLoading,
  lastId,
  setActiveIndex,
  loading,
  hasMore,
  productsData,
  setProductsData
}) => {

  const [archivedProductsData, setArchivedProductsData] = useState([]);
  const [archivedHasMore, setArchivedHasMore] = useState(null);

  const [showTypes, setShowTypes] = useState(false);

  const [nameSortOrder, setNameSortOrder] = useState('asc')
  const [priceSortOrder, setPriceSortOrder] = useState('asc')
  const [typeSortOrder, setTypeSortOrder] = useState('asc')
  const [currentSortColumn, setCurrentSortColumn] = useState('name')

  const history = useHistory();

  useEffect(async () => {
    await getProductData(false);
  }, [])



  const getArchivedProductsData = async (isActiveIndexChanged) => {
    if (archivedProductsData.length === 0) {
      archivedLastId = "";
    }
    setIsLoading(true);
    const archivedProductsDataResponse = await getProductsData(30, archivedLastId, true);
    const { data } = archivedProductsDataResponse;
    if (data) {
      setArchivedHasMore(data.isNextPageExist);
      archivedLastId = data?.productList?.at(-1)?.id;
      !isActiveIndexChanged ?
        setArchivedProductsData(archivedProductsData.concat(data.productList))
        : setArchivedProductsData(data.productList)
    }
    setIsLoading(false);
  }


  const productsAvailableId = products
    .filter((x) => !x.isArchived)
    .map((x) => x.id);

  const productsArchivedId = products
    .filter((x) => x.isArchived)
    .map((x) => x.id);


  /* Available product handling */
  const handleAvailableAll = (e) => {
    var ariaStatus = e.target.checked;
    if (ariaStatus === true) {
      const checkboxes = document.getElementsByName('prod-checkbox');

      for (var i = 0, n = checkboxes?.length; i < n; i++) {
        checkboxes[i].checked = true;
      }

      deleteIdArray = [];

      for (let i = 0; i < productsData.length; i++) {
        deleteIdArray.push(productsData[i]?.id);
      }

      setDeleteIdArray([...deleteIdArray])

      setAvailableCheckCount(productsData.length);
    }
    if (ariaStatus === false) {
      const checkboxes = document.getElementsByName('prod-checkbox');

      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }
      setAvailableCheckCount(0);
    }

    if (ariaStatus === "mixed") {
      setActionAvailableProduct(false);
      setAvailableCheckCount(0);
    }
  };

  const handleAvailableProduct = (e, id) => {
    if (e.target.checked) {
      if (availableCheckCount + 1 === productsData?.length) {
        const checkbox = document.getElementsByName('prod-all-checkbox');
        if (checkbox) {
          checkbox[0].checked = true;
        }
      }

      setAvailableCheckCount(availableCheckCount + 1);
      setDeleteIdArray([...deleteIdArray, id])

    }
    if (!e.target.checked) {
      const checkbox = document.getElementsByName('prod-all-checkbox');
      if (checkbox) {
        checkbox[0].checked = false;
      }


      const index = deleteIdArray.indexOf(id);
      if (index > -1) {
        deleteIdArray.splice(index, 1);
      }
      setDeleteIdArray([...deleteIdArray])

      setAvailableCheckCount(availableCheckCount - 1);
    }
  };

  /* Archived product handling */
  const handleArchivedAll = (e) => {
    var ariaStatus = e.target.checked;
    if (ariaStatus === true) {
      const checkboxes = document.getElementsByName('a-prod-checkbox');

      for (var i = 0, n = checkboxes?.length; i < n; i++) {
        checkboxes[i].checked = true;
      }

      deleteIdArray = [];

      for (let i = 0; i < archivedProductsData.length; i++) {
        deleteIdArray.push(archivedProductsData[i]?.id);
      }

      setDeleteIdArray([...deleteIdArray])
      setArchivedCheckCount(archivedProductsData.length);
    }
    if (ariaStatus === false) {
      const checkboxes = document.getElementsByName('a-prod-checkbox');

      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }
      setArchivedCheckCount(0);
    }

    if (ariaStatus === "mixed") {
      setArchivedCheckCount(0);
    }
  };

  const handleArchivedProduct = (e, id) => {
    if (e.target.checked) {
      if (archivedCheckCount + 1 === archivedProductsData?.length) {
        const checkbox = document.getElementsByName('a-prod-all-checkbox');
        if (checkbox) {
          checkbox[0].checked = true;
        }
      }

      setArchivedCheckCount(archivedCheckCount + 1);
      setDeleteIdArray([...deleteIdArray, id])


    }
    if (!e.target.checked) {
      const checkbox = document.getElementsByName('a-prod-all-checkbox');
      if (checkbox) {
        checkbox[0].checked = false;
      }


      const index = deleteIdArray.indexOf(id);
      if (index > -1) {
        deleteIdArray.splice(index, 1);
      }
      setDeleteIdArray([...deleteIdArray])

      setArchivedCheckCount(archivedCheckCount - 1);
    }
  };


  return (
    <>
      <Card className={styles.card}>
        <div style={{ marginTop: 28 }}>
          <LargeNav
            className={styles.typeNav}
            tabOptions={tabContent}
            activeTab={1}
            onClick={(x, index) => {
              if (index === 0) {
                history.push(`/contacts`);
              } else if (index === 1) {
                history.push(`/products`);
              } else {
                setActiveIndex(index + 1);
                history.push(`/studio/${index}`);
              }
            }}
          />

          <LargeNav
            tabOptions={productStatus}
            activeTab={0}
            onClick={(x, index) => {
              if (index === 1) {
                history.push("/discounts")
              } else if (index === 2) {
                history.push("/taxRate")
              }
              // else {
              //   setDeleteIdArray([]);
              //   if (activeIndex !== index + 1) {
              //     if (index === 0) {
              //       lastId = "";
              //       setArchivedProductsData([]);
              //       getProductData(true);
              //     } else {
              //       archivedLastId = "";
              //       setProductsData([]);
              //       getArchivedProductsData(true);
              //     }
              //   }

              //   setActiveIndex(index + 1);
              //   actionBtnHandle();
              // }
            }}
            category="title"
            onlyLarge
          />
        </div>
        {loading && (productsData.length < 1 && archivedProductsData.length < 1) ?
          <div className={styles.spinner} style={{ height: window.outerHeight - 450 }}>
            <Spinner loading={loading} size={50} color={'gray'} />
          </div>
          :
          <>
            <div>
              {activeIndex === 1 && (
                <TriStateContext controls={productsAvailableId}>
                  <div className={cn(styles.wrapper, className)}>
                    {productsData.length > 0 ?
                      <div className={cn(styles.table)}>
                        <div className={cn(styles.row)}>
                          <div className={styles.editOverlayHead}></div>
                          <div></div>
                          <div className={styles.col}>
                            <Checkbox className={styles.checkbox} onChange={handleAvailableAll} name={"prod-all-checkbox"} />
                          </div>
                          <div className={styles.col}>
                            <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                              onClick={async () => {
                                setCurrentSortColumn("name");
                                const currentSortValue = nameSortOrder === 'asc' ? 'desc' : 'asc';
                                setProductsData([]);
                                await getProductData(
                                  true,
                                  "name",
                                  currentSortValue,
                                  true
                                );
                                setNameSortOrder(currentSortValue)
                                setPriceSortOrder('asc');
                                setTypeSortOrder('asc');
                              }}
                            >
                              <span> Name </span>
                              {currentSortColumn === "name" && (
                                <div style={{
                                  marginLeft: 4,
                                  rotate: nameSortOrder === "asc" ? '180deg' : '0deg'
                                }}
                                >
                                  <Icon name="sort_descending" size="20" fill="#C7C5BF" />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={styles.col}>
                            <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                              onClick={async () => {
                                setCurrentSortColumn("price");
                                const currentSortValue = priceSortOrder === 'asc' ? 'desc' : 'asc';
                                setProductsData([]);
                                await getProductData(
                                  true,
                                  "price",
                                  currentSortValue,
                                  true
                                );
                                setPriceSortOrder(currentSortValue)
                                setNameSortOrder('asc');
                                setTypeSortOrder('asc');
                              }}
                            >
                              <span> Price </span>
                              {currentSortColumn === "price" && (
                                <div style={{
                                  marginLeft: 4,
                                  rotate: priceSortOrder === "asc" ? '180deg' : '0deg'
                                }}
                                >
                                  <Icon name="sort_descending" size="20" fill="#C7C5BF" />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={styles.col}>
                            <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                              onClick={async () => {
                                setCurrentSortColumn("type");
                                const currentSortValue = typeSortOrder === 'asc' ? 'desc' : 'asc';
                                setProductsData([]);
                                await getProductData(
                                  true,
                                  "type",
                                  currentSortValue,
                                  true
                                );
                                setTypeSortOrder(currentSortValue)
                                setNameSortOrder('asc');
                                setPriceSortOrder('asc');
                              }}
                            >
                              <span> Type </span>
                              {currentSortColumn === "type" && (
                                <div style={{
                                  marginLeft: 4,
                                  rotate: typeSortOrder === "asc" ? '180deg' : '0deg'
                                }}
                                >
                                  <Icon name="sort_descending" size="20" fill="#C7C5BF" />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={styles.col}>
                            <div
                              onClick={() => setShowTypes(!showTypes)}
                              className={styles.typesHead}
                            >
                              Status <Icon name='arrow-down' size='16' />{" "}
                            </div>
                            {showTypes ? (
                              <div className={styles.typesBody}>
                                <span
                                  onClick={() => {
                                    setActiveIndex(1);
                                    setDeleteIdArray([]);
                                    lastId = "";
                                    setArchivedProductsData([]);
                                    getProductData(true);
                                    setShowTypes(false);
                                    actionBtnHandle()
                                  }}
                                  style={{ color: 'black' }}
                                >
                                  Active
                                </span>
                                <span
                                  onClick={() => {
                                    setActiveIndex(2);
                                    setDeleteIdArray([]);
                                    archivedLastId = "";
                                    setProductsData([]);
                                    getArchivedProductsData(true);
                                    setShowTypes(false);
                                  }}
                                >
                                  Inactive
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {productsData.map((x, index) =>
                          <Row
                            item={x}
                            key={index}
                            name="prod-checkbox"
                            onChange={handleAvailableProduct}
                            isArchived={false}
                            availableCheckCount={availableCheckCount}
                            editAction={
                              <button
                                className={styles.actionItem}
                                onClick={() => {
                                  setId(x.id);
                                  setCustomerVisible(false);
                                }}
                              >
                                <Icon name="edit" size="24" />
                                Edit
                              </button>
                            }
                            archiveAction={
                              <button
                                className={styles.actionItem}
                              // onClick={() => {
                              //   setId(x.id);
                              // }}
                              >
                                <Icon name="inbox" size="24" />
                                Archive product
                              </button>
                            }
                            toggleArchivedText="Archived Product"
                            deleteAction={
                              <DeleteModal
                                id={x.id}
                                deleteFunction={removeProductsData}
                                setShowMainContainer={setShowMainContainer}
                                setValue={true}
                                icon={
                                  <Icon
                                    className={styles.btnIcon}
                                    fill="#FF4900"
                                    name="trash"
                                    size="20"
                                  />
                                }
                                className={styles.deleteBtn}
                                btnClass={cn(styles.btnClass)}
                              />
                            }
                            editProduct={editProduct}
                          />
                        )
                        }
                      </div>
                      :
                      <NoProduct onConfirm={onNoProductClickHandler} />
                    }
                  </div>
                  {hasMore ?
                    <div className={styles.foot}>
                      <button className={cn("button-stroke-red button-small", styles.button)} onClick={() => {
                        let sort = "name";
                        let sortOrder = "asc";

                        if (nameSortOrder === "desc") {
                          sortOrder = "desc";
                        } else if (priceSortOrder === "desc") {
                          sort = "price";
                          sortOrder = "desc"
                        } else if (typeSortOrder === "desc") {
                          sort = "type";
                          sortOrder = "desc";
                        }

                        getProductData(false, sort, sortOrder)
                      }} style={{ width: 200 }}>
                        {loading ?
                          <Spinner loading={loading} size={20} color={'gray'} />
                          :
                          <>
                            <span>See More Products</span>
                            <Icon name="arrow-next" size="20"></Icon>
                          </>
                        }

                      </button>
                    </div>
                    : null
                  }
                </TriStateContext>
              )}
            </div>
            <div>
              {activeIndex === 2 && (
                <TriStateContext controls={productsArchivedId}>
                  <div className={cn(styles.wrapper, className)}>
                    {archivedProductsData.length > 0 ?
                      <div className={cn(styles.table)}>
                        <div className={cn(styles.row)}>
                          <div className={styles.editOverlayHead}></div>
                          <div></div>
                          <div className={styles.col}>
                            {/* <Checkbox className={styles.checkbox} onChange={handleArchivedAll} name={"a-prod-all-checkbox"} /> */}
                          </div>
                          <div className={styles.col}>Name</div>
                          <div className={styles.col}>price</div>
                          <div className={styles.col}>Type</div>
                          <div className={styles.col}>
                            <div
                              onClick={() => setShowTypes(!showTypes)}
                              className={styles.typesHead}
                            >
                              Status <Icon name='arrow-down' size='16' />{" "}
                            </div>
                            {showTypes ? (
                              <div className={styles.typesBody}>
                                <span
                                  onClick={() => {
                                    setActiveIndex(1);
                                    setDeleteIdArray([]);
                                    lastId = "";
                                    setArchivedProductsData([]);
                                    getProductData(true);
                                    setShowTypes(false);
                                    actionBtnHandle()
                                  }}
                                >
                                  Active
                                </span>
                                <span
                                  onClick={() => {
                                    setActiveIndex(2);
                                    setDeleteIdArray([]);
                                    archivedLastId = "";
                                    setProductsData([]);
                                    getArchivedProductsData(true);
                                    setShowTypes(false);
                                  }}
                                  style={{ color: 'black' }}
                                >
                                  Inactive
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {archivedProductsData.map((x, index) =>
                          <Row
                            item={x}
                            isArchived={true}
                            name="a-prod-checkbox"
                            onChange={handleArchivedProduct}
                            archivedCheckCount={archivedCheckCount}
                            key={index}
                            setId={setId}
                            setCustomerVisible={setCustomerVisible}
                            // singleCheck={
                            //   <Checkbox
                            //     onClick={changeArchivedProduct}
                            //     className={cn(x.image ? styles.triCheckboxImg : styles.triCheckbox)}
                            //     id={x.id}
                            //   />
                            // }
                            archiveStatus="Archived"
                            toggleArchivedText="Unarchived Product"

                            deleteAction={
                              <DeleteModal
                                icon={
                                  <Icon
                                    className={styles.btnIcon}
                                    fill="#FF4900"
                                    name="trash"
                                    size="20"
                                  />
                                }
                                className={styles.deleteBtn}
                                btnClass={cn(styles.btnClass)}
                              />
                            }
                            editProduct={editProduct}
                          />
                        )}
                      </div>
                      :
                      <NoProduct onConfirm={onNoProductClickHandler} />
                    }
                  </div>
                  {archivedHasMore ?
                    <div className={styles.foot}>
                      <button className={cn("button-stroke-red button-small", styles.button)} onClick={() => getArchivedProductsData(false)} style={{ width: 250 }}>
                        {loading ?
                          <Spinner loading={loading} size={20} color={'gray'} />
                          :
                          <>
                            <span>See More Archived Products</span>
                            <Icon name="arrow-next" size="20"></Icon>
                          </>
                        }

                      </button>
                    </div>
                    : null
                  }
                </TriStateContext>
              )}

            </div>
          </>
        }
      </Card>
    </>
  );
};

export default Table;
