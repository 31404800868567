/* global Stripe */
import Card from "../../../../../components/Card";
import Icon from "../../../../../components/Icon";
import Modal from "../../../../../components/Modal";
import styles from "./AddNewAccount.module.sass";
import Image from "../../../../../components/Image";
import TextInput from "../../../../../components/TextInput";
import Dropdown from "../../../../../components/Dropdown";
import React, { useState } from "react";
import {
  checkOpenAccountEligibility,
  createLinkAccountSession,
  RequestFinancialAccount
} from "../../../../../utils/apiCallHanlder";
import Spinner from "../../../../../utils/spinner";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../../../utils/utils";
import CashAccount from "../CashAccount";
import { useEffect } from "react";

const newAccountDetails = [
  {
    title: "Open a NEW bank account",
    txt: "GreatWeek.com partnered with Stripe.com who is operating several bank accounts. This new account will be opened through Evolve Bank & Trust and is FDIC insured. ",
    features: [
      "For US clients only",
      "New Account number",
      "New Routing number",
      "FDIC Insured",
      "Free of charge",
      "Limited to 1 per account",
    ],
    btnText: "Open Bank account",
    action: "handleOpenBankAccount",
    type: "BankAccount"
  },
  {
    title: "Link an EXISTING bank account",
    txt: "You can link an existing bank account to your GreatWeek.com platform. The benefit is that you can see all your transactions in one place, which simplifies Accounting and general business operations. ",
    features: [
      "For US clients only",
      "Bank info not visible",
      "Transaction auto sync",
      "Stripe.com handles sync",
      "Free of charge",
      "Link unlimited accounts",
    ],
    btnText: "Link existing Bank account",
    action: "handleExistingBankAccount",
    type: "LinkAccount"
  },
  {
    title: "Open a cash account",
    txt: "A cash account is mainly used for accounting purposes. You can either use the cash account for your “Cash” transactions. Or you can use the cash account for bank account, that is not linked. For instance if you have a foreign bank account. ",
    features: [
      "For any clients",
      "Best for accounting",
      "Optional bank details",
      "Great fro sole proprietors",
      "Free of charge",
      "Link unlimited accounts",
    ],
    btnText: "Open a cash account",
    action: "handleOpenCashAccount",
    type: "CashAccount"
  },
];

const paymentDetails = [
  "IBAN",
  "BIC",
  "Routing number",
  "Account number",
  "Cash",
  "Leave blank",
];

const AddNewAccount = ({ visible, setVisible, gettingBankAccountDetails, setLoading, setCurrentlyActive }) => {
  const [thanksPopup, setThanksPopup] = useState(false);
  const [showCashAccountPopup, setShowCashAccountPopup] = useState(false);

  const [startLoading, setStartLoading] = useState(true);
  const [bankAccountingOpeningLoading, setBankAccountOpeningLoading] = useState(false);
  const [linkAccountLoading, setLinkAccountLoading] = useState(false);

  useEffect(async () => {
    const connectStatus = JSON.parse(localStorage.getItem("connectStatus"));
    const { data } = await checkOpenAccountEligibility({});
    if (data) {

      if (data?.showOpenBankAccount && connectStatus?.status === 1) {
        newAccountDetails[0]["isEnabled"] = true;
      }
      if (data?.showOpenCashAccount) {
        newAccountDetails[2]["isEnabled"] = true;
      }
      if (connectStatus?.status !== 1 || data?.showOpenExternalAccount) {
        newAccountDetails[1]["isEnabled"] = true;
      }
    }

    setStartLoading(false);
  }, [])

  const handleOpenBankAccount = async () => {
    setBankAccountOpeningLoading(true);
    const { data, error } = await RequestFinancialAccount({});
    if (data) {
      setVisible(false);
      setThanksPopup(true);
    } else {
      toast.error(error, toastConfiguration);
      setVisible(false);
    }
    setBankAccountOpeningLoading(false)

  };

  const handleExistingBankAccount = async () => {
    setLinkAccountLoading(true);

    const { data, error } = await createLinkAccountSession();
    setVisible(false);

    if (data) {
      var stripe = Stripe(data?.stripe_pk, {
        stripeAccount: data.stripeAccountId
      });
      stripe.collectFinancialConnectionsAccounts({
        clientSecret: data?.session?.clientSecret
      })
        .then(async function (result) {
          //result.financialConnectionsSession.accounts[0].id
          if (result.error) {
            setLoading(false);
            // Inform the customer that there was an error.
            toast.error(result.error.message || "Failed to add linked account", toastConfiguration);
            // Handle next step based on length of accounts array
          } else if (result.financialConnectionsSession.accounts.length === 0) {
            setLoading(false);
            toast.error("No accounts were linked", toastConfiguration);
          } else {
            const faId = result?.financialConnectionsSession?.accounts[0]?.id
            setCurrentlyActive(faId);
            await gettingBankAccountDetails(true, faId);
            setVisible(false);
            setLoading(false)
          }
        }).catch(() => {
          setLoading(false);
        })
    } else {
      toast.error(error, toastConfiguration);
    }

    setLinkAccountLoading(false);
  };

  const handleOpenCashAccount = () => {
    setVisible(false);
    setShowCashAccountPopup(true);
  };

  return (
    <>
      <CashAccount
        setShowCashAccountModal={setShowCashAccountPopup}
        showCashAccountModal={showCashAccountPopup}
        setVisible={setVisible}
        gettingBankAccountDetails={gettingBankAccountDetails}
        setCurrentlyActive={setCurrentlyActive}
      />

      <Modal
        visible={visible}
        onClose={setVisible}
        outerClassName={styles.outer}
        children={
          <Card
            title={"Add a new account"}
            /* classTitle="title-blue" */
            className={styles.card}
          >
            {startLoading ?
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'
              }}>
                <Spinner size="44" color="gray" />
              </div>
              :

              <div className={styles.row}>
                {newAccountDetails.map((x, index) => (
                  <div key={index} className={styles.col}>
                    <div className={styles.singleAccountInfo}>
                      <div>
                        <div className={styles.title}>{x.title}</div>
                        <p className={styles.txt}>{x.txt}</p>
                      </div>
                      <div>
                        {x.features.map((y, yIndex) => (
                          <div key={yIndex} className={styles.singleFeature}>
                            <Icon name="check" size="20" />
                            {y}
                          </div>
                        ))}
                        {

                          <button
                            onClick={
                              x.action === "handleOpenBankAccount"
                                ? handleOpenBankAccount
                                : x.action === "handleExistingBankAccount"
                                  ? handleExistingBankAccount
                                  : x.action === "handleOpenCashAccount"
                                    ? handleOpenCashAccount
                                    : null
                            }
                            className="button"
                            style={x?.isEnabled ?
                              { width: '100%' }
                              :
                              {
                                width: '100%',
                                pointerEvents: 'none',
                                backgroundColor: 'grey'
                              }}
                          >
                            {(bankAccountingOpeningLoading &&
                              x.type === "BankAccount"
                            ) || (linkAccountLoading &&
                              x.type === "LinkAccount"
                              ) ?
                              <Spinner size="24" color="white" />
                              :
                              x.btnText
                            }
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }
          </Card>
        }
      />
      {/* Thanks popup */}
      <Modal
        visible={thanksPopup}
        onClose={() => setThanksPopup(false)}
        children={
          <div className={styles.thanksPopup}>
            <div className={styles.imgWrap}>
              <Image
                src={"/images/content/lock.png"}
                srcDark={"/images/content/lock.png"}
              />
            </div>
            <h2>Thanks for your request to open a bank account with us!</h2>
            <p>
              We need to verify some things, before we can open up your account,
              and should get back to you within 24hrs. We recommend customers to
              set up an intro session with us. Free of charge.{" "}
            </p>
            <a
              onClick={() => setThanksPopup(false)}
              className="button"
              target="_blank"
              rel="noreferrer"
              href="https://greatweek.com/contact/"
            >
              <Icon name="arrow-right" size="20" />
              Set up a call
            </a>
          </div>
        }
      />
    </>
  );
};

export default AddNewAccount;
