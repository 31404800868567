import React, { useState } from "react";
import styles from "./NewSubscriptions.module.sass";
import Card from "../../../../components/Card";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import SubscriptionsOverview from "../SubscriptionsOverview";
import SubscriptionsCustomer from "./SubscriptionsCustomer";
import SubscriptionsSummary from "./SubscriptionsSummary";
import SaveSuccess from "./Modals/SaveSuccess";
import CancelModal from "./Modals/CancelModal";
import {
  toastConfiguration,
  trackIntercomEvent,
  transactionDateFormatter,
} from "../../../../utils/utils";
import { toast, ToastContainer } from "react-toastify";
import {
  addSubscription,
  cancelSubscription,
  getCustomer,
  GetPaymentMethods,
  getSubscription,
} from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { formatStripeAmount } from "../../../../utils/utils";
import EmailPopup from "../../../../components/Email";

let paymentMethodsToSend = ["ach_credit_transfer", "card", "us_bank_account"];

const NewSubscriptions = ({ id, customerId }) => {
  const [visible, setVisible] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showActionBtn, setShowActionBtn] = useState(false);
  const [showSummary, setShowSummary] = useState(false);

  const [selectedCustomerObject, setSelectedCustomerObject] = useState({});
  const [lines, setLines] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [activeCardPaymentType, setActiveCardPaymentType] = useState(true);
  const [activeAchPaymentType, setActiveAchPaymentType] = useState(true);
  const [billingCycle, setBillingCycle] = useState(new Date());
  const [memo, setMemo] = useState("");

  const [addSubscriptionLoading, setAddSubscriptionLoading] = useState(false);
  const [subscriptionResponse, setSubscriptionResponse] = useState({});

  const [subscription, setSubscription] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [startLoading, setStartLoading] = useState(true);

  const [todayDate, setTodayDate] = useState(true);
  const [dateSelector, setDateSelector] = useState(false);

  const [foreverDate, setForeverDate] = useState(true);
  const [endDateSelector, setEndDateSelector] = useState(false);

  const [invoiceNow, setInvoiceNow] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isSubscriptionSent, setIsSubscriptionSent] = useState(false);

  const [lineId, setLineId] = useState(0);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showSendInvoiceButton, setShowSendInvoiceButton] = useState(false);
  const [selectedCustomerEmail, setSelectedCustomerEmail] = useState('');

  const history = useHistory();

  const intervals = ["Today", "10 Days", "1 Month"];

  // const intervals = [{
  //   value: transactionDateFormatter(new Date(startDate)),
  //   text: "Today",
  //   display: "Today"
  // },
  // {
  //   value: transactionDateFormatter(new Date(new Date().setDate(new Date(startDate).getDate() + 10))),
  //   text: "10 Days",
  //   display: "10 Days"
  // },
  // {
  //   value: transactionDateFormatter(new Date(new Date().setDate(new Date(startDate).getDate() + 30))),
  //   text: "1 Month",
  //   display: "1 Month"
  // }];

  const [durations, setDurations] = useState(intervals[0]);

  useEffect(() => {
    if (durations === "Today") {
      setBillingCycle(new Date(startDate));
    } else if (durations === "10 Days") {
      setBillingCycle(
        new Date(new Date().setDate(new Date(startDate).getDate() + 10))
      );
    } else if (durations === "1 Month") {
      setBillingCycle(
        new Date(new Date().setDate(new Date(startDate).getDate() + 30))
      );
    }
  }, [startDate, durations]);

  useEffect(async () => {
    if (id) {
      const { data } = await getSubscription({
        id,
      });
      if (data) {
        const subscriptionRes = data?.subscription;
        setShowSendInvoiceButton(data?.subscription?.showSendInvoiceButton)
        setSubscription(data?.subscription);
        setSelectedCustomerObject({
          id: subscriptionRes.customerID,
          name: subscriptionRes?.customerName,
          email: subscriptionRes?.customerEmail,
          imageUrl: subscriptionRes?.customerImage,
          phone: subscriptionRes?.customerPhone,
        });
        setLines(subscriptionRes.lineItems);
        if (subscriptionRes?.lineItems?.length > 0) {
          setShowProduct(true);
        }

        paymentMethodsToSend = subscriptionRes?.paymentTypes;
        setMemo(subscriptionRes?.memo);

        if (subscriptionRes?.startBillingOn) {
          if (
            new Date(subscriptionRes?.startBillingOn).setHours(0, 0, 0, 0) ===
            new Date().setHours(0, 0, 0, 0)
          ) {
            setTodayDate(true);
            setDateSelector(false);
          } else {
            setTodayDate(false);
            setDateSelector(true);
          }

          setStartDate(new Date(subscriptionRes.startBillingOn));
          setBillingCycle(new Date(subscriptionRes?.startBillingOn));

          const oneDay = 24 * 60 * 60 * 1000;
          const firstDate = new Date(subscriptionRes.startDate);
          const secondDate = new Date(subscriptionRes?.startBillingOn);

          const diffDays = Math.round(
            Math.abs((firstDate - secondDate) / oneDay)
          );

          if (diffDays === 0) {
            setDurations(intervals[0]);
          } else if (diffDays === 10) {
            setDurations(intervals[1]);
          } else if (diffDays === 30) {
            setDurations(intervals[2]);
          }
        }
        if (subscriptionRes.endDate) {
          setEndDate(new Date(subscriptionRes.endDate));
          setForeverDate(false);
          setEndDateSelector(true);
        } else {
          setForeverDate(true);
          setEndDateSelector(false);
        }

        setShowActionBtn(true);
        setShowSummary(true);
        setShowDetails(true);
      }
    } else if (customerId) {
      const { data } = await getCustomer({ id: customerId });
      if (data) {
        setSelectedCustomerObject({
          id: customerId,
          name: data?.account?.name,
          email: data?.account?.email,
          imageUrl: data?.account?.imageUrl,
          phone: data?.account?.phone,
        });

        setShowActionBtn(true);
        setShowSummary(true);
        setShowDetails(true);
      }
    }

    const paymentMethods = await GetPaymentMethods();
    if (paymentMethods?.data) {
      setPaymentMethods(paymentMethods?.data?.paymentMethods);
    }
    setStartLoading(false);
  }, []);

  useEffect(() => {
    if (subscriptionResponse?.subscription) {
      setIsSubscriptionSent(true);
      setShowEmailModal(true);
    }
  }, [subscriptionResponse])

  const schedulePaymentClickHandler = async () => {
    if (addSubscriptionLoading) {
      return;
    }
    setAddSubscriptionLoading(true);

    for (let i = 0; i < lines.length; i++) {
      let priceVal = formatStripeAmount(lines[i].priceValue);
      let data = priceVal * parseInt(lines[i].quantity);

      if (data > 999999) {
        toast.error(
          "The subscription total amount must be no more than 999,999.99.",
          toastConfiguration
        );
        setAddSubscriptionLoading(false);
        return;
      }
    }

    //const paymentTypes = [];
    // if (activeAchPaymentType) {
    //   paymentTypes.push("ach_credit_transfer")
    // }
    // if (activeCardPaymentType) {
    //   paymentTypes.push("card")
    // }

    let doesHaveRecurring = false;
    let recurringTypes = [];
    for (let i = 0; i < lines?.length; i++) {
      if (lines[i].priceTypeValue === "recurring") {
        doesHaveRecurring = true;
        recurringTypes.push(lines[i]?.recurringType);
      }
    }

    const todaysDate = new Date();

    if (!doesHaveRecurring) {
      toast.error(
        "Atleast one product should have recurring type.",
        toastConfiguration
      );
      //} else if (lines.length > 1 && !recurringTypes.every((val, arr) => val === arr[0])) {
      //toast.error("Products with two different recurring type identified.", toastConfiguration);
    } else if (new Date(endDate).getTime() <= todaysDate.getTime() && endDate) {
      toast.error(
        "End date can't be less than today's date",
        toastConfiguration
      );
    } else if (paymentMethodsToSend?.length < 1) {
      toast.error("Select atleast one payment type", toastConfiguration);
    } else {
      const payload = {
        customerId: selectedCustomerObject?.id,
        customerImage: selectedCustomerObject?.imageUrl,
        customerMobile: selectedCustomerObject?.mobile,
        memo: memo,
        startDate: transactionDateFormatter(startDate),
        startBillingOn: transactionDateFormatter(billingCycle),
        //paymentTypes: paymentMethodsToSend,
        lineItems: lines,
      };

      if (endDate) payload["endDate"] = transactionDateFormatter(endDate);

      const { data, error } = await addSubscription(payload);
      if (data) {
        setSubscriptionResponse(data);
        trackIntercomEvent("subscription-created");
      } else {
        toast.error(
          error || "Error in creating subscription",
          toastConfiguration
        );
      }
    }
    setAddSubscriptionLoading(false);
  };

  const postSubscriptionSend = (subscriptionDetails, recipient) => {
    setSelectedCustomerEmail(recipient);
    setShowSuccessModal(true);
  }
  const emailModalCloseHandler = () => setShowEmailModal(false);
  return (
    <>
      <EmailPopup
        showModal={showEmailModal}
        closeModalHandler={emailModalCloseHandler}
        isInvoice={false}
        id={subscriptionResponse?.subscription?.id || subscription?.id}
        postSendHandler={postSubscriptionSend}
        showSendInvoiceButton={showSendInvoiceButton}
      />
      {visible ? (
        <>
          <div className={styles.title}>
            <h5
              className={styles.backBtn}
              onClick={() => {
                setVisible(false);
                history.push("/subscriptions");
              }}
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ marginTop: -2 }}>
                <Icon name='arrow-left' size='14' />
              </div>
              <label style={{ marginLeft: 8, cursor: "pointer" }}>
                Back To Payments
              </label>
            </h5>
          </div>
          {startLoading ? (
            <div
              className={styles.spinner}
              style={{ height: window.outerHeight - 450 }}
            >
              <Spinner loading={startLoading} size={150} color={"gray"} />
            </div>
          ) : (
            <div
              className={styles.row}
              style={
                subscription?.status === "canceled"
                  ? { pointerEvents: "none" }
                  : {}
              }
            >
              <div className={styles.col}>
                <SubscriptionsCustomer
                  setShowActionBtn={setShowActionBtn}
                  setShowSummary={setShowSummary}
                  selectedCustomerObject={selectedCustomerObject}
                  setSelectedCustomerObject={setSelectedCustomerObject}
                  lines={lines}
                  setLines={setLines}
                  startDate={startDate}
                  paymentMethods={paymentMethods}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  durations={durations}
                  setDurations={setDurations}
                  showDetails={showDetails}
                  paymentMethodsToSend={paymentMethodsToSend}
                  lineId={lineId}
                  setLineId={setLineId}
                  setShowDetails={setShowDetails}
                  activeCardPaymentType={activeCardPaymentType}
                  activeAchPaymentType={activeAchPaymentType}
                  setActiveAchPaymentType={setActiveAchPaymentType}
                  setActiveCardPaymentType={setActiveCardPaymentType}
                  intervals={intervals}
                  memo={memo}
                  subscription={subscription}
                  setMemo={setMemo}
                  showProduct={showProduct}
                  setShowProduct={setShowProduct}
                  id={id}
                  schedulePaymentClickHandler={schedulePaymentClickHandler}
                  addSubscriptionLoading={addSubscriptionLoading}
                  todayDate={todayDate}
                  setTodayDate={setTodayDate}
                  dateSelector={dateSelector}
                  setDateSelector={setDateSelector}
                  foreverDate={foreverDate}
                  setForeverDate={setForeverDate}
                  endDateSelector={endDateSelector}
                  isSubscriptionSent={isSubscriptionSent}
                  setEndDateSelector={setEndDateSelector}
                  cancelSubscription={
                    subscription?.status === "canceled" ? null : (
                      <>
                        {id ?
                          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 12 }}>
                            <button
                              onClick={() => setShowCancelModal(true)}
                              className={cn("button-stroke-red", styles.cancelBtn)}
                              style={{ marginTop: 12, pointerEvents: 'auto' }}
                            >
                              <Icon name={"trash"} size='24' />
                              <span>Cancel Subscription</span>
                            </button>
                            {showSendInvoiceButton && (
                              <button
                                onClick={() => setShowEmailModal(true)}
                                className={cn("button", styles.cancelBtn)}
                                style={{ marginTop: 12, pointerEvents: 'auto' }}
                              >
                                <Icon name={"trash"} size='24' />
                                <span>Send</span>
                              </button>
                            )}
                          </div>
                          : null}

                        <CancelModal
                          showCancelModal={showCancelModal}
                          setShowCancelModal={setShowCancelModal}
                          invoiceNow={invoiceNow}
                          setInvoiceNow={setInvoiceNow}
                          cancelLoading={cancelLoading}
                          onConfirm={async () => {
                            setCancelLoading(true);
                            const { data } = await cancelSubscription({
                              id: id,
                              invoiceNow,
                            });
                            if (data) {
                              history.push("/subscriptions/tabs/2");
                            } else {
                              toast.error(
                                "Error in canceling subscription",
                                toastConfiguration
                              );
                            }
                            setCancelLoading(false);
                          }}
                        />
                      </>
                    )}
                />
              </div>
              {/* <div className={styles.col2}>
                {!showSummary ? (
                  null
                ) : (
                  <SubscriptionsSummary
                    selectedCustomerObject={selectedCustomerObject}
                    lines={lines}
                    startDate={startDate}
                    endDate={endDate}
                    durations={durations}
                    activeAchPaymentType={activeAchPaymentType}
                    activeCardPaymentType={activeCardPaymentType}
                    memo={memo}
                    // Extra props
                    lineId={lineId}
                  />
                )}
              </div> */}
            </div>
          )}
          {/* {showActionBtn ? (
            <div className={styles.row}>
              <Card className={styles.actionCard}>
                <button
                  onClick={() => {
                    history.push("/subscriptions/tabs/1");
                  }}
                  className={cn("button-stroke", styles.cancelBtn)}
                >
                  <Icon name={"arrow-left"} size='24' />
                  <span>Cancel</span>
                </button>
              </Card>
            </div>
          ) : null} */}
          <SaveSuccess
            showSuccessModal={showSuccessModal}
            setShowSuccessModal={setShowSuccessModal}
            selectedCustomerEmail={selectedCustomerEmail}
            onConfirm={() => {
              history.push("/subscriptions/tabs/1");
            }}
          />
        </>
      ) : (
        <SubscriptionsOverview />
      )}
    </>
  );
};

export default NewSubscriptions;
