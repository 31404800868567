import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";
import Icon from "../Icon";

const Dropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  onChange,
  options,
  label,
  tooltip,
  small,
  upBody,
  handleChange,
  handleChangeIndex,
  statementClickHandler,
  paysomeOneClickHandler,
  width,
  showIcon,
  seeAndEditClickHanlder,
  addTransactionClickHandler,
  classOptions,
  bodyStyles,
  noOptionsHandler,
  exportButtonClickHandler
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (value, index) => {
    setValue(value);
    setVisible(false);
    { handleChange && handleChange(value) }
    { onChange && onChange(value); }
    { handleChangeIndex && handleChangeIndex(index) }
    { value === 'Statement' && statementClickHandler && statementClickHandler() }
    { value === 'Link a Bank Account' && paysomeOneClickHandler && paysomeOneClickHandler() }
    { value === "See & edit account details" && seeAndEditClickHanlder && seeAndEditClickHanlder() }
    { value === "Add transaction" && addTransactionClickHandler && addTransactionClickHandler() }
    { value === "Export" && exportButtonClickHandler && exportButtonClickHandler() }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => {
            if (noOptionsHandler && options?.length < 2) {
              noOptionsHandler();
            } else {
              setVisible(!visible)
            }
          }}
        >
          <div className={styles.selection}>{value}</div>
        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}
          style={options.length > 6 ?
            { width: width ? width : '', height: 200, overflowY: "scroll", ...bodyStyles } :
            { width: width ? width : '', ...bodyStyles }
          }
        >
          {options.map((x, index) => {
            return (x !== 'Action' && x !== "Choose") && <div
              className={cn(styles.option, classOptions, {
                [styles.selectioned]: x === value,
              })}
              onClick={() => handleClick(x, index)}
              key={index}
            >
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 16,
                marginLeft: 8,
                alignItems: 'center'
              }}>
                {showIcon &&
                  <Icon
                    name={x?.split(/(\s+)/)[0]?.toLowerCase()}
                    size="20"
                    viewBox={
                      (x === "Statement" || x === "Pay someone") ? "0 0 20 20" : null
                    }
                    fill={x === value ? "#4464E2" : "#6F767E"}
                  />
                }
                <label style={{ cursor: 'pointer' }}>{x}</label>
              </div>
            </div>
          }
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
