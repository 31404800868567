import React from 'react';

const readNotifications = ({ color }) => {
    return <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="25" height="24" fill={color} />
        <rect width="375" height="908" transform="translate(-263 -36)" fill={color} />
        <g clip-path="url(#clip0_0_1)">
            <rect width="375" height="96" transform="translate(-263 -36)" fill={color} />
            <g filter="url(#filter0_i_0_1)">
                <rect width="375" height="96" transform="translate(-263 -36)" fill="#FFFDF9" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 16.5959C2.25 16.2151 2.40471 15.8506 2.67864 15.586L3.70759 14.5922C4.09928 14.2139 4.31977 13.6922 4.31814 13.1476L4.30867 9.9946C4.29543 5.58319 7.86789 2 12.2793 2C16.6814 2 20.25 5.56859 20.25 9.97067L20.25 13.1716C20.25 13.702 20.4607 14.2107 20.8358 14.5858L21.8358 15.5858C22.101 15.851 22.25 16.2107 22.25 16.5858C22.25 17.3668 21.6168 18 20.8358 18H16.25C16.25 20.2091 14.4591 22 12.25 22C10.0409 22 8.25 20.2091 8.25 18H3.65408C2.87863 18 2.25 17.3714 2.25 16.5959ZM10.25 18C10.25 19.1046 11.1454 20 12.25 20C13.3546 20 14.25 19.1046 14.25 18H10.25ZM18.25 13.1716C18.25 14.2324 18.6714 15.2499 19.4216 16L5.12851 16C5.89222 15.246 6.32136 14.2161 6.31813 13.1416L6.30867 9.9886C6.29875 6.6841 8.9748 4 12.2793 4C15.5768 4 18.25 6.67316 18.25 9.97067L18.25 13.1716Z" fill={color} />
                <rect x="14.25" width="10" height="10" rx="5" fill="#FF4900" />
            </g>
        </g>
        <defs>
            <filter id="filter0_i_0_1" x="-263" y="-36" width="375" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="-1" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.956863 0 0 0 0 0.956863 0 0 0 0 0.956863 0 0 0 1 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_1" />
            </filter>
            <clipPath id="clip0_0_1">
                <rect width="375" height="96" fill="white" transform="translate(-263 -36)" />
            </clipPath>
        </defs>
    </svg>
}

export default readNotifications;