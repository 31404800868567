import cn from 'classnames';
import styles from '../../../Customers/CustomersOverview/Table/Table.module.sass'
import Card from "../../../../components/Card";
import Row from './Row';
import { useEffect, useState } from 'react';
import Spinner from '../../../../utils/spinner';
import Icon from '../../../../components/Icon';
import DeleteModal from '../../../../components/DeleteModal';
import TextInput from '../../../../components/TextInput';
import Modal from '../../../../components/Modal';
import { addToDoPipelineColumn, deleteToDoPipelineColumn, getToDoPipelineColumns, updateSortOrderToDoPipeline, updateToDoPipelineColumn } from '../../../../utils/apiCallHanlder';
import { ToastContainer, toast } from 'react-toastify';
import { toastConfiguration } from '../../../../utils/utils';

const AdjustPipeline = ({ setShowAdjustPipeline }) => {
    const [loading, setLoading] = useState(true);
    const [addColumnLoading, setAddColumnLoading] = useState(false);
    const [deleteColumnLoading, setDeleteColumnLoading] = useState(false);

    const [name, setName] = useState('');
    const [items, setItems] = useState([]);
    const [selectedColumnId, setSelectedColumnId] = useState(null);
    const [showAddColumnModal, setShowAddColumnModal] = useState(false);

    useEffect(() => {
        getBoardPipelineColumns();
    }, [])

    const getBoardPipelineColumns = async () => {
        setLoading(true);
        const { data } = await getToDoPipelineColumns();
        if (data) {
            setItems(data?.list || []);
        }
        setLoading(false);
    }

    const closeModalHandler = () => {
        setName('');
        setSelectedColumnId(null)
        setShowAddColumnModal(false);
    }

    const addUpdateColumnHandler = async () => {
        setAddColumnLoading(true);
        const { data, error } = selectedColumnId ?
            await updateToDoPipelineColumn({ toDoPipelineId: selectedColumnId, title: name }) :
            await addToDoPipelineColumn({ title: name });
        if (data) {
            closeModalHandler();
            getBoardPipelineColumns();
            toast.success(`Successfully ${selectedColumnId ? 'updated' : 'added'} column`, toastConfiguration);
        } else {
            toast.error(error || `Error in ${selectedColumnId ? 'updating' : 'adding'} column`, toastConfiguration);
        }
        setAddColumnLoading(false);
    }

    const deleteHandler = async (id, tasks = 0) => {
        if (parseInt(tasks) > 0) {
            toast.error('Column has pending tasks', toastConfiguration);
            return;
        }

        setDeleteColumnLoading(true);
        const { data, error } = await deleteToDoPipelineColumn(id);
        if (data) {
            getBoardPipelineColumns();
            toast.success('Successfully delete column', toastConfiguration);
        } else {
            toast.error(error || 'Error in deleting column', toastConfiguration);
        }
        setDeleteColumnLoading(false);
    }

    const handleDragStart = (event, item) => {
        event.dataTransfer.setData('text/plain', item?.toDoPipelineId);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = async (event, targetItem) => {
        event.preventDefault();

        if (targetItem?.toDoPipelineId) {
            setLoading(true);
            const itemId = event.dataTransfer.getData('text/plain');
            const updatedItems = items;

            const sourceIndex = updatedItems?.findIndex(item => item?.toDoPipelineId === parseInt(itemId || "0"));
            const targetIndex = updatedItems?.findIndex(item => item?.toDoPipelineId === targetItem?.toDoPipelineId);

            const temp = updatedItems[sourceIndex];
            updatedItems[sourceIndex] = updatedItems[targetIndex];
            updatedItems[targetIndex] = temp;

            setItems(updatedItems);

            const ids = updatedItems?.map(item => item?.toDoPipelineId)
            await updateSortOrderToDoPipeline({
                ids: ids
            })
            setLoading(false);
        }
    };

    return (
        <>
            <ToastContainer />
            {/* Add Column Modal */}
            <Modal
                visible={showAddColumnModal}
                outerClassName={styles.modalOuter}
                onClose={closeModalHandler}
                children={
                    <Card
                        title={"Column"}
                        head={
                            <button className='button' onClick={addUpdateColumnHandler} style={{ width: 90 }}>
                                {addColumnLoading ?
                                    <Spinner size="24" color="white" />
                                    :
                                    "Save"
                                }
                            </button>
                        }
                        classCardHead={styles.cardHeader}
                    >
                        <div >
                            <TextInput
                                onChange={(e) => setName(e.target.value)}
                                classInput={styles.input}
                                value={name}
                                label='Name'
                                name='name'
                                type='text'
                                required
                            />
                        </div>

                    </Card>
                }
            />

            <>
                <div
                    style={{ marginBottom: 10, cursor: 'pointer', display: 'flex', gap: 6, alignItems: 'center' }}
                    onClick={() => setShowAdjustPipeline(false)}
                >
                    <Icon name="arrow_backward" size="14" />
                    <span style={{ marginTop: 2 }}>Back</span>
                </div>
                <Card
                    title={"Adjust Pipeline"}
                    id="card-pipe"
                    head={<button className='button' onClick={() => setShowAddColumnModal(true)}>Add Column</button>}
                >
                    {loading ?
                        <div className={styles.spinnerToDo}>
                            <Spinner size="48" color="gray" />
                        </div>
                        :
                        items?.length > 0 ?
                            <div className={cn(styles.table)}>
                                <>
                                    <div className={cn(styles.row)}>
                                        <div className={styles.col}></div>
                                        <div className={styles.col}>Name</div>
                                        <div className={styles.col}># Tasks</div>
                                        <div className={styles.col}></div>
                                    </div>

                                    {items.map((item, index) => {
                                        return <>
                                            <div
                                                key={item.toDoPipelineId}
                                                draggable={true}
                                                onDragStart={(e) => handleDragStart(e, item)}
                                                onDragOver={handleDragOver}
                                                onDrop={(e) => handleDrop(e, item)}
                                                style={{
                                                    position: 'relative',
                                                    display: 'table-row',
                                                    verticalAlign: 'middle',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    setName(item?.title);
                                                    setSelectedColumnId(item?.toDoPipelineId);
                                                    setShowAddColumnModal(true);
                                                }}
                                            >
                                                <Row
                                                    index={index}
                                                    item={item}
                                                />
                                            </div>
                                            <div style={{
                                                position: 'relative',
                                                left: (document?.getElementById("card-pipe")?.clientWidth) - 80,
                                                top: -38
                                            }}>
                                                <DeleteModal
                                                    className={styles.deleteBtn}
                                                    btnClass={styles.btnClassToDo}
                                                    icon={<Icon name="trash" size="24" fill="#FF4900" />}
                                                    btnTxt={' '}
                                                    deleteBtn={
                                                        <button
                                                            className="button"
                                                            style={{ width: 150 }}
                                                            onClick={() => deleteHandler(item?.toDoPipelineId, item?.tasks)}
                                                        >
                                                            {deleteColumnLoading ?
                                                                <Spinner size="24" color="white" loading={deleteColumnLoading} />
                                                                :
                                                                "Yes I'm sure!"
                                                            }
                                                        </button>
                                                    }
                                                />
                                            </div>
                                        </>
                                    })}
                                </>
                            </div>
                            :
                            <div className={styles.noDataToDo}>
                                <span>No Pipeline to show</span>
                            </div>
                    }
                </Card>
            </>

        </>
    )
}

export default AdjustPipeline;