import React, { useEffect, useState, useCallback } from "react";
import styles from "./TransactionSend.module.sass";
import Card from "../../../components/Card";
import cn from "classnames";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Search from "./Search";
import DateInput from "../../../components/DateInput";
import AdjustRecipient from "./AdjustRecipient";
import SaveSuccess from "../../../components/SaveSuccess";
import RecipientOverview from "./RecipientOverview";
import Dropdown from "../../../components/Dropdown_c";
import Spinner from "../../../utils/spinner";
import TransactionPreview from "./TransactionPreview";
import {
  getFinancialAccountDetails,
  getRecipient,
  searchRecipients,
  sendPayment,
  uploadPaySomeoneDocument,
  addRecipient,
} from "../../../utils/apiCallHanlder";
import { Router, useHistory } from "react-router";
import {
  getCurrencyFromLocalStorage,
  toastConfiguration,
} from "../../../utils/utils";
import AmountInCents from "../../../components/AmountInCents";
import DropdownCurrency from "../../../components/Dropdown";
import ReactPhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import Dropzone, { useDropzone } from "react-dropzone";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import CustomerInfoBox from "../../../components/CustomerInfoBox";

const searchNames = ["Recipient #1", "Recipient #2"];
let cardTypes = [
  {
    text: "Wire Transfer",
    value: "us_domestic_wire",
    display: "Wire Transfer",
  },
  {
    text: "ACH Transfer",
    value: "ach",
    display: "ACH Transfer",
  },
];

const Income = ({
  setShowTransactionSend,
  id,
  isComingFromCustomer,
  recipientId,
  recipient,
}) => {
  const [showAdjustRecipient, setShowAdjustRecipient] = useState(
    id && id !== "send" && id !== "all" ? true : false
  );
  const [showRecipient, setShowRecipient] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showRecipientOverview, setShowRecipientOverview] = useState(
    id === "all" ? true : false
  );

  const [selectedRecipient, setSelectedRecipient] = useState({});

  const [startLoading, setStartLoading] = useState(true);
  const [recipientList, setRecipientList] = useState([]);
  const [searchStr, setSearchStr] = useState("");

  const [amount, setAmount] = useState(null);
  const [reference, setReference] = useState("");
  const [selectCardType, setSelectCardType] = useState(cardTypes[0]?.value);

  const [sendLoading, setSendLoading] = useState(false);
  const [errorString, setErrorString] = useState("");

  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const [financialAccountDetail, setFinancialAccountDetail] = useState(null);
  const [financialLoading, setFinancialLoading] = useState(null);

  const [selectCurrency, setSelectCurrency] = useState("USD");

  const [uploading, setUploading] = useState(false);
  const [ocrResultPresent, setOcrResultPresent] = useState(false);
  const [ocrResultIsCreateRecipient, setOcrResultIsCreateRecipient] =
    useState(false);
  const [ocrResultCreateRecipientName, setOcrResultCreateRecipientName] =
    useState("");
  const [createRecipientLoading, setCreateRecipientLoading] = useState(false);
  const [ambigousOcrRecipients, setAmbigousOcrRecipients] = useState([]);
  const [lastOcrData, setLastOcrData] = useState({});
  const [uploadedOcrPreviewDocs, setUploadedOcrPreviewDocs] = useState([]);

  const inputStyle = {
    position: "relative",
    fontSize: "14px",
    letterSpacing: ".01rem",
    marginTop: "0px !important",
    marginBottom: " 0px !important",
    paddingLeft: "48px",
    marginLeft: "0px",
    background: "#F4F4F4",
    border: "none",
    borderRadius: "10px",
    lineHeight: "25px",
    height: "45px",
    width: "100%",
    outline: "none",
  };

  const history = useHistory();

  useEffect(() => {
    setShowAdjustRecipient(id && id !== "send" && id !== "all" ? true : false);
    setShowRecipientOverview(id === "all" ? true : false);
  }, [id]);

  useEffect(async () => {
    searchRecipient(null, setStartLoading);
    setFinancialLoading(true);
    if (recipientId) {
      await loadRecipientById(recipientId);
    }
    const { data } = await getFinancialAccountDetails();
    if (data) {
      setFinancialAccountDetail(data?.accountDetail);
    }
    setFinancialLoading(false);
  }, [recipientId]);

  useEffect(() => {
    if (recipient) {
      presentRecipientToView(recipient);
    }
  }, [recipient]);

  const loadRecipientById = async (recId) => {
    const { data } = await getRecipient({ id: recId });
    if (data) {
      presentRecipientToView(data?.recipient);
    }
  };

  const presentRecipientToView = (recipient, ocrDataLocal) => {
    var ocrData = lastOcrData;
    if (ocrDataLocal) {
      ocrData = ocrDataLocal;
    }
    setSelectedRecipient(recipient);
    setEmail(recipient?.email);
    setMobile(recipient?.mobile);

    setRoutingNumber(recipient?.accountRouting);
    setAccountNumber(recipient?.accountNumber);
    setAddress(
      recipient?.addressLine1 +
        " " +
        recipient?.addressLine2 +
        (recipient?.addressLine2 && ", ") +
        recipient?.addressCity +
        (recipient?.addressCity && ", ") +
        recipient?.addressState +
        (recipient?.addressState && ", ") +
        recipient?.addressPostalCode +
        (recipient?.addressPostalCode && ", ") +
        recipient?.addressCountry
    );
    if (!recipient?.accountNumber && ocrData && ocrData.accountNumber) {
      setAccountNumber(ocrData.accountNumber);
    }
    if (!recipient?.accountRouting && ocrData && ocrData.routingNumber) {
      setRoutingNumber(ocrData.routingNumber);
    }
    if (ocrData && ocrData.invoiceId) {
      setReference("Invoice #: " + ocrData.invoiceId);
    }
    if (ocrData && ocrData.total) {
      setAmount(ocrData.total);
    }

    let isShowRecipient = recipient && recipient !== null;
    setShowRecipient(isShowRecipient);
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (!acceptedFiles || acceptedFiles.length == 0) return;
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);

    var previewDocs = [];
    previewDocs.push({
      uri: window.URL.createObjectURL(acceptedFiles[0]),
      fileName: acceptedFiles[0].name,
    });
    setUploadedOcrPreviewDocs(previewDocs);

    setUploading(true);

    const { data } = await uploadPaySomeoneDocument(formData);
    if (data) {
      setLastOcrData(data);
      setOcrResultPresent(true);
      if (data.isRecipientFound) {
        if (data.recipients.length == 1) {
          presentRecipientToView(data.recipients[0], data);
        } else {
          setAmbigousOcrRecipients(data.recipients);
        }
      } else {
        setOcrResultIsCreateRecipient(true);
        setOcrResultCreateRecipientName(data.name);
      }
    }
    setUploading(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const searchRecipient = async (searchString, setLoading) => {
    searchString = searchString ? searchString : "";
    setLoading(true);
    const { data } = await searchRecipients(4, "", searchString);
    if (data) {
      setRecipientList(data.list);
    }
    setLoading(false);
  };

  const createRecipientFromOcr = async () => {
    setCreateRecipientLoading(true);
    const payload = {
      name: ocrResultCreateRecipientName,
      email: "",
      mobile: "",
      imageUrl: "",
      accountType: "individual",
      accountHolderType: "individual",
      accountNumber: "",
      accountRouting: "",
      addressCountry: "US",
      addressLine1: "",
      addressLine2: "",
      addressCity: "",
      addressState: "AL",
      addressPostalCode: "",
    };

    const { data, error } = await addRecipient(payload);

    if (data && data.recipient) {
      presentRecipientToView(data.recipient);
    }
    setCreateRecipientLoading(false);
  };

  const sendPaymentHandler = async () => {
    if (amount && amount !== "") {
      if (amount > 999999) {
        toast.error(
          "The total amount must be no more than 999,999.99.",
          toastConfiguration
        );
        return;
      }
      if (reference !== "") {
        if (sendLoading) {
          return;
        }
        setSendLoading(true);

        const { data, error } = await sendPayment({
          recipientId: selectedRecipient?.id,
          transactionType: selectCardType,
          amountValue: parseInt(amount * 100),
          email: email,
          mobile: mobile,
          accountNumber: accountNumber,
          accountRouting: routingNumber,
          notes: reference,
        });

        if (data) {
          setShowSuccessModal(true);
        } else {
          toast.error(error, toastConfiguration);
          //setErrorString(error);
        }

        setSendLoading(false);
      } else {
        setErrorString("Reference is required.");
      }
    } else {
      setErrorString("Amount is required.");
    }
  };

  let currency = getCurrencyFromLocalStorage();

  return (
    <>
      {!showAdjustRecipient && !showRecipientOverview ? (
        <>
          <div className={styles.title}>
            <h5
              className={styles.backBtn}
              onClick={() => history.push("/transactions")}
            >
              <Icon name='arrow-left' size='14' /> Back to transactions
            </h5>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <Card
                className={styles.card}
                classCardHead={styles.head}
                title='Pay someone'
                border
                /* classTitle={cn("title-blue", styles.title)} */
                // head={
                //   <div className={styles.dotsBtnWrap}>
                //     <button
                //       onClick={() => history.push("/transactions")}
                //       className={styles.deleteBtn}
                //     >
                //       <Icon name="close" size="20" />
                //     </button>
                //   </div>
                // }
              >
                {!showRecipient ? (
                  <>
                    {ocrResultPresent ? (
                      <div className={styles.field}>
                        {ocrResultIsCreateRecipient ? (
                          <>
                            <span>Create recipient:</span>
                            {ocrResultCreateRecipientName &&
                            ocrResultCreateRecipientName != "" ? (
                              <button
                                onClick={createRecipientFromOcr}
                                className={"button"}
                                style={{
                                  width: "100%",
                                  marginTop: 24,
                                }}
                              >
                                {createRecipientLoading ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Spinner size='18px' color='white' />
                                  </div>
                                ) : (
                                  <>
                                    <Icon name='user' size='24px' />
                                    <span>{ocrResultCreateRecipientName}</span>
                                  </>
                                )}
                              </button>
                            ) : (
                              <span>Recipient name was not detected.</span>
                            )}
                          </>
                        ) : (
                          <>
                            {ambigousOcrRecipients &&
                            ambigousOcrRecipients.length > 0 ? (
                              <span>Select recipient:</span>
                            ) : null}

                            {ambigousOcrRecipients.map(
                              (dtoRecipient, index) => (
                                <button
                                  key={index}
                                  onClick={() =>
                                    presentRecipientToView(dtoRecipient)
                                  }
                                  className={"button"}
                                  style={{
                                    width: "100%",
                                    marginTop: 24,
                                  }}
                                >
                                  <>
                                    <Icon name='user' size='24' />
                                    <span>{dtoRecipient.name}</span>
                                  </>
                                </button>
                              )
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div className={styles.field}>
                        <div {...getRootProps({ className: styles.dropzone })}>
                          <div style={{ left: "50%", top: "50%" }}>
                            <Spinner
                              loading={uploading}
                              color='gray'
                              size='45px'
                            />
                          </div>
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p>Drop invoice here ...</p>
                          ) : (
                            <p>Drop invoice here, or click to select</p>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ) : null}

                <div className={styles.field}>
                  <div className={styles.fieldLabel}>
                    <span>Recipient</span>
                  </div>
                  {showRecipient ? (
                    <>
                      <CustomerInfoBox
                        imageUrl={selectedRecipient?.imageUrl}
                        name={selectedRecipient?.name}
                        email={selectedRecipient?.email}
                        mobile={selectedRecipient?.mobile}
                        onClick={() => {
                          setSelectedRecipient(null);
                          setShowRecipient(false);
                        }}
                      />
                      {/* <div className={styles.recipientHead}>
                        <div style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: 'space-between',
                          marginTop: 8
                        }}>
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}>
                            <img
                              src={selectedRecipient?.imageUrl || "/images/content/avatar.jpg"}
                              alt="customer-image"
                              className={styles.customerImage}
                            />
                            <h3
                              className={styles.email}
                              style={{ marginLeft: 8 }}
                            >{selectedRecipient?.name}</h3>
                          </div>
                          <button
                            onClick={() => {
                              setSelectedRecipient(null);
                              setShowRecipient(false)
                            }}
                            className={styles.deleteBtn}
                          >
                            <Icon
                              name="trash"
                              size="20"
                              fill="#FF4900"
                            />
                          </button>
                        </div>
                      </div> */}
                    </>
                  ) : (
                    <Search
                      className={styles.search}
                      inputPlaceholder='Search or add new recipient'
                      addNewBtnTxt='Create new recipient'
                      searchNames={searchNames}
                      recipientList={recipientList}
                      startLoading={startLoading}
                      searchStr={searchStr}
                      setSearchStr={setSearchStr}
                      searchRecipient={searchRecipient}
                      addNewRecipient={() => {
                        //setShowAdjustRecipient(true);
                        history.push({
                          pathname: "/contacts/add",
                          state: { comingFromRecipient: true },
                        });
                      }}
                      onSaveClickHandler={(x) => {
                        presentRecipientToView(x);
                        // setSelectedRecipient(x);
                        // setEmail(x?.email);
                        // setMobile(x?.mobile);
                        // setRoutingNumber(x?.accountRouting);
                        // setAccountNumber(x?.accountNumber);
                        // setAddress(x?.addressLine1 + " " +
                        //   x?.addressLine2 + ", " + x?.addressCity + ", " +
                        //   x?.addressState + ", " +
                        //   x?.addressPostalCode + ", " + x?.addressCountry
                        // )
                        // setShowRecipient(true);
                      }}
                      setShowRecipient={setShowRecipient}
                      setShowRecipientOverview={setShowRecipientOverview}
                    />
                  )}
                </div>
                {selectedRecipient &&
                Object.keys(selectedRecipient)?.length > 0 ? (
                  <>
                    <div className={styles.field}>
                      <div className={styles.fieldLabel}>
                        <span>From account</span>
                      </div>

                      <div className={styles.details}>
                        {financialLoading ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                              marginLeft: -16,
                            }}
                          >
                            <Spinner size='24px' color='gray' />
                          </div>
                        ) : (
                          <>
                            <div className={styles.subtitle}>
                              {true
                                ? financialAccountDetail?.name +
                                  "  (****" +
                                  financialAccountDetail?.accountNumberLast4 +
                                  ")"
                                : financialAccountDetail?.name}
                            </div>
                            <div className={styles.counter}>
                              <AmountInCents
                                amountInCents={
                                  financialAccountDetail?.balances?.usd
                                }
                                subtitleEmValue={"0.5em"}
                                currency={"USD"}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={styles.field}>
                      <div className={styles.fieldLabel}>
                        <span>Amount</span>
                      </div>
                      <div className={styles.currencyContainer}>
                        <div style={{ width: 100 }}>
                          <TextInput
                            value={amount}
                            onChange={(e) => {
                              setErrorString("");
                              setAmount(e.target.value);
                            }}
                            name='amount'
                            onWheel={(e) => e.target.blur()}
                            type='number'
                            required
                          />
                        </div>
                        <div style={{ width: 100 }}>
                          <DropdownCurrency
                            className={styles.dropdown}
                            classDropdownHead={styles.dropdownHead}
                            value={selectCurrency}
                            setValue={setSelectCurrency}
                            options={["USD"]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.field}>
                      <div className={styles.fieldLabel}>
                        <span>Type</span>
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          width: 140,
                        }}
                      >
                        <Dropdown
                          className={styles.dropdown}
                          classDropdownHead={styles.dropdownHead}
                          value={selectCardType}
                          setValue={setSelectCardType}
                          options={cardTypes}
                        />
                      </div>
                    </div>
                    <div className={styles.field}>
                      <TextInput
                        label='Email'
                        value={email}
                        onChange={(e) => {
                          setErrorString("");
                          setEmail(e.target.value);
                        }}
                        name='email'
                        type='text'
                        required
                      />
                    </div>
                    <div className={styles.field}>
                      <div className={styles.fieldLabel}>
                        <span>Mobile</span>
                      </div>
                      <ReactPhoneInput
                        inputExtraProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{ border: "none" }}
                        inputStyle={inputStyle}
                        country={"us"}
                        countryCodeEditable={false}
                        value={mobile}
                        onChange={(value) => setMobile(value)}
                      />
                      {/* <TextInput
                    label="Mobile"
                    value={mobile}
                    onChange={(e) => {
                      setErrorString("");
                      setMobile(e.target.value)
                    }}
                    name="mobile"
                    type="text"
                    required
                  /> */}
                    </div>

                    <div
                      className={styles.field}
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <div
                        className={styles.fieldLabel}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: 8,
                        }}
                      >
                        <span>Recipient address</span>
                        <div
                          style={{
                            marginLeft: 8,
                            cursor: "pointer",
                            pointerEvents: "auto",
                          }}
                          onClick={() => {
                            if (selectedRecipient?.id) {
                              history.push(
                                `/transactions/${selectedRecipient?.id}`
                              );
                            } else {
                              setShowAdjustRecipient(true);
                            }
                          }}
                        >
                          <Icon
                            name='opening'
                            size='24'
                            viewBox='0 0 24 24'
                            fill='#6F767E'
                          />
                        </div>
                      </div>
                      <textarea
                        value={address}
                        className={styles.input}
                        onChange={(e) => {
                          setErrorString("");
                          setAddress(e.target.value);
                        }}
                        style={{ height: 110 }}
                        name='address'
                        type='text'
                        required
                      />
                    </div>

                    <div className={styles.field}>
                      <TextInput
                        label='Routing number'
                        value={routingNumber}
                        onChange={(e) => {
                          setRoutingNumber("");
                          setRoutingNumber(e.target.value);
                        }}
                        name='routingNumber'
                        type='number'
                        // onWheel={(e) => e.target.blur()}
                        required
                      />
                    </div>
                    <div className={styles.field}>
                      <TextInput
                        label='Account number'
                        value={accountNumber}
                        onChange={(e) => {
                          setErrorString("");
                          setAccountNumber(e.target.value);
                        }}
                        name='accountNumber'
                        onWheel={(e) => e.target.blur()}
                        type='number'
                        required
                      />
                    </div>
                    <div className={styles.field}>
                      <TextInput
                        label='Reference'
                        value={reference}
                        onChange={(e) => {
                          setErrorString("");
                          setReference(e.target.value);
                        }}
                        name='reference'
                        type='text'
                        required
                      />
                    </div>

                    <button
                      onClick={sendPaymentHandler}
                      className={"button"}
                      style={{
                        width: "100%",
                        marginTop: 24,
                        backgroundColor: "#490000",
                      }}
                    >
                      {sendLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Spinner size='18px' color='white' />
                        </div>
                      ) : (
                        <>
                          <Icon name='rocket' size='24' />
                          <span>Send</span>
                        </>
                      )}
                    </button>
                    {errorString !== "" ? (
                      <div className={styles.errorContainer}>
                        <span className={styles.errorMessage}>
                          {errorString}
                        </span>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </Card>
            </div>

            {ocrResultPresent ? (
              <>
                <div className={styles.colDocPreview}>
                  <DocViewer
                    documents={uploadedOcrPreviewDocs}
                    config={{
                      header: {
                        disableHeader: true,
                        disableFileName: true,
                        retainURLParams: false,
                      },
                    }}
                    pluginRenderers={DocViewerRenderers}
                    style={{ borderRadius: "10px" }}
                  />
                </div>
              </>
            ) : null}

            {/* <div className={styles.col}>
              {selectedRecipient && selectedRecipient.id && <TransactionPreview
                amount={amount}
                selectedRecipient={selectedRecipient}
                reference={reference}
                selectCardType={selectCardType}
                cardTypes={cardTypes}
              />}
            </div> */}
            {showRecipient ? (
              <div className={styles.col12}>
                <SaveSuccess
                  showSuccessModal={showSuccessModal}
                  onClose={() => {
                    history.push("/transactions");
                  }}
                  headText={currency + amount}
                  text={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          color: "#6F767E",
                        }}
                      >
                        {" "}
                        Notification was sent to:
                      </span>
                      <span>{selectedRecipient?.email}</span>
                    </div>
                  }
                  onConfirm={() => {
                    history.push("/transactions");
                  }}
                />
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      {showAdjustRecipient && !showRecipientOverview ? (
        <AdjustRecipient
          setShowTransactionSend={setShowTransactionSend}
          setShowRecipient={setShowRecipient}
          id={id}
          setShowAdjustRecipient={setShowAdjustRecipient}
          setSelectedRecipient={setSelectedRecipient}
          isComingFromCustomer={isComingFromCustomer}
        />
      ) : null}
      {!showAdjustRecipient && showRecipientOverview ? (
        <RecipientOverview
          setShowTransactionSend={setShowTransactionSend}
          setShowRecipientOverview={setShowRecipientOverview}
          setShowRecipient={setShowRecipient}
        />
      ) : null}
    </>
  );
};

export default Income;
