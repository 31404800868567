import React, { useState } from "react";
import styles from "./AddOns.module.sass";
import Card from "../../../../../components/Card";
import {
  activateCompanyFeature,
  connectWithGoogleExternalInt,
  deActivateCompanyFeature,
  disconnectGoogleEmails,
  isGoogleConnected,
} from "../../../../../utils/apiCallHanlder";
import { toast, ToastContainer } from "react-toastify";
import { CheckIfEnabledInStripeAccountType, getCompanyDataValue, getConnectStatus, getReturnUrl, reverifyHandler, toastConfiguration } from "../../../../../utils/utils";
import Spinner from "../../../../../utils/spinner";
import { useEffect } from "react";
import cn from 'classnames'


let freePlan = [];
let growthPlan = [];
let activatedFeatures = null;
let connect = 1;
let isGoogleConnectedBit = false;

const AddOns = (
  {
    isFeatureChanged,
    setIsFeatureChanged
  }
) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentlyClicked, setCurrentlyClicked] = useState(null);
  const stripeAccountType =  getCompanyDataValue('stripeAccountType')

  useEffect(async () => {
    if (!activatedFeatures) {
      const { data } = await isGoogleConnected({});
      isGoogleConnectedBit = data?.isGoogleConnected
    }

    connect = getConnectStatus()?.status;
    activatedFeatures = JSON.parse(localStorage.getItem("activatedFeatures") || "");

    freePlan = [
      {
        title: 'Dashboard',
        subHeading: 'Get a business performance snapshot. Best with all categories enabled.',
        isActivated: activatedFeatures?.isDashboardEnabled,
        activateFeature: () => {
          setCurrentlyClicked('Dashboard');
          activateHandler('Dashboard', 'isDashboardEnabled');
        },
        deactivateFeature: () => {
          setCurrentlyClicked('Dashboard');
          deactivateHandler('Dashboard', 'isDashboardEnabled');
        },
        stripeAccountTypes: [],
      },
      {
        title: 'Property',
        subHeading: 'Set up yor Projects to manage ROI and get funding.',
        isActivated: activatedFeatures?.isPropertyEnabled,
        activateFeature: () => {
          setCurrentlyClicked('Property');
          activateHandler('Property', 'isPropertyEnabled');
        },
        deactivateFeature: () => {
          setCurrentlyClicked('Property');
          deactivateHandler('Property', 'isPropertyEnabled');
        },
        stripeAccountTypes: ['custom'],
      },
      {
        title: 'To-Do',
        subHeading: 'Manage your tasks at one place',
        isActivated: activatedFeatures?.isTodoTaskEnabled,
        activateFeature: () => {
          setCurrentlyClicked('TodoTask');
          activateHandler('TodoTask', 'isTodoTaskEnabled');
        },
        deactivateFeature: () => {
          setCurrentlyClicked('TodoTask');
          deactivateHandler('TodoTask', 'isTodoTaskEnabled');
        },
        stripeAccountTypes: [],
      },
      {
        title: 'Get Paid',
        subHeading: 'An intuitive invoicing tool packed with features, from rent collection to beyond.',
        isActivated: activatedFeatures?.isGetPaidEnabled,
        activateFeature: () => {
          setCurrentlyClicked('GetPaid');
          activateHandler('GetPaid', 'isGetPaidEnabled');
        },
        deactivateFeature: () => {
          setCurrentlyClicked('GetPaid');
          deactivateHandler('GetPaid', 'isGetPaidEnabled');
        },
        stripeAccountTypes: ['custom', 'standard'],
      },
      {
        title: 'Accounting',
        subHeading: 'Manage events, emails, contracts, and tasks, in one place, with our easy to use CRM. ',
        isActivated: activatedFeatures?.isAccountingEnabled,
        activateFeature: () => {
          setCurrentlyClicked('Accounting');
          activateHandler('Accounting', 'isAccountingEnabled');
        },
        deactivateFeature: () => {
          setCurrentlyClicked('Accounting');
          deactivateHandler('Accounting', 'isAccountingEnabled');
        },
        stripeAccountTypes: ['custom', 'standard'],
      },
      {
        title: 'CRM',
        subHeading: 'Get your business organized and humming with our powerful CRM service. Stay on top of events, emails, contracts, and track customer feedback via questionnaires – all in one place!.',
        isActivated: activatedFeatures?.isStudioEnabled,
        activateFeature: () => {
          setCurrentlyClicked('Studio');
          activateHandler('Studio', 'isStudioEnabled');
        },
        deactivateFeature: () => {
          setCurrentlyClicked('Studio');
          deactivateHandler('Studio', 'isStudioEnabled');
        },
        stripeAccountTypes: ['custom', 'standard'],
      },
      {
        title: 'Cards',
        subHeading: 'Get organized with debit cards for each staff member, contractor, project and/or supplier! Make tracking expenses easier than ever - all in one convenient place and piece of mind.',
        isActivated: activatedFeatures?.isCardsEnabled,
        activateFeature: () => {
          setCurrentlyClicked('Cards');
          activateHandler('Cards', 'isCardsEnabled');
        },
        deactivateFeature: () => {
          setCurrentlyClicked('Cards');
          deactivateHandler('Cards', 'isCardsEnabled');
        },
        stripeAccountTypes: ['custom'],
      },
      {
        title: 'Stripe',
        subHeading: 'Link to or set up Stripe. This lets you offer clients varied payment options like cards and ACH and unlocks features like Visa Business Expense cards and more.',
        isActivated: connect === 0 ? false : true,
        activateFeature: async () => {
          setCurrentlyClicked('Stripe');
          reverifyHandler(setLoading);
        },
        deactivateFeature: () => { },
        stripeAccountTypes: ['custom', 'standard'],
      },
      {
        title: 'Google',
        subHeading: 'Connect your Google account to handle events, emails with contacts, and more. Its a game-changer for productivity!',
        isActivated: isGoogleConnectedBit,
        activateFeature: async () => {
          setCurrentlyClicked('Google');
          setLoading(true);
          const ReturnUrl = getReturnUrl("settings/Billing");
          const signInResponse = await connectWithGoogleExternalInt({ ReturnUrl });
          const { data } = signInResponse;
          if (data) {
            window.location = data.redirectURL;
          }
          setLoading(false);
        },
        deactivateFeature: async () => {
          setCurrentlyClicked('Google');
          setLoading(true);
          const { data } = await disconnectGoogleEmails({});
          if (data) {
            isGoogleConnectedBit = false;
            setIsFeatureChanged(!isFeatureChanged)
            toast.success(
              "Successfully unsynced google mail",
              toastConfiguration
            );
          } else {
            toast.error("Error in unsyncing google mail", toastConfiguration);
          }
          setLoading(false);
        },
        stripeAccountTypes: ['custom', 'standard'],
      },

    ];


    freePlan = freePlan.filter(item => CheckIfEnabledInStripeAccountType(item, stripeAccountType));
  



    // growthPlan = [
    //   {
    //     title: 'Bank Account',
    //     subHeading: 'Secure your financial future with an FDIC-insured bank account from us. Our services guarantee the smoothest way to send and receive payments!'
    //   },
    //   {
    //     title: 'Linked bank Account',
    //     subHeading: 'In free plan you can link one account. In Premium Plan you can link unlimited amount of plans. '
    //   },
    //   {
    //     title: 'Multiple companies',
    //     subHeading: 'Flip between different companies with the click of a button.'
    //   },
    //   {
    //     title: 'Accounting',
    //     subHeading: 'Quickbooks may be the industry standard for accounting software but now theres an easier option that offers all the same features without the hassle. All your transactions are automatically synced and you can reconcile your chart of accounts.',
    //     isActivated: activatedFeatures?.isAccountingEnabled,
    //     activateFeature: () => {
    //       setCurrentlyClicked('Accounting');
    //       activateHandler('Accounting', 'isAccountingEnabled');
    //     },
    //     deactivateFeature: () => {
    //       setCurrentlyClicked('Accounting');
    //       deactivateHandler('Accounting', 'isAccountingEnabled');
    //     }
    //   }
    // ]

    setIsLoaded(true);

  }, [isFeatureChanged])

  const activateHandler = async (featureName, apiFeatureName) => {
    const { data, error } = await activateCompanyFeature({
      featureName
    })
    if (data) {
      toast.success(`${featureName} Enabled Successfully`, toastConfiguration);

      Object.keys(activatedFeatures)?.map(item => {
        if (item === apiFeatureName) {
          activatedFeatures[item] = !activatedFeatures[item];
        }
      })

      localStorage.setItem("activatedFeatures", JSON.stringify(activatedFeatures))
      setTimeout(setIsFeatureChanged(!isFeatureChanged), 2000);
    } else {
      toast.error(error || `Error in deactivating ${featureName}`, toastConfiguration);
    }

    setLoading(false);
    setCurrentlyClicked(null);
  }

  const deactivateHandler = async (featureName, apiFeatureName) => {
    setLoading(true);
    const { data, error } = await deActivateCompanyFeature({
      featureName
    })
    if (data) {
      toast.success(`${featureName} Disabled Successfully`, toastConfiguration);

      Object.keys(activatedFeatures)?.map(item => {
        if (item === apiFeatureName) {
          activatedFeatures[item] = !activatedFeatures[item];
        }
      })

      localStorage.setItem("activatedFeatures", JSON.stringify(activatedFeatures))
      setTimeout(setIsFeatureChanged(!isFeatureChanged), 2000);
    } else {
      toast.error(error || `Error in deactivating ${featureName}`, toastConfiguration);
    }

    setLoading(false);
    setCurrentlyClicked(null);
  }

  return (
    <>
      {!isLoaded ?
        <div className={styles.spinner}>
          <Spinner size="48" color="gray" />
        </div>
        :
        (
          <>
            <Card className={styles.card} title="Free Plan">
              <div className={styles.features}>
                <div className={styles.featureWrap}>
                  {freePlan?.map((item, index) => {
                    return <div className={styles.freePlanContainer} key={index}>
                      <Card
                        className={
                          cn(styles.itemCard,
                            { [styles.activeCard]: item?.isActivated })
                        }
                        classTitle={
                          cn(styles.itemCardTitle, { [styles.activeTitle]: item?.isActivated })
                        }
                        classCardHead={styles.itemCardHead}
                        title={item.title}
                      >
                        <div
                          style={{ color: !item?.isActivated ? '#464542' : '#FF4900' }}
                          className={styles.subHeading}
                        >
                          {item?.subHeading}
                        </div>
                        {item?.isActivated ?
                          item?.title === "Stripe" ?
                            <button
                              className={`button ${styles.deActivateButton}`}
                              style={{ pointerEvents: 'none' }}
                            >{
                                (connect === 3 || connect === 2) ? "Verification Pending" :
                                  "Activated"
                              }
                            </button>
                            :
                            <button
                              className={`button-stroke-red ${styles.deActivateButton}`}
                              onClick={item?.deactivateFeature}
                            >
                              {(currentlyClicked === item?.title && loading) ?
                                <Spinner size="24" color="gray" />
                                :
                                item?.title === "Google" ? "Disconnect" : "Deactivate"
                              }
                            </button>
                          :
                          <button
                            className={`button ${styles.activateButton}`}
                            onClick={item?.activateFeature}
                          >
                            {(currentlyClicked === item?.title && loading) ?
                              <Spinner size="24" color="white" />
                              :
                              item?.title === "Google" ? "Connect" : "Activate"
                            }
                          </button>
                        }
                      </Card>
                    </div>
                  })}
                </div>
              </div>
            </Card>
            {/* <Card className={styles.card} title="Growth Plan">
              <div className={styles.features}>
                <div className={styles.featureWrap}>
                  {growthPlan?.map((item, index) => {
                    return <div className={styles.freePlanContainer} key={index}>
                      <Card
                        className={styles.itemCard}
                        classTitle={styles.itemCardTitle}
                        classCardHead={styles.itemCardHead}
                        title={item.title}
                      >
                        <div className={styles.subHeading}>
                          {item?.subHeading}
                        </div>
                        {item?.isActivated ?
                          <button
                            className={`button-stroke-red ${styles.deActivateButton}`}
                            onClick={item?.deactivateFeature}
                          >
                            {(currentlyClicked === item?.title && loading) ?
                              <Spinner size="24" color="gray" />
                              :
                              "Deactivate"
                            }
                          </button>
                          :
                          <button
                            className={`button ${styles.activateButton}`}
                            onClick={item?.activateFeature}
                          >
                            {(currentlyClicked === item?.title && loading) ?
                              <Spinner size="24" color="white" />
                              :
                              "Activate"
                            }
                          </button>
                        }
                      </Card>
                    </div>
                  })}
                </div>
              </div>
            </Card> */}
          </>
        )}
    </>
  );
};

export default AddOns;
