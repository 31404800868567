import React, { useEffect, useState, useMemo } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../../Item";
import Checkbox from "../../../../components/Checkbox";
import Icon from "../../../../components/Icon";
import TextInput from "../../../../components/TextInput";
import Notifications from "../../Notifications";
import Image from "../../../../components/Image";
import UpdatePasswordModal from "./UpdatePassword";
import Select from "react-select";
import countryList from "react-select-country-list";
import { STRIPE_COUNTRIES } from "../../../../utils/appConstanst";
import {
  emailHandleChange,
  getReturnUrl,
  getStripeReturnURL,
  handleLoginSignUpResponse,
  handleLoginSignUpResponseWithoutRedirect,
} from "../../../../utils/utils";
import Spinner from "../../../../utils/spinner";
import {
  uploadProductImage,
  getUserData,
  updateUserProfile,
  sendEmailVerificationCode,
  verifyEmailVerificationCode,
  updateUserProfileMobile,
  isGoogleConnected,
  connectWithGoogleExternalInt,
  disconnectGoogleEmails,
  uploadTokenedImage,
  signUpWithUserDetails,
  reverifyAccount,
} from "../../../../utils/apiCallHanlder";
import { emailDesc, displayNameDesc } from "../../../../utils/descriptions";
import { ToastContainer, toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../../../components/Modal";
import Card from "../../../../components/Card";
import ReactPhoneInput from "react-phone-input-2";
import AlternativeAvatar from "../../../../components/AlternativeAvatar";
import { useHistory } from 'react-router-dom';

const inputStyle = {
  position: "relative",
  fontSize: "14px",
  letterSpacing: ".01rem",
  marginTop: "0px !important",
  marginBottom: " 0px !important",
  paddingLeft: "48px",
  marginLeft: "0px",
  background: "#F4F4F4",
  border: "none",
  borderRadius: "10px",
  lineHeight: "25px",
  height: "45px",
  width: "100%",
  outline: "none",
};
const countries = [];

let permanentImageSave = null;
let isUserSingedInwithGoogle = null;

const avatarTypes = ["Human", "Jungle", "Modern", "Monster", "Superhero"];

const ProfileInformation = ({ className, setChangeProfile, changeProfile, comingFromSignup, continueClickHanlder, userEmail, token }) => {
  const [visible, setVisibility] = useState(false);
  const history = useHistory();

  const [name, setName] = useState("");
  const [email, setEmail] = useState(userEmail || "");
  const [mobile, setMobile] = useState("");
  const [newMobile, setNewMobile] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [showUpdateMobilePopup, setShowUpdateMobilePopup] = useState(false);
  const [showUpdateEmailPopup, setShowUpdateEmailPopup] = useState(false);
  const [isEmailVerifyMailSent, setIsEmailVerifyMailSent] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [text, setText] = useState("Upload or drag here");
  const [activeIndex, setActiveIndex] = useState(1);

  const [isCustomStripe, setIsCustomStripe] = useState(true);
  const [isStandardStripe, setIsStandardStripe] = useState(false);

  const [invoiceDue, setInvoiceDue] = useState(true);
  const [paymentRecieved, setPaymentRecieved] = useState(true);
  const [messageFromGW, setMessageFromGW] = useState(true);
  const [imageUrl, setImageUrl] = useState("");

  const [unsyncLoading, setUnSyncLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [continueLoading, setContinueLoading] = useState(false);

  const [startLoading, setStartLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lastName, setLastName] = useState("");
  const [savingMobile, setSavingMobile] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [isGoogleConnectedBit, setIsGoogleConnectedBit] = useState(false);

  const [newEmail, setNewEmail] = useState("");
  const [newEmailError, setNewEmailError] = useState("");

  const [country, setCountry] = useState("");

  const [displayCountry, setDisplayCountry] = useState({
    value: null,
    label: "Choose Country",
  });

  let options = useMemo(() => countryList().getData(), []);

  if (countries.length < 1) {
    for (let j = 0; j < STRIPE_COUNTRIES?.length; j++) {
      for (let i = 0; i < options?.length; i++) {
        if (
          options[i]?.label?.toLowerCase() ===
          STRIPE_COUNTRIES[j]?.toLowerCase()
        ) {
          countries.push(options[i]);
        }
      }
    }
  }

  useEffect(async () => {
    if (comingFromSignup) {
      setStartLoading(false);
      return;
    }

    const res = await isGoogleConnected({});
    if (res.data) {
      setIsGoogleConnectedBit(res.data?.isGoogleConnected);
    }

    const userData = await getUserData();
    const { data } = userData;
    if (data) {
      const user = data?.user;

      setName(user?.name);
      setLastName(user?.surname);
      setEmail(user?.email);
      setMobile(user?.mobile);
      setIsEmailVerified(user?.isEmailVerified);
      setImageUrl(user?.imageurl);
      setInvoiceDue(user?.notificationOnDueInvoice);
      setPaymentRecieved(user?.notificationOnPaymentReceived);
      setMessageFromGW(user?.notificationOnGreatWeekMsg);
    }
    setStartLoading(false);
  }, []);

  const settings = [
    {
      id: 0,
      title: "When an Invoice is past due",
      setState: setInvoiceDue,
      currentValue: invoiceDue,
    },
    {
      id: 1,
      title: "When a payment is recieved",
      setState: setPaymentRecieved,
      currentValue: paymentRecieved,
    },
    {
      id: 2,
      title: "When I get a message from GreakWeek",
      setState: setMessageFromGW,
      currentValue: messageFromGW,
    },
  ];

  const sendVerifyEmailCode = async () => {
    setIsEmailVerifyMailSent(true);
    const sendCode = await sendEmailVerificationCode();
    toast.success("Email with code is sent.", toastConfiguration);
  };

  const checkVerifyEmailCode = async () => {
    const resp = await verifyEmailVerificationCode(verifyCode);
    if (resp && resp.code == 200) {
      setIsEmailVerifyMailSent(false);
      setIsEmailVerified(true);
      toast.success("Email verified.", toastConfiguration);
    } else if (resp && resp.code == 400) {
      toast.error(resp.error, toastConfiguration);
    }
  };

  const handleChangeVerifyCode = async (event) => {
    setVerifyCode(event?.target?.value);
  };

  const continueClickHandler = async (code) => {
    if (loading) return;
    if (name?.trim() === "") {
      toast.error("Name is required", toastConfiguration);
      return;
    }

    if (mobile?.length < 7) {
      toast.error("Mobile is required", toastConfiguration);
      return;
    }

    if (country?.trim() === "") {
      toast.error("Country is required", toastConfiguration);
      return;
    }
    let details = {
      isCustomStripeAccount: false,
      user: {
        imageUrl,
        name: name,
        surName: lastName,
        phone: mobile,
        country
      }
    };
    setLoading(true);
    const payload = {
      ...details,
      onlyCompany: false,
      myNewToken: token,
      skipCompany: true,
      refferedBy: code,
      returnUrl: "https://somedummyUrl.com"
    }

    const { data, error } = await signUpWithUserDetails(payload, false);
    if (data) {
      localStorage.removeItem("fbclid");
      localStorage.removeItem("gclid");
      localStorage.setItem(
        "connectStatus",
        JSON.stringify(data?.connectStatus)
      );

      localStorage.setItem("stripeConnection", data.isConnected);
      localStorage.setItem("isPending", data.isPending);
      localStorage.setItem("isTestMode", data.istestmode);
      localStorage.setItem("currencySymbol", data.currencySymbol || "$");
      localStorage.setItem("currency", data.currency || "USD");
      localStorage.setItem("isAccountingEnabled", false);
      data["redirectURL"] = null;
      data["noRedirect"] = false;

      await handleLoginSignUpResponse(data, history, true);
    } else {
      toast.error(error || "Error in signing up", toastConfiguration)
      setLoading(false);
    }
  }
  const saveMobileHandler = async () => {
    if (newMobile === "") {
      toast.error("New mobile number is required.", toastConfiguration);
    } else {
      const profilePayload = {
        phone: newMobile,
      };

      setSavingMobile(true);
      const updateProfileResponse = await updateUserProfileMobile(profilePayload);
      const { data, error } = updateProfileResponse;
      if (data) {
        setShowUpdateMobilePopup(false);
        toast.success("Mobile Updated Successfully", toastConfiguration);
        setMobile(newMobile);
      } else {
        toast.error(error || "Error In Updating Mobile", toastConfiguration);
      }
      setSavingMobile(false);
    }
  };

  const saveButtonHandler = async () => {
    if (name === "" || lastName === "" || name.trim() === "" || lastName.trim() === "") {
      if (name === "" || name.trim() === "") {
        toast.error("First name is required.", toastConfiguration);
      }
      else {
        toast.error("Last name is required.", toastConfiguration);
      }
    } else if (email === "") {
      toast.error("Email is required", toastConfiguration);
    } else if (emailError === "Email is invalid") {
      toast.error(emailError, toastConfiguration);
    } else {
      const profilePayload = {
        name: name,
        email: email,
        notificationOnDueInvoice: invoiceDue,
        notificationOnPaymentReceived: paymentRecieved,
        notificationOnGreatWeekMsg: messageFromGW,
        imageUrl: imageUrl,
        surName: lastName,
        phone: mobile
      };
      if (loading) {
        return;
      }
      setLoading(true);
      const updateProfileResponse = await updateUserProfile(profilePayload);
      const { data } = updateProfileResponse;
      if (data) {
        setServerError(null);
        toast.success("Profile Information Updated Successfully", toastConfiguration);
        handleLoginSignUpResponseWithoutRedirect(data);
        //setShowMainContainer(true);
        setChangeProfile(!changeProfile);
      } else {
        toast.error("Error In Updating Information", toastConfiguration);
        setServerError(updateProfileResponse?.error);
      }

      setLoading(false);
    }
  };

  /* const imageUploadHandler = async (imageFile) => {
    permanentImageSave = imageFile;
    setSelectedImage(imageFile);
    const formData = new FormData();
    formData.append("", imageFile);
    const uploadImageResponse = await uploadProductImage(formData);
    const { data } = uploadImageResponse;
    if (data) {
      setUploadingError("No error");
      setImageUrl(data.image.url);
    } else {
      setUploadingError(uploadImageResponse.error);
    }
  }; */

  const onDropHandler = (ev) => {
    ev.preventDefault();

    let file = "";
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file = [...ev.dataTransfer.items].find((item) => item.kind === "file").getAsFile();
    } else {
      // Use DataTransfer interface to access the file(s)
      file = ev.dataTransfer.files[0];
    }
    imageUploadHandler(file);
    setText(file?.name);
  };

  const onDragOver = (ev) => ev.preventDefault();

  const imageUploadHandler = async (imageFile) => {
    setUploadLoading(true);
    const formData = new FormData();
    formData.append("", imageFile);
    const { data } = token ? await uploadTokenedImage(formData, token) : await uploadProductImage(formData);
    if (data) {
      setImageUrl(data.image.url);
    } else {
      toast.error("Error in uploading Image", toastConfiguration);
    }

    setText("Upload or drag here");
    setShowAvatarModal(false);
    setUploadLoading(false);
  };

  const handleOnChange = (value, country) => {
    setMobile(value);
  };

  const ConnectWithGmailHandler = async () => {
    setSyncLoading(true);
    const ReturnUrl = getReturnUrl("settings/Profile");

    const signInResponse = await connectWithGoogleExternalInt({ ReturnUrl });
    const { data } = signInResponse;
    if (data) {
      window.location = data.redirectURL;
    }
    setSyncLoading(false);
  };

  return (
    <>
      {visible ? (
        <UpdatePasswordModal visible={visible} setVisible={setVisibility} />
      ) : (
        <>
          {startLoading ? (
            <div className={styles.spinner} style={{ height: window.outerHeight - 250 }}>
              <Spinner loading={startLoading} size={150} color={"gray"} />
            </div>
          ) : (
            <>
              <div className={styles.mainContainer}>
                <div className={styles.profileContainer}>
                  <Card
                    className={comingFromSignup ? styles.signUpCard : cn(styles.card, className)}
                  >
                    <div className={styles.avatarWrap}>
                      <button onClick={() => setShowAvatarModal(true)}>
                        <Icon name="close" size="16" />
                      </button>
                      {imageUrl ?
                        <Image
                          className={styles.avatar}
                          src={imageUrl}
                          srcDark={imageUrl}
                        />
                        :
                        <AlternativeAvatar name={name || ""} className={"big-icon"} />
                      }
                    </div>
                    <div className={styles.fieldset}>
                      <TextInput
                        className={styles.field}
                        style={
                          name === ""
                            ? {
                              backgroundColor: "#FFBC9925",
                              borderColor: "#FFBC9925",
                            }
                            : null
                        }
                        label="First Name"
                        name="display-name"
                        type="text"
                        value={name}
                        onChange={(event) => {
                          setNameError("");
                          setServerError("");
                          setName(event?.target?.value);
                        }}
                        required
                      />
                      <TextInput
                        className={cn(styles.field, styles.marginLeftFields)}
                        classLabel={styles.classLabel}
                        style={
                          lastName === ""
                            ? {
                              marginLeft: 8,
                              backgroundColor: "#FFBC9925",
                              borderColor: "#FFBC9925",
                            }
                            : { marginLeft: 8 }
                        }
                        label="Last Name"
                        name="display-name"
                        type="text"
                        value={lastName}
                        onChange={(event) => {
                          setNameError("");
                          setServerError("");
                          setLastName(event?.target?.value);
                        }}
                        required
                      />
                      <div className={styles.field} style={{ position: "relative" }}>
                        <div className={styles.fieldLabel}>Mobile</div>
                        <div
                          className={styles.mobileField}
                        >
                          <ReactPhoneInput
                            inputExtraProps={{
                              name: "phone",
                              required: true,
                              autoFocus: true,
                            }}
                            buttonStyle={{ border: "none" }}
                            inputStyle={inputStyle}
                            containerStyle={{ border: "2px solid #C7C5BF", }}
                            country={"us"}
                            countryCodeEditable={false}
                            value={mobile}
                            onChange={handleOnChange}
                          />
                        </div>
                        <div className={styles.verifiedBox}>
                        </div>
                      </div>
                      {comingFromSignup ?
                        <div className={cn(styles.field, styles.marginLeftFields)} style={{ position: "relative", marginLeft: 8 }}>
                          <div className={styles.fieldLabel}>Country</div>
                          <Select
                            options={countries}
                            className={cn(styles.dropdown, "drop")}
                            value={displayCountry}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            onChange={(item) => {
                              setCountry(item?.value);
                              setDisplayCountry(item);
                            }}
                          />
                        </div>
                        :
                        <div className={cn(styles.field, styles.marginLeftFields)} style={{ position: "relative", marginLeft: 8 }}>
                          <TextInput
                            className={styles.inputWithBottom}
                            style={
                              email === "" || emailError
                                ? {
                                  backgroundColor: "#FFBC9925",
                                  borderColor: "#FFBC9925",
                                  pointerEvents: "none",
                                }
                                : { pointerEvents: "none" }
                            }
                            label={(isEmailVerified || comingFromSignup) ? "Email" : "Email (not verified)"}
                            name="email"
                            type="email"
                            value={email}
                            onChange={(event) => {
                              setServerError("");
                              emailHandleChange(event, setEmailError, setEmail);
                            }}
                            required
                          />
                          {!isEmailVerified && isEmailVerifyMailSent ? (
                            <>
                              <button
                                className={cn("button-stroke", styles.button)}
                                onClick={() => checkVerifyEmailCode()}
                                style={{
                                  marginTop: 47,
                                  marginRight: 20,
                                  float: "left",
                                }}
                              >
                                Verify
                              </button>
                              <div
                                style={{
                                  width: 120,
                                  marginTop: 10,
                                  overflow: "hidden",
                                  paddingRight: ".5em",
                                }}
                              >
                                <TextInput
                                  type="text"
                                  label="Code from email"
                                  value={verifyCode}
                                  onChange={(event) => {
                                    handleChangeVerifyCode(event);
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      }
                      {/* <div className="stripeTypeContainer">
                        <div className={styles.title}>Stripe Account Type</div>
                        <div className={styles.cardType}>
                          <div className={styles.singleCard}>
                            <Checkbox
                              onChange={() => {
                                setIsCustomStripe(!isCustomStripe);
                                setIsStandardStripe(!isStandardStripe);
                              }}
                              value={isCustomStripe}
                            />
                            <span>
                              Custom <br />{" "}
                              <div className={styles.cardDetails}>
                                Let you create spending Cards. Does not allow to
                                login to Stripe directly.
                              </div>
                            </span>
                          </div>
                          <div className={styles.singleCard}>
                            <Checkbox
                              onChange={() => {
                                setIsCustomStripe(!isCustomStripe);
                                setIsStandardStripe(!isStandardStripe);
                              }}
                              value={isStandardStripe}
                            />
                            <span>
                              Standard &nbsp;
                              <br />
                              <div className={styles.cardDetails}>
                                Usual Stripe account
                              </div>
                            </span>
                          </div>
                        </div>
                      </div> */}
                      {emailError !== "" &&
                        emailError !== "Email is invalid" ? (
                        <div className={styles.errorContainer}>
                          <span className={styles.errorMessage}>
                            {emailError}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    {!comingFromSignup && (
                      <div className={styles.btnWrap}>
                        {!isGoogleConnectedBit ? (
                          <div className={styles.externalLogin}>
                            <div
                              className={cn("button", styles.button)}
                              onClick={ConnectWithGmailHandler}
                              style={{ width: 300 }}
                            >
                              <button
                                className={cn(styles.link, styles.google2)}
                              >
                                {syncLoading ? (
                                  <Spinner size="24" color="white" />
                                ) : (
                                  <>Link Google Account</>
                                )}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className={styles.externalLogin}>
                              <div
                                className={cn("button", styles.button)}
                                onClick={async () => {
                                  setUnSyncLoading(true);
                                  const { data } = await disconnectGoogleEmails({});
                                  if (data) {
                                    toast.success(
                                      "Successfully unsynced google mail",
                                      toastConfiguration
                                    );
                                    setIsGoogleConnectedBit(false);
                                  } else {
                                    toast.error("Error in unsyncing google mail", toastConfiguration);
                                  }
                                  setUnSyncLoading(false);
                                }}
                                style={{ width: 300 }}
                              >
                                <button className={cn(styles.link, styles.google2)}>
                                  {unsyncLoading ? (
                                    <Spinner size="24" color="white" />
                                  ) : (
                                    <>Unlink Google Account</>
                                  )}
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    {!comingFromSignup && (
                      <div className={cn(styles.btnWrap, styles.btnWrapTop)}>
                        <div>
                          <button
                            className={cn("button", styles.button)}
                            type="button"
                            onClick={saveButtonHandler}
                          >
                            {loading ? (
                              <Spinner loading={loading} size={15} />
                            ) : (
                              <>
                                <span>Save</span>
                              </>
                            )}
                          </button>
                          {serverError !== "" ? (
                            <div className={styles.errorContainer}>
                              <span className={styles.errorMessage}>
                                {serverError}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}

                    {comingFromSignup && (
                      <>
                        <div className={styles.signUpText}>
                          Greatweek collaborates with Stripe.com, a leading financial platform globally, to streamline payment processes for businesses. As the next step, you'll be directed to our partner site to finalize your signup.
                        </div>
                        <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap' }}>
                          <button
                            className={`button ${styles.continueButton}`}
                            style={{ width: 204 }}
                            onClick={() =>
                              continueClickHandler()
                            }
                          >
                            {loading ? (
                              <Spinner size="24" color="white" />
                            ) : (
                              "Continue onboarding"
                            )}

                          </button>
                          <button
                            className={`button-stroke-red ${styles.setupCallButton}`}
                            style={{ width: 204 }}
                            onClick={() => {
                              if (window) window.open('https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0JkIt9CuHs99KRmdJZAqSfRgyrp3-Znsj-jsf56Qdp7rdI0n9aPmJH0m3fP1fO4xjTjLvc-BCg', "_blank")
                            }}
                          > Setup an intro call </button>
                        </div>
                      </>
                    )}

                    {/* Avatar Modal */}
                    <Modal
                      visible={showAvatarModal}
                      onClose={() => setShowAvatarModal(false)}
                      outerClassName={styles.outerClass}
                      children={
                        <>
                          <Card
                            /* classTitle="title-blue" */
                            title="Image"
                            className={styles.card}
                          >
                            <div
                              className={styles.uploadImage}
                              id="drop_zone"
                              onDrop={onDropHandler}
                              onDragOver={onDragOver}
                              onClick={() => {
                                document?.getElementById("imgupload").click();
                              }}
                            >
                              {uploadLoading ? (
                                <div style={{ marginTop: 5 }}>
                                  <Spinner size="24" color="gray" />
                                </div>
                              ) : (
                                <Icon name="upload" size="24" />
                              )}
                              {text}
                            </div>
                            <input
                              type="file"
                              id="imgupload"
                              accept="image/png, image/gif, image/jpeg"
                              style={{
                                display: "none",
                              }}
                              onChange={(event) => imageUploadHandler(event.target.files[0])}
                            />
                            <p className={styles.orChoose}>Or choose an avatar</p>
                            <div className={styles.nav}>
                              <div className={styles.typeNav}>
                                {avatarTypes.map((x, index) => (
                                  <div
                                    className={cn(styles.item, {
                                      [styles.active]: index === activeIndex - 1,
                                    })}
                                    key={index}
                                    onClick={() => {
                                      setActiveIndex(index + 1);
                                    }}
                                  >
                                    {x}
                                  </div>
                                ))}
                              </div>
                            </div>
                            {activeIndex === 1 ? (
                              <div className={styles.avatarFlex}>
                                {[...Array(36).keys()].map((x, index) => (
                                  <Image
                                    key={index}
                                    className={styles.avatar}
                                    src={`/avatars/HUMANS/GREATWEEK.COM AVATAR ${x + 1}.png`}
                                    srcDark={`/avatars/HUMANS/GREATWEEK.COM AVATAR ${x + 1}.png`}
                                    onClick={() => {
                                      setShowAvatarModal(false);
                                      setImageUrl(
                                        `/avatars/HUMANS/GREATWEEK.COM AVATAR ${x + 1}.png`
                                      );
                                    }}
                                  />
                                ))}
                              </div>
                            ) : activeIndex === 2 ? (
                              <div className={styles.avatarFlex}>
                                {[...Array(18).keys()].map((x, index) => (
                                  <Image
                                    key={index}
                                    className={styles.avatar}
                                    src={`/avatars/JUNGLE/GREATWEEK.COM JUNGLE ${x + 1}.png`}
                                    srcDark={`/avatars/JUNGLE/GREATWEEK.COM JUNGLE ${x + 1}.png`}
                                    onClick={() => {
                                      setShowAvatarModal(false);
                                      setImageUrl(
                                        `/avatars/JUNGLE/GREATWEEK.COM JUNGLE ${x + 1}.png`
                                      );
                                    }}
                                  />
                                ))}
                              </div>
                            ) : activeIndex === 3 ? (
                              <div className={styles.avatarFlex}>
                                {[...Array(75).keys()].map((x, index) => (
                                  <Image
                                    key={index}
                                    className={styles.avatar}
                                    src={`/avatars/MODERN/GREATWEEK.COM ANIMATED ${x + 1}.png`}
                                    srcDark={`/avatars/MODERN/GREATWEEK.COM ANIMATED ${x + 1}.png`}
                                    onClick={() => {
                                      setImageUrl(
                                        `/avatars/MODERN/GREATWEEK.COM ANIMATED ${x + 1}.png`
                                      );
                                      setShowAvatarModal(false);
                                    }}
                                  />
                                ))}
                              </div>
                            ) : activeIndex === 4 ? (
                              <div className={styles.avatarFlex}>
                                {[...Array(17).keys()].map((x, index) => (
                                  <Image
                                    key={index}
                                    className={styles.avatar}
                                    src={`/avatars/MONSTER/GREATWEEK.COM MONSTER ${x + 1}.png`}
                                    srcDark={`/avatars/MONSTER/GREATWEEK.COM MONSTER ${x + 1}.png`}
                                    onClick={() => {
                                      setImageUrl(
                                        `/avatars/MONSTER/GREATWEEK.COM MONSTER ${x + 1}.png`
                                      );
                                      setShowAvatarModal(false);
                                    }}
                                  />
                                ))}
                              </div>
                            ) : activeIndex === 5 ? (
                              <div className={styles.avatarFlex}>
                                {[...Array(17).keys()].map((x, index) => (
                                  <Image
                                    key={index}
                                    className={styles.avatar}
                                    src={`/avatars/SUPERHERO/GREATWEEK.COM SUPERHERO ${x + 1}.png`}
                                    srcDark={`/avatars/SUPERHERO/GREATWEEK.COM SUPERHERO ${x + 1
                                      }.png`}
                                    onClick={() => {
                                      setImageUrl(
                                        `/avatars/SUPERHERO/GREATWEEK.COM SUPERHERO ${x + 1}.png`
                                      );
                                      setShowAvatarModal(false);
                                    }}
                                  />
                                ))}
                              </div>
                            ) : null}
                          </Card>
                        </>
                      }
                    />
                    {/* update phone popup */}
                    <Modal
                      visible={showUpdateMobilePopup}
                      onClose={() => setShowUpdateMobilePopup(false)}
                      children={
                        <Card
                          /* classTitle="title-secondary-03" */
                          title="Update Mobile"
                          className={styles.card}
                        >
                          <div className={styles.popupField} style={{ pointerEvents: "none" }}>
                            <ReactPhoneInput
                              inputExtraProps={{
                                name: "phone",
                                required: true,
                                autoFocus: true,
                              }}
                              buttonStyle={{ border: "none" }}
                              inputStyle={inputStyle}
                              country={"us"}
                              countryCodeEditable={false}
                              value={mobile}
                            />
                          </div>
                          <div className={styles.popupField} style={{ position: "relative" }}>
                            <div className={styles.fieldLabel}>New Mobile</div>
                            <div className={styles.mobileField}>
                              <ReactPhoneInput
                                inputExtraProps={{
                                  name: "phone",
                                  required: true,
                                  autoFocus: true,
                                }}
                                buttonStyle={{ border: "none" }}
                                inputStyle={inputStyle}
                                country={"us"}
                                countryCodeEditable={false}
                                value={newMobile}
                                onChange={handleOnChange}
                              />
                            </div>
                          </div>
                          <hr />
                          <button
                            style={{ marginTop: "12px" }}
                            className="button"
                            onClick={() => saveMobileHandler()}
                          >
                            {savingMobile ? (
                              <Spinner loading={savingMobile} size={15} />
                            ) : (
                              <>
                                <span>Save</span>
                              </>
                            )}
                          </button>
                        </Card>
                      }
                    />
                    {/* update Email popup */}
                    <Modal
                      visible={showUpdateEmailPopup}
                      onClose={() => setShowUpdateEmailPopup(false)}
                      children={
                        <Card
                          /* classTitle="title-secondary-03" */
                          title="Update Email"
                          className={styles.card}
                        >
                          <div className={styles.popupField} style={{ pointerEvents: "none" }}>
                            <TextInput label="Current Email" value={email} />
                          </div>
                          <div className={styles.popupField} style={{ position: "relative" }}>
                            <TextInput
                              style={
                                newEmail === "" || newEmailError
                                  ? {
                                    backgroundColor: "#FFBC9925",
                                    borderColor: "#FFBC9925",
                                  }
                                  : null
                              }
                              label={"New Email"}
                              name="email"
                              type="email"
                              value={newEmail}
                              onChange={(event) => {
                                setServerError("");
                                emailHandleChange(event, setNewEmailError, setNewEmail);
                              }}
                              required
                            />
                            {newEmailError ? (
                              <Icon name="warning" className={styles.warningIcon} size="24" />
                            ) : (
                              ""
                            )}
                          </div>
                          <hr />
                          <button
                            style={{ marginTop: "12px" }}
                            className="button"
                            onClick={() => setShowUpdateEmailPopup(false)}
                          >
                            <Icon name="save" size="24" />
                            Save
                          </button>
                        </Card>
                      }
                    />
                  </Card>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <TooltipGlodal />
    </>
  );
};

export default ProfileInformation;
