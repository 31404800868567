import React, { useState, useEffect, useCallback } from "react";
import styles from "./Documents.module.sass";
import Card from "../../../components/Card";
import cn from "classnames";
import Icon from "../../../components/Icon";
import Table from "./Table"
import { useDropzone } from "react-dropzone";
import { getAccountingDocuments, uploadAccountingDocuments, deleteAccountingDocuments } from "../../../utils/apiCallHanlder";
import Spinner from "../../../utils/spinner";
import { ToastContainer, toast } from "react-toastify";
import { toastConfiguration } from "../../../utils/utils";
import Search from "../prompts/Search";

let pageNumber = 1;

const Documents = ({ className, setActiveTab }) => {
    const [actionBtn, setActionBtn] = useState(true);
    const [show, setShow] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [isNextPageExist, setIsNextPageExist] = useState(false);
    const [nextPageLoading, setNextPageLoading] = useState(false);
    const [showNewDocModal, setShowNewDocModal] = useState(false);

    const [loading, setLoading] = useState(true);
    const [searchStr, setSearchStr] = useState('');

    const onDrop = useCallback(async (acceptedFiles) => {
        const formData = new FormData();
        setUploading(true);

        acceptedFiles.map((x) => {
            formData.append('files', x);
        })

        const { data } = await uploadAccountingDocuments(formData);

        if (data) {
            fetchDocuments(1);
        }

        setUploading(false);
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })


    const handleEditDocumentMetadata = (docInfo) => {
        setShowNewDocModal(true);
    };

    const deleteItem = async (obj) => {

        const { data } = await deleteAccountingDocuments([obj.id]);
        if (data) {
            for (var i = 0; i < documents.length; i++) {
                if (documents[i].documentFileId == obj.id) {
                    documents.splice(i, 1);
                    setDocuments(documents.slice(0));
                    break;
                }
            }
            toast.success("Document deleted successfully", toastConfiguration);
        } else {
            toast.error("Error in deleting document", toastConfiguration);
        }


        return { data: true };
    };

    useEffect(() => {
        if (!showNewDocModal) {
            fetchDocuments(1);
        }
    }, [showNewDocModal]);

    const fetchDocuments = async (pageNo, string) => {
        pageNo === 1 && setLoading(true);
        const { data } = await getAccountingDocuments({
            page: pageNo || pageNumber || 1,
            pageSize: 15,
            searchString: string || searchStr || ''
        });
        if (data && data.documents) {
            if (pageNo === 1) {
                setDocuments(data.documents);
            } else {
                setDocuments([...(documents || []), ...(data.documents || [])])
            }

            setIsNextPageExist(data.hasNextPage);
        }
        pageNo === 1 && setLoading(false)
    };

    const fetchNextPage = async () => {
        setNextPageLoading(true);
        pageNumber = pageNumber + 1;
        await fetchDocuments(pageNumber)
        setNextPageLoading(false);
    };

    const actionBtnHandle = () => {
        setActionBtn(!actionBtn);
    };

    const getSearchResult = () => {
        pageNumber = 1;
        fetchDocuments(1);
    }

    const resetSearch = () => {
        pageNumber = 1;
        setSearchStr("");
        fetchDocuments(1, "");
    }

    return (
        <>
            <div className={styles.title}>
                <h5
                    className={styles.backBtn}
                    onClick={() => setActiveTab(0)}
                >
                    <Icon name="arrow_backward" size="18" />
                    Back
                </h5>
            </div>
            <ToastContainer />
            <Card
                className={cn(styles.card, className)}
                classCardHead={styles.head}
                title="Documents"
            >
                <div {...getRootProps({ className: styles.dropzone })}>
                    <Icon name="documentAdd" size="48" viewBox="0 0 48 48" />

                    <div style={{ margin: 'auto' }}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <p>Drop the file here ...</p> :
                                <p>Drag and drop or click to upload</p>
                        }
                    </div>
                </div>
                <Search
                    setSearchString={setSearchStr}
                    searchString={searchStr}
                    getSearchResult={getSearchResult}
                    resetSearch={resetSearch}
                />
                {loading ?
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        <Spinner size="64" color="gray" />
                    </div>
                    :
                    <Table
                        className={styles.table}
                        activeTable={show}
                        attachmentClickHandler={() => { }}
                        setActiveTable={setShow}
                        deleteItem={deleteItem}
                        editAction={
                            <button
                                className={styles.actionItem}
                                onClick={() => { }}
                            >
                                <Icon name="edit" size="24" />
                                Edit
                            </button>
                        }
                        editDocument={handleEditDocumentMetadata}
                        actionBtnHandle={actionBtnHandle}
                        documents={documents}
                        isNextPageExist={isNextPageExist}
                        fetchNextPage={fetchNextPage}
                        nextPageLoading={nextPageLoading}
                    />
                }

            </Card>
        </>
    );
};

export default Documents;
