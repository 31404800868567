import React, { useEffect, useState } from "react";
import styles from "./CardDetails.module.sass";
import Icon from "../../../components/Icon";
import Details from "./Details";
import Transactions from "../../../components/TransactionsCards";
import Card from "../../../components/Card";
import { useHistory } from "react-router";
import cn from "classnames";
import { toast, ToastContainer } from 'react-toastify';
import { toastConfiguration } from "../../../utils/utils";
import { cardStatusChange, makeCardAsDefault } from "../../../utils/apiCallHanlder";
import Spinner from "../../../utils/spinner";

const CardDetails = ({
  setShowCardDetails,
  setShowNewCard,
  setAddCard,
  setShowCards,
  cardDetail,
  id,
  setVisibleCardDetails,
  setIndividualCardDetail,
  setCardDetails
}) => {
  const [colorName, setColorName] = useState("gray");
  const [value, setValue] = useState("2244");
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);
  const [spendingLimit, setSpendingLimit] = useState(cardDetail?.spendingLimit);

  const [makeDefaultLoading, setMakeDefaultLoading] = useState(false);


  const history = useHistory();

  useEffect(() => {
    setSpendingLimit(cardDetail?.spendingLimit);
  }, [cardDetail])

  const saveButtonHandler = () => {
    setShowCards && setShowCards(true);
    setShowNewCard(false);
    setShowCardDetails && setShowCardDetails(false);
    setAddCard(false);
    setVisibleCardDetails && setVisibleCardDetails(false)
  }

  const statusChangeHandler = async (status) => {
    setStatusChangeLoading(true);
    const { data } = await cardStatusChange({
      id: cardDetail?.id,
      status
    })

    if (data) {
      setIndividualCardDetail(data?.card);
      let toastMessage = "Card Activated Successfully."
      if (status === "inactive") {
        toastMessage = "Card Freezed Successfully.";
      }

      toast.success(toastMessage, toastConfiguration);
    }
    setStatusChangeLoading(false);
  }

  const makeDefaultHandler = async () => {
    setMakeDefaultLoading(true);
    const { data } = await makeCardAsDefault({
      id
    })

    if (data) {
      toast.success("Card marked as default", toastConfiguration);
    } else {
      toast.error("Error in making card as defualt", toastConfiguration);
    }

    setMakeDefaultLoading(false);
  }

  return (
    <>
      <div className={styles.row} style={cardDetail?.id ? { width: '100%' } : null}>
        <ToastContainer />
        <div className={styles.col12}>
          <div className={styles.title}>
            <h5
              className={styles.backBtn}
              onClick={() => {
                setCardDetails(false)
              }}
            >
              Cards <Icon name="arrow-next" size="14" />
            </h5>
            <p>Details</p>
          </div>
        </div>
        <div className={styles.col12}>
          <Details
            setColorName={setColorName}
            setValue={setValue}
            setSpendingLimit={setSpendingLimit}
            colorName={colorName}
            value={value}
            cardDetail={cardDetail}
            spendingLimit={spendingLimit}
            setIndividualCardDetail={setIndividualCardDetail}
          />
        </div>
        <div className={styles.col12}>
          <Transactions
            id={id}
          />
        </div>
      </div>
    </>
  );
};

export default CardDetails;
