import React, { useEffect, useState } from 'react';
import Card from '../../components/Card';

import styles from './Capital.module.sass';
import LargeNav from '../../components/LargeNav';
import WorkingCapital from './WorkingCapital';
import VentureCapital from './VentureCapital';
import { ToastContainer, toast } from 'react-toastify';
import { acceptCapitalOffer, cancelCapitalApplication, getAccountDetail, getCapitalApplication, startCapitalApplication } from '../../utils/apiCallHanlder';
import { toastConfiguration } from '../../utils/utils';
import Spinner from '../../utils/spinner';

const navigations = ["Business Loan", "Venture Capital"]

const Capital = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [showApplication, setShowApplication] = useState(true);
    const [currentStep, setCurrentStep] = useState(0);

    const [terms, setTerms] = useState(1);
    const [loanAmount, setLoanAmount] = useState(10000);
    const [selectedAccount, setSelectedAccount] = useState(null);

    const [loading, setLoading] = useState(true);
    const [startApplicationLoading, setStartApplicationLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [applicationData, setApplicationData] = useState(null);

    const [applicationOfferId, setApplicationOfferId] = useState(null);
    const [acceptOfferLoading, setAcceptOfferLoading] = useState(false);

    const [isOfferAccepted, setIsOfferAccepted] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [showWarning, setShowWarning] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState(null);
    const [showAddProspect, setShowAddProspect] = useState(false);
    const [ventureActiveIndex, setVentureActiveIndex] = useState(0);

    const [showAdjustPipeline, setShowAdjustPipeline] = useState(false);
    const [columnId, setColumnId] = useState([]);
    const [showAddColumnModal, setShowAddColumnModal] = useState(null);

    // useEffect(() => {
    //     if (((accounts?.length > 0 && accounts[0]?.balance < 1) && accounts?.length < 2) || accounts?.length === 0) {
    //         setShowWarning(true);
    //     } else {
    //         setShowWarning(false);
    //     }
    // }, [accounts])

    useEffect(() => {
        if (activeIndex === 0) {
            getApplictionState();
            getBusinessInfo();
        }
    }, [activeIndex])

    const getBusinessInfo = async () => {
        const { data } = await getAccountDetail();
        if (data?.account?.phone && data?.account?.phone?.length > 6) {
            setPhoneNumber(data?.account?.phone);
        }
    }

    const getApplictionState = async () => {
        const { data } = await getCapitalApplication({});
        if (data) {
            if (data?.offer?.offerDetails?.length > 0) {
                const { offer } = data;
                const { offerDetails } = offer;
                let hasAcceptedOffer = false;

                offerDetails?.map(item => {
                    if (item?.isOfferAccepted) {
                        setIsOfferAccepted(true);
                        hasAcceptedOffer = true;
                        setApplicationOfferId(item?.capitalApplicationOfferDetailId)
                    }
                })

                if (!hasAcceptedOffer) {
                    setApplicationOfferId(offerDetails[0]?.capitalApplicationOfferDetailId)
                }
            }

            setCurrentStep(data?.capitalApplied?.status)
            setApplicationData(data);
        }

        setLoading(false);
    }

    const startApplication = async () => {

        if (!loanAmount || loanAmount < 10000 || loanAmount > 500000) {
            toast.error("Please enter loan amount from 10,000 to 500,000.", toastConfiguration);
            return;
        }

        if (!terms || terms < 1 || terms > 18) {
            toast.error("Please enter term from 1 to 18 months.", toastConfiguration);
            return;
        }

        if (!phoneNumber) {
            toast.error("Please add contact number in settings (Business info) before proceeding.", toastConfiguration);
            return;
        }

        if (accounts === undefined || accounts == null || accounts?.length == 0 ||
            (accounts?.length > 0 && accounts[0]?.balance <= 0 && accounts?.length < 2)) {
            setShowWarning(true);
            return;
        }

        if (startApplicationLoading) return;
        setStartApplicationLoading(true);

        const { data, error } = await startCapitalApplication({
            capitalApplicationId: applicationData?.capitalApplied?.capitalApplicationId || undefined,
            linkBankAccountId: selectedAccount,
            amount: parseInt(loanAmount || "10000"),
            term: parseInt(terms || '3'),
        })

        if (data) {
            await getApplictionState();
            toast.success("Application has been started successfully", toastConfiguration);
        } else {
            toast.error(error || "Error in starting application", toastConfiguration);
        }

        setStartApplicationLoading(false);
    }

    const cancelApplicationHandler = async () => {
        if (cancelLoading) return;

        setCancelLoading(true);
        const { data, error } = await cancelCapitalApplication({})
        if (data) {
            await getApplictionState();
            toast.success("Application cancelled successfully", toastConfiguration);
        } else {
            toast.error(error || "Error in application cancelling", toastConfiguration);
        }
        setCancelLoading(false);
    }

    const acceptOfferHandler = async () => {
        if (acceptOfferLoading) return;
        if (isOfferAccepted) return;

        setAcceptOfferLoading(true);
        const { data, error } = await acceptCapitalOffer({
            CapitalApplicationOfferId: applicationData?.offer?.capitalApplicationOfferId,
            CapitalApplicationOfferDetailId: applicationOfferId
        })
        if (data) {
            await getApplictionState();
            toast.success("Offer accepted successfully", toastConfiguration);
        } else {
            toast.error(error || "Error in accepting offer", toastConfiguration);
        }
        setAcceptOfferLoading(false);
    }

    return <>
        <ToastContainer />
        <Card
            title="Capital"
            className={styles.headerCard}
        >
            <LargeNav
                className={styles.typeNav}
                tabOptions={navigations}
                activeTab={activeIndex}
                onClick={(_, index) => setActiveIndex(index)}
            />
        </Card>
        <Card
            className={currentStep === 0 ? styles.cardMargin : styles.card}
            classCardHead={styles.head}
            head={activeIndex === 0 ?
                <>
                    {
                        (currentStep === 0 && showApplication) &&
                        <button className="button-stroke-red" onClick={startApplication} style={{ width: 184 }}>
                            {startApplicationLoading ?
                                <Spinner size={24} color={'grey'} />
                                :
                                "Start Application"
                            }
                        </button>
                    }
                    {(currentStep === 1 && showApplication) &&
                        <>
                            <button className="button-stroke-red" onClick={cancelApplicationHandler} style={{ width: 184 }}>
                                {cancelLoading ?
                                    <Spinner size={24} color={'grey'} />
                                    :
                                    "Cancel Application"
                                }
                            </button>
                        </>
                    }
                    {(currentStep === 2) &&
                        <button
                            className={isOfferAccepted ? "button-stroke-red" : "button"}
                            onClick={acceptOfferHandler}
                            style={isOfferAccepted ? { pointerEvents: "none", width: 154 } : { width: 134 }}
                        >
                            {acceptOfferLoading ?
                                <Spinner size={24} color={'grey'} />
                                :
                                isOfferAccepted ? "Offer Accepted" : "Accept offer"
                            }
                        </button>
                    }
                </> :
                ventureActiveIndex === 1 && (
                    showAdjustPipeline ?
                        <div className={styles.buttonContainer}>
                            <button className='button-stroke-red' onClick={() => setShowAdjustPipeline(false)}>
                                Back to Pipeline
                            </button>
                            <button className='button' onClick={() => {
                                setColumnId(undefined);
                                setShowAddColumnModal(true)
                            }}>
                                Add Column
                            </button>
                        </div>
                        :
                        <div className={styles.buttonContainer}>
                            <button className='button-stroke-red' onClick={() => setShowAdjustPipeline(true)}>
                                Adjust Pipeline
                            </button>
                            <button className='button' onClick={() => {
                                setShowAddProspect(true)
                            }}>
                                Add prospect
                            </button>
                        </div>
                )
            }
            title={activeIndex === 0 ? "Business Loan" : showAdjustPipeline ? "Adjust Pipeline" : "Venture Capital"}
        >
            {activeIndex === 0 && (
                <WorkingCapital
                    currentStep={currentStep}
                    showApplication={showApplication}
                    setShowApplication={setShowApplication}
                    setCurrentStep={setCurrentStep}
                    loading={loading}
                    applicationData={applicationData}
                    terms={terms}
                    setTerms={setTerms}
                    loanAmount={loanAmount}
                    setLoanAmount={setLoanAmount}
                    selectedAccount={selectedAccount}
                    setSelectedAccount={setSelectedAccount}
                    applicationOfferId={applicationOfferId}
                    setApplicationOfferId={setApplicationOfferId}
                    accounts={accounts}
                    setAccounts={setAccounts}
                    showWarning={showWarning}
                />
            )}
            {activeIndex === 1 && (
                <VentureCapital
                    setShowAddProspect={setShowAddProspect}
                    showAddProspect={showAddProspect}
                    setActiveIndex={setVentureActiveIndex}
                    activeIndex={ventureActiveIndex}
                    showAdjustPipeline={showAdjustPipeline}
                    setShowAdjustPipeline={setShowAdjustPipeline}
                    setColumnId={setColumnId}
                    columnId={columnId}
                    setShowAddColumnModal={setShowAddColumnModal}
                    showAddColumnModal={showAddColumnModal}
                />
            )}
        </Card >
    </>
}

export default Capital;