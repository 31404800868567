import React, { useState, useReducer, useEffect } from "react";
import styles from "./SingleChecklist.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Icon from "../../../../components/Icon";
import Modal from "../../../../components/Modal";
import Card from "../../../../components/Card";
import TextInput from "../../../../components/TextInput";
import DeleteModal from "../../../../components/DeleteModal";
import SingleField from "../SingleField";
import NewFieldForm from "../NewFieldForm";
import PreviewFields from "./PreviewFields";
import { useRef } from "react";
import {
  addCheckList,
  addCheckListField,
  deleteCheckList,
  removeFieldFromDb,
  updatedFieldsSequence,
} from "../../../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import Spinner from "../../../../utils/spinner";

const SingleChecklist = ({
  x,
  remove,
  update,
  getCheckListFunction,
  setChecklists,
  currentChecklistId,
  setCurrentChecklistId,
  setTargetId,
  targetId,
}) => {
  const [showActionBtn, setShowActionBtn] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showFieldPopup, setShowFieldPopup] = useState(false);
  const [fields, setFields] = useState([]);
  const [viewFields, setViewFields] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteFieldLoading, setDeleteFieldLoading] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);
  const [currentSortedfieldId, setCurrentSortedFieldId] = useState("");
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      checklistName: x?.name,
    }
  );
  const handleChange = (e) => {
    setUserInput({ [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userInput?.checklistName || userInput?.checklistName.trim() === "") {
      toast.error("Please enter checklist name", toastConfiguration);
      return;
    }

    setUpdateLoading(true);
    const { data } = await addCheckList({
      id: x?.checkListId,
      name: userInput.checklistName,
    });
    if (data) {
      setChecklists([]);
      toast.success("Successfully updated field", toastConfiguration);
      await getCheckListFunction(1);
    } else {
      toast.error("Error in updating field", toastConfiguration);
    }
    setUpdateLoading(false);
  };

  const createField = (newField) => {
    setFields([...fields, newField]);
  };

  const removeField = async (id) => {
    setDeleteFieldLoading(true);
    const { data } = await removeFieldFromDb(id);
    if (data) {
      x.fields = x?.fields?.filter(item => item.checkListFieldId !== parseInt(id || "0"));
    }
    setDeleteFieldLoading(false);
  };

  const updateFields = (updateField) => {
    const updateFields = fields.map((field) => {
      if (field.id === updateField.id) {
        return { ...field, fieldName: updateField.fieldName };
      }
      return field;
    });
    setFields(updateFields);
  };

  /* Drag functions */

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleSort = async () => {
    let _fields = [...x?.fields];

    if (!dragOverItem?.current && dragOverItem?.current !== 0) {
      return;
    }

    const draggedItem = _fields[dragItem.current];
    _fields[dragItem?.current] = _fields[dragOverItem?.current];
    _fields[dragOverItem?.current] = draggedItem;

    setCurrentSortedFieldId(draggedItem?.checkListFieldId);


    dragItem.current = null;
    dragOverItem.current = null;
    x.fields = _fields;

    setUpdateState(!updateState);

    let idsArray = [];
    for (let i = 0; i < x?.fields?.length; i++) {
      idsArray.push(x.fields[i]?.checkListFieldId);
    }

    setSortLoading(true);
    await updatedFieldsSequence(idsArray, x?.checkListId);

    setSortLoading(false);
  };

  // useEffect(() => {
  //   if (x.checkListId === currentChecklistId) {
  //     setIsUpdate(true);
  //   }
  // }, [currentChecklistId]);

  // useEffect(() => {
  //   if (x.checkListId === targetId) {
  //     setIsUpdate(true);
  //   }
  // }, [targetId]);

  return (
    <>
      <div className={styles.singleChecklist} id={x.checkListId}>
        <div
          onClick={() => {
            setIsUpdate(true);
            setUserInput({ ['checklistName']: x?.name });
            setTargetId(x.checkListId);
          }}
          className={styles.col}
        >
          {x?.name}
        </div>
        <div
          onClick={() => {
            setIsUpdate(true);
            setUserInput({ ['checklistName']: x?.name });
            setTargetId(x.checkListId);
          }}
          className={styles.col}
        >
          {x?.fields?.length}
        </div>
        <button
          id={x.id}
          onClick={() => {
            setShowDeleteModal(true);
          }}
          className={cn(styles.col, styles.deleteItem)}
        >
          <Icon name='trash' size='24' />
        </button>
        {/* <button
              onClick={() => setShowPreview(true)}
              className={cn("button button-small", styles.previewBtn)}
            >
              Preview
            </button> */}
        {/* <div className={styles.right}>
            <OutsideClickHandler onOutsideClick={() => setShowActionBtn(false)}>
              <div
                className={cn(styles.dotsBtn, {
                  [styles.active]: showActionBtn,
                })}
              >
                <div className={styles.head}>
                  <button
                    className={cn(styles.btn)}
                    onClick={() => setShowActionBtn(!showActionBtn)}
                  >
                    <Icon name="dots" size="24" />
                  </button>
                </div>
                <div className={styles.actionBody}>
                  <button
                    id={x.id}
                    onClick={() => setShowFieldPopup(true)}
                    className={styles.actionItem}
                  >
                    <Icon name="add" size="24" />
                    Add Fields
                  </button>
                  <button
                    onClick={() => {
                      setViewFields(!viewFields);
                      setShowActionBtn(false);
                    }}
                    className={styles.actionItem}
                  >
                    {viewFields ? (
                      <>
                        <Icon name="hide" size="24" />
                        Hide Fields
                      </>
                    ) : (
                      <>
                        <Icon name="eye" size="24" />
                        View Fields
                      </>
                    )}
                  </button>
                  <button
                    onClick={() => {
                      setIsUpdate(true);
                    }}
                    className={styles.actionItem}
                  >
                    <Icon name="edit" size="24" />
                    Edit
                  </button>
                  <button
                    id={x.id}
                    onClick={() => {
                      setShowDeleteModal(true);
                    }}
                    className={cn(styles.actionItem, styles.deleteItem)}
                  >
                    <Icon name="trash" size="24" />
                    Delete
                  </button>
                </div>
              </div>
            </OutsideClickHandler>
            <DeleteModal
              visibleDelete={showDeleteModal}
              setVisibleDelete={setShowDeleteModal}
              button={true}
              deleteBtn={
                <button
                  className="button"
                  id={x.id}
                  style={{ width: 150 }}
                  onClick={async (e) => {
                    setDeleteLoading(true);
                    const { data } = await deleteCheckList(x?.checkListId);
                    if (data) {
                      setChecklists([]);
                      toast.success(
                        "Successfully deleted checklist",
                        toastConfiguration
                      );
                      await getCheckListFunction(1);
                    } else {
                      toast.error(
                        "Error in deleting checklist",
                        toastConfiguration
                      );
                    }

                    setDeleteLoading(false);
                    setShowActionBtn(false);
                    setShowDeleteModal(false);
                  }}
                >
                  {deleteLoading ? (
                    <Spinner size="24" color="white" />
                  ) : (
                    "Yes I'm sure!"
                  )}
                </button>
              }
            />
          </div> */}
      </div>
      {/* <Card
          className={cn(styles.checklistField, {
            [styles.showField]: viewFields,
          })}
        >
          {x?.fields?.map((x, index) => (
            <div
              className={styles.dragWrap}
              draggable={sortLoading ? false : true}
              key={index}
              onDragStart={(e) => (dragItem.current = index)}
              onDragEnter={(e) => (dragOverItem.current = index)}
              onDragEnd={handleSort}
              onDragOver={(e) => e.preventDefault()}
            >
              <SingleField
                x={x}
                remove={removeField}
                updateFields={updateFields}
                deleteFieldLoading={deleteFieldLoading}
                setChecklists={setChecklists}
                getCheckListFunction={getCheckListFunction}
              />
            </div>
          ))}
        </Card> */}
      <DeleteModal
        visibleDelete={showDeleteModal}
        setVisibleDelete={setShowDeleteModal}
        button={true}
        deleteBtn={
          <button
            className='button'
            id={x.id}
            style={{ width: 150 }}
            onClick={async (e) => {
              setDeleteLoading(true);
              const { data } = await deleteCheckList(x?.checkListId);
              if (data) {
                setTargetId(null)
                setChecklists([]);
                toast.success(
                  "Successfully deleted checklist",
                  toastConfiguration
                );
                await getCheckListFunction(1);
              } else {
                toast.error("Error in deleting checklist", toastConfiguration);
              }

              setDeleteLoading(false);
              setShowActionBtn(false);
              setShowDeleteModal(false);
              setTargetId(null);
            }}
          >
            {deleteLoading ? (
              <Spinner size='24' color='white' />
            ) : (
              "Yes I'm sure!"
            )}
          </button>
        }
      />
      <Modal
        visible={isUpdate}
        onClose={() => {
          setIsUpdate(false);
          setUserInput({ checklistName: x.checklistName });
          setCurrentChecklistId(null);
        }}
        children={
          <Card
            /* classTitle="title-blue" */
            title='Checklist'
            className={cn(styles.card, styles.checklistUpdatePopup)}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
              }}>
                <TextInput
                  className={styles.input}
                  label='Name'
                  placeholder='Enter the name for this checklist'
                  onChange={handleChange}
                  name='checklistName'
                  id='checklistName'
                  type='text'
                  value={userInput.checklistName}
                  required
                />
                <button
                  className='button'
                  style={{ width: 100 }}
                >
                  {updateLoading ? (
                    <Spinner size='24' color='white' />
                  ) : (
                    <>Save</>
                  )}
                </button>
              </div>
              <div
                className={cn(styles.newChecklistBtn)}
              >
                <button
                  className='button-stroke'
                  style={{ width: 100 }}
                  onClick={() => {
                    setIsUpdate(false);
                    setUserInput({ checklistName: x.checklistName });
                    setCurrentChecklistId(null);
                  }}
                >
                  Close
                </button>
              </div>
            </form>
            <Card className={cn(styles.checklistField, styles.showField)}>
              {x?.fields?.map((x, index) => (
                <div
                  className={styles.dragWrap}
                  draggable={sortLoading ? false : true}
                  key={index}
                  onDragStart={(e) => (dragItem.current = index)}
                  onDragEnter={(e) => (dragOverItem.current = index)}
                  onDragEnd={handleSort}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <SingleField
                    x={x}
                    remove={removeField}
                    updateFields={updateFields}
                    deleteFieldLoading={deleteFieldLoading}
                    setChecklists={setChecklists}
                    getCheckListFunction={getCheckListFunction}
                    sortLoading={sortLoading}
                    currentSortedfieldId={currentSortedfieldId}
                  />
                </div>
              ))}
            </Card>
            <div className={styles.addFieldBtnWrap}>
              {/* add field popup */}
              {showFieldPopup ? (
                <NewFieldForm
                  CreateField={createField}
                  x={x}
                  getCheckListFunction={getCheckListFunction}
                  setChecklists={setChecklists}
                  setShowFieldPopup={setShowFieldPopup}
                />
              ) : (
                <button
                  id={x.id}
                  onClick={() => setShowFieldPopup(true)}
                  className={styles.addFieldBtn}
                >
                  <Icon name='plus-circle' size='24' />
                  <span>Add a field</span>
                </button>
              )}
            </div>
          </Card>
        }
      />
      {/* Preview Popup */}
      {/* {showPreview ? (
        <Modal
          visible={showPreview}
          onClose={() => setShowPreview(false)}
          children={
            <Card
              classTitle="title-blue"
              title="View Checklist"
              className={styles.card}
            >
              <div className={styles.title}>{x.checklistName}</div>
              <div className={styles.previewFieldsBody}>
                {x?.fields?.map((x, index) => (
                  <PreviewFields x={x} key={index} />
                ))}
              </div>
            </Card>
          }
        />
      ) : null} */}
    </>
  );
};

export default SingleChecklist;
