import React from "react";
import styles from "./ContractDisplay.module.sass";
import cn from "classnames";
import { useHistory } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { directContractAddSignature, directContractGetDetail } from "../../../utils/apiCallHanlder";
import { toast, ToastContainer } from "react-toastify";
import { dateFormatterWithTime, toastConfiguration } from "../../../utils/utils";
import Icon from "../../../components/Icon";
import { Markup } from "interweave";
import TextInput from "../../../components/TextInput";
import Spinner from "../../../utils/spinner";

const ContractDisplay = () => {

  const history = useHistory();
  const [contractDetail, setContractDetail] = useState(null)
  const [contactSignatures, setContactSignatures] = useState([])
  const [showSignHere, setShowSignHere] = useState(false)
  const [signature, setSignature] = useState("")
  const [loading, setLoading] = useState(true)
  const [updateLoading, setUpdateLoading] = useState(false)
 
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const tokenId = params.get("token") || params.get("tokenid");

  useEffect(async () => { 
    if (tokenId) {
      const { code, data, error, message } = await directContractGetDetail({}, tokenId);
      setLoading(false);
      if (code === 200 && data) {
        setContractDetail(data.contract);
        setContactSignatures(data.contract?.contactSignatures || []);
      }
      else
      {
        toast.error(error, toastConfiguration)
        alert(error);
        history.push('/');
      }

    }
    else
    {
      setLoading(false);
      alert('Contract token not found');
      
      toast.error('Contract token not found', toastConfiguration)
      history.push('/');
    }
  }, [])

  const addSignature = async () => {
    if (!signature) {
      toast.error("Signature is required", toastConfiguration)
    }
    else
    {
      setUpdateLoading(true);
       const { data, error } = await directContractAddSignature({
        Signature: signature
       }, tokenId);
      if (data) {
        setShowSignHere(false)
        setContractDetail(data.contract);

        setContactSignatures(data.contract?.contactSignatures|| []);

        toast.success("Your signature is added.", toastConfiguration);
      } else {
        toast.error( error || "Error in adding signature", toastConfiguration);
      }
      setUpdateLoading(false);
    }
  };


  return (
    <>
    <ToastContainer></ToastContainer>
    {loading ? <Spinner loading={updateLoading} size={15} /> : (
                   null
                  )}
{contractDetail &&    <div className={styles.agreementWrap}>
      <div className={styles.head}>{contractDetail?.contractName}</div>
      
      <div className={styles.para}>
        <Markup content={contractDetail?.contractDescription} /> 
      </div>
      
      {contactSignatures.map((x, index) => {
         
      return  <div className={cn(styles.row, styles.signRow)}>

              <div className={styles.signedWrap}>
                <h3 className={styles.signTitle}>Signed by: </h3>
                <p className={styles.signSignature}>{x.signature}</p>
                <p className={styles.signName}>{x.signature || x.personName}</p>
                <p className={styles.signName}>{dateFormatterWithTime(x.signedOn, true)} </p>
              </div>
        </div>
      })}
{ (!contractDetail?.isSignedByCompany || !contractDetail?.isSignedByContact)?(
      <div className={styles.signedWrap}>
        {!showSignHere && 
          <button className={cn("button", styles.signedBtn)}
                onClick={() =>{setSignature(''); setShowSignHere(true)}}>
              <Icon name="edit" size="24" />
              Sign here
          </button>
        }
        {showSignHere && 
          <>
            <div className={styles.row}>
              <div className={styles.field}>
                    <TextInput
                      onChange={(event) => setSignature(event?.target?.value)}
                      name="signature"
                      type="text"
                      className={styles.signature}
                      value={signature}
                      placeholder="Sign Here"
                      required
                    />
              </div>
            </div>
            <div className={styles.row}  style={{ marginTop: 20 }}>
              <>
                <button
                  onClick={async () => await addSignature()}
                  className={cn("button", styles.signedBtn)}
                  style={{ width: 200,  display: "inline" }}
                >

                  {updateLoading ? <Spinner loading={updateLoading} size={15} /> : (
                    <>
                     <Icon name="edit" size="24" />
                      Sign Contract
                    </>
                  )}
                </button>

                <button
                  onClick={() => setShowSignHere(false)}
                  className={cn("button-stroke-red", styles.button)}
                  style={{ width: 150 , marginLeft:12}}
                >
                      Remove Field
                </button>
              </>
            </div>
          </>
          }

      </div>

        ):null}
    </div>}
    </>
  );
};

export default ContractDisplay;
