import { useEffect, useRef, useState } from "react";
import Card from "../../../../components/Card";
import Checkbox from "../../../../components/Checkbox";
import TextInput from "../../../../components/TextInput";
import styles from './DealSheet.module.sass';
import Search from "../Search";
import Icon from "../../../../components/Icon";
import GeneralDetails from "./GeneralDetails";
import LoanDetails from "./LoanDetails";
import CompanyDetails from "./CompanyDetails";
import ProofAndFunds from "./ProofAndFunds";
import ProfitAndLoss from "../../../Accounting/ProfitAndLoss";
import BalanceSheet from "../../../Accounting/BalanceSheet";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { generatePropertyLink, searchPropertyDocument, sendLoanApplicationWithPdf, uploadFile } from "../../../../utils/apiCallHanlder";
import { useHistory } from "react-router-dom";
import Spinner from "../../../../utils/spinner";
import { ToastContainer, toast } from "react-toastify";
import { dateFormatterWithTime, toastConfiguration } from "../../../../utils/utils";
import documentStyles from '../../EditNewProject/Documents/Documents.module.sass';
import cn from 'classnames';
import SaveSuccess from "../../../../components/SaveSuccess";
import Modal from "../../../../components/Modal";
import { icons } from "../../../../utils/appConstanst";

const DealSheet = ({
    matchedLendersCount,
    property,
    accounts,
    transactions,
    companyDetails,
    matchedLendersId
}) => {
    const history = useHistory();
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    const [isLoanDetails, setIsLoanDetails] = useState(false);
    const [isProofOfFunds, setIsProofOfFunds] = useState(false);
    const [isCompanyPL, setIsCompanyPL] = useState(false);
    const [isCompanyBalanceSheet, setIsCompanyBalanceSheet] = useState(false);
    const [isCompanyDetails, setIsCompanyDetails] = useState(false);

    const [searchString, setSearchString] = useState("");
    const [selectedDocumentName, setSelectedDocumentName] = useState(null);
    const [showAddDocument, setShowAddDocument] = useState(true);
    const [visible, setVisible] = useState(false);
    const [documentsList, setDocumentsList] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [documents, setDocuments] = useState([]);

    const [pdfLoading, setPdfLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [showMapControl, setShowMapControl] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [generatedDoc, setGeneratedDoc] = useState(null);
    const [showDownloadModal, setShowDownloadModal] = useState(false);

    const [pdfUrl, setPdfUrl] = useState(null);
    const [propertyLinkUrl, setPropertyLinkUrl] = useState(null);

    const [plData, setPlData] = useState(null);
    const [balanceSheetData, setBalanceSheetData] = useState(null);
    const [generatePropertyLinkLoading, setGeneratePropertyLinkLoading] = useState(false);

    const [checkboxes, setCheckboxes] = useState([
        // {
        //     name: 'Loan Details',
        //     value: false,
        //     setValue: setIsLoanDetails,
        //     component: <LoanDetails property={property} />
        // },
        {
            name: 'Proof of funds',
            value: false,
            setValue: setIsProofOfFunds,
            isSelectedAccountsUpdated: false,
            component: <ProofAndFunds
                accounts={accounts}
                transactions={transactions}
                filteredAccounts={filteredAccounts}
                selectedAccounts={selectedAccounts}
                setSelectedAccounts={setSelectedAccounts}
            />
        },
        {
            name: 'Company P&L',
            value: false,
            setValue: setIsCompanyPL,
            component: <Card title={"Company P&L"} className={styles.companyCard} id="printAbleDiv">
                <ProfitAndLoss
                    hideTabs={true}
                    setPlData={setPlData}
                />
            </Card>
        },
        {
            name: 'Company Balance Sheet',
            value: false,
            setValue: setIsCompanyBalanceSheet,
            component: <Card title={"Balance Sheet"} className={styles.companyCard} id="printAbleDiv">
                <BalanceSheet
                    hideTabs={true}
                    setBalanceSheetData={setBalanceSheetData}
                />
            </Card>
        },
        {
            name: 'Company Details',
            value: false,
            setValue: setIsCompanyDetails,
            component: <CompanyDetails companyDetails={companyDetails} />
        },
    ]);

    useEffect(async () => {
        if (property) await generatePropertyLinkHandler("https://dummyUrl.com");
    }, [checkboxes, documentsList])

    useEffect(() => {
        if (accounts) {
            setSelectedAccounts([accounts[0]?.id]);
            setFilteredAccounts([accounts[0]?.id])
        }
    }, [accounts])

    useEffect(async () => {
        if (generatedDoc) {
            var file_name = "Property.pdf";

            var file_object = new File([generatedDoc?.output("blob")], file_name, {
                type: "application/pdf",
            });

            const formData = new FormData();
            formData.append("", file_object);
            const { data, error } = await uploadFile(formData);
            
            if (data) {
                setPdfUrl(data?.file?.url);
                await generatePropertyLinkHandler(data?.file?.url);
            } else {
                toast.error( error || "Error in uploading file", toastConfiguration);
            }

            setGeneratedDoc(null);
            setPdfLoading(false);
        }
    }, [generatedDoc])

    const generatePropertyLinkHandler = async (pdf) => {
        setGeneratePropertyLinkLoading(true);
        const _data = {
            property: property,
            transactions: isProofOfFunds ? transactions : null,
            accounts: isProofOfFunds ? accounts?.filter(item => selectedAccounts?.includes(item.id)) : null,
            companyDetails: isCompanyDetails ? companyDetails : null,
            plData: isCompanyPL ? plData : null,
            balanceSheetData: isCompanyBalanceSheet ? balanceSheetData : null,
            documents: documentsList
        };

        const { data } = await generatePropertyLink({
            dataString: JSON.stringify(_data),
            returnUrl: 'https://my.greatweek.com',
            propertyId: property?.propertyId,
            pdfUrl: pdf
        })
        if (data) {
            setPropertyLinkUrl(data?.propertyLinkUrl);
        }
        setGeneratePropertyLinkLoading(false);
    }

    const sendApplicationToAllLenders = async () => {
        if (!pdfUrl) {
            toast.error("No Pdf is attached", toastConfiguration);
            return;
        }

        setSaveLoading(true);
        const { data, error } = await sendLoanApplicationWithPdf({
            lenderIds: matchedLendersId,
            propertyId: property?.propertyId,
            pdfUrl: pdfUrl,
        });

        if (data) {
            setShowDownloadModal(false);
            setShowSuccess(true);
            setPdfUrl(null);
        } else {
            toast.error(error || "Error in sending loan application", toastConfiguration);
        }

        setSaveLoading(false);
    }

    const documentRemoveHandler = (item) => {
        const filteredArray = documentsList?.filter(document => document?.id !== item?.id);
        if (filteredArray?.length === 0) setShowAddDocument(true)
        setDocumentsList([...filteredArray]);
    }

    const handleDrag = (e) => {
        e.preventDefault();

        let _fields = [...checkboxes];

        if (!dragOverItem?.current && dragOverItem?.current !== 0) {
            return;
        }

        const draggedItem = _fields[dragItem.current];
        _fields[dragItem?.current] = _fields[dragOverItem?.current];
        _fields[dragOverItem?.current] = draggedItem;


        dragItem.current = null;
        dragOverItem.current = null;
        setCheckboxes([..._fields]);
    }

    const generatePdf = async (retrunPdf) => {
        setPdfLoading(true);
        const hideElementsMapClass1 = document?.getElementsByClassName('gmnoprint');
        const hideElementsMapClass2 = document?.getElementsByClassName('gm-style-cc');
        const documentNameList = document?.getElementsByClassName('documentName');
        const crouselList = document?.getElementsByClassName('react-multi-carousel-list');
        const proofFundSection = document?.getElementById('proofFund');

        if (crouselList[0] && proofFundSection) {
            proofFundSection.style.width = `${crouselList[0]?.scrollWidth < 1000 ? 1000 : crouselList[0]?.scrollWidth}px`;
        }

        for (let i = 0; i < hideElementsMapClass1?.length; i++) {
            if (hideElementsMapClass1[i]) {
                hideElementsMapClass1[i].style.display = 'none';
            }
        }

        for (let i = 0; i < hideElementsMapClass2?.length; i++) {
            if (hideElementsMapClass2[i]) {
                hideElementsMapClass2[i].style.display = 'none';
            }
        }

        for (let i = 0; i < documentNameList?.length; i++) {
            if (documentNameList[i]) {
                documentNameList[i].style.color = 'blue';
            }
        }

        setShowMapControl(false);


        const content = document?.querySelector('#pdf-container');
        const divs = content?.querySelectorAll('#printAbleDiv');
        const doc = new jsPDF('portrait', 'mm', 'a4', true);

        for (let i = 0; i < divs?.length; i++) {
            if (divs[i]) {
                divs[i].style.backgroundColor = '#FFFFFF';
            }
        }

        let page = 1;

        function addContentToPDF(index) {
            if (index < divs?.length) {
                if (index > 0) {
                    doc.addPage();
                    page = page + 1;
                }

                html2canvas(divs[index], {
                    useCORS: true
                }).then(canvas => {
                    let imgData = canvas?.toDataURL('image/png');
                    let imgWidth = 210;
                    let pageHeight = 295;
                    let imgHeight = canvas.height * imgWidth / canvas.width;
                    let heightLeft = imgHeight;
                    let position = 10;

                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;

                    while (heightLeft >= 0) {
                        position += heightLeft - imgHeight;
                        doc.addPage();
                        page = page + 1;
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                    }

                    addContentToPDF(index + 1);
                });
            } else {
                for (let i = 0; i < hideElementsMapClass1?.length; i++) {
                    if (hideElementsMapClass1[i]) {
                        hideElementsMapClass1[i].style.display = 'unset';
                    }
                }

                for (let i = 0; i < hideElementsMapClass2?.length; i++) {
                    if (hideElementsMapClass2[i]) {
                        hideElementsMapClass2[i].style.display = 'unset';
                    }
                }

                for (let i = 0; i < documentNameList?.length; i++) {
                    if (documentNameList[i]) {
                        documentNameList[i].style.color = 'unset';
                    }
                }

                for (let i = 0; i < divs?.length; i++) {
                    if (divs[i]) {
                        divs[i].style.backgroundColor = '#FFFDF9';
                    }
                }

                if (proofFundSection) proofFundSection.style.width = 'unset';
                setShowMapControl(true);

                const textX = 7, textY = 42;
                documentsList?.map((item, index) => {
                    doc.link(textX, textY + (index * 10), 40, 5, {
                        url: item?.link,
                        pageNumber: page,
                        magFactor: 'Fit',
                    });
                })

                if (retrunPdf) {
                    setGeneratedDoc(doc);
                    return;
                };
                doc.save('property.pdf');
                setPdfLoading(false);
            }
        }

        addContentToPDF(0);
    }

    const getDocuments = async (str) => {
        const { data } = await searchPropertyDocument({
            entityId: property?.propertyId,
            searchString: str || ''
        })
        if (data) {
            setDocuments(data?.documents);
        }
    }

    const saveHandler = async () => {
        setSaveLoading(true);
        generatePdf(true);
    }

    const downloadPdfHandler = async () => {
        fetch(pdfUrl)
            ?.then(response => {
                response.blob()?.then(blob => {
                    let url = window?.URL?.createObjectURL(blob);
                    let a = document?.createElement('a');
                    a.href = url;
                    a.download = `${property?.name}-property.pdf`;
                    a.click();
                });
            })
    }

    return (
        <div className={styles.mainContainer}>
            <Modal
                visible={showDownloadModal}
                onClose={() => {
                    setPdfUrl(null);
                    setShowDownloadModal(false);
                }}
                outerClassName={styles.modalOuter}
                children={
                    <div className={styles.modalContentContainer}>
                        <div className={styles.iconContainer}>
                            <Icon name='propertyDownload' fill="white" size="100" viewBox="0 0 100 100" />
                        </div>
                        <div className={styles.title}>{property?.name}</div>
                        {pdfLoading ?
                            <>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div className={styles.downloadLink}>Generating link...</div>
                                    <div style={{ marginLeft: 16, marginTop: 8 }}>
                                        <Spinner size="24" color={"gray"} />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className={styles.downloadContainer}>
                                    <div className={styles.downloadLink}>{`${propertyLinkUrl?.slice(0, 36)}...`}</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
                                        <img src="/images/copyLink.svg" className={styles.copy} onClick={() => {
                                            navigator?.clipboard?.writeText(propertyLinkUrl);
                                            toast.success("Pdf link copied successfully", toastConfiguration);
                                        }} />
                                        <div className={styles.downloadIcon} onClick={downloadPdfHandler}>
                                            <Icon name="download" size="24" fill="#464542" />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.downloadButtonContainer}>
                                    <button className="button-stroke-red" onClick={sendApplicationToAllLenders}>
                                        Send loan application to matched lenders
                                    </button>
                                    <button className="button" onClick={() => {
                                        history.push("/projects");
                                    }}>Done</button>
                                </div>
                            </>
                        }
                    </div>
                }
            />
            < SaveSuccess
                showSuccessModal={showSuccess}
                text={`You have submitted your loan application to ${matchedLendersCount} lenders. We’ll notify you once you have an offer to review.`}
                onClose={() => setShowSuccess(false)}
                onConfirm={() => setShowSuccess(false)}
            />
            < ToastContainer />
            <Card
                title="Deal Sheet"
                className={styles.card}
                classCardHead={styles.cardHead}
                head={
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
                        <img src="/images/copyLink.svg" className={styles.copy} onClick={() => {
                            navigator?.clipboard?.writeText(propertyLinkUrl);
                            toast.success("Property link copied successfully", toastConfiguration);
                        }} />
                        {pdfLoading ?
                            <Spinner size="24" color="gray" />
                            :
                            <div className={styles.downloadIcon} onClick={() => generatePdf(false)}>
                                <Icon name="download" size="24" fill="#464542" />
                            </div>
                        }
                    </div>
                }
            >
                <div className={styles.dealSheetContainer}>
                    {/* <TextInput
                        label="Apply for loan with:"
                        name="appliedLoan"
                        type="text"
                        className={styles.input}
                        value={`${matchedLendersCount} Matched Lenders`}
                        required
                    /> */}

                    {generatePropertyLinkLoading ?
                        <div className={styles.linkLoadingWrapper}>
                            <span>Generating Link</span>
                            <Spinner size="24" color="gray" />
                        </div>
                        :
                        <div className={styles.propertyLink} onClick={() => {
                            navigator?.clipboard?.writeText(propertyLinkUrl);
                            toast.success("Property link copied successfully", toastConfiguration);
                        }}>
                            {propertyLinkUrl}
                        </div>
                    }

                    <div className={styles.label} style={{ marginTop: 12 }}>Include</div>
                    {checkboxes?.map((item, index) => {
                        return <>
                            <div
                                draggable
                                key={index}
                                className={styles.checkboxContainer}
                                onDragStart={() => (dragItem.current = index)}
                                onDragEnter={() => (dragOverItem.current = index)}
                                onDragOver={(e) => e.preventDefault()}
                                onDragEnd={handleDrag}
                            >
                                <div style={{ cursor: 'pointer' }}>
                                    <Icon name="drag" size="25" viewBox="0 0 25 25" />
                                </div>
                                <Checkbox
                                    className={styles.checkbox}
                                    classCheckboxTick={styles.checkboxTick}
                                    value={item?.value}
                                    onChange={() => {
                                        item.setValue(!item?.value);
                                        const updatedCheckboxes = checkboxes?.map(checkbox => {
                                            if (checkbox.name === item.name) {
                                                return {
                                                    ...checkbox,
                                                    value: !item.value
                                                }
                                            }
                                            return { ...checkbox }
                                        });
                                        setCheckboxes([...updatedCheckboxes]);
                                    }}
                                />
                                <span>{item?.name}</span>
                            </div>
                            {(isProofOfFunds && item?.name === "Proof of funds") && (
                                <div style={{ marginLeft: 72 }}>
                                    {accounts?.map((account, index) => {
                                        return <div className={styles.checkboxContainer} key={index}>
                                            <Checkbox
                                                className={styles.checkbox}
                                                classCheckboxTick={styles.checkboxTick}
                                                value={filteredAccounts?.includes(account?.id)}
                                                onChange={() => {
                                                    if (filteredAccounts?.includes(account?.id)) {
                                                        const newAccounts = filteredAccounts?.filter(x => x !== account?.id);
                                                        if (newAccounts?.length < 1) newAccounts?.push(accounts[0]?.id);
                                                        setFilteredAccounts([...newAccounts]);
                                                        setSelectedAccounts([...newAccounts]);
                                                    } else {
                                                        setFilteredAccounts([...filteredAccounts, account?.id]);
                                                        setSelectedAccounts([...selectedAccounts, account?.id]);
                                                    }

                                                    const updatedCheckboxes = checkboxes?.map(checkbox => {
                                                        if (checkbox.name === item.name) {
                                                            return {
                                                                ...checkbox,
                                                                isSelectedAccountsUpdated: !checkbox?.isSelectedAccountsUpdated
                                                            }
                                                        }
                                                        return { ...checkbox }
                                                    });
                                                    setCheckboxes([...updatedCheckboxes]);
                                                }}
                                            />
                                            <span>{account?.name}</span>
                                        </div>
                                    })}
                                </div>
                            )}
                        </>
                    })}
                    <div className={styles.documentContainer}>
                        <div className={styles.label}>Documents</div>
                        {documentsList?.length > 0 &&
                            <>
                                {documentsList?.map((item, index) => {
                                    return <>
                                        <div className={styles.singleDocument} key={index}>
                                            <a
                                                className={styles.documentName}
                                                href={item?.link}
                                                download="attachment"
                                                target='_blank'
                                            >
                                                {item?.name}
                                            </a>
                                            <div style={{ cursor: 'pointer' }} onClick={() => documentRemoveHandler(item)}>
                                                <Icon name="trash" size="24" fill="#FF4900" />
                                            </div>
                                        </div>
                                    </>
                                })}
                                {!showAddDocument && (
                                    <div
                                        className={styles.highlightedText}
                                        onClick={() => setShowAddDocument(true)}
                                    >
                                        Add another document
                                    </div>
                                )}
                            </>
                        }
                        {showAddDocument && (
                            <>
                                <Search
                                    className={styles.search}
                                    inputPlaceholder='Search or add'
                                    addNewBtnTxt='Add new Document'
                                    searchNames={documents}
                                    propertyId={property?.propertyId}
                                    selectedCustomerName={selectedDocumentName}
                                    setSearchString={setSearchString}
                                    visible={visible}
                                    setVisible={setVisible}
                                    setSearchNames={setDocuments}
                                    searchString={searchString}
                                    selectCustomerLoading={false}
                                    hideAdd={false}
                                    getDocuments={getDocuments}
                                    nameClickHandler={(name, id, link, x) => {
                                        const isPresent = documentsList?.filter(item => item?.id === id);

                                        if (isPresent?.length < 1) {
                                            setDocumentsList([...documentsList, {
                                                ...x,
                                                name,
                                                id,
                                                link
                                            }]);
                                        }
                                        setShowAddDocument(false);
                                        setVisible(false);
                                    }}
                                />
                            </>
                        )}
                    </div>
                    <div className={styles.buttonContainer}>
                        {/* <button className="button" onClick={() => {
                            setPdfUrl(null);
                            setPropertyLinkUrl(null);
                            setShowDownloadModal(true);
                            generatePdf(true);
                        }}>
                            Next
                        </button> */}
                        {/* <button className="button-stroke-red" style={{ width: 120 }} onClick={async () => {
                            !pdfLoading && await generatePdf();
                        }}>
                            {pdfLoading ?
                                <Spinner size={"24"} color="gray" />
                                :
                                "Download"
                            }
                        </button>

                        <button className="button" style={{ width: 80 }} onClick={saveHandler}>
                            {saveLoading ?
                                <Spinner size={"24"} color="white" />
                                :
                                "Send"
                            }
                        </button> */}
                    </div>
                </div>
            </Card>
            <div className={styles.detailContainer} id="pdf-container">
                <GeneralDetails
                    property={property}
                    showMapControl={showMapControl}
                />
                <div>
                    {checkboxes?.map((item, index) => {
                        return item?.value && (
                            item?.name === "Proof of funds" ? <div key={index} id="proofFund"><ProofAndFunds
                                accounts={accounts}
                                transactions={transactions}
                                filteredAccounts={filteredAccounts}
                                selectedAccounts={selectedAccounts}
                                setSelectedAccounts={setSelectedAccounts}
                            /> </div>
                                :
                                <div key={index}>{item?.component}</div>
                        )
                    })}
                </div>
                {documentsList?.length > 0 && (
                    <Card
                        title={"Document Appendix"}
                        className={styles.companyCard}
                        id="printAbleDiv"
                    >
                        <div className={documentStyles.table}>
                            <div className={documentStyles.head}>
                                <div className={cn(documentStyles.col, documentStyles.colName)}>Name</div>
                                <div className={cn(documentStyles.col, documentStyles.colName)}>Type</div>
                            </div>
                            {documentsList?.map((x, index) => (
                                <div className={documentStyles.row} key={index}>
                                    <div className={documentStyles.col}>
                                        <p className={documentStyles.mblLabel}>Name</p>
                                        <span className="documentName">{x.displayName}</span>
                                    </div>
                                    <div className={documentStyles.col}>
                                        <p className={documentStyles.mblLabel}>Type</p>
                                        <span>{x.documentType}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Card>
                )}
            </div>
        </div >
    );
};

export default DealSheet;
