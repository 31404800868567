import React, { useState } from "react";
import styles from "./Details.module.sass";
import TextInput from "../../../../components/TextInput";
import cn from "classnames";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Card from "../../../../components/Card";
import Tooltip from "../../../../components/Tooltip";
import Image from "../../../../components/Image";
import Icon from "../../../../components/Icon";
import Dropdown from "../../../../components/Dropdown_c";
import { getCompanyTextSettings, uploadProductImage } from "../../../../utils/apiCallHanlder";
import { useEffect } from "react";

const Details = ({
  className,
  name,
  setName,
  description,
  setDescription,
  setValueHandler,
  taxCodeName,
  setTaxCodeName,
  taxCode,
  setTaxCode,
  setImageUrl,
  nameError,
  taxcodes,
  setNameError,
  archived,
  imageUrl,
  taxCodesByGroups,
  setUploadingError,
  isAdd,
  uploadingError,
  UploadingError,
  comingFromInvoice,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [defaultTaxCategory, setdefaultTaxCategory] = useState("");
  const [searchString, setSearchString] = useState(taxCodeName || "");

  useEffect(() => {
    setSearchString(taxCodeName);
  }, [taxCodeName]);

  const imageUploadHandler = async (imageFile) => {
    const supportedTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"]; // Add supported image file types here

    if (!supportedTypes.includes(imageFile.type)) {
      setUploadingError("Please Upload image of type .jpg,.jpeg,.gif,.png.");
    }
    setSelectedImage(imageFile);

    const formData = new FormData();
    formData.append("", imageFile);
    const uploadImageResponse = await uploadProductImage(formData);
    const { data } = uploadImageResponse;
    if (data) {
      setUploadingError("");
      setImageUrl(data.image.url);
    } else {
    }
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={isAdd ? "Create new product" : "Details"}
        /* classTitle="title-blue" */
        classCardHead={styles.cardHead}
      >
        <div className={styles.row}>
          <div className={styles.col} style={comingFromInvoice && { marginTop: "unset" }}>
            <div className={styles.field} style={archived ? { pointerEvents: "none" } : null}>
              <TextInput
                style={
                  name === "" ? { backgroundColor: "#FFBC9925", borderColor: "#FFBC9925" } : null
                }
                label="Name"
                name="Name"
                onChange={(event) => setValueHandler(event?.target?.value, setName)}
                value={name}
                type="text"
                //tooltip='Products are the goods or services you sell. If you sell subscriptions, you might call them "plans." Product names are visible to customers at checkout, in receipts, invoices, and the customer portal.'
                required
              />
              {nameError !== "" ? (
                <div className={styles.errorContainer}>
                  <span className={styles.errorMessage}>{nameError}</span>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={styles.col}
            style={
              archived
                ? comingFromInvoice
                  ? { marginTop: "unset", pointerEvents: "none" }
                  : { pointerEvents: "none" }
                : comingFromInvoice && { marginTop: "unset" }
            }
          >
            <div className={styles.field} style={{ display: "flex", flexDirection: "column" }}>
              <span className={styles.fieldLabel}>Product Tax Category</span>
              <Dropdown
                inputStyles={
                  searchString === ""
                    ? { backgroundColor: "#FFBC9925", borderColor: "#FFBC9925" }
                    : null
                }
                className={cn(styles.dropdown, styles.userChooseDrop)}
                classDropdownHead={styles.dropdownHead}
                value={taxCode}
                showSearch={"true"}
                setValue={setTaxCode}
                options={taxcodes}
                taxcodes={taxcodes}
                taxCodesByGroups={taxCodesByGroups}
                autoFocus={true}
                taxCodeName={taxCodeName}
                setTaxCodeName={setTaxCodeName}
                hideDropdown={true}
                searchString={searchString}
                setSearchString={setSearchString}
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.fieldLabel}>
              Description
              {/* <span className={styles.optional}>Optional</span> */}
            </div>
            {/* <TextInput
              className={styles.field}
              style={archived ? { pointerEvents: 'none' } : null}
              name="description"
              onChange={(event) => setValueHandler(event?.target?.value, setDescription)}
              value={description}
              type="text"
              required
            /> */}
            <textarea
              rows={"4"}
              className={cn(styles.description, styles.field)}
              style={archived ? { pointerEvents: "none" } : null}
              name="description"
              onChange={(event) => setValueHandler(event?.target?.value, setDescription)}
              value={description}
              type="text"
              required
            ></textarea>
          </div>
          <div className={styles.col}>
            <div className={styles.field} style={archived ? { pointerEvents: "none" } : null}>
              <div className={styles.fieldLabel}>
                <span>Image</span>
                {/* <Tooltip
                  className={styles.tooltip}
                  title="Optional upload of a JPEG or PNG that is less than 2MB. The images will be shown on all customer facing channels. "
                  icon="info"
                  place="right"
                /> */}
                {/* <span className={styles.optional}>Optional</span> */}
              </div>
              <div className={styles.inputWrap}>
                {selectedImage || imageUrl ? (
                  <div className={styles.selectedImageWrap}>
                    <Image
                      className={styles.selectedImage}
                      src={selectedImage ? URL.createObjectURL(selectedImage) : imageUrl}
                      srcDark={selectedImage ? URL.createObjectURL(selectedImage) : imageUrl}
                    />
                    {!archived ? (
                      <div className={styles.action}>
                        <button
                          onClick={() => {
                            setSelectedImage(null);
                            setImageUrl("");
                            setUploadingError("");
                          }}
                        >
                          <Icon name="close" size="16" />
                        </button>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className={styles.imgInput} style={{ width: 100, height: 100 }}>
                    <label
                      className={cn(selectedImage ? styles.inputLabelImg : styles.inputLabel)}
                      style={{ cursor: "pointer" }}
                      htmlFor="fileInput"
                    >
                      <Icon name="add" />
                    </label>
                    <input
                      type="file"
                      className={styles.input}
                      name="myImage"
                      id="fileInput"
                      onChange={(event) => imageUploadHandler(event.target.files[0])}
                    />
                  </div>
                )}
              </div>
              {uploadingError !== "" && uploadingError === "No error" ? (
                <div className={styles.errorContainer}>
                  <span className={styles.successMessage}>Uploaded Successfully</span>
                </div>
              ) : (
                <div className={styles.errorContainer}>
                  <span className={styles.errorMessage}>{}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Details;
