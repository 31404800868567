import React, { useEffect, useState } from 'react';
import { icons, iconsLoans } from '../../../../utils/appConstanst';
import Icon from '../../../../components/Icon';
import cn from 'classnames';
import TextInput from '../../../../components/TextInput';
import { addProperty, deleteSingleProperty, updateSingleProperty, uploadFile } from '../../../../utils/apiCallHanlder';
import Dropdown from '../../../../components/Dropdown';
import Spinner from '../../../../utils/spinner';
import styles from './Details.module.sass';
import { useDropzone } from 'react-dropzone';
import { GetFileNameFromPath, getCompanyData, getNumberWithSpaces, isValidHttpUrl, toastConfiguration } from '../../../../utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import DeleteModal from '../../../../components/DeleteModal';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import Card from '../../../../components/Card';
import Modal from '../../../../components/Modal';
import DateInput from '../../../../components/DateInput'
import Switch from '../../../../components/Switch';
import ImagePreview from '../../../../components/ImagePreview';


const imageWRTExtension = {
    common: "/images/content/image.png",
    xlsx: "/images/content/xlsx.png",
    xls: "/images/content/xlsx.png",
    pdf: "/images/content/pdf.png",
};

const containerStyle = {
    width: '100%',
    height: '409px'
};

const statusDropdownValues = ["Prospect", "Ongoing", "Finished"]
const options = [...Array(60).keys()].map(i => i === 0 ? `${i + 1} Month` : `${i + 1} Months`);

const Details = ({
    setShowAddProject,
    getPropertiesData,
    id,
    property,
    onSaveHandler,
    setProperty,
    name,
    setName,
    matchedLendersCount
}) => {
    const history = useHistory();
    const country = getCompanyData().country || null;
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCTpbuCUVv_C2RRr09oRimIlrjGzcP9C-0'
    })

    const [selectedIcon, setSelectedIcon] = useState(icons[0]?.icon);
    const [selectedLoanIcon, setSelectedLoanIcon] = useState(iconsLoans[0]?.icon);

    const [propertyTypeId, setPropertyTypeId] = useState(1);
    const [loanTypeId, setLoanTypeId] = useState(1);
    const [listedPrice, setListedPrice] = useState("");
    const [address, setAddress] = useState("");
    const [dealDescription, setDealDescription] = useState("");
    const [refurbCost, setRefurbCost] = useState("");
    const [otherCost, setOtherCost] = useState("");

    const [downPayment, setDownPayment] = useState("");
    const [intrestRate, setInterestRate] = useState("");
    const [borrowedCapital, setBorrowedCapital] = useState("");
    const [homeInsurance, setHomeInsurance] = useState("");
    const [hoa, setHoa] = useState("");
    const [propertyTaxes, setPropertyTaxes] = useState("");
    const [maintenance, setMaintenance] = useState("");
    const [minusUtilities, setMinusUtilities] = useState("");
    const [borrowingCost, setBorrowingCost] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [timeline, setTimeline] = useState(options[0]);
    const [sellingCost, setSellingCost] = useState("");
    const [apr, setApr] = useState("");
    const [monthlyCost, setMonthlyCost] = useState("");
    const [holdingCost, setHoldingCost] = useState("");
    const [totalFixedCost, setTotalFixedCost] = useState("");
    const [projectCost, setProjectCost] = useState("");
    const [profit, setProfit] = useState("");
    const [ltv, setLtv] = useState("");
    const [ltc, setLtc] = useState("");

    const [images, setImages] = useState([]);
    const [roiAttachments, setRoiAttachments] = useState([]);
    const [imageUploadLoading, setImageUploadLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [mapCenter, setMapCenter] = useState(null);
    const [link, setLink] = useState("");
    const [status, setStatus] = useState(statusDropdownValues[0]);
    const [showAddFieldPopup, setShowAddFieldPopup] = useState(false);
    const [newPropertyName, setNewPropertyName] = useState("");
    const [propertyFields, setPropertyFields] = useState([]);
    const [imagePreviewPopup, setImagePreviewPopup] = useState(false);
    const [currentlySelectedImage, setCurrentlySelectedImage] = useState(null);

    const [needFinancing, setNeedFinancing] = useState(true);

    const onLoad = React.useCallback(function callback(map) {
        if (mapCenter) {
            const bounds = new window.google.maps.LatLngBounds(mapCenter);
            bounds?.extend(mapCenter);
            map.fitBounds(bounds);
        }
    }, [])


    useEffect(() => {
        if (property) {
            if (property?.address) setMapLocation(property?.address);
            setLoanTypeId(property?.loanTypeId === 0 ? 0 : (property?.loanTypeId || 1));
            setSelectedLoanIcon(iconsLoans[(property?.loanTypeId - 1) || 0]?.icon);
            setPropertyTypeId(property?.propertyTypeId || 1);
            setSelectedIcon(icons[(property?.propertyTypeId - 1 || 0)]?.icon);
            setDealDescription(property?.description || '')
            setName(property?.name || '');
            setListedPrice(property?.listedPrice || '');
            setAddress(property?.address || '');
            setImages(property?.attachments || []);
            setPropertyFields(property?.propertyFields || [])
            setStatus(property?.status || statusDropdownValues[0]);
            setLink(property?.link || '');

            setRefurbCost(property?.refurbCost);
            setOtherCost(property?.otherCost);
            setDownPayment(property?.cashInvested)
            setInterestRate(property?.interestRate)
            setHomeInsurance(property?.homeInsurance)
            setHoa(property?.hoa)
            setPropertyTaxes(property?.propertyTaxes);
            setMaintenance(property?.maintenance);
            setMinusUtilities(property?.otherUtilities)
            setStartDate(new Date(startDate));
            setTimeline(
                !property?.projectTimeline ? '1 Month' :
                    property?.projectTimeline === 1 ? `${property?.projectTimeline} Month` : `${property?.projectTimeline} Months`
            );
            setApr(property?.salePriceAfterCost)
            setSellingCost(property?.sellingCost);
            setNeedFinancing(property?.isFinancingNeeded === false ? false : true);
        }
    }, [property])


    //Formulas
    useEffect(() => {
        const totalFixedPriceCalculated = parseFloat(listedPrice || "0") + parseFloat(refurbCost || "0") + parseFloat(otherCost || "0");
        setTotalFixedCost(totalFixedPriceCalculated);
    }, [listedPrice, refurbCost, otherCost])

    useEffect(() => {
        const borrowedCapitalCalculated = parseFloat(totalFixedCost || "0") -
            parseFloat(downPayment || "0");
        setBorrowedCapital(borrowedCapitalCalculated);
    }, [totalFixedCost, downPayment])

    useEffect(() => {
        const borrowingCostCalculated = (parseFloat(borrowedCapital || "0") * ((parseFloat(intrestRate || "0") / 100))) / 12;
        setBorrowingCost(borrowingCostCalculated);
    }, [borrowedCapital, intrestRate])

    useEffect(() => {
        const monthlyCostCalculated = parseFloat(homeInsurance || "0") +
            parseFloat(hoa || "0") +
            parseFloat(propertyTaxes || "0") +
            parseFloat(maintenance || "0") +
            parseFloat(minusUtilities || "0") +
            parseFloat(borrowingCost || "0");

        setMonthlyCost(monthlyCostCalculated);
    }, [
        hoa,
        homeInsurance,
        propertyTaxes,
        maintenance,
        minusUtilities,
        borrowingCost
    ])

    useEffect(() => {
        const years = parseInt((timeline)?.split(' ')[0]);
        const holdingCostCalculated = parseFloat(monthlyCost || "0") * years;
        setHoldingCost(holdingCostCalculated);
    }, [monthlyCost, timeline])

    useEffect(() => {
        const projectCostCalculated = parseFloat(totalFixedCost || "0") + parseFloat(holdingCost || "0")
        setProjectCost(projectCostCalculated);
    }, [totalFixedCost, holdingCost])

    useEffect(() => {
        const profiltCalculated = parseFloat(apr || "0") -
            parseFloat(projectCost || "0") -
            parseFloat(sellingCost || "0")
        setProfit(profiltCalculated);
    }, [apr, projectCost, sellingCost])

    useEffect(() => {
        const intPrice = parseFloat(listedPrice || "0");
        const intCashDownpayment = parseFloat(downPayment || "0");

        const ltvCalculated = (((intPrice - intCashDownpayment) / (intPrice || 1)) * 100)?.toFixed(2);

        setLtv(ltvCalculated);
    }, [listedPrice, downPayment])

    useEffect(() => {
        const ltcCalculated = ((parseFloat(borrowedCapital || "0")) / (parseFloat(projectCost || 1))) * 100
        setLtc(ltcCalculated?.toFixed(2));
    }, [borrowedCapital, projectCost])

    // End of formulas

    const iconClickHandler = (currentIcon, index) => {
        setPropertyTypeId(index + 1);
        setSelectedIcon(currentIcon);
    }

    const loanIconClickHandler = (currentIcon, index) => {
        setLoanTypeId(index + 1);
        setSelectedLoanIcon(currentIcon);
    }

    const imageUploadHandler = async (event) => {
        const formData = new FormData();
        const files = event?.target?.files;
        let uploadedImagesUrl = [];

        for (let i = 0; i < files?.length; i++) {
            formData.append("", files[i]);
            const { data, error } = await uploadFile(formData);

            if (data) {
                uploadedImagesUrl.push(data?.file?.url);
            }
            else {
                toast.error(error || "Error in uploading file.", toastConfiguration);
            }

            formData.delete("");
        }

        setImages([...images, ...uploadedImagesUrl]);
    };

    const imageRemoveHandler = (img) => {
        const filteredImages = images?.filter(item => item !== img);
        setImages([...filteredImages]);
    }

    const saveClickHandler = async () => {
        if (saveLoading) {
            return;
        }

        if (!name || name?.trim() === "") {
            toast.error("Name is required", toastConfiguration);
            return;
        }


        if (link && !isValidHttpUrl(link)) {
            toast.error("Link should be a valid URL.", toastConfiguration);
            return;
        }

        setSaveLoading(true);
        const payload = {
            propertyTypeId: propertyTypeId,
            loanTypeId: loanTypeId,
            name: name,
            description: dealDescription,
            address: address,
            attachments: images,
            roiAttachments: roiAttachments,
            listedPrice: listedPrice || 0,
            status: status,
            link: link,
            propertyFields: propertyFields || [],

            refurbCost: refurbCost,
            otherCost: otherCost,
            cashInvested: downPayment,
            interestRate: intrestRate,
            homeInsurance: homeInsurance,
            hoa: hoa,
            propertyTaxes: propertyTaxes,
            maintenance: maintenance,
            otherUtilities: minusUtilities,
            startedOn: startDate,
            projectTimeline: parseInt((timeline)?.split(' ')[0]),
            salePriceAfterCost: apr,
            sellingCost: sellingCost,
            isFinancingNeeded: needFinancing,

            //fixed values
            borrowedCapital: borrowedCapital,
            borrowedCost: borrowingCost,
            totalFixedCost: totalFixedCost,
            monthlyCost: monthlyCost,
            projectCost: projectCost,
            profit: profit
        }

        const { data , error} = id ? await updateSingleProperty({
            propertyId: id,
            ...payload
        }) : await addProperty(payload);

        if (data) {
            setProperty(data?.property);
            history.push(`/projects/${data?.property?.propertyId}`);
            //onSaveHandler && onSaveHandler();
            toast.success(`Successfully ${id ? "updated" : "added"} property`, toastConfiguration);
        } else {
            toast.error(error || `Error in ${id ? "updating" : "adding"} property`, toastConfiguration);
        }

        setSaveLoading(false);
    }

    const removeHandler = async (id) => {
        const { data } = await deleteSingleProperty({
            propertyId: id
        })

        if (data) {
            history.push("/projects");
            setShowAddProject(false);
            getPropertiesData(false);
            toast.success(`Property successfully removed`, toastConfiguration);
        } else {
            toast.error(`Error in removing property`, toastConfiguration);
        }
    }

    const setMapLocation = (address) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                if (lat && lng) {
                    setMapCenter({
                        lat: lat,
                        lng: lng
                    })
                }
            }
            );
    }

    const loanApplicationSendHandler = () => {
        history.push({
            pathname: "/projects-download",
            state: {
                propertyId: id
            }
        });
    }


    return (
        <>
            <ImagePreview
                visible={imagePreviewPopup}
                setVisible={setImagePreviewPopup}
                imageUrl={currentlySelectedImage}
            />
            <Modal
                visible={showAddFieldPopup}
                onClose={() => {
                    setShowAddFieldPopup(false);
                }}
                outerClassName={styles.outer}
            >
                <Card
                    title="Add Field"
                    classCardHead={styles.classCardHead}
                >
                    <TextInput
                        label="Name"
                        name="fieldName"
                        type="text"
                        onChange={(e) => {
                            setNewPropertyName(e.target.value);
                        }}
                        value={newPropertyName}
                        required
                    />
                    <button className={cn('button', styles.propertyAddButton)} onClick={() => {
                        if (newPropertyName?.trim() === "") {
                            toast.error("Property name is required", toastConfiguration);
                            return;
                        }

                        const items = propertyFields?.filter(item => item?.name === newPropertyName);
                        if (items?.length > 0) {
                            toast.error("Field already exists with this name", toastConfiguration);
                            return;
                        }

                        setPropertyFields([...propertyFields, {
                            name: newPropertyName,
                            value: "",
                            fieldType: 1
                        }])
                        setShowAddFieldPopup(false);
                    }}>
                        Save
                    </button>
                </Card>
            </Modal>
            <ToastContainer />

            {(id && matchedLendersCount > 0) && (
                <button
                    className={cn('button-stroke-red', styles.downloadButton)}
                    onClick={loanApplicationSendHandler}
                    style={{ width: 126 }}
                >
                    Download/Link
                </button>
            )}

            {id && (
                <DeleteModal
                    icon={<Icon name="trash" size="24" />}
                    button={
                        <button className={cn('button-stroke-red', styles.deleteButton)} onClick={() => {
                            setVisibleDelete(true);
                        }}>
                            Delete
                        </button>
                    }
                    visibleDelete={visibleDelete}
                    setVisibleDelete={setVisibleDelete}
                    btnClass=" "
                    onClickHandler={(id) => removeHandler(id)}
                    id={id}
                />
            )}
            <button className={cn('button', styles.saveButton)} onClick={saveClickHandler} style={{ width: 95 }}>
                {saveLoading ?
                    <Spinner size="24" color="gray" />
                    :
                    "Save"
                }
            </button>

            <div className={styles.detailContainer}>
                <div className={styles.iconContainer}>
                    {icons?.map((item, index) => {
                        return <div
                            className={styles?.singleIconContainer}
                            onClick={() => iconClickHandler(item?.icon, index)}
                            style={{ marginLeft: index === 0 ? -24 : 0 }}
                        >
                            <div className={styles.icon} key={index} style={{
                                backgroundColor: selectedIcon === item?.icon ? "#FF4900" : "#E9E7E0"
                            }}>
                                <Icon name={item?.icon} size="40" viewBox="0 0 40 40" fill={
                                    selectedIcon === item?.icon ? "#FFFDF9" : "#C7C5BF"
                                } />
                            </div>
                            <span>{item?.name}</span>
                        </div>
                    })}
                </div>

                <div className={cn(styles.iconContainer, styles.marginIconContainer)}>
                    {iconsLoans?.slice(0, 1)?.map((item, index) => {
                        return <div
                            className={styles?.singleIconContainer}
                            onClick={() => loanIconClickHandler(item?.icon, index)}
                            style={{ marginLeft: index === 0 ? -24 : 0 }}
                        >
                            <div className={styles.icon} key={index} style={{
                                backgroundColor: selectedLoanIcon === item?.icon ? "#FF4900" : "#E9E7E0"
                            }}>
                                <Icon name={item?.icon} size="40" viewBox="0 0 40 40" fill={
                                    selectedLoanIcon === item?.icon ? "#FFFDF9" : "#C7C5BF"
                                } />
                            </div>
                            <span>{item?.name}</span>
                        </div>
                    })}
                </div>

                <div className={styles.inputContainer}>
                    <div className={styles.firstRow} style={{ marginTop: 36 }}>
                        <TextInput
                            label="Name"
                            name="name"
                            type="text"
                            className={styles.input}
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            required
                        />
                        <div className={styles.input} style={{ position: 'relative' }}>
                            <div className={styles.title} style={{ marginBottom: 5 }}>Address</div>
                            <GooglePlacesAutocomplete
                                autocompletionRequest={{
                                    types: ["geocode"],
                                    componentRestrictions: { country: [country] },

                                }}
                                selectProps={{
                                    placeholder: address ? address : "",
                                    formatOptionLabel: ({ label, description }) => (
                                        <div>
                                            <span>{label}</span>
                                            <span>{description}</span>
                                        </div>
                                    ),
                                    styles: {
                                        input: (provided) => ({
                                            ...provided,
                                            width: "33%",
                                            height: "40px"
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            backgroundColor: '#F4F4F4',
                                            border: '2px solid #C7C5BF',
                                            color: '#46455A',
                                            borderRadius: '0px',
                                            height: '48px',
                                        })
                                    },

                                    address,
                                    onChange: (e) => {
                                        setAddress(e.label);
                                        setMapLocation(e.label);
                                    },
                                    defaultInputValue: address,
                                }}
                            />
                            <button
                                className={styles.close}
                                onClick={() => {
                                    setAddress('');
                                    const divToClear = document.getElementsByClassName(" css-1uccc91-singleValue")[0];
                                    if (divToClear) {
                                        divToClear.innerHTML = "";
                                    }
                                }}
                            >
                                <Icon name="close-circle" size="24" />
                            </button>
                        </div>

                        <div className={styles.input}>
                            <Dropdown
                                label="Status"
                                className={styles.dropdown}
                                classDropdownHead={styles.dropdownHead}
                                value={status}
                                setValue={setStatus}
                                options={statusDropdownValues || []}
                                bodyStyles={{ width: '100%' }}
                            />
                        </div>
                    </div>

                    <div className={styles.firstRow} style={{ marginTop: 24 }}>
                        <TextInput
                            label="Price"
                            name="listedPrice"
                            type="text"
                            className={styles.input}
                            onChange={(e) => setListedPrice(e.target.value)}
                            value={listedPrice}
                            required
                        />
                        <TextInput
                            label="Refurb Cost"
                            name="refurbCost"
                            type="text"
                            className={styles.input}
                            onChange={(e) => setRefurbCost(e.target.value)}
                            value={refurbCost}
                            required
                        />

                        <TextInput
                            label="Other Cost (Closing Cost, Valuation Fee etc)"
                            name="otherCost"
                            type="text"
                            className={styles.input}
                            onChange={(e) => setOtherCost(e.target.value)}
                            value={otherCost}
                            required
                        />
                    </div>

                    <div className={styles.headingTitle}>Total Fixed Cost = {getNumberWithSpaces(totalFixedCost)}</div>
                    <div className={styles.switchContainer}>
                        <Switch
                            value={needFinancing}
                            onChange={() => {
                                if (needFinancing) {
                                    setDownPayment(totalFixedCost);
                                } else {
                                    setDownPayment(property?.cashInvested);
                                }

                                setNeedFinancing(!needFinancing)
                            }}
                        />
                        <span className={styles.title} style={{ marginLeft: 16 }}>
                            Needs financing
                        </span>
                    </div>

                    {needFinancing && (
                        <>
                            <div className={styles.firstRow} style={{ marginTop: 24 }}>
                                <TextInput
                                    label="Down payment / Cash invested"
                                    name="downpayment"
                                    type="text"
                                    className={styles.input}
                                    onChange={(e) => setDownPayment(e.target.value)}
                                    value={downPayment}
                                    required
                                />
                                <TextInput
                                    label="Interest rate (%)"
                                    name="interestRate"
                                    type="text"
                                    className={styles.input}
                                    onChange={(e) => setInterestRate(e.target.value)}
                                    value={intrestRate}
                                    required
                                />
                                <div className={styles.input}></div>
                            </div>

                            <div className={styles.firstRow} style={{ marginTop: 24 }}>
                                <TextInput
                                    label="Loan"
                                    name="mls"
                                    type="text"
                                    className={cn(styles.inputWithBottom, styles.disable)}
                                    value={borrowedCapital}
                                    required
                                />
                                <TextInput
                                    label="Loan to Value (LTV)%"
                                    name="ltv"
                                    type="text"
                                    className={cn(styles.inputWithBottom, styles.disable)}
                                    value={`${ltv}%`}
                                    required
                                />
                                <TextInput
                                    label="Loan to Cost (LTC)%"
                                    name="ltc"
                                    type="text"
                                    className={cn(styles.inputWithBottom, styles.disable)}
                                    value={`${ltc}%`}
                                    required
                                />
                            </div>
                        </>
                    )}

                    <div className={styles.firstRow} style={{ marginTop: 24 }}>
                        <TextInput
                            label="Home insurance (Monthly)"
                            name="homeInsurance"
                            type="text"
                            className={styles.input}
                            onChange={(e) => setHomeInsurance(e.target.value)}
                            value={homeInsurance}
                            required
                        />
                        <TextInput
                            label="HOA (Monthly)"
                            name="hoa"
                            type="text"
                            className={styles.input}
                            onChange={(e) => setHoa(e.target.value)}
                            value={hoa}
                            required
                        />
                        <TextInput
                            label="Property taxes (Monthly)"
                            name="propertyTaxes"
                            type="text"
                            className={styles.input}
                            onChange={(e) => setPropertyTaxes(e.target.value)}
                            value={propertyTaxes}
                            required
                        />
                    </div>

                    <div className={styles.firstRow} style={{ marginTop: 24 }}>
                        <TextInput
                            label="Maintenance (Monthly)"
                            name="maintenance"
                            type="text"
                            className={styles.input}
                            onChange={(e) => setMaintenance(e.target.value)}
                            value={maintenance}
                            required
                        />
                        <TextInput
                            label="Other - utilities (Monthly)"
                            name="minusUtitlites"
                            type="text"
                            className={styles.input}
                            onChange={(e) => setMinusUtilities(e.target.value)}
                            value={minusUtilities}
                            required
                        />
                        <TextInput
                            label="Borrowing Cost (Monthly)"
                            name="borrowingCost"
                            type="text"
                            className={cn(styles.inputWithBottom, styles.disable)}
                            value={borrowingCost}
                            required
                        />
                    </div>

                    <div className={styles.headingTitle}>Monthly cost =  {getNumberWithSpaces(monthlyCost)}</div>

                    <div className={styles.firstRow} style={{ marginTop: 24 }}>
                        <div className={styles.input}>
                            <DateInput
                                label={"Start date"}
                                className={styles.dateInput}
                                allowFuture={true}
                                portalClassInput={styles.inputDate}
                                classInputValue={styles.inputValue}
                                dateInputId='dateInputId'
                                componentStartDate={startDate}
                                setComponentStartDate={setStartDate}
                            />
                        </div>
                        <div className={styles.input}>
                            <Dropdown
                                label="Project timeline"
                                className={styles.dropdown}
                                classDropdownHead={styles.dropdownHead}
                                value={timeline}
                                setValue={setTimeline}
                                options={options || []}
                                bodyStyles={{ width: '100%' }}
                            />
                        </div>
                        <div className={styles.input}></div>
                    </div>

                    <div className={styles.headingTitle}>Holding Cost =  {getNumberWithSpaces(holdingCost)}</div>
                    <div className={cn(styles.headingTitle, styles.color)}>Project cost =  {getNumberWithSpaces(projectCost)}</div>

                    <div className={styles.firstRow} style={{ marginTop: 24 }}>
                        <TextInput
                            label="Sales price (or ARV)"
                            name="apr"
                            type="text"
                            className={styles.input}
                            onChange={(e) => setApr(e.target.value)}
                            value={apr}
                            required
                        />
                        <TextInput
                            label="Selling cost"
                            name="sellingCost"
                            type="text"
                            className={styles.input}
                            onChange={(e) => setSellingCost(e.target.value)}
                            value={sellingCost}
                            required
                        />
                        <div className={styles.input}></div>
                    </div>

                    <div className={styles.headingTitle}>Profit =  {getNumberWithSpaces(profit)}</div>
                    <div className={styles.headingTitle}>Profit margin =  {
                        (((parseFloat(apr || "0") -
                            (parseFloat(sellingCost || "0") + parseFloat(projectCost || "0"))) / (parseFloat(apr || "1"))) * 100)?.toFixed(2)
                    }%
                    </div>
                    <div className={styles.headingTitle}>COCR (Cash-on-Cash-Return) =  {
                        ((parseFloat(profit || "0") / parseFloat(downPayment || "1")) * 100)?.toFixed(2)
                    }%
                    </div>

                    <div className={styles.map}>
                        <div className={styles.xMap}>
                            {(isLoaded && mapCenter) ? (
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={mapCenter}
                                    zoom={15}
                                    onLoad={onLoad}
                                    options={{
                                        disableDefaultUI: true,
                                        fullscreenControl: false,
                                        zoomControl: true,
                                    }}
                                >
                                    <Marker
                                        position={mapCenter}
                                    />
                                </GoogleMap>
                            ) :
                                <div className={styles.emptyMapText}>
                                    Please provide property address
                                </div>
                            }
                        </div>
                    </div>


                    <div className={styles.imageWrapper}>
                        <span className={styles.title}>Images</span>
                        <div className={styles.imageContainer}>
                            {images?.map((item, index) => {
                                return <div style={{ position: 'relative' }} >
                                    <div className={styles.crossIcon} onClick={() => {
                                        imageRemoveHandler(item)
                                    }}>
                                        <Icon name="cross" size="8" />
                                    </div>
                                    <img src={item} className={styles.img} key={index} onClick={() => {
                                        const fileNameObj = GetFileNameFromPath(item, true);
                                        if (fileNameObj.extension === "png" ||
                                            fileNameObj.extension === "jpg" ||
                                            fileNameObj.extension === "jpeg") {
                                            setCurrentlySelectedImage(item);
                                            setImagePreviewPopup(true);
                                        }
                                    }} />
                                </div>
                            })}

                            <label className={styles.inputLabel} htmlFor="fileInput">
                                <Icon name="add" />
                            </label>
                            <input
                                type="file"
                                className={styles.inputImage}
                                name="myImage"
                                id="fileInput"
                                multiple={true}
                                onChange={async (event) => {
                                    setImageUploadLoading(true);
                                    await imageUploadHandler(event)
                                    setImageUploadLoading(false);
                                }}
                            />
                            {imageUploadLoading && (
                                <Spinner color="gray" size="24" />
                            )}
                        </div>
                    </div>

                    <TextInput
                        label="Link"
                        name="link"
                        type="text"
                        className={styles.dealDescription}
                        onChange={(e) => setLink(e.target.value)}
                        value={link}
                        required
                    />

                    <TextInput
                        label="Description"
                        name="description"
                        type="text"
                        className={styles.dealDescription}
                        onChange={(e) => setDealDescription(e.target.value)}
                        value={dealDescription}
                        required
                    />


                    <div className={styles.dynamicFields}>
                        {propertyFields?.map((item, index) => {
                            return <TextInput
                                crossOnLabel={true}
                                onClickClose={() => {
                                    const filteredProperties = propertyFields?.filter(i => i?.name !== item?.name);
                                    setPropertyFields([...(filteredProperties || [])]);
                                }}
                                key={index}
                                label={item?.name}
                                name={item?.name}
                                type="text"
                                className={styles.inputDynamic}
                                onChange={(e) => {
                                    propertyFields?.map(property => {
                                        if (property?.name === item?.name) {
                                            property.value = e.target.value;
                                        }
                                    })

                                    setPropertyFields([...propertyFields]);
                                }}
                                value={item?.value}
                                required
                            />
                        })}
                    </div>
                    <button className={cn('button-stroke-red', styles.addFieldButton)} onClick={() => {
                        setNewPropertyName("");
                        setShowAddFieldPopup(true)
                    }}>
                        Add a Field
                    </button>


                    {/* <div className={styles.seperator}></div>

                    <div className={styles.roiContainer}>
                        <div className={styles.header}>ROI</div>
                        {roiAttachments?.length > 0 ?
                            roiAttachments?.map((x, index) => {
                                const fileNameObj = GetFileNameFromPath(x, true);
                                return (
                                    <div key={index} style={{ position: 'relative', width: 210 }}>
                                        <div className={styles.selectedImageWrap}>
                                            <img
                                                className={styles.selectedImage}
                                                src={
                                                    fileNameObj.extension === "png" ||
                                                        fileNameObj.extension === "jpg" ||
                                                        fileNameObj.extension === "jpeg"
                                                        ? x
                                                        : imageWRTExtension[
                                                        fileNameObj.extension
                                                        ] || imageWRTExtension["common"]
                                                }
                                                hideZoom
                                            />
                                        </div>
                                        <div className={styles.action}>
                                            <button
                                                onClick={() => { setRoiAttachments([]) }}
                                            >
                                                <Icon name='close' size='16' />
                                            </button>
                                        </div>
                                    </div>
                                );
                            })
                            :
                            <div {...getRootProps({ className: "dropzone" })}>
                                <div className={styles.dropzoneWrap}>
                                    <p className={styles.dropzone}>
                                        {" "}
                                        <Icon name='file-add' size='72' />
                                        <span>
                                            Drag and drop here or click to upload P&L
                                        </span>
                                    </p>
                                    {roiAttachmentLoading && (
                                        <Spinner size="24" color="gray" />
                                    )}
                                </div>
                            </div>
                        }
                    </div> */}

                </div>
            </div >
        </>
    )
}

export default Details;