import React, { useEffect, useState } from "react";
import Search from "../../SearchProduct";
import cn from "classnames";
import styles from "./DetailsCustomer.module.sass";
import TextInput from "../../../../../../components/TextInput";
import { useHistory } from "react-router-dom";
import Icon from "../../../../../../components/Icon";
import CreateNewProduct from "../../../../Invoices/NewInvoice/Modals/CreateNewProduct";
import Card from "../../../../../../components/Card";
import Lines from "../../Lines/Lines";
import { toast, ToastContainer } from "react-toastify";
import {
  dateFormatterWithTimeZone,
  toastConfiguration,
  transactionDateFormatter,
} from "../../../../../../utils/utils";
import {
  finalizeQuote,
  updateQuote,
} from "../../../../../../utils/apiCallHanlder";
import OutsideClickHandler from "react-outside-click-handler";
import Spinner from "../../../../../../utils/spinner";
import Image from "../../../../../../components/Image";
import DateInput from "../../../../../../components/DateInput";
import Coupons from "../../../../Invoices/NewInvoice/InvoiceCustomer/DetailsCustomer/Coupons";
import TaxRates from "../../../../Invoices/NewInvoice/InvoiceCustomer/DetailsCustomer/TaxRates";
import AlternativeAvatar from "../../../../../../components/AlternativeAvatar";

let typingTimer;
let doneTypingInterval = 300;

const DetailsCustomer = ({
  className,
  setShowActionBtn,
  customerTaxExempt,
  setShowDetails,
  setShowSummary,
  selectedCustomerObject,
  getProducts,
  products,
  lines,
  setLines,
  startDate,
  setStartDate,
  dbTaxRates,
  memo,
  setMemo,
  showProduct,
  setShowProduct,
  subscription,
  setSubscription,
  lineId,
  setLineId,
  id,
  schedulePaymentClickHandler,
  addSubscriptionLoading,
  setShowSuccessModal,
}) => {
  const [addAnotherProduct, setAddAnotherProduct] = useState(false);
  const [showAddAnotherProductBtn, setShowAddAnotherProductBtn] =
    useState(true);

  const [visible, setVisible] = useState(false);
  const [productName, setProductName] = useState("");
  const [searchString, setSearchString] = useState("");

  const [updateState, setUpdateState] = useState(false);

  const [showNewProductModal, setShowNewProductModal] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [addLoading, setAddLoading] = useState(false);

  const [oustsideVisible, setOutsideVisible] = useState(false);
  const [updateExpiryDateLoading, setUpdateExpiryDateLoading] = useState(false);

  const [memoLoading, setMemoLoading] = useState(false);
  const [finalizeLoading, setFinalizeLoading] = useState(false);

  const [discount, setdiscount] = useState(
    subscription?.discount?.amountDiscountValue > 0
      ? subscription?.discount
      : null
  );

  const [taxRate, setTaxRate] = useState(subscription?.taxRate);
  const [taxRatesList, setTaxRatesList] = useState(null);

  const history = useHistory();

  useEffect(() => {
    setStartDate(new Date(subscription.expiryDate));
    setdiscount(
      subscription?.discount?.amountDiscountValue > 0
        ? subscription?.discount
        : null
    );
    setLines(subscription.lines);
  }, [subscription]);

  useEffect(async () => {
    setSearchLoading(true);
    if (visible) {
      await getProducts(searchString);
    }
    setSearchLoading(false);
  }, [visible]);

  const saveDiscountHandler = async (id, x, removeDiscount) => {
    let payload = {
      ...subscription,
      discount: { couponId: id ?? null },
    };

    const { data, error, message } = await updateQuote(payload);
    if (data) {
      setSubscription(data.quote);
      message && toast.success(message, toastConfiguration);
      //setInvoiceObject(data?.invoice);
      //setdiscount(data?.invoice?.discount);
    } else {
      toast.error(error, toastConfiguration);
    }
  };

  const saveTaxRateHandler = async (ids, x, removeTaxRate) => {
    let payloadIds = [];
    if (ids?.length > 0) {
      for (let i = 0; i < ids?.length; i++) {
        payloadIds.push({
          id: ids[i]
        })
      }

    }
    let payload = {
      ...subscription,
      taxRates: payloadIds
    };

    const { data, error, message } = await updateQuote(payload);
    if (data) {
      setSubscription(data.quote);
      setTaxRate(data.quote?.taxRate);

      message && toast.success(message, toastConfiguration);
    } else {
      toast.error(error, toastConfiguration);
    }
  };

  const productSelectHandler = async (product) => {
    const alreadyAddedProduct = containsObject(product, lines);

    if (alreadyAddedProduct) {
      toast.error("Product already added", toastConfiguration);
    } else {
      if (subscription?.id) {
        setAddLoading(true);
        const lineObject = {
          priceTypeValue: product?.priceTypeValue,
          recurringType: product?.recurringType,
          productId: product?.id,
          priceId: product?.priceId,
          pricingModel: product?.pricingModel,
          name: product?.name,
          quantity: 1,
          description: product?.description,
          isCustomerAdjustQuantity: false,
          priceValue: product?.priceValue,
          price: product?.price,
        };

        const { data, error } = await updateQuote({
          ...subscription,
          lines: [...lines, lineObject],
        });

        if (data) {
          setSubscription(data.quote);
          lineObject.lineId = data?.subscriptionLine?.id;
          lines.push(lineObject);
          setLineId((prevLineId) => prevLineId + 1);
        } else {
          toast.error(error || "Error in adding product", toastConfiguration);
        }
        setAddLoading(false);
      } else {
        const lineObject = {
          lineId: lineId + 1,
          priceTypeValue: product?.priceTypeValue,
          recurringType: product?.recurringType,
          productId: product?.id,
          priceId: product?.priceId,
          pricingModel: product?.pricingModel,
          name: product?.name,
          quantity: 1,
          description: product?.description,
          isCustomerAdjustQuantity: false,
          priceValue: product?.priceValue,
        };
        lines.push(lineObject);
        setLineId((prevLineId) => prevLineId + 1);
      }
    }

    setVisible(false);
    setProductName("");
    setShowProduct(true);
    setAddAnotherProduct(false);
    setShowAddAnotherProductBtn(true);
    setShowNewProductModal(false);
    setShowActionBtn(true);
  };

  const containsObject = (obj, list) => {
    var i;
    for (i = 0; i < list?.length; i++) {
      if (list[i]?.productId === obj?.id) {
        return true;
      }
    }

    return false;
  };

  const memoChangeHandler = (e) => {
    setMemo(e.target.value);
  };

  const handleChange = async () => {
    setMemoLoading(true);
    const { data } = await updateQuote({
      ...subscription,
      description: memo,
    });
    if (!data) {
      toast.error("Error in updating memo description", toastConfiguration);
    } else {
      setSubscription(data.quote);
    }
    setMemoLoading(false);
  };
  return (
    <div className={styles.detailsWrap}>
      <Card className={cn(styles.card, className)} title='Quote Details' border>
        <div className={styles.customerHead}>
          <label className={styles.customerLabel}>Customer</label>

          <div className={styles.infoWrapper}>
            <div className={styles.left}>
              
            {selectedCustomerObject?.imageUrl ? (
                <Image
                  src={selectedCustomerObject?.imageUrl}
                  srcDark={selectedCustomerObject?.imageUrl}
                  className={styles.pic}
                />
              ) : (
                <AlternativeAvatar
                  name={selectedCustomerObject?.name || selectedCustomerObject?.email}
                  className={"customer-info"}
                />
              )}
 
              {!subscription?.id ? (
                <button
                  onClick={() => {
                    setShowActionBtn(false);
                    setShowDetails();
                    setShowSummary(false);
                  }}
                  className={styles.deleteBtn}
                >
                  <Icon name={"trash"} size='20' fill='#FF4900' />
                </button>
              ) : (
                <OutsideClickHandler
                  onOutsideClick={() => setOutsideVisible(false)}
                >
                  <div
                    className={cn(styles.dotsBtn, className, {
                      [styles.active]: oustsideVisible,
                    })}
                  >
                    <div className={styles.head}>
                      <button
                        className={cn(styles.btn)}
                        onClick={() => setOutsideVisible(!oustsideVisible)}
                      >
                        <Icon name='dots' size='32' />
                      </button>
                    </div>
                    <div className={styles.actionBody}>
                      <button
                        className={styles.actionItem}
                        onClick={() =>
                          history.push(`/contacts/${subscription?.customerId}`)
                        }
                      >
                        <Icon name='profile-circle' size='24' />
                        View Customer
                      </button>
                    </div>
                  </div>
                </OutsideClickHandler>
              )}
            </div>
            <div className={styles.infoWrap}>
              <div className={styles.info}>
                <p className={styles.label}>Name</p>
                <p className={styles.txt}>{selectedCustomerObject?.name}</p>
              </div>
              <div className={styles.info}>
                <p className={styles.label}>Email</p>
                <p className={styles.txt}>{selectedCustomerObject?.email}</p>
              </div>
              <div className={styles.info}>
                <p className={styles.label}>Mobile</p>
                <p className={styles.txt}>{selectedCustomerObject?.phone}</p>
              </div>
            </div>
          </div>
        </div>
        {!showProduct ? (
          <div style={{ marginTop: 32 }}>
            <label className={styles.customerLabel}>Products</label>
            <Search
              className={styles.search}
              inputPlaceholder='Search or add new Product'
              addNewItem={() => setShowNewProductModal(true)}
              addNewBtnTxt='Create new Product'
              visible={visible}
              productName={productName}
              setVisible={setVisible}
              getProducts={getProducts}
              productSelectHandler={productSelectHandler}
              searchNames={products}
              searchString={searchString}
              setSearchString={setSearchString}
              addLoading={addLoading}
              searchLoading={searchLoading}
              setSearchLoading={setSearchLoading}
              showDetails={() => {
                setShowProduct(true);
                setShowActionBtn(true);
              }}
            />
            <CreateNewProduct
              showNewProductModal={showNewProductModal}
              searchString={searchString}
              setShowNewProductModal={setShowNewProductModal}
              productSelectHandler={productSelectHandler}
              showSubscription={true}
              showProduct={() => {
                setShowProduct(true);
                setShowNewProductModal(false);
                setShowActionBtn(true);
              }}
            />
          </div>
        ) : (
          <div style={{ marginTop: 32 }}>
            <div className={styles.detailsTxt}>Products</div>
            {lines?.map((x, index) => (
              <div key={index}>
                <Lines
                  x={x}
                  index={index}
                  lines={lines}
                  setLines={setLines}
                  setLineId={setLineId}
                  lineId={lineId}
                  subscription={subscription}
                  setSubscription={setSubscription}
                  setShowActionBtn={setShowActionBtn}
                />
              </div>
            ))}
            {showAddAnotherProductBtn ? (
              <button
                onClick={() => {
                  setAddAnotherProduct(true);
                  setShowAddAnotherProductBtn(false);
                }}
                className={styles.addProductBtn}
              >
                Add another product
              </button>
            ) : null}
            {addAnotherProduct ? (
              <>
                <Search
                  className={styles.search}
                  inputPlaceholder='Search or add new Product'
                  addNewBtnTxt='Create new Product'
                  visible={visible}
                  addNewItem={() => setShowNewProductModal(true)}
                  productName={productName}
                  setVisible={setVisible}
                  addLoading={addLoading}
                  getProducts={getProducts}
                  productSelectHandler={productSelectHandler}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  searchLoading={searchLoading}
                  setSearchLoading={setSearchLoading}
                  searchNames={products}
                  showDetails={() => {
                    setShowProduct(true);
                    setShowActionBtn(true);
                  }}
                />
                <CreateNewProduct
                  showNewProductModal={showNewProductModal}
                  searchString={searchString}
                  showSubscription={true}
                  setShowNewProductModal={setShowNewProductModal}
                  productSelectHandler={productSelectHandler}
                  showProduct={() => {
                    setShowProduct(true);
                    setShowNewProductModal(false);
                    setShowActionBtn(true);
                  }}
                />
              </>
            ) : null}
          </div>
        )}
        <div>
          <>
            {lines?.length > 0 && (
              <Coupons
                discount={discount}
                setdiscount={setdiscount}
                saveDiscountHandler={saveDiscountHandler}
              ></Coupons>
            )}
            {lines?.length > 0 && customerTaxExempt !== "exempt" && (
              <TaxRates
                dbTaxRates={dbTaxRates}
                taxRate={taxRate}
                saveTaxRateHandler={saveTaxRateHandler}
                taxRatesList={taxRatesList}
                setTaxRatesList={setTaxRatesList}
              ></TaxRates>
            )}

            <div className={styles.wrap} style={{ zIndex: 8 }}>
              <h4 className={styles.label}>Expire By</h4>
              <DateInput
                className={styles.dateInput}
                isWithPortal={true}
                hideText={true}
                allowFuture={true}
                portalClassInput={styles.input}
                classInputValue={styles.inputValue}
                dateInputId='dateInputId'
                componentStartDate={startDate}
                setComponentStartDate={setStartDate}
                loading={updateExpiryDateLoading}
                markAsPaidHandler={async (date) => {
                  setUpdateExpiryDateLoading(true);
                  const { data } = await updateQuote({
                    ...subscription,
                    expiryDate: transactionDateFormatter(date),
                  });
                  if (!data) {
                    toast.error(
                      "Error in updating expiry date",
                      toastConfiguration
                    );
                  } else {
                    setSubscription(data.quote);
                  }
                  setUpdateExpiryDateLoading(false);
                }}
              />
            </div>
            <div className={styles.wrap}>
              <div className={styles.label}>Memo</div>
              <TextInput
                onChange={memoChangeHandler}
                value={memo}
                descriptionLoading={memoLoading}
                onKeyUp={() => {
                  clearTimeout(typingTimer);
                  typingTimer = setTimeout(handleChange, doneTypingInterval);
                }}
                onKeyDown={() => {
                  clearTimeout(typingTimer);
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {subscription.status == "draft" && (
                <>
                  <div
                    style={{
                      background: "#272B30",
                      opacity: "0.25",
                      borderRadius: "1px",
                      height: "1px",
                      marginTop: 16,
                    }}
                  ></div>
                  <button
                    onClick={async () => {
                      if (finalizeLoading) {
                        return;
                      }
                      setFinalizeLoading(true);
                      const { data, error } = await finalizeQuote({
                        id: subscription?.id,
                      });
                      if (data) {
                        setShowSuccessModal(true);
                      } else {
                        toast.error( error ||
                          "Error in finalizing Quote",
                          toastConfiguration
                        );
                      }
                      setFinalizeLoading(false);
                    }}
                    className={cn("button", styles.sendBtn)}
                    style={{
                      width: "174px",
                      marginTop: 16,
                    }}
                  >
                    {finalizeLoading ? (
                      <Spinner size='24' color='white' />
                    ) : (
                      <>
                        <span>Finalize Quote</span>
                      </>
                    )}
                  </button>
                </>
              )}
            </div>
          </>
        </div>
      </Card>
    </div>
  );
};

export default DetailsCustomer;
