import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignUp.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, NavLink, useParams, withRouter } from "react-router-dom";
import Entry from "./Entry";
import Takeoff from "./Takeoff";
import Image from "../../components/Image";
import withUserAuth from "../../utils/withUserAuth";
import Spinner from "../../utils/spinner";
import Icon from "../../components/Icon";
import Dropdown from "../../components/Dropdown";
import Theme from "../../components/Theme";
import { Helmet } from "react-helmet"

const items = [
  {
    icon: "thumbs_up",
    text: "Signup",
  },
  {
    icon: "receipt",
    text: "Register details",
  },
  {
    icon: "mission",
    text: "Finish",
  },
];

const SignUp = (props) => {
  let isnewcompany = props.isnewcompany;

  const { sequence } = useParams();
  //const email = props.location?.state?.email; // This will contain state from route

  const GetParams = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const fbclid = params.get("fbclid");
    if (fbclid) localStorage.setItem("fbclid", fbclid);

    const gclid = params.get("gclid");
    if (gclid) localStorage.setItem("gclid", gclid);

    const emailState = props.location?.state?.email;
    const nextActionState = props.location?.state?.nextAction;
    const signUpTokenState = props.location?.state?.signUpToken;

    const emailParam = params.get("email") || emailState || "";
    const nextActionParam = params.get("nextAction") || nextActionState || "";
    const signUpTokenParam = params.get("nextAction") || signUpTokenState || "";

    const inviteStatusParam = params.get("inviteStatus") || "";

    let sequenceParam = sequence;
    let showCodeParam = false;

    if (nextActionParam == "signup_details") {
      sequenceParam = 2;
    }

    if (nextActionParam == "signup_verifyemail") {
      sequenceParam = 1;
      showCodeParam = true;
    }

    sequenceParam = parseInt(sequenceParam);

    return {
      sequenceParam,
      nextActionParam,
      emailParam,
      showCodeParam,
      signUpTokenParam,
    };
  };

  const params = GetParams();
  const [themeVisible, setThemeVisible] = useState(true);
  const [visible, setVisible] = useState(true);
  const [activeIndex, setActiveIndex] = useState(params.sequenceParam || 1);
  const heightWindow = use100vh();
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [showCode, setShowCode] = useState(params.showCodeParam || false);
  const [whereMeet, setWhereMeet] = useState(true);
  const [signUpToken, setSignUpToken] = useState(params.signUpTokenParam || "");

  const [email, setEmail] = useState(params.emailParam || "");

  const ProcessNextAction = (nextAction) => {
    let sequenceParam = 1;

    if (nextAction.nextAction == "signup_details") {
      sequenceParam = 2;
    }

    if (nextAction.nextAction == "signup_verifyemail") {
      sequenceParam = 1;
      if (!showCode) setShowCode(true);
    }

    if (sequenceParam != activeIndex) setActiveIndex(sequenceParam);
  };

  return (
    <>
      <Helmet>
        <script> 
          {`gtag('event','conversation', {'send_to': 'AW-10999539170/owtBCL7YsOoYEOLL_vwo'})`}
        </script>
      </Helmet>
      <div className={styles.row} style={{ alignItems: "center" }}>
        {signUpLoading ? (
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              left: 0,
              top: 0,
            }}
          >
            <div style={{ position: "relative", left: "50%", top: "50%" }}>
              <Spinner size='34' color='gray'></Spinner>
            </div>
          </div>
        ) : (
          <>
            <div>
              <div className={cn(styles.wrap, styles.leftList)}></div>
            </div>
            <div
              className={styles.col}
              style={{
                paddingTop: 0,
                height: "max-content",
              }}
            >
              {isnewcompany && (
                <div className={styles.infoBack}>
                  <Link className={styles.link} to='/'>
                    Back
                  </Link>
                </div>
              )}
              <div className={styles.head}>
                <Link
                  className={styles.logo}
                  to={{ pathname: "https://greatweek.com" }}
                  target='_blank'
                >
                  <Image
                    className={styles.pic}
                    src='/images/logo-dark.png'
                    srcDark='/images/logo-light.png'
                    alt='logo'
                  />
                </Link>
              </div>

              <div className={styles.wrap}>
                {activeIndex === 1 ? (
                  !showCode ? (
                    <>
                      <div className={cn("h4", styles.title)}>
                        {props.istestmode && <span>Test </span>}Sign up
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={cn("h3", styles.title)}>
                        Check your email
                      </div>
                      <p className={styles.subtext}>
                        Enter verification code below
                      </p>
                    </>
                  )
                ) : activeIndex === 2 ? (
                  <>
                    <div
                      className={cn("h2", styles.title)}
                      style={{ marginBottom: -12 }}
                    >
                      Location
                    </div>
                  </>
                ) : activeIndex === 3 ? (
                  whereMeet ? (
                    <>
                      <div className={cn("h2", styles.title)}>
                        Where did we meet?
                      </div>
                      <p>How did you find out about us?</p>
                    </>
                  ) : (
                    <>
                      <div className={cn("h2", styles.title)}>Last Steps</div>
                      <p>
                        You are ready to go. But more information may be required
                        for you to use the full potential of your account. You can
                        fill this out now, or skip for later.
                      </p>
                    </>
                  )
                ) : null}
                <div className='h5'>&nbsp;</div>

                {visible ? (
                  <>
                    <Entry
                      onConfirm={() => setVisible(false)}
                      istestmode={props.istestmode}
                      isCustom={props.isCustom}
                      setSignUpLoading={setSignUpLoading}
                      setActiveIndex={setActiveIndex}
                      activeIndex={activeIndex}
                      showCode={showCode}
                      setShowCode={setShowCode}
                      whereMeet={whereMeet}
                      setWhereMeet={setWhereMeet}
                      emailParam={email}
                      signUpTokenParam={signUpToken}
                      ProcessNextAction={ProcessNextAction}
                    />
                    {!isnewcompany && activeIndex === 1 && !showCode && (
                      <div className={styles.info}>
                        Already a member?{" "}
                        <Link className={styles.link} to='/'>
                          Log in
                        </Link>
                      </div>
                    )}
                  </>
                ) : (
                  <Takeoff />
                )}
              </div>
            </div>
            <div className={styles.themeWrap}>
              {/* <a
                className={styles.link}
                href='https://help.greatweek.com/'
                target='blank'
              >
                <Icon name='help' size='18' />
                <span>Help Center</span>
              </a> */}
              <Theme className={styles.theme} visibleSidebar={themeVisible} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default withRouter(withUserAuth(SignUp));
