import React, { useState, useEffect } from "react";
import styles from "./ChartOfAccounts.module.sass";
import Card from "../../../components/Card";
import cn from "classnames";
import Icon from "../../../components/Icon";
import Table from "./Table";
import Search from "./Search";
import AccountInfo from "./AccountInfo";

import { accountsLedger } from "../../../mocks/accountsLedger";
import DeleteModal from "../../../components/DeleteModal";
const liabilitiesAccounts = ["1030 - Sales", "3409 - Cost of Goods Sold"];
const assetsAccounts = ["1030- Sales"];

const ChartOfAccounts = ({ className, setVisible, visibleExportCOAPopup, setVisibleExportCOAPopup }) => {
  const [actionLedger, setActionLedger] = useState(true);
  const [ledgerCheckCount, setLedgerCheckCount] = useState(0);
  const [actionBtn, setActionBtn] = useState(true);
  const [show, setShow] = useState(false);
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [needNewData, setNeedNewData] = useState(false);

  const [searchString, setSearchString] = useState("");

  const [COAList, setCOAList] = useState([]);

  const ledgerIdLength = Object.keys(accountsLedger).length;

  const handleLedgerAll = (e) => {
    var ariaStatus = e.target.getAttribute("aria-Checked");
    if (ariaStatus === "true") {
      setActionLedger(true);
      setLedgerCheckCount(0);
    }
    if (ariaStatus === "false") {
      setActionLedger(false);
      setLedgerCheckCount(ledgerIdLength);
    }
    if (ariaStatus === "mixed") {
      setActionLedger(false);
      setLedgerCheckCount(ledgerIdLength);
    }
  };

  const handleLedger = (e) => {
    if (e.target.checked) {
      setLedgerCheckCount(ledgerCheckCount + 1);
    }
    if (!e.target.checked) {
      setLedgerCheckCount(ledgerCheckCount - 1);
    }
  };

  const handleEditProduct = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (ledgerCheckCount === 0) {
      setActionLedger(true);
    } else {
      setActionLedger(false);
    }
  });

  const actionBtnHandle = () => {
    setActionBtn(!actionBtn);
    setLedgerCheckCount(0);
  };

  return (
    <Card
      className={cn(styles.card, className)}
      classCardHead={styles.head}
      title=""
      //classTitle={cn("title-blue", styles.title)}
      head={
        <button className={cn("button", styles.addBtn)} onClick={() => setShowAccountInfo(true)}>
          Add new account
        </button>
      }
    >
      <AccountInfo
        visible={showAccountInfo}
        searchString={searchString}
        setSearchString={setSearchString}
        setVisible={setShowAccountInfo}
        needNewData={needNewData}
        setNeedNewData={setNeedNewData}
        COAList={COAList}
        setCOAList={setCOAList}
      />
      <Table
        className={styles.table}
        activeTable={show}
        setActiveTable={setShow}
        editAction={
          <button className={styles.actionItem} onClick={() => setVisible(false)}>
            <Icon name="edit" size="24" />
            Edit
          </button>
        }
        editProduct={handleEditProduct}
        setShowAccountInfo={setShowAccountInfo}
        liabilitiesAccounts={liabilitiesAccounts}
        assetsAccounts={assetsAccounts}
        setVisible={setVisible}
        searchString={searchString}
        setSearchString={setSearchString}
        changeLedger={handleLedger}
        changeAllLedger={handleLedgerAll}
        actionBtnHandle={actionBtnHandle}
        needNewData={needNewData}
        setNeedNewData={setNeedNewData}
        COAList={COAList}
        setCOAList={setCOAList}
        visibleExportCOAPopup={visibleExportCOAPopup}
        setVisibleExportCOAPopup={setVisibleExportCOAPopup}
      />
    </Card>
  );
};

export default ChartOfAccounts;
