import React, { useEffect } from 'react';
import Icon from "../../../components/Icon";
import cn from "classnames";
import styles from "./PaymentsHeader.module.sass";
import { useHistory } from 'react-router';
import LargeNav from '../../../components/LargeNav';
import { getConnectStatus } from '../../../utils/utils';

let connect = 1;

const PaymentsHeader = ({ currentPage }) => {
  const history = useHistory();

  const connectStatus = getConnectStatus();
  connect = connectStatus?.status;

  const preCategory = [
    {
      icon: "document",
      name: "Invoices",
      onClick: () => history.push("/payments"),
      value: 1
    },
  ]

  const commonCategory = [
    {
      icon: "document",
      name: "Quotes",
      onClick: () => history.push("/quotes"),
      value: 4
    },
  ]

  const extraCategory = [
    {
      icon: "sync",
      name: "Subscriptions",
      onClick: () => history.push("/subscriptions"),
      value: 2
    },
    {
      icon: "link",
      name: "Payment Links",
      onClick: () => history.push("/paymentLinks"),
      value: 3
    },
  ];

  const activeCategory = connect !== 1 ? [...preCategory, ...commonCategory] : [...preCategory, ...commonCategory, ...extraCategory]

  return (
    <>
      <LargeNav
        tabOptions={activeCategory}
        activeTab={currentPage}
        onClick={(x) => { x.onClick() }}
        value="value"
        category="name"
        onlyLarge
      />
    </>
  );
};

export default PaymentsHeader;