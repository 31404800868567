import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import Page from "../components/Page";
import Home from "./Home";
import CompleteProfile from "./CompleteProfile";
import IdentityVerify from "./IdentityVerify";
import Products from "./Products";
import Customers from "./Customers";
import Settings from "./Settings";
import InviteTeam from "./InviteTeam";
import Payments from "./Payments";
import Forward from "./Forward";
import AccountsAndCards from "./AccountsAndCards";
import Coupons from "./Coupons";
import Transactions from "./Transactions";
import TransactionDetail from "./TransactionsDetails";
import MessageCenter from "./MessageCenter";
import ResetPassword from "./ResetPassword";
import Cards from "./Cards";
import Accounting from "./Accounting";
import SignUpExternal from "./SignUpExternal";
import SignInGoogle from "./SignInGoogle";
import Studio from "./Studio";
import CustomerHtmlEmbeddedForm from "./CustomerHtmlEmbeddedForm";
import ContractDisplay from "./Anonymous/ContractDisplay/ContractDisplay";
import QuestionnaireDisplay from "./Anonymous/QuestionnaireDisplay/QuestionnaireDisplay";
import EmbededForms from "./Anonymous/EmbededForms/EmbededForm";
import Portal from "./Portal";
import Quotes from "./Payments/Quotes";
import TaxRate from "./TaxRate";
import MultiCompany from "./MultiCompany";
import AddTaxRate from "./Settings/AddtaxRate";
import Inventory from "./Inventory";
import Projects from "./Projects";
import LendersPortal from "./LendersPortal";
import DownloadProject from "./Projects/DownloadProject";
import PreviewProject from "./Projects/PreviewProject";
import SignUpDetails from "./SignupDetails";
import Messages from "./Projects/EditNewProject/Messages";
import MessagesWithAI from "./MessagesWithAI";
import Board from "./Board";
import Capital from "./Capital";
import { useState } from "react";

function Routing() {
  const [changeProfile, setChangeProfile] = useState(false);
  return (
    <Router basename='/'>
      <Switch>
        <Route
          exact
          path='/dashboard'
          render={() => (
            <Page viewnName='dashboard' changeProfile={changeProfile} setChangeProfile={setChangeProfile}>
              <Home />
            </Page>
          )}
        />
        <Route
          exact
          path='/message-center'
          render={() => (
            <Page title='Messages'>
              <MessageCenter />
            </Page>
          )}
        />
        <Route
          exact
          path='/messages'
          render={() => (
            <Page>
              <Messages />
            </Page>
          )}
        />
        <Route
          exact
          path='/ai'
          render={() => (
            <Page>
              <MessagesWithAI />
            </Page>
          )}
        />
        <Route
          exact
          path='/accountsandcards'
          render={() => (
            <Page>
              <AccountsAndCards />
            </Page>
          )}
        />
        <Route
          exact
          path='/transactionDetails/:id'
          render={(props) => (
            <Page>
              <TransactionDetail {...props} />
            </Page>
          )}
        />
        <Route
          exact
          path='/accountsandcards/cards/:id'
          render={(props) => (
            <Page>
              <AccountsAndCards {...props} />
            </Page>
          )}
        />
        <Route
          exact
          path='/accountsandcards/linkedAccount/:linkedAccountId'
          render={() => (
            <Page>
              <AccountsAndCards />
            </Page>
          )}
        />
        <Route
          exact
          path='/payments'
          render={() => (
            <Page>
              <Payments />
            </Page>
          )}
        />
        <Route
          exact
          path='/subscriptions'
          render={() => (
            <Page>
              <Payments />
            </Page>
          )}
        />
        <Route
          exact
          path='/quotes'
          render={() => (
            <Page>
              <Payments />
            </Page>
          )}
        />
        <Route
          exact
          path='/subscriptions/tabs/:activeIndex'
          render={(props) => (
            <Page>
              <Payments {...props} />
            </Page>
          )}
        />
        <Route
          exact
          path='/subscriptions/:subId'
          render={(props) => (
            <Page>
              <Payments {...props} />
            </Page>
          )}
        />
        <Route
          exact
          path='/quote/:subId'
          render={(props) => (
            <Page>
              <Payments {...props} />
            </Page>
          )}
        />
        <Route
          exact
          path='/paymentLinks'
          render={(props) => (
            <Page>
              <Payments props={props} />
            </Page>
          )}
        />
        {/* <Route
          exact
          path='/transactions'
          render={(props) => (
            <Page>
              <Transactions props={props} />
            </Page>
          )}
        /> */}
        <Route
          exact
          path='/discounts'
          render={(props) => (
            <Page>
              <Coupons props={props} />
            </Page>
          )}
        />

        <Route
          exact
          path='/discounts/:id'
          render={(props) => (
            <Page>
              <Coupons props={props} />
            </Page>
          )}
        />
        <Route
          exact
          path='/transactions/:id'
          render={(props) => (
            <Page>
              <Transactions props={props} />
            </Page>
          )}
        />
        <Route
          exact
          path='/payments/:id'
          render={(props) => (
            <Page>
              <Payments {...props} />
            </Page>
          )}
        />
        <Route
          exact
          path='/payments/tabs/:activeIndex'
          render={(props) => (
            <Page>
              <Payments {...props} />
            </Page>
          )}
        />
        {/* <Route
          exact
          path="/events"
          render={(props) => (
            <Page>
              <Events {...props} />
            </Page>
          )}
        /> */}
        <Route
          exact
          path='/products'
          render={(props) => (
            <Page>
              <Products {...props} />
            </Page>
          )}
        />
        <Route
          exact
          path='/resetPassword/:id'
          render={() => <ResetPassword />}
        />
        <Route
          exact
          path='/products/:id'
          render={() => (
            <Page>
              <Products />
            </Page>
          )}
        />
        <Route exact path='/invite-team/:id' render={() => <InviteTeam />} />
        <Route
          exact
          path='/contacts'
          render={() => (
            <Page>
              <Customers />
            </Page>
          )}
        />
        <Route
          exact
          path='/contacts/:id'
          render={() => (
            <Page>
              <Customers />
            </Page>
          )}
        />

        <Route
          exact
          path='/contacts/template/:id/:templateId'
          render={() => (
            <Page>
              <Customers />
            </Page>
          )}
        />

        <Route
          exact
          path='/contacts/questionnaire/:id/:questionnaireId'
          render={() => (
            <Page>
              <Customers />
            </Page>
          )}
        />

        <Route
          exact
          path='/contacts/res/:id'
          render={() => (
            <Page>
              <Transactions />
            </Page>
          )}
        />

        <Route
          exact
          path='/contacts/tabs/:activeTab'
          render={() => (
            <Page>
              <Customers />
            </Page>
          )}
        />


        <Route
          exact
          path='/inventory'
          render={() => (
            <Page>
              <Inventory />
            </Page>
          )}
        />

        <Route
          exact
          path='/projects/:id'
          render={(props) => (
            <Page>
              <Projects {...props} />
            </Page>
          )}
        />

        <Route
          exact
          path='/projects-download'
          render={(props) => (
            <Page>
              <DownloadProject {...props} />
            </Page>
          )}
        />

        <Route
          exact
          path='/projects'
          render={(props) => (
            <Page>
              <Projects {...props} />
            </Page>
          )}
        />

        <Route
          exact
          path='/lenderportal/:id/:propertyId'
          render={(props) => (
            <Page>
              <LendersPortal {...props} />
            </Page>
          )}
        />

        <Route
          exact
          path='/property-link/:companyId/:propertyId'
          render={() => (
            <PreviewProject />
          )}
        />

        <Route
          exact
          path='/taxRate'
          render={(props) => (
            <Page>
              <TaxRate props={props} />
            </Page>
          )}
        />

        <Route
          exact
          path='/contacts/:id/:customerTab'
          render={() => (
            <Page>
              <Customers />
            </Page>
          )}
        />

        <Route
          exact
          path='/settings/:activeIndex'
          render={(props) => <Settings {...props} />}
        />

        <Route
          exact
          path='/settings/:tabName/:coaId'
          render={(props) => (
            <Page>
              <Accounting {...props} />
            </Page>
          )}
        />

        <Route
          exact
          path='/settings'
          render={(props) => <Settings {...props} />}
        />
        <Route exact path='/sign-up/:sequence' render={() => <SignUp />} />
        <Route exact path='/addrate' render={() => <AddTaxRate />} />
        <Route exact path='/sign-up' render={() => <SignUp />} />
        <Route exact path='/sign-up-details' render={() => <SignUpDetails />} />
        <Route
          exact
          path='/sign-up-custom'
          render={() => <SignUp isCustom={true} />} />
        <Route
          exact
          path='/sign-up-test'
          render={() => <SignUp istestmode={true} />}
        />
        <Route
          exact
          path='/sign-up-company'
          render={() => <SignUp isnewcompany={true} />}
        />
        <Route
          exact
          path='/no-account-found'
          render={() => <SignUpExternal />}
        />
        <Route exact path='/' render={() => <SignIn />} />
        <Route exact path='/sign-in' render={() => <SignIn />} />
        <Route exact path='/sign-in-google' render={() => <SignInGoogle />} />
        <Route exact path='/sign-in-test' render={() => <SignIn />} />

        <Route exact path='/multi-company' render={() => <MultiCompany />} />

        <Route
          exact
          path='/contractdisplay'
          render={() => <ContractDisplay />}
        />
        <Route
          exact
          path='/questionnairedisplay'
          render={() => <QuestionnaireDisplay />}
        />
        <Route
          exact
          path='/embeddedFormdisplay/:id'
          render={() => <EmbededForms />}
        />
        <Route exact path='/portal' render={() => <Portal />} />

        <Route exact path='/forward' render={() => <Forward />} />
        <Route
          exact
          path='/customer-html-embedded-form'
          render={() => <CustomerHtmlEmbeddedForm />}
        />
        <Route
          exact
          path='/forgot-password'
          render={() => <ForgotPassword />}
        />
        <Route
          exact
          path='/complete-profile'
          render={() => <CompleteProfile />}
        />
        <Route
          exact
          path='/identity-verify'
          render={() => <IdentityVerify />}
        />
        {/* Premium pages routing start */}
        <Route
          exact
          path='/cards'
          render={(props) => (
            <Page>
              <Cards {...props} />
            </Page>
          )}
        />
        <Route
          exact
          path='/board'
          render={() => (
            <Page>
              <Board />
            </Page>
          )}
        />
        <Route
          exact
          path='/board/:id'
          render={() => (
            <Page>
              <Board />
            </Page>
          )}
        />
        <Route
          exact
          path='/transactions'
          render={() => (
            <Page>
              <Accounting />
            </Page>
          )}
        />
        <Route
          exact
          path='/accounting'
          render={() => (
            <Page>
              <Accounting />
            </Page>
          )}
        />
        <Route
          exact
          path='/accounting/:tabName/:coaId'
          render={(props) => (
            <Page>
              <Accounting {...props} />
            </Page>
          )}
        />

        <Route
          exact
          path='/capital'
          render={(props) => (
            <Page>
              <Capital {...props} />
            </Page>
          )}
        />
        <Route
          exact
          path='/accounting/:tabName'
          render={(props) => (
            <Page>
              <Accounting {...props} />
            </Page>
          )}
        />
        <Route
          exact
          path='/studio'
          render={() => (
            <Page>
              <Studio />
            </Page>
          )}
        />
        <Route
          exact
          path='/studio/:tabIndex'
          render={() => (
            <Page>
              <Studio />
            </Page>
          )}
        />
        <Route
          exact
          path='/studio/:tabIndex/:id'
          render={() => (
            <Page>
              <Studio />
            </Page>
          )}
        />
        {/* Premium pages routing end */}
        <Route
          render={() => (
            <Page title='Dashboard'>
              {" "}
              <Home />{" "}
            </Page>
          )}
        />
      </Switch>
    </Router>
  );
}

export default Routing;
