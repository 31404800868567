import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styles from "./ChartOfAccounts.module.sass";
import Card from "../../../components/Card";
import cn from "classnames";
import Icon from "../../../components/Icon";
import {
  getCOATransactions,
  getReconcileAccounts,
  getReconcileTransactions,
  getTaxRate,
  searchCOAList,
} from "../../../utils/apiCallHanlder";
import Transactions from "../../../components/TransactionsCOA";
import Item from "../../Home/Overview/Item";
import Spinner from "../../../utils/spinner";
import Search from "../../MessagesWithAI/prompts/Search";
import Carousel from "react-multi-carousel";
import TransactionPopup from "./TransactionPopup";
import Dropdown from "../../../components/Dropdown";
import LargeNav from "../../../components/LargeNav";
import DateRangeInput from "../../../components/DateRangeInput";
import { getQuarterDates, toastConfiguration, transactionDateFormatter } from "../../../utils/utils";
import { toast } from "react-toastify";
import ExportReconcileListPopup from "../../../components/ExportReconcileListPopup";

const conciledTypes = ["Unreconciled", "Booked"];

const pageSize = 30;
let page = 1;

const filters = ["All Time", "Last Quarter", "This Quarter", "Last Year", "This Year"]
const filterHandler = {
  "All Time": () => {
    return {
      start: new Date("01/01/2020"),
      end: new Date()
    }
  },
  "Last Quarter": () => {
    const dates = getQuarterDates("previous");
    return {
      start: dates?.StartDate,
      end: dates?.EndDate
    }
  },
  "This Quarter": () => {
    const dates = getQuarterDates("current");
    return {
      start: dates?.StartDate,
      end: dates?.EndDate
    }
  },
  "Last Year": () => {
    return {
      start: new Date(new Date().getFullYear() - 1, 0, 1),
      end: new Date(new Date().getFullYear() - 1, 11, 31)
    }
  },
  "This Year": () => {
    return {
      start: new Date(new Date().getFullYear(), 0, 1),
      end: new Date(new Date().getFullYear(), 11, 31)
    }
  }
}

let currentActive = 1;
const ChartOfAccounts = ({
  className,
  accounts,
  setAccounts,
  selectedAccountId,
  setSelectAccountId,
  visibleExportPopup,
  setVisibleExportPopup,
  activeIndex,
  setActiveIndex,
  refetchDetails
}) => {
  const targetRef = useRef();
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [conciledType, setConciledType] = useState(conciledTypes[0]);

  const [accountDetailPayload, setAccountDetailPayload] = useState({});
  const [isNextPageExist, setIsNextPageExist] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date("01/01/2020"))
  const [endDate, setEndDate] = useState(new Date());


  const [showDetails, setShowDetails] = useState(false);
  const [accountsLoading, setAccountsLoading] = useState(true);
  const [currentTransactionObject, setCurrentTransactionObject] = useState([]);
  const [activeFilter, setActiveFilter] = useState(0);
  const [taxRates, setTaxRates] = useState(null);
  const [width, setWidth] = useState("max-content");

  const [showTransactionPopup, setShowTransactionPopup] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [coaOptions, setCoaOptions] = useState([]);

  const [currentSortOrder, setCurrentSortOrder] = useState("desc");
  const [currentSortColumn, setCurrentSortColumn] = useState("Date");

  const [coaTypes, setCoaTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);

  useEffect(async () => {
    await getChartOfAccounts();
    const { data } = await getReconcileAccounts();
    if (data) {
      //data?.accounts?.splice(1, 0, stripeBalance);
      setAccounts(data?.accounts);
      setSelectAccountId(data?.accounts[0]?.coaId);
      setAccountsLoading(false);
      setLoading(true);
      await getUpdatedTransactions(null, isFirstLoad, []);
      setLoading(false);
      setIsFirstLoad(false);
      const taxRates = await getTaxRate();
      if (taxRates.data) {
        setTaxRates(taxRates.data?.taxRates);
      }
    } else {
      setAccountsLoading(false);
    }
  }, []);

  useEffect(async () => {
    console.log('active',activeIndex)
    if (!isFirstLoad) {
      currentActive = activeIndex;
      page = 1;
      setLoading(true);
      await getUpdatedTransactions(null, false, []);
      setLoading(false);
    }
  }, [activeIndex, selectedAccountId, refetchDetails]);

  const getChartOfAccounts = async () => {
    const { data } = await searchCOAList('');
    if (data) {
      let minifiedTypes = ["Select"];
      minifiedTypes = [minifiedTypes, ...data?.list?.map(item => item.name)];

      setCoaOptions(data?.list);
      setCoaTypes(minifiedTypes);
    }
  }

  const getUpdatedTransactions = async (pageNo, firstLoad, previousTransactions, sort, sortOrder, start, end) => {
    if (pageNo) {
      page = page + 1;
    }

    setCurrentSortOrder(sortOrder);
    setCurrentSortColumn(sort);

    const { data } = await getReconcileTransactions({
      isStripeBalance: selectedAccountId === -99 && true,
      isFirstLoad: firstLoad || false,
      coaId: selectedAccountId || 0,
      page: page || 0,
      startDate: transactionDateFormatter(start || startDate),
      pageSize,
      endDate: transactionDateFormatter(end || endDate),
      status:
        conciledTypes[activeIndex - 1] === "Booked" ? "booked" : conciledTypes[activeIndex - 1],
      sort: sort || "Date",
      sortOrder: sortOrder || 'desc',
      searchString: searchStr
    });
    if (data) {
      const list = data?.list;
      previousTransactions && previousTransactions.length > 0
      ? setTransactions(transactions.concat(list))
      : setTransactions(list);
    setIsNextPageExist(data?.isNextPageExist);
      // if (
      //   (list[0]?.accountingStatus === "Booked" && currentActive === 2) ||
      //   (list[0]?.accountingStatus === "Unreconciled" && currentActive === 1)
      // ) {
      //   previousTransactions && previousTransactions.length > 0
      //     ? setTransactions(transactions.concat(list))
      //     : setTransactions(list);
      //   setIsNextPageExist(data?.isNextPageExist);
      // }
    }
  };

  const getAccountDataForExport = () => {
    return {
      isStripeBalance: selectedAccountId === -99 && true,
      isFirstLoad: false,
      coaId: selectedAccountId || 0,
      page: 1,
      pageSize,
      status: conciledTypes[activeIndex - 1] === "Booked" ? "booked" : conciledTypes[activeIndex - 1],
    }

  };

  useLayoutEffect(() => {
    var carouselWrapper = document.getElementById("carouselWrap");
    setCarouselWidth(carouselWrapper.offsetWidth);
  });

  useEffect(() => {
    var carouselWrapper = document.getElementById("carouselWrap");
    const handleResize = () => {
      setCarouselWidth(carouselWrapper.offsetWidth);
    };
    if (carouselWrapper) {
      window.addEventListener("resize", handleResize);
    }
  });

  const responsive = {
    sizeOne: {
      breakpoint: { max: 8000, min: 0 },
      items: carouselWidth / 208,
    },
  };

  return (
    <Card
      className={cn(styles.card, className)}
      id="Overview"
      classCardHead={styles.head}
      head={
        <button className={cn("button", styles.addBtn)} onClick={() => setShowAccountInfo(true)}>
          Add new account
        </button>
      }
    >
      <ExportReconcileListPopup
        visible={visibleExportPopup}
        setVisible={setVisibleExportPopup}
        paramStartDate={startDate}
        paramEndDate={endDate}
        accountData={getAccountDataForExport()}
      ></ExportReconcileListPopup>

      <div id={"carouselWrap"} ref={targetRef}>
        {accountsLoading ? null : (
          <div
            className={styles.carouselWrapper}
            id="check"
            style={{
              width: "100%",
              paddingRight: 30,
            }}
          >
            <Carousel
              responsive={responsive}
              autoPlay={false}
              showDots={false}
              arrows={false}
              containerClass={styles.carouselContainer}
              partialVisible={true}
            >
              {accounts.map((x, index) => {
                return (
                  <Item
                    className={cn(styles.itemAccount, {
                      [styles.active]: selectedAccountId === x?.coaId,
                      [styles["activeItem" + index]]: selectedAccountId === x?.coaId,
                    })}
                    onActive={() => {
                      setSelectAccountId(x.coaId);
                    }}
                    style={{
                      border: selectedAccountId === x?.coaId ? 'unset' : '2px solid #6F767E',
                    }}
                    key={index}
                    item={{
                      title: x.name,
                      accountDigit: null,
                      counter: x.balance ?? "0",
                    }}
                    currency={"USD"}
                  />
                );
              })}
            </Carousel>
          </div>
        )}
      </div>
      <div className={cn(styles.dateParent, styles.balanceWrapper)} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={styles.child1} style={{ marginBottom: -32 }}>
          <div data-html2canvas-ignore="true">
            <LargeNav
              tabOptions={filters}
              activeTab={activeFilter}
              onClick={async (x, index) => {
                setActiveFilter(index);
                const dates = filterHandler[x]();
                setStartDate(dates.start || new Date());
                setEndDate(dates.end || new Date());
                page = 1;

                setLoading(true);
                await getUpdatedTransactions(null, null, null, null, null, dates.start, dates.end);
                setLoading(false);
              }}
            />
          </div>
          <Search
            setSearchString={setSearchStr}
            searchString={searchStr}
            getSearchResult={async () => {
              page = 1;
              setLoading(true);
              await getUpdatedTransactions();
              setLoading(false);
            }}
            resetSearch={async () => {
              page = 1;
              setLoading(true);
              setSearchStr('');
              await getUpdatedTransactions()
              setLoading(false);
            }}
          />
        </div>
      </div>
      <Transactions
        setShowDetails={setShowDetails}
        transactions={transactions}
        accountDetailPayload={accountDetailPayload}
        setAccountDetailPayload={setAccountDetailPayload}
        page={page}
        isNextPageExist={isNextPageExist}
        setTransactions={setTransactions}
        setIsNextPageExist={setIsNextPageExist}
        getUpdatedTransactions={getUpdatedTransactions}
        payThroughTrans={(x) => {
          setCurrentTransactionObject(x);
          setShowTransactionPopup(true);
        }}
        showCalender=" "
        loading={loading}
        setLoading={setLoading}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        isExpandable={false}
        taxRates={taxRates}
        hideHeader={true}
        comingFromReconcile={true}
        coaOptions={coaOptions}
        coaTypes={coaTypes}
        subTypes={subTypes}
        setSubTypes={setSubTypes}
      />

      {taxRates && (
        <TransactionPopup
          showTransactionPopup={showTransactionPopup}
          setShowTransactionPopup={setShowTransactionPopup}
          taxRates={taxRates}
          setLoading={setLoading}
          getUpdatedTransactions={getUpdatedTransactions}
          setCurrentTransactionObject={setCurrentTransactionObject}
          currentTransactionObject={currentTransactionObject}
          activeIndex={activeIndex}
        />
      )}
    </Card>
  );
};

export default ChartOfAccounts;
