import React, { useEffect, useRef, useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Image from "../Image";
import Customers from "../../pages/Customers";
import { CheckIfEnabledInStripeAccountType, getCompanyDataValue, getConnectStatus, getCurrentPageName, getCurrentUser, reverifyHandler, toastConfiguration } from "../../utils/utils";
import User from "../Header/User";
import Notifications from "./notifications";
import Card from "../Card";
import { toast } from "react-toastify";
import Modal from "../Modal";
import { activateCompanyFeature, deActivateCompanyFeature, sortFeatures } from "../../utils/apiCallHanlder";
import Spinner from "../../utils/spinner";
import { useHistory } from "react-router-dom";
import SaveSuccess from '../SaveSuccess';

const initialNavigationConst = [
  {
    title: "Dashboard",
    icon: "dashboard",
    url: "/dashboard",
    stripeAccountTypes: []
  },
  {
    title: "Property",
    icon: "property",
    url: "/projects",
    paths: ['projects', 'lenderportal', 'projects-download'],
    stripeAccountTypes: ['custom'],
  },
  {
    title: "To-Do",
    icon: "stack",
    url: "/board",
    paths: ['board'],
    stripeAccountTypes: [],
  },
  {
    title: "Capital",
    icon: "capital",
    url: "/capital",
    paymentsLocation: true,
    stripeAccountTypes: [],
  },
  {
    title: "Get Paid",
    icon: "dollar",
    url: "/payments",
    paths: ['subscriptions', 'paymentLinks', 'payments', 'quotes', 'quote'],
    paymentsLocation: true,
    stripeAccountTypes: ['custom', 'standard'],
  },
  {
    title: "Accounting",
    icon: "hybrid_view",
    url: "/transactions",
    paths: ['transactions', 'accounting'],
    stripeAccountTypes: ['custom', 'standard'],

  },
  // {
  //   title: "Contacts",
  //   icon: "profile-circle",
  //   url: "/contacts",
  //   component: Customers,
  // },
  {
    title: "Cards",
    icon: "payment",
    url: "/cards",
    paths: ['accountsandcards'],
    stripeAccountTypes: ['custom'],

  },
  {
    title: "CRM",
    icon: "schedule",
    url: "/studio",
    paths: ['discounts', 'taxrate', 'products', 'contacts'],
    stripeAccountTypes: ['custom', 'standard'],

  },
];

let settingsNode = {
  title: "Settings",
  icon: "setting",
  url: "/settings/Profile",
  paths: ['settings'],
  stripeAccountTypes: ['custom', 'standard'],

};

let messagesNode = {
  title: "Messages",
  icon: "message",
  url: "/messages",
  paths: ['messages'],
  stripeAccountTypes: ['custom', 'standard'],
};

let messagesWithAINode = {
  title: "AI Co-Pilot",
  icon: "rocket",
  url: "/ai",
  paths: ['ai'],
};

let currentlySelectedTab = "";
let currentConfiurations = null;

const Sidebar = ({
  className,
  onClose,
  hasUnreadNotifications,
  setHasUnreadNotification,
  isFeatureChanged
}) => {
  const history = useHistory();
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const stripeAccountType = getCompanyDataValue('stripeAccountType')

  const initialNavigation = initialNavigationConst.filter(item => CheckIfEnabledInStripeAccountType(item, stripeAccountType));

  const [navigation, setNavigation] = useState(initialNavigation);
  const [visible, setVisible] = useState(false);
  const [isDashboardEnabled, setIsDashboardEnabled] = useState(null);
  const [isContactsEnabled, setIsContactsEnabled] = useState(null);
  const [isGetPaidEnabled, setIsGetPaidEnabled] = useState(null);
  const [isAccountingEnabled, setIsAccountingEnabled] = useState(null);
  const [isStudioEnalbed, setIsStudioEnabled] = useState(null);
  const [isPropertyEnabled, setIsPropertyEnabled] = useState(null);
  const [isCardsEnabled, setIsCardsEnabled] = useState(null);
  const [isToDoEnabled, setIsToDoEnabled] = useState(null);

  const [showEditMenu, setShowEditMenu] = useState(false);
  const [currentlyClicked, setCurrentlyClicked] = useState("");
  const [loading, setLoading] = useState(false);
  const [menuUpdated, setMenuUpdated] = useState(false);
  const [loadedMenu, setLoadedMenu] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(false);

  const [menuItems, setMenuItems] = useState([]);
  const [showStripeConnectModal, setShowStripeConnectModal] = useState(false);

  let user = null;

  useEffect(() => {
    // const activatedFeatures = JSON.parse(localStorage.getItem("activatedFeatures") || "");
    // const keys = activatedFeatures?.featuresSort;
    // const modifiedKeys = keys?.map(item => item === 'Transactions' ? "Accounting" : item === "GetPaid" ? "Get Paid" : item === "Studio" ? "CRM" : item === 'TodoTask' ? 'To-Do' : item);
    // const navigationWithSortedOrder = navigation?.sort(function (a, b) {
    //   const indexA = modifiedKeys?.indexOf(a?.title);
    //   const indexB = modifiedKeys?.indexOf(b?.title);

    //   if (indexA === -1) return 1;
    //   if (indexB === -1) return -1;

    //   return indexA - indexB;
    // });

    // setNavigation([...navigationWithSortedOrder]);
  }, [])

  useEffect(() => {
    if (showEditMenu) {
      currentConfiurations = JSON.parse(localStorage.getItem("activatedFeatures") || "");
      const menuWithUpdatedValues = [
        {
          title: 'Dashboard',
          subHeading: 'Get a business performance snapshot. Best with all categories enabled.',
          isActivated: currentConfiurations?.isDashboardEnabled,
          activateFeature: () => {
            setCurrentlyClicked('isDashboardEnabled');
            activateHandler('Dashboard', 'isDashboardEnabled');
          },
          deactivateFeature: () => {
            setCurrentlyClicked('isDashboardEnabled');
            deactivateHandler('Dashboard', 'isDashboardEnabled');
          }
        },
        {
          title: 'Property',
          subHeading: 'Set up yor Projects to manage ROI and get funding.',
          isActivated: currentConfiurations?.isPropertyEnabled,
          activateFeature: () => {
            setCurrentlyClicked('Property');
            activateHandler('Property', 'isPropertyEnabled');
          },
          deactivateFeature: () => {
            setCurrentlyClicked('Property');
            deactivateHandler('Property', 'isPropertyEnabled');
          }
        },
        {
          title: 'Contacts',
          subHeading: 'Automate customer communications, set up a prospect pipeline, and more.',
          isActivated: currentConfiurations?.isContactsEnabled,
          activateFeature: () => {
            setCurrentlyClicked('Contacts');
            activateHandler('Contacts', 'isContactsEnabled');
          },
          deactivateFeature: () => {
            setCurrentlyClicked('Contacts');
            deactivateHandler('Contacts', 'isContactsEnabled');
          }
        },
        {
          title: 'Get Paid',
          subHeading: 'An intuitive invoicing tool packed with features, from rent collection to beyond.',
          isActivated: currentConfiurations?.isGetPaidEnabled,
          activateFeature: () => {
            setCurrentlyClicked('Get Paid');
            activateHandler('GetPaid', 'isGetPaidEnabled');
          },
          deactivateFeature: () => {
            setCurrentlyClicked('Get Paid');
            deactivateHandler('GetPaid', 'isGetPaidEnabled');
          }
        },
        {
          title: 'Cards',
          subHeading: 'Manage expenses with dedicated Visa debit cards for projects or team members.',
          isActivated: currentConfiurations?.isCardsEnabled,
          activateFeature: () => {
            const connectStatus = getConnectStatus()?.status;
            if (connectStatus === 0) {
              setShowStripeConnectModal(true);
            } else {
              setCurrentlyClicked('Cards');
              activateHandler('Cards', 'isCardsEnabled');
            }
          },
          deactivateFeature: () => {
            setCurrentlyClicked('Cards');
            deactivateHandler('Cards', 'isCardsEnabled');
          }
        },
        {
          title: 'Accounting',
          subHeading: 'Manage events, emails, contracts, and tasks, in one place, with our easy to use CRM. ',
          isActivated: currentConfiurations?.isAccountingEnabled,
          activateFeature: () => {
            setCurrentlyClicked('Accounting');
            activateHandler('Accounting', 'isAccountingEnabled');
          },
          deactivateFeature: () => {
            setCurrentlyClicked('Accounting');
            deactivateHandler('Accounting', 'isAccountingEnabled');
          }
        },
        {
          title: 'Studio',
          subHeading: 'Manage events, emails, contracts, and tasks, in one place, with our easy to use CRM. ',
          isActivated: currentConfiurations?.isStudioEnabled,
          activateFeature: () => {
            setCurrentlyClicked('Studio');
            activateHandler('Studio', 'isStudioEnabled');
          },
          deactivateFeature: () => {
            setCurrentlyClicked('Studio');
            deactivateHandler('Studio', 'isStudioEnabled');
          }
        },
      ]

      const keys = navigation?.map(item => item?.title);
      const modifiedKeys = keys?.map(item => item === 'Transactions' ? "Accounting" : item);
      const menuWithSortedOrder = menuWithUpdatedValues?.sort(function (a, b) {
        return modifiedKeys?.indexOf(a?.title) - modifiedKeys?.indexOf(b?.title);
      });

      setMenuItems([...menuWithSortedOrder]);
      setLoadedMenu(true);
    }
  }, [showEditMenu, menuUpdated])

  useEffect(() => {
    const activatedFeatures = JSON.parse(localStorage.getItem("activatedFeatures") || "");
    setIsPropertyEnabled(activatedFeatures?.isPropertyEnabled);
    setIsStudioEnabled(activatedFeatures?.isStudioEnabled);
    setIsAccountingEnabled(activatedFeatures?.isAccountingEnabled);
    setIsCardsEnabled(activatedFeatures?.isCardsEnabled);
    setIsDashboardEnabled(activatedFeatures?.isDashboardEnabled)
    setIsContactsEnabled(activatedFeatures?.isContactsEnabled)
    setIsGetPaidEnabled(activatedFeatures?.isGetPaidEnabled)
    setIsToDoEnabled(activatedFeatures?.isTodoTaskEnabled)
  }, [isFeatureChanged, menuUpdated])

  const activateHandler = async (featureName, apiFeatureName) => {
    setLoading(true)
    const { data, error } = await activateCompanyFeature({
      featureName
    })
    if (data) {
      toast.success(`${featureName} Enabled Successfully`, toastConfiguration);

      Object.keys(currentConfiurations)?.map(item => {
        if (item === apiFeatureName) {
          currentConfiurations[item] = !currentConfiurations[item];
        }
      })

      localStorage.setItem("activatedFeatures", JSON.stringify(currentConfiurations))
      setTimeout(setMenuUpdated(!menuUpdated), 2000);
    } else {
      toast.error(error || `Error in deactivating ${featureName}`, toastConfiguration);
    }

    setLoading(false);
    setCurrentlyClicked(null);
  }

  const deactivateHandler = async (featureName, apiFeatureName) => {
    setLoading(true);
    const { data, error } = await deActivateCompanyFeature({
      featureName
    })
    if (data) {
      toast.success(`${featureName} Disabled Successfully`, toastConfiguration);

      Object.keys(currentConfiurations)?.map(item => {
        if (item === apiFeatureName) {
          currentConfiurations[item] = !currentConfiurations[item];
        }
      })

      localStorage.setItem("activatedFeatures", JSON.stringify(currentConfiurations))
      setTimeout(setMenuUpdated(!menuUpdated), 2000);
    } else {
      toast.error(error || `Error in deactivating ${featureName}`, toastConfiguration);
    }

    setLoading(false);
    setCurrentlyClicked(null);
  }

  if (typeof window !== "undefined") {
    user = getCurrentUser();
  }

  const currentPage = getCurrentPageName();

  var path = window?.location?.pathname;
  var page = path?.split("/")[1];

  const CheckIfSelected = (pagePath, node) => {
    if (node && node.url && pagePath) {
      if (node.url.toUpperCase() === ('/' + pagePath).toUpperCase()) {
        return true;
      }
      if (node.paths && node.paths.find(r => r.toUpperCase() == pagePath.toUpperCase())) {
        return true;
      }
    }
    return false;
  }

  const handleDrag = async (e) => {
    e.preventDefault();

    let _fields = [...menuItems];

    if (!dragOverItem?.current && dragOverItem?.current !== 0) {
      return;
    }

    const draggedItem = _fields[dragItem.current];
    _fields[dragItem?.current] = _fields[dragOverItem?.current];
    _fields[dragOverItem?.current] = draggedItem;



    dragItem.current = null;
    dragOverItem.current = null;
    setMenuItems([..._fields]);

    const items = _fields?.map(item => item?.title === "Get Paid" ? "GetPaid" : item?.title);
    const itemsWithUpdatedName = items?.map(item => item === 'Transactions' ? "Accounting" : item === "GetPaid" ? "Get Paid" : item);
    const updatedNavigation = navigation?.sort(function (a, b) {
      return itemsWithUpdatedName?.indexOf(a?.title) - itemsWithUpdatedName?.indexOf(b?.title);
    });

    setNavigation([...updatedNavigation || initialNavigation]);

    localStorage.setItem("activatedFeatures", JSON.stringify({
      ...currentConfiurations,
      featuresSort: items
    }))

    await sortFeatures({ featuresSort: items });
  }
  return (
    <>
      <SaveSuccess
        showSuccessModal={showStripeConnectModal}
        text={"Stripe should be connected before activating cards"}
        onClose={() => setShowStripeConnectModal(false)}
        onConfirm={() => {
          reverifyHandler(setIsStripeLoading);
        }}
        loading={isStripeLoading}
        isForStripe={true}
      />

      {/* Edit Sidebar Modals */}
      <Modal
        outerClassName={styles.outerClass}
        visible={showEditMenu}
        dontCloseOnClickOutside
        onClose={() => {
          let selectedCategory = null;

          navigation?.map(item => {
            const isSelected = CheckIfSelected(page, item);
            if (isSelected) {
              selectedCategory = item;
            }
          })

          menuItems?.map(item => {
            if (item?.title === selectedCategory?.title) {
              if (!item?.isActivated) {
                history.push("/settings")
              }
            }
          })

          setShowEditMenu(false);
        }}
        children={
          loadedMenu ? (
            <Card
              title="Edit your Dashboard setup"
              classCardHead={styles.modalCardHead}
            >
              <div className={styles.itemsContainer}>
                {menuItems?.map((item, index) => {
                  return <div
                    draggable
                    onDragStart={() => (dragItem.current = index)}
                    onDragEnter={() => (dragOverItem.current = index)}
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnd={handleDrag}
                    className={styles.editMenuItem}
                    key={index}
                    style={{ border: !item?.isActivated ? '3px solid  #C7C5BF' : '3px solid #FF4900' }}
                  >
                    <div style={{ cursor: 'pointer' }}>
                      <Icon name="drag" size="25" viewBox="0 0 25 25" />
                    </div>
                    <span
                      className={styles.itemHeader}
                      style={{ color: !item?.isActivated ? '#464542' : '#FF4900' }}
                    >
                      {item?.title}
                    </span>
                    <span
                      className={styles.itemSubHeading}
                      style={{ color: !item?.isActivated ? '#464542' : '#FF4900' }}
                    >
                      {item?.subHeading}
                    </span>
                    {
                      item?.isActivated ?
                        <button className={`button-stroke-red ${styles.menuButtonDisable}`} onClick={item?.deactivateFeature}>
                          {(currentlyClicked === item?.title && loading) ?
                            <Spinner size="24" color="gray" />
                            :
                            "Deactivate"
                          }
                        </button>
                        :
                        <button className={`button ${styles.menuButton}`} onClick={item?.activateFeature}>
                          {(currentlyClicked === item?.title && loading) ?
                            <Spinner size="24" color="white" />
                            :
                            "Activate"
                          }
                        </button>
                    }
                  </div>
                })}
              </div>
            </Card>
          ) :
            <></>
        }
      />


      <div
        className={cn(styles.sidebar, className, { [styles.active]: visible })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link className={styles.logo} to="/" onClick={onClose}>
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-dark.png"
            alt="Core"
          />
        </Link>
        <div className={styles.menu}>
          {navigation.map((x, index) => {

            //let isSelected = (x.title === currentlySelectedTab || page?.toUpperCase() === x.title.toUpperCase());
            let isSelected = CheckIfSelected(page, x);

            if (page?.toUpperCase() === x.title.toUpperCase()) {
              currentlySelectedTab = ""
            }
            return (x.title === "Accounting" && !isAccountingEnabled) ||
              (x.title === "Property" && !isPropertyEnabled) ||
              (x.title === "Dashboard" && !isDashboardEnabled) ||
              (x.title === "Get Paid" && !isGetPaidEnabled) ||
              (x.title === "Contacts" && !isContactsEnabled) ||
              (x.title === "CRM" && !isStudioEnalbed) ||
              (x.title === "Cards" && !isCardsEnabled) ||
              (x.title === "To-Do" && !isToDoEnabled)
              ?
              null :
              <NavLink
                className={cn(styles.item,
                  (isSelected) &&
                  styles.active
                )}
                activeClassName={styles.active}
                to={
                  x.accountsAndCardsLocation
                    ? {
                      pathname: x.url,
                      state: { setAddCard: false, visibleCardDetails: false },
                    }
                    : x.paymentsLocation
                      ? {
                        pathname: x.url,
                        state: { setNewInvoice: true },
                      }
                      : x.url
                }
                key={index}
                exact
                onClick={() => {
                  currentlySelectedTab = x.title;
                  if (currentPage.toUpperCase() === x.title.toUpperCase()) {
                    window.location.reload();
                  }
                  onClose();
                }}
              >
                {x.title === 'To-Do' ?
                  <Icon name={x.icon} size="24" viewBox="0 0 32 24" />
                  : x.title === "Capital" ?
                    <Icon name={x.icon} size="24" viewBox="0 0 34 35" />
                    :
                    <Icon name={x.icon} size="24" />
                }
                {x.title}
              </NavLink>
          })}
        </div>
        <div className={cn(styles.menu, styles.premiumMenu)}>
          {/* <p style={{ padding: "0px 13px" }}>Add Ons</p> */}
          {/* {premiumNavigation.map((x, index) => {
            let isSelected = CheckIfSelected(page, x);
            return (x.title === "Studio" && !isStudioEnalbed) ||
              (x.title === "Cards" && !isCardsEnabled)
              ?
              null :
              <NavLink
                className={cn(styles.item, isSelected && styles.active)}
                activeClassName={styles.active}
                to={
                  x.accountsAndCardsLocation
                    ? {
                      pathname: x.url,
                      state: { setAddCard: false, visibleCardDetails: false },
                    }
                    : x.paymentsLocation
                      ? {
                        pathname: x.url,
                        state: { setNewInvoice: true },
                      }
                      : x.url
                }
                key={index}
                exact
                onClick={() => {
                  currentlySelectedTab = x.title;
                  if (currentPage.toUpperCase() === x.title.toUpperCase()) {
                    window.location.reload();
                  }
                  onClose();
                }}

              >
                <Icon name={x.icon} size="24" />
                {x.title}
              </NavLink>
          })} */}
          {/* <div
            className={cn(styles.item, showEditMenu && styles.active)}
            activeClassName={styles.active}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setLoadedMenu(false);
              setShowEditMenu(true)
            }}
          >
            <Icon name="arrow-right" size="24" />
            Edit Categories
          </div> */}
        </div >
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          <Notifications
            className={styles.messages}
            hasUnreadNotifications={hasUnreadNotifications}
            setHasUnreadNotification={setHasUnreadNotification}
          />
          <NavLink activeClassName={styles.active} to="/messages"
            className={cn(styles.link,
              (CheckIfSelected(page, messagesNode)) &&
              styles.active
            )}>
            <Icon name="messages" size="24" />
            Messages
          </NavLink>
          {/* <NavLink activeClassName={styles.active} to="/ai"
            className={cn(styles.link,
              (CheckIfSelected(page, messagesWithAINode)) &&
              styles.active
            )}>
            <Icon name="rocket" size="24" />
            AI Co-Pilot
          </NavLink> */}
          {/* <Messages className={styles.messages} /> */}
          <User
            className={styles.user}
            userNamePos={
              <div className={styles.footerUserWrapper}>
                <div className={styles.namePos}>
                  <h5>{user?.name}</h5>
                  <p>{user?.roleid == 1 ? 'Admin' : 'Staff'}</p>
                </div>
                <Icon name="arrow-down" size="24" />
              </div>
            }
          />
          {/* <a className={styles.link} href="https://help.greatweek.com/" target="blank" style={{ marginTop: -5 }}>
            <Icon name="help" size="24" />
            Help Center
          </a> */}
          <NavLink activeClassName={styles.active} to="/settings/Profile"
            className={cn(styles.link,
              (CheckIfSelected(page, settingsNode)) &&
              styles.active
            )}>
            <Icon name="setting" size="24" />
            Settings
          </NavLink>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div >
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
