import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Amount from "../../../../components/Amount";
import AmountInCents from "../../../../components/AmountInCents";

const Item = ({ className, onActive, item, showSubtitle, currentFilter }) => {
  const date = new Date();
  return (
    <div className={cn(styles.item, className)} onClick={onActive}>
      <div className={styles.details}>
        <div className={styles.subtitle}>
          {item.title + " " + (currentFilter === 4 ? (date?.getFullYear() - 1) : (date?.getFullYear()))}
        </div>
        {showSubtitle && (
          <div className={styles.subtitleX}>
            {item?.subTitle}
          </div>
        )}
        <div className={styles.counter}>
          <Amount amount={item.counter} subtitleEmValue={"0.7em"} alignItemsEnd />
          {/* <AmountInCents amountInCents={item.counter} subtitleEmValue={"0.5em"} currency="USD" /> */}
        </div>
      </div>
    </div>
  );
};

export default Item;
