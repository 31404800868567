import React, { useState } from "react";
import Card from "../../../../components/Card";
import styles from "./AccountInfo.module.sass";
import Icon from "../../../../components/Icon";
import TextInput from "../../../../components/TextInput";
import ReactPhoneInput from "react-phone-input-2";
import Image from "../../../../components/Image";
import Modal from "../../../../components/Modal";
import AlternativeAvatar from "../../../../components/AlternativeAvatar";
import cn from "classnames";
import { uploadProductImage } from "../../../../utils/apiCallHanlder";
import { toast } from "react-toastify";
import Spinner from "../../../../utils/spinner";
import { toastConfiguration } from "../../../../utils/utils";
import Dropdown from "../../../../components/Dropdown";
import Dropdown_c from "../../../../components/Dropdown_c";

const inputStyle = {
  position: "relative",
  fontSize: "14px",
  letterSpacing: ".01rem",
  marginTop: "0px !important",
  marginBottom: " 0px !important",
  paddingLeft: "48px",
  marginLeft: "0px",
  background: "#F4F4F4",
  border: "none",
  borderRadius: "10px",
  lineHeight: "25px",
  height: "45px",
  width: "100%",
  outline: "none",
};

const superHeros = [
  {
    imageLink: "./images/content/avatar-1.jpg",
  },
  {
    imageLink: "./images/content/avatar-1.jpg",
  },
  {
    imageLink: "./images/content/avatar-1.jpg",
  },
  {
    imageLink: "./images/content/avatar-1.jpg",
  },
  {
    imageLink: "./images/content/avatar-1.jpg",
  },
  {
    imageLink: "./images/content/avatar-1.jpg",
  },
  {
    imageLink: "./images/content/avatar-1.jpg",
  },
  {
    imageLink: "./images/content/avatar-1.jpg",
  },
  {
    imageLink: "./images/content/avatar-1.jpg",
  },
  {
    imageLink: "./images/content/avatar-1.jpg",
  },
  {
    imageLink: "./images/content/avatar-1.jpg",
  },
  {
    imageLink: "./images/content/avatar-1.jpg",
  },
];
const animated = [
  {
    imageLink: "./images/content/avatar-2.jpg",
  },
  {
    imageLink: "./images/content/avatar-2.jpg",
  },
  {
    imageLink: "./images/content/avatar-2.jpg",
  },
  {
    imageLink: "./images/content/avatar-2.jpg",
  },
  {
    imageLink: "./images/content/avatar-2.jpg",
  },
  {
    imageLink: "./images/content/avatar-2.jpg",
  },
  {
    imageLink: "./images/content/avatar-2.jpg",
  },
  {
    imageLink: "./images/content/avatar-2.jpg",
  },
  {
    imageLink: "./images/content/avatar-2.jpg",
  },
  {
    imageLink: "./images/content/avatar-2.jpg",
  },
  {
    imageLink: "./images/content/avatar-2.jpg",
  },
  {
    imageLink: "./images/content/avatar-2.jpg",
  },
];
const jungles = [
  {
    imageLink: "./images/content/avatar-3.jpg",
  },
  {
    imageLink: "./images/content/avatar-3.jpg",
  },
  {
    imageLink: "./images/content/avatar-3.jpg",
  },
  {
    imageLink: "./images/content/avatar-3.jpg",
  },
  {
    imageLink: "./images/content/avatar-3.jpg",
  },
  {
    imageLink: "./images/content/avatar-3.jpg",
  },
  {
    imageLink: "./images/content/avatar-3.jpg",
  },
  {
    imageLink: "./images/content/avatar-3.jpg",
  },
  {
    imageLink: "./images/content/avatar-3.jpg",
  },
  {
    imageLink: "./images/content/avatar-3.jpg",
  },
  {
    imageLink: "./images/content/avatar-3.jpg",
  },
  {
    imageLink: "./images/content/avatar-3.jpg",
  },
];

const avatarTypes = ["Human", "Jungle", "Modern", "Monster", "Superhero"];
let randomNumber = 1;

const AccountInfo = ({
  setName,
  isComingFromRecipient,
  id,
  setAccountEmail,
  setDescription,
  setValueHandler,
  error,
  description,
  setError,
  setMobile,
  mobile,
  email,
  name,
  nameError,
  setNameError,
  customerNav,
  activeNavIndex,
  setActiveNavIndex,
  type,
  setType,
  imageUrl,
  setImageUrl,
  comingFromModal,
  taxStatus,
  setTaxStatus,
  taxes,
}) => {
  const [valid, setValid] = useState(null);
  const [showAvatarModal, setShowAvatarModal] = useState(false);

  const [text, setText] = useState("Upload or drag here");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const emailHandleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else if (isValidEmail(event.target.value)) {
      setError(null);
      setValid("valed");
    } else {
      setError(null);
      setValid(null);
    }
    setValueHandler(event?.target?.value, setAccountEmail);
  };

  const nameChangeHandler = (event) => {
    event.target.value === "" ? setNameError("error") : setNameError(null);
    setValueHandler(event?.target?.value, setName);
  };

  const handleOnChange = (value) => {
    setMobile(value);
  };

  const onDropHandler = (ev) => {
    ev.preventDefault();

    let file = "";
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file = [...ev.dataTransfer.items]
        .find((item) => item.kind === "file")
        .getAsFile();
    } else {
      // Use DataTransfer interface to access the file(s)
      file = ev.dataTransfer.files[0];
    }
    imageUploadHandler(file);
    setText(file?.name);
  };

  const onDragOver = (ev) => ev.preventDefault();

  const imageUploadHandler = async (imageFile) => {
    setUploadLoading(true);
    const formData = new FormData();
    formData.append("", imageFile);
    const { data } = await uploadProductImage(formData);
    if (data) {
      setImageUrl(data.image.url);
    } else {
      toast.error("Error in uploading Image", toastConfiguration);
    }

    setText("Upload or drag here");
    setShowAvatarModal(false);
    setUploadLoading(false);
  };

  return (
    <>
      <Card className={styles.card}>
        {customerNav && !comingFromModal && customerNav}
        {activeNavIndex === 1 ? (
          <>
            <div className={styles.row}>
              <div className={styles.avatarWrap}>
                <button onClick={() => setShowAvatarModal(true)}>
                  <Icon name='close' size='16' />
                </button>
                {imageUrl ? (
                  <Image
                    className={styles.avatar}
                    src={imageUrl ? imageUrl : `/images/content/avatar.jpg`}
                    srcDark={imageUrl}
                  />
                ) : (
                  <AlternativeAvatar name={name || ""} className={"big-icon"} />
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div
                className={
                  nameError && name === "" ? styles.hasError : styles.field
                }
              >
                <TextInput
                  onChange={nameChangeHandler}
                  classInput={name === "" && styles.inputRed}
                  label='Name'
                  name='name'
                  type='text'
                  value={name}
                  required
                />
                {nameError !== "" && nameError !== "error" ? (
                  <div className={styles.errorContainer}>
                    <span className={styles.errorMessage}>{nameError}</span>
                  </div>
                ) : null}
              </div>

              <div className={error ? styles.hasError : styles.field}>
                <TextInput
                  name='email'
                  type='email'
                  label='Email'
                  required
                  value={email}
                  onChange={emailHandleChange}
                  classInput={
                    (error === "Email is invalid" || email === "") &&
                    styles.inputRed
                  }
                />
                {error !== "" && error !== "Email is invalid" ? (
                  <div className={styles.errorContainer}>
                    <span className={styles.errorMessage}>{error}</span>
                  </div>
                ) : null}
              </div>

              <div className={styles.field}>
                <div className={styles.fieldLabel}>Mobile</div>
                <div className={styles.mobileField}>
                  <ReactPhoneInput
                    inputExtraProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    buttonStyle={{ border: "none" }}
                    inputStyle={inputStyle}
                    country={"us"}
                    countryCodeEditable={false}
                    value={mobile?.indexOf('+') > -1 ? mobile : '+' + mobile}
                    onChange={handleOnChange}
                  />
                </div>
              </div>

              <TextInput
                className={styles.field}
                onChange={(event) =>
                  setValueHandler(event?.target?.value, setDescription)
                }
                label='Description '
                name='description'
                value={description}
                placeholder=''
                type='text'
                required
              />
              <div className={styles.field}>
                <Dropdown_c
                  label={"Tax Status"}
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={taxStatus}
                  setValue={setTaxStatus}
                  options={taxes}
                />
              </div>
              <div
                 className={styles.field}
                // style={{
                //   pointerEvents:
                //     (id && id !== "add") ||
                //       comingFromModal ||
                //       isComingFromRecipient
                //       ? "none"
                //       : "unset",
                // }}
              >
                <Dropdown
                  label='Type'
                  bodyStyles={{ width: "100%" }}
                  value={type}
                  setValue={setType}
                  options={["Customer", "Recipient"]}
                  className={styles.TypeDropdown}
                />
              </div>
            </div>
            {/* Avatar Modal */}
            <Modal
              visible={showAvatarModal}
              onClose={() => setShowAvatarModal(false)}
              outerClassName={styles.outerClass}
              children={
                <>
                  <Card
                    /* classTitle="title-blue" */
                    title='Image'
                    className={styles.card}
                  >
                    <div
                      className={styles.uploadImage}
                      id='drop_zone'
                      onDrop={onDropHandler}
                      onDragOver={onDragOver}
                      onClick={() => {
                        document?.getElementById("imgupload").click();
                      }}
                    >
                      {uploadLoading ? (
                        <div style={{ marginTop: 5 }}>
                          <Spinner size='24' color='gray' />
                        </div>
                      ) : (
                        <Icon name='upload' size='24' />
                      )}
                      {text}
                    </div>
                    <input
                      type='file'
                      id='imgupload'
                      accept='image/png, image/gif, image/jpeg'
                      style={{
                        display: "none",
                      }}
                      onChange={(event) =>
                        imageUploadHandler(event.target.files[0])
                      }
                    />
                    <p className={styles.orChoose}>Or choose an avatar</p>
                    <div className={styles.nav}>
                      <div className={styles.typeNav}>
                        {avatarTypes.map((x, index) => (
                          <div
                            className={cn(styles.item, {
                              [styles.active]: index === activeIndex - 1,
                            })}
                            key={index}
                            onClick={() => {
                              setActiveIndex(index + 1);
                            }}
                          >
                            {x}
                          </div>
                        ))}
                      </div>
                    </div>
                    {activeIndex === 1 ? (
                      <div className={styles.avatarFlex}>
                        {[...Array(36).keys()].map((x, index) => (
                          <Image
                            key={index}
                            className={styles.avatar}
                            src={`/avatars/HUMANS/GREATWEEK.COM AVATAR ${x + 1
                              }.png`}
                            srcDark={`/avatars/HUMANS/GREATWEEK.COM AVATAR ${x + 1
                              }.png`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowAvatarModal(false);
                              setImageUrl(
                                `/avatars/HUMANS/GREATWEEK.COM AVATAR ${x + 1
                                }.png`
                              );
                            }}
                          />
                        ))}
                      </div>
                    ) : activeIndex === 2 ? (
                      <div className={styles.avatarFlex}>
                        {[...Array(18).keys()].map((x, index) => (
                          <Image
                            key={index}
                            className={styles.avatar}
                            src={`/avatars/JUNGLE/GREATWEEK.COM JUNGLE ${x + 1
                              }.png`}
                            srcDark={`/avatars/JUNGLE/GREATWEEK.COM JUNGLE ${x + 1
                              }.png`}
                            onClick={() => {
                              setShowAvatarModal(false);
                              setImageUrl(
                                `/avatars/JUNGLE/GREATWEEK.COM JUNGLE ${x + 1
                                }.png`
                              );
                            }}
                          />
                        ))}
                      </div>
                    ) : activeIndex === 3 ? (
                      <div className={styles.avatarFlex}>
                        {[...Array(75).keys()].map((x, index) => (
                          <Image
                            key={index}
                            className={styles.avatar}
                            src={`/avatars/MODERN/GREATWEEK.COM ANIMATED ${x + 1
                              }.png`}
                            srcDark={`/avatars/MODERN/GREATWEEK.COM ANIMATED ${x + 1
                              }.png`}
                            onClick={() => {
                              setImageUrl(
                                `/avatars/MODERN/GREATWEEK.COM ANIMATED ${x + 1
                                }.png`
                              );
                              setShowAvatarModal(false);
                            }}
                          />
                        ))}
                      </div>
                    ) : activeIndex === 4 ? (
                      <div className={styles.avatarFlex}>
                        {[...Array(17).keys()].map((x, index) => (
                          <Image
                            key={index}
                            className={styles.avatar}
                            src={`/avatars/MONSTER/GREATWEEK.COM MONSTER ${x + 1
                              }.png`}
                            srcDark={`/avatars/MONSTER/GREATWEEK.COM MONSTER ${x + 1
                              }.png`}
                            onClick={() => {
                              setImageUrl(
                                `/avatars/MONSTER/GREATWEEK.COM MONSTER ${x + 1
                                }.png`
                              );
                              setShowAvatarModal(false);
                            }}
                          />
                        ))}
                      </div>
                    ) : activeIndex === 5 ? (
                      <div className={styles.avatarFlex}>
                        {[...Array(17).keys()].map((x, index) => (
                          <Image
                            key={index}
                            className={styles.avatar}
                            src={`/avatars/SUPERHERO/GREATWEEK.COM SUPERHERO ${x + 1
                              }.png`}
                            srcDark={`/avatars/SUPERHERO/GREATWEEK.COM SUPERHERO ${x + 1
                              }.png`}
                            onClick={() => {
                              setImageUrl(
                                `/avatars/SUPERHERO/GREATWEEK.COM SUPERHERO ${x + 1
                                }.png`
                              );
                              setShowAvatarModal(false);
                            }}
                          />
                        ))}
                      </div>
                    ) : null}
                  </Card>
                </>
              }
            />
          </>
        ) : null}
      </Card>
    </>
  );
};

export default AccountInfo;
