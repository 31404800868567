import React from 'react';
import styles from './BusinessDetails.module.sass';
import BusinessAccount from '../../Settings/Tabs/Account';
import { ToastContainer, toast } from 'react-toastify';
import { toastConfiguration } from '../../../utils/utils';

const BusinessDetails = ({ setCurrentStep, setDetails, details, token }) => {
    return <div className={styles.wrapper}>
        <ToastContainer />
        <div>
            <h3 className={styles.heading}>Ok, let’s fill out your biz details</h3>
            <h3 className={styles.subHeading}>If you're still setting up your business or wish to proceed without it, simply click skip and leave the fields empty.</h3>
            <div className={styles.containerWrapper}>
                <BusinessAccount
                    token={token}
                    isComingFromSignup={true}
                    skipClickHanlder={() => {
                        setDetails({
                            ...details,
                            skipCompany: true,
                            company: null
                        })
                        setCurrentStep(3)
                    }}
                    continueClickHandler={(
                        businessName,
                        legalBusinessName,
                        addressLine1,
                        country,
                        imageUrl,
                        email,
                        phone,
                        coverImage,
                        type,
                        date,
                        taxNumber,
                        emailError
                    ) => {
                        // if (businessName?.trim() === "") {
                        //     toast.error("Business Name is required", toastConfiguration);
                        //     return;
                        // }

                        // if (legalBusinessName?.trim() === "") {
                        //     toast.error("Legal Business Name is required", toastConfiguration);
                        //     return;
                        // }

                        if (emailError) { //email?.trim() === "" ||
                            toast.error("Valid email is required", toastConfiguration);
                            return;
                        }

                        setDetails({
                            ...details,
                            skipCompany: false,
                            company: {
                                ...details?.company,
                                imageUrl: imageUrl,
                                headerImageUrl: coverImage,
                                businessName: businessName,
                                companyName: legalBusinessName,
                                businessType: type,
                                country: country,
                                address: addressLine1,
                                taxNumber: taxNumber,
                                email: email,
                                phone: phone,
                                incorporationDate: date
                            }
                        })
                        setCurrentStep(3)
                    }}
                />
            </div>
        </div>
    </div>
}

export default BusinessDetails;