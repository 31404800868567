import React, { useEffect, useState } from "react";
import styles from "./CreateNewProduct.module.sass";
import Modal from "../../../../../../components/Modal";
import AddNewProduct from '../../../../../Products/EditNewProduct';

const CreateNewProduct = ({
  showNewProductModal,
  setShowNewProductModal,
  searchString,
  showProduct,
  productSelectHandler,
  showSubscription,
  showOneTime
}) => {


  return (
    <>
      <Modal
        visible={showNewProductModal}
        onClose={() => {
          setShowNewProductModal(false);
        }}
        outerClassName={styles.outer}
        buttonClass={styles.closeButton}
      >
        <AddNewProduct
          searchString={searchString}
          isAdd={true}
          comingFromInvoice={true}
          productSelectHandler={productSelectHandler}
          showProduct={showProduct}
          showOneTime={showOneTime}
          showSubscription={showSubscription}
          className={styles.card}
        />

      </Modal>
    </>
  );
};

export default CreateNewProduct;
