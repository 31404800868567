import React, { useEffect, useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Icon from "../../../../../components/Icon";
import Dropdown from "../../../../../components/Dropdown_c";
import {
  dateFormatter,
  getBaseURLOfCurrentApp,
  getCurrentUser,
  protectCardRoutingNumbers,
} from "../../../../../utils/utils";
import {
  teamUpdateMemberRole,
  deleteTeamMember,
  resendTeamInvite,
} from "../../../../../utils/apiCallHanlder";
import Spinner from "../../../../../utils/spinner";
import AddNew from "../AddNew";
import { toast, ToastContainer } from "react-toastify";
import { toastConfiguration } from "../../../../../utils/utils";

const Row = ({
  item,
  className,
  roles,
  setUpdateUseEffect,
  updateUseEffect,
}) => {
  let user = getCurrentUser();
  const [visible, setVisible] = useState(false);
  const [role, setRole] = useState(item?.roleid.toString());
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);

  const WithOwnerRoles = [
    ...roles,
    {
      display: "Owner",
      text: "Owner",
      value: "0",
    },
  ];

  const dropdownHandleChange = async (value) => {
    const uploadRolePayload = {
      userid: item.userid,
      roleid: parseInt(value),
    };
    setLoading(true);

    const res = await teamUpdateMemberRole(uploadRolePayload);
    const { data } = res;
    if (data) {
      toast.success("Team member role updated succesfully", toastConfiguration);
      //setUpdateUseEffect(!updateUseEffect)
    } else {
      toast.error("Error in updating member role", toastConfiguration);
    }
    setLoading(false);
  };

  const deleteHandler = async () => {
    setDeleteLoading(true);
    const deleteResponse = await deleteTeamMember(item.userid);
    const { data } = deleteResponse;

    if (data) {
      setUpdateUseEffect(!updateUseEffect);
    }

    setDeleteLoading(false);
  };

  const resendInviteHandler = async () => {
    setResendLoading(true);

    const resendResponse = await resendTeamInvite({
      userid: item.userid,
      returnUrl: getBaseURLOfCurrentApp() + "/invite-team",
    });

    const { data } = resendResponse;

    if (data) {
      //setInviteSent(true);
      toast.success(
        "Invitation has been sent on given email address",
        toastConfiguration
      );
    } else {
      toast.error("Error in sending email address", toastConfiguration);
    }

    setResendLoading(false);
  };

  item.icons = "dots";
  return (
    <>
      <div
        className={cn(
          styles.row
          // { [styles.selected]: activeId === item.id },
          // { [styles.active]: activeTable }
        )}
      >
        <ToastContainer />
        <div className={styles.editOverlay}></div>
        <div className={cn(styles.col, styles.product)}>
          {
            <img
              className={styles.image}
              src={item.imageUrl || "/placeholder.png"}
              alt='product'
            />
          }
          <div
            className={cn(styles.productDetailsImg)}
            style={{ width: "74%", display: "flex" }}
          >
            {item.inviteStatus === "Pending" ? (
              <span className={styles.archivedText}> Pending </span>
            ) : (
              <span className={styles.name}>{item.name}</span>
            )}
            <br />
          </div>
        </div>

        <div
          className={cn(styles.col, styles.email)}
          style={{ padding: "0px 46px 16px" }}
        >
          <div className={cn(styles.amount)} style={{ width: "187px" }}>
            {item.email}
          </div>
        </div>
        {!item.isOwner ? (
          <div className={styles.col}>
            <div className={cn(styles.amount)}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={role}
                loading={loading}
                handleChange={dropdownHandleChange}
                setValue={setRole}
                options={roles}
              />
            </div>
          </div>
        ) : (
          <div className={styles.col} style={{ pointerEvents: "none" }}>
            <div className={cn(styles.amount)}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={"0"}
                handleChange={dropdownHandleChange}
                setValue={setRole}
                options={WithOwnerRoles}
              />
            </div>
          </div>
        )}

        <div className={styles.col}>
          <div className={styles.mblCreate}>Card</div>
          {item?.card ? (
            <div className={cn(styles.amount)}>{"**" + item.card}</div>
          ) : (
            <span className={cn(styles.amount)}> N/A </span>
          )}
        </div>
        <div
          className={`${styles.col} ${styles.dateCol}`}
          style={
            item.inviteStatus === "Pending"
              ? { position: "unset", left: "unset" }
              : null
          }
        >
          <div className={styles.mblCreate}>Last Login</div>
          <div className={styles.created} style={{ width: "115px" }}>
            {item.inviteStatus === "Pending" ? (
              <button
                className={cn("button", styles.button, styles.resendButton)}
                type='button'
                onClick={resendInviteHandler}
              >
                {resendLoading ? (
                  <Spinner loading={resendLoading} color={"blue"} size={"24"} />
                ) : (
                  <span>Resend Invite</span>
                )}
              </button>
            ) : (
              <span
                className={cn("button", styles.button, styles.date)}
                style={{ width: "115px" }}
              >
                {dateFormatter(item.lastloginon)}
              </span>
            )}
          </div>
        </div>
        <div className={styles.col}>
          <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div
              className={cn(styles.dotsBtn, className, {
                [styles.active]: visible,
              })}
            >
              <div
                className={styles.head}
                style={
                  item.isOwner || user?.userid == item.userid
                    ? { display: "none" }
                    : {}
                }
              >
                {deleteLoading ? (
                  <Spinner loading={deleteLoading} size={24} color={"gray"} />
                ) : (
                  <button className={cn(styles.btn)} onClick={deleteHandler}>
                    <Icon name={"trash"} size='20' fill={"#FF4900"} />
                  </button>
                )}
              </div>
              <div className={styles.actionBody}></div>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </>
  );
};

export default Row;
