import React, { useState, useEffect, useCallback } from "react";
import Card from "../../../../components/Card";
import Modal from "../../../../components/Modal";
import TextInput from "../../../../components/TextInput";
import Dropdown from "../../../../components/Dropdown";
import styles from "./DocumentOverviewPopup.module.sass";
import Icon from "../../../../components/Icon";
import cn from "classnames";
import Image from "../../../../components/Image";
import Dropzone, { useDropzone } from "react-dropzone";
import {
  dateFormatterWithTime,
  dateFormatterWithTimeZone,
  GetFileNameFromPath,
  toastConfiguration,
} from "../../../../utils/utils";
import {
  updateAccountDocumentMetadata,
  deleteAccountingDocuments,
  getAccountingDocumentsByIds,
  uploadFile,
  setContactDocuments,
  searchCustomer,
  addPropertyDocument,
  updatePropertyDocument,
} from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { toast } from "react-toastify";
import Search from "../../../Payments/Invoices/NewInvoice/Search";

const imageWRTExtension = {
  common: "/images/content/image.png",
  xlsx: "/images/content/xlsx.png",
  xls: "/images/content/xlsx.png",
  pdf: "/images/content/pdf.png",
};

const documentTypes = [
  { text: "Other", value: 0 },
  { text: "Income", value: 1 },
  { text: "Expense", value: 2 },
];


const DocumentOverviewPopup = ({
  editDocItem,
  showNewDocModal,
  setShowNewDocModal,
  onItemDeleted,
  onItemUpdated,
  customerId,
  customerName,
  setUpdateDocumentObject,
  updateDocumentObject,
  currentlySelectedDocument,
  setCurrentlySelectedDocument,
  isComingFromProperty,
  propertyId,
  getDocumentList,
  preSetName = "",
  onDocumentSaveHandler
}) => {
  const [docName, setDocName] = useState("");
  const [description, setDescription] = useState("");
  const [contact, setContact] = useState(customerName);
  const [transectionId, setTransectionId] = useState("");
  const [documentType, setDocumentType] = useState(documentTypes[0].text);
  const [uploading, setUploading] = useState(false);
  const [attachmentLink, setAttachmentLink] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [downloadLink, setDownloadLink] = useState("");
  const [uploadedOn, setUploadedOn] = useState("");
  const [docTypesOptions, setDocTypeOptions] = useState([]);
  const [ocrIsDone, setOcrIsDone] = useState(false);
  const [ocrData, setOcrData] = useState(null);

  const [searchNames, setSearchNames] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [selectCustomerLoading, setSelectCustomerLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [blobUrl, setBlobUrl] = useState("");

  const [counterPartyId, setCustomerId] = useState(customerId);

  const [status, setStatus] = useState(null);

  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    if (preSetName) {
      setDocName(preSetName);
    }
  }, [preSetName])

  useEffect(() => {
    setCustomerId(customerId);
  }, [customerId])

  useEffect(() => {
    if (!showNewDocModal) {
      setCustomerId(undefined);
      setContact(undefined);
      setDescription(undefined);
      setSearchString("");
      setDocName("");
      setDocumentType(documentTypes[0].text)
      setDescription("");
      setAttachmentLink([]);
      setVisible(false)
    }
  }, [showNewDocModal])

  useEffect(async () => {
    if (showNewDocModal) {
      if (currentlySelectedDocument) {
        getBlobUrl(currentlySelectedDocument?.attachmentLink || "")
        setAttachmentLink([currentlySelectedDocument?.attachmentLink]);
        setDocName(currentlySelectedDocument?.displayName);
        setDocumentType(currentlySelectedDocument?.documentType);
        setContact(currentlySelectedDocument?.counterPartyName);
        setDescription(currentlySelectedDocument?.notes);
        setUploadedOn(currentlySelectedDocument?.uploadedOn);
        setStatus(currentlySelectedDocument?.status);
      }

      if (!isComingFromProperty) {
        setSelectCustomerLoading(true);
        await getCustomers("");
        setSelectCustomerLoading(false);
      }
    }
  }, [showNewDocModal]);

  const getCustomers = async (searchString) => {
    const { data } = await searchCustomer(searchString);
    if (data) {
      setSearchNames(data?.list);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    const formData = new FormData();
    acceptedFiles.map((x, index) => {
      formData.append("files", x);
    });

    setUploading(true);
    await uploadFile(formData);
    const { data, error } = await uploadFile(formData);

    if (data) {
      const docLink = data?.file?.url;
      if (docLink) {
        //attachmentLink.push(docLink);
        setAttachmentLink([docLink]);
        getBlobUrl(docLink)
      }
    }
    else
    {
      toast.error(error || "Error in uploading file", toastConfiguration);
    }
    setUploading(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const deleteDocumentHandler = async (e, docLink) => {
    setDeleteLoading(true);
    e.stopPropagation();
    setAttachmentLink(
      attachmentLink.filter(function (item) {
        return item !== docLink;
      })
    );
    attachmentLink.pop(docLink);
    setDeleteLoading(false);
  };

  useEffect(() => {
    var currentDocDirection = null;
    let docTypesOptions = [];
    for (const item of documentTypes) {
      docTypesOptions.push(item.text);
      if (editDocItem && editDocItem.directionType == item.value) {
        currentDocDirection = item.text;
      }
    }
    setDocTypeOptions(docTypesOptions);

    if (editDocItem) {
      if (editDocItem?.contactId) {
        setCustomerId(editDocItem?.contactId);
        setContact(editDocItem?.contactName);
      }

      setDocName(editDocItem.displayName);
      setDescription(editDocItem?.notes);
      setDocumentType(editDocItem?.documentType);
      getBlobUrl(editDocItem?.attachmentLink || "")
      setAttachmentLink([editDocItem?.attachmentLink]);
      setUploadedOn(editDocItem.uploadedOn);
      setDownloadLink(editDocItem.attachmentLink);
      setOcrIsDone(editDocItem.ocrIsDone);
      setOcrData(editDocItem.ocrData);

      if (!ocrIsDone) {
        ocrChecker();
      }
    }
  }, [editDocItem]);

  const ocrChecker = async () => {
    for (let retries = 0; retries < 3; ++retries) {
      if (ocrIsDone) break;

      const ids = [];
      ids.push(editDocItem.documentFileId);
      const { data } = await getAccountingDocumentsByIds(ids);

      if (data && data.documents && data.documents.length > 0 && data.documents[0].ocrIsDone) {
        setOcrIsDone(data.documents[0].ocrIsDone);
        setOcrData(data.documents[0].ocrData);
        break;
      }

      await timeout(5000);
    }
  };

  const toastRef1 = (val) => {
    if (val === 0) {
      var toastRef = toast.error("Attachment is not provided", toastConfiguration);
    } else if (val === 2) {
      toastRef = toast.error("Document Name is not provided", toastConfiguration);
    }
  };

  const savePropertyDocument = async () => {
    if (attachmentLink.length === 0) {
      toastRef1(0);
    } else if (docName === "" || docName.trim() === "") {
      toastRef1(2);
    } else {
      toastRef1(1);
      const commonFields = {
        DisplayName: docName,
        DocumentType: documentType,
        AttachmentLink: attachmentLink[0],
        entityId: propertyId,
        Notes: description,
        documentFileId: currentlySelectedDocument?.documentFileId
      };

      setSaveLoading(true);
      const { data, error } = currentlySelectedDocument ?
        await updatePropertyDocument(commonFields) :
        await addPropertyDocument(commonFields);


      if (data) {
        toast.success(
          currentlySelectedDocument
            ? "Successfully updated Document"
            : "Successfully Added New Document",
          toastConfiguration
        );
        setCurrentlySelectedDocument && setCurrentlySelectedDocument(null);
        setShowNewDocModal(false);
        setSaveLoading(false);
        getDocumentList && getDocumentList(false);
        onDocumentSaveHandler && onDocumentSaveHandler(data?.document)
      } else {
        toast.error(
          error ||
          (currentlySelectedDocument ? "Error in updating document" : "Error in Adding New Document"),
          toastConfiguration
        );
        setSaveLoading(false);
      }
    }
  }

  const saveDocument = async () => {
    if (attachmentLink.length === 0) {
      toastRef1(0);
    } else if (docName === "" || docName.trim() === "") {
      toastRef1(2);
    } else {
      toastRef1(1);
      const commonFields = {
        DisplayName: docName,
        DocumentType: documentType,
        AttachmentLink: attachmentLink[0],
        CounterPartyId: counterPartyId,
        CounterPartyName: contact,
        TransactionId: "",
        TransactionAccountId: "",
        Notes: description,
        DocumentFileId: editDocItem?.documentFileId
      };

      setSaveLoading(true);

      const { data, error } = await setContactDocuments(
        currentlySelectedDocument
          ? {
            documentFileId: currentlySelectedDocument?.documentFileId,
            ...commonFields,
          }
          : {
            ...commonFields,
          }
      );
      if (data) {
        setUpdateDocumentObject && setUpdateDocumentObject(!updateDocumentObject);
        toast.success(
          (currentlySelectedDocument || editDocItem)
            ? "Successfully updated Document"
            : "Successfully Added New Document",
          toastConfiguration
        );
        setCurrentlySelectedDocument && setCurrentlySelectedDocument(null);
        setShowNewDocModal(false);
        setSaveLoading(false);
      } else {
        toast.error(
          error ||
          ((currentlySelectedDocument || editDocItem) ? "Error in updating document" : "Error in Adding New Document"),
          toastConfiguration
        );
        setSaveLoading(false);
      }
    }
  };

  const timeout = (delay) => {
    return new Promise((resolve) => setTimeout(resolve, delay));
  };

  const deleteDocument = async () => {
    if (editDocItem) {
      let its = [];
      its.push(editDocItem.documentFileId);
      deleteAccountingDocuments(its);
      onItemDeleted(editDocItem);
      setShowNewDocModal(false);
    }
  };

  const updateDocMetadata = async () => {
    if (editDocItem) {
      var docType = -1;
      for (const item of documentTypes) {
        if (item.text === documentType) {
          docType = item.value;
        }
      }
      editDocItem.displayName = docName;
      editDocItem.description = description;
      editDocItem.directionType = docType;

      updateAccountDocumentMetadata(editDocItem);
      onItemUpdated(editDocItem);
      //setShowNewDocModal(false);
    }
  };

  useEffect(() => {
    if (attachmentLink.length !== 0) {
      toastRef1(1);
    }
    if (docName !== "") {
      toastRef1(1);
    }
  });

  const getBlobUrl = async (url) => {
    var logoVideoXhr = new XMLHttpRequest();
    logoVideoXhr.open("GET", url, true);
    logoVideoXhr.responseType = "arraybuffer";

    logoVideoXhr.send();

    logoVideoXhr.onload = function (oEvent) {
      var blob = new Blob([oEvent.target.response], {
        type: "application/pdf",
      });
      var blobURL = URL.createObjectURL(blob);
      setBlobUrl(blobURL);
    };
  };

  return (
    <>
      <Modal
        visible={showNewDocModal}
        onClose={() => {
          toastRef1(1);
          setShowNewDocModal(false);
        }}
        outerClassName={styles.modalOuter}
      >
        <div className={styles.row}>
          <div className={styles.col}>
            <Card title="Input" /* classTitle="title-blue" */ className={styles.card}>
              <TextInput
                label="Document name"
                className={styles.input}
                classLabel={styles.label}
                onChange={(event) => setDocName(event?.target?.value)}
                value={docName}
                required
              />
              <Dropdown
                label="Document type"
                value={documentType}
                setValue={setDocumentType}
                options={docTypesOptions}
                className={styles.dropdown}
                bodyStyles={{ width: "100%" }}
              />
              {isComingFromProperty ? null :
                !customerName ? (
                  <>
                    <p className={styles.label}>Assign to a contact</p>
                    <Search
                      className={styles.input}
                      inputPlaceholder="Search customer"
                      addNewBtnTxt="Add new Customer"
                      searchNames={searchNames}
                      selectedCustomerName={contact}
                      getCustomers={getCustomers}
                      setSearchString={setSearchString}
                      visible={visible}
                      setVisible={setVisible}
                      setSearchNames={setSearchNames}
                      searchString={searchString}
                      selectCustomerLoading={selectCustomerLoading}
                      nameClickHandler={(name, id) => {
                        setContact(name);
                        setCustomerId(id);
                        setVisible(false)
                        setSearchString(name);
                      }}
                    />
                  </>
                ) : (
                  <TextInput
                    label="Contact"
                    placeholder="Assign to a contact"
                    value={contact}
                    className={customerName ? cn(styles.disabledInput, styles.input) : styles.input}
                    classLabel={styles.label}
                    onChange={(event) => setContact(event?.target?.value)}
                  />
                )}
              {/* <TextInput
                label="Link to transaction"
                placeholder="Choose the account"
                className={styles.input}
                classLabel={styles.label}
                onChange={(event) => setTransectionId(event?.target?.value)}
              /> */}
              <TextInput
                label="Note"
                placeholder="Enter a description"
                className={styles.input}
                classLabel={styles.label}
                onChange={(event) => setDescription(event?.target?.value)}
                value={description}
              />
              <button
                className="button"
                onClick={() => {
                  if (isComingFromProperty) {
                    savePropertyDocument();
                  } else {
                    saveDocument();
                  }
                }}
              >
                {saveLoading ? <Spinner size="24" color="white" /> : <>Save</>}
              </button>
            </Card>
          </div>
          <div className={styles.col}>
            <Card
              title="Metadata"
              /* classTitle="title-red" */
              className={styles.card}
            >
              <div className={styles.metaWrapper}>
                <div className={styles.singleMeta}>
                  <p className={styles.label}>Uploaded date</p>
                  <p className={styles.text}>
                    {uploadedOn ? dateFormatterWithTime(uploadedOn) : "N/A"}
                  </p>
                </div>
                <div className={styles.singleMeta}>
                  <p className={styles.label}>Uploaded by</p>
                  <p className={styles.text}>{editDocItem?.uploadedBy || "N/A"}</p>
                </div>
                <div className={styles.singleMeta}>
                  <p className={styles.label}>Status</p>
                  <p className={styles.text}>{editDocItem?.status || "N/A"}</p>
                </div>
                <div className={styles.singleMeta}>
                  <p className={styles.label}>OCR Status</p>
                  <p className={styles.text}>{ocrIsDone ? "Done" : "Not ready"}</p>
                </div>
              </div>
            </Card>
            {ocrIsDone && ocrData ? (
              <Card
                title="Scanned data (OCR)"
                /* classTitle="title-green" */
                className={styles.card}
              >
                <div className={styles.metaWrapper}>
                  {Object.keys(ocrData).map((x, index) => (
                    <div className={styles.singleMeta}>
                      <p className={styles.label}>{x}</p>
                      <p className={styles.text}>{ocrData[x]}</p>
                    </div>
                  ))}
                </div>
              </Card>
            ) : null}
          </div>
          <div className={styles.col}>
            <Card
              title="Attachments"
              /* classTitle="title-yellow" */
              className={styles.card}
            >
              {attachmentLink.length < 1 ? (
                <div {...getRootProps({ className: styles.dropZone })}>
                  <div
                    style={{
                      left: "50%",
                      top: "50%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner loading={uploading} color="gray" size="45px" />
                  </div>
                  <input {...getInputProps()} />
                  {uploading ? null : isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <p>Drop documents here, or click to select documents</p>
                  )}
                </div>
              ) : (
                <div className={styles.imgInput}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {attachmentLink.map((x, index) => {
                      const fileNameObj = GetFileNameFromPath(x, true);
                      return (
                        </* onClick={(event) => {
                          event.stopPropagation();
                          //setShowPreviewModal(true);
                          //setPreviewImage(x);
                          //setFileNameObject(fileNameObj);
                          
                        }} */
                          /* style={{ marginLeft: 10, marginTop: 10 }} */>
                          <div className={styles.selectedImageWrap}>
                            {(fileNameObj.extension === "pdf" && blobUrl) ? (
                              <iframe
                                src={
                                  blobUrl + "#toolbar=0"
                                }
                                height={300}
                                width={250}
                                style={{ pointerEvents: "auto" }}
                              />
                            ) :
                              <img
                                alt="img"
                                className={styles.selectedImage}
                                /* style={{ cursor: 'pointer', maxWidth: 319 }} */
                                src={
                                  fileNameObj.extension === "png" ||
                                    fileNameObj.extension === "jpg" ||
                                    fileNameObj.extension === "jpeg"
                                    ? x
                                    : imageWRTExtension[fileNameObj.extension] ||
                                    imageWRTExtension["common"]
                                }
                                //large={previewImage}
                                hideZoom
                              />
                            }
                            <div className={styles.action}>
                              {deleteLoading ? (
                                <>
                                  <div className={styles.modal}>
                                    <div
                                      style={{
                                        position: "fixed",
                                        left: "50%",
                                        top: "45%",
                                      }}
                                    >
                                      <Spinner size="48" color="gray" />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <a href={x} target="blank" download>
                                    <Icon name="download" size="24" />
                                  </a>
                                  {!currentlySelectedDocument && !editDocItem && (
                                    <button
                                      className={styles.dropImgDelete}
                                      onClick={(e) => deleteDocumentHandler(e, x)}
                                    >
                                      <Icon name="trash" size="24" />
                                    </button>
                                  )}
                                  {isComingFromProperty && <button
                                    className={styles.dropImgDelete}
                                    onClick={(e) => deleteDocumentHandler(e, x)}
                                  >
                                    <Icon name="trash" size="24" />
                                  </button>
                                  }
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              )}

              {/* <Image
                src="https://via.placeholder.com/319x530.png"
                srcDark="https://via.placeholder.com/319x530.png"
                className={styles.image}
              /> */}
              {/* <div className={styles.attachmentButtons}>
                <button className={cn("button button-stroke", styles.button)}>
                  <Icon name="expand" size="24" />
                  Expand
                </button>
                <a href={downloadLink} download>
                  <button className={cn("button button-stroke", styles.button)}>
                    <Icon name="download" size="24" />
                    Download
                  </button>
                </a>
                <button
                  className="button button-stroke-red"
                  onClick={deleteDocument}
                >
                  <Icon name="trash" size="24" />
                  Delete
                </button>
              </div> */}
            </Card>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DocumentOverviewPopup;
