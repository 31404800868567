import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Icon from "../../../../components/Icon";
import Balance from "../../../../components/Balance";
import AmountInCents from "../../../../components/AmountInCents";
import Image from "../../../../components/Image";

const Item = ({ className, onActive, item, currency, style, disableClick }) => {
  return (
    <div
      className={cn(styles.item, className)}
      onClick={onActive}
      style={{ ...style, pointerEvents: disableClick ? 'none' : 'unset' }}
    >
      {item.icon || item.color ? (
        <div className={styles.icon} style={{ backgroundColor: item.color }}>
          <Icon name={item.icon} size='24' />
        </div>
      ) : /* item.title?.slice(0, 14) === "Stripe Balance" ? (
          (
            <Image
              src="/images/icons/payment.png"
              srcDark="/images/icons/payment.png"
            />
          )
        ) : item.title?.slice(0, 12) === "Bank Account" ? (
          (
            <Image
              src="/images/logo-dark.png"
              srcDark="/images/logo-light.png"
            />
          )
        ) : */ null}
      <div className={styles.details}>
        <div className={styles.subtitle}>
          {item.accountDigit
            ? item.title + " " + item?.accountDigit
            : item.title}
        </div>
        <div className={styles.counter}>
          <AmountInCents
            amountInCents={item.counter}
            subtitleEmValue={"0.5em"}
            currency={currency}
          />
        </div>
      </div>
    </div>
  );
};

export default Item;
