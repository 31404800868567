import React, { useEffect, useState } from "react";
import styles from "./ProjectOverview.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Table from "./Table";
import EditNewProject from "../EditNewProject";
import Icon from "../../../components/Icon";
import { getAllPropertiesList, multiplePropertyDelete } from "../../../utils/apiCallHanlder";
import { useHistory } from 'react-router-dom';
import { getCurrentPageName, toastConfiguration } from "../../../utils/utils";
import { toast } from "react-toastify";
import DeleteModal from "../../../components/DeleteModal";

let page = 1;

const PropertiesList = ({ id, showLenders }) => {
    const history = useHistory();

    const [checkCount, setCheckCount] = useState(0);
    const [showAddProject, setShowAddProject] = useState(false);
    const [deleteIdArray, setDeleteIdArray] = useState([]);
    const [loading, setLoading] = useState(true);
    const [propertiesData, setPropertiesData] = useState([]);
    const [isNextPageExists, setIsNextPageExists] = useState(false);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [propertyId, setPropertyId] = useState(null);

    const currentPageName = getCurrentPageName();

    useEffect(() => {
        if (currentPageName?.toLowerCase() === 'projects') {
            setShowAddProject(false);
        }
    }, [currentPageName])

    useEffect(async () => {
        await getPropertiesData(false);
        setLoading(false);
    }, [])

    useEffect(() => {
        if (id) {
            setPropertyId(id);
            setShowAddProject(true);
        }
    }, [id])

    const getPropertiesData = async (doConcat) => {
        if (!doConcat) setLoading(true);
        page = doConcat ? (page + 1) : 1;

        const { data } = await getAllPropertiesList({
            page: page,
            pageSize: 30
        });

        if (data) {
            setIsNextPageExists(data?.isNextPageExist);
            doConcat ? setPropertiesData(propertiesData?.concat(data?.propertyList)) :
                setPropertiesData(data?.propertyList);
        }
        if (!doConcat) setLoading(false);
    }

    const multipleDeleteHandler = async () => {
        const { data } = await multiplePropertyDelete({
            ids: deleteIdArray
        })

        if (data) {
            setCheckCount(0);
            setVisibleDelete(false);
            toast.success("Properties deleted successfully", toastConfiguration);
            getPropertiesData(false);
        } else {
            toast.error("Error in bulk deleting properties", toastConfiguration);
        }
    }

    return (
        <>
            {showAddProject ?
                <>
                    {showAddProject && (
                        <span
                            className={styles.backBtn}
                            onClick={() => {
                                history.push("/projects")
                                setShowAddProject(false);
                                getPropertiesData(false);
                            }}
                        >
                            <Icon name="arrow_backward" size="18" />
                            <div style={{ marginTop: 2 }}>Back</div>
                        </span>
                    )}
                    <EditNewProject
                        getPropertiesData={getPropertiesData}
                        setShowAddProject={setShowAddProject}
                        id={propertyId}
                        showLendersTab={showLenders}
                    />
                </>
                :
                <Card
                    className={styles.card}
                    title='Property'
                    border
                    classCardHead={cn(styles.head, { [styles.hidden]: false })}
                    head={
                        <>
                            <div className={styles.buttonContainer}>
                                <button className='button' onClick={() => {
                                    setPropertyId(null);
                                    setShowAddProject(true)
                                }}>
                                    Add Property
                                </button>
                                {checkCount > 0 && (
                                    <DeleteModal
                                        button={
                                            <button className={cn('button-stroke-red', styles.deleteButton)}
                                                onClick={() => setVisibleDelete(true)}>
                                                Delete Marked
                                            </button>
                                        }
                                        visibleDelete={visibleDelete}
                                        setVisibleDelete={setVisibleDelete}
                                        btnClass=" "
                                        onClickHandler={multipleDeleteHandler}
                                        id={id}
                                    />
                                    // <button
                                    //     onClick={async () => { 

                                    //     }}
                                    //     className={cn("button-stroke-red", styles.deleteMarkedBtn)}
                                    //     style={{ width: 150 }}
                                    // >
                                    //     Delete Marked
                                    // </button>
                                )}
                            </div>
                        </>
                    }
                >
                    <div className={cn(styles.row, { [styles.flex]: false })}>
                        <Table
                            checkCount={checkCount}
                            setCheckCount={setCheckCount}
                            setDeleteIdArray={setDeleteIdArray}
                            deleteIdArray={deleteIdArray}
                            propertiesData={propertiesData}
                            hasMore={isNextPageExists}
                            className={styles.table}
                            loading={loading}
                            getPropertiesData={getPropertiesData}
                        />
                    </div>
                </Card>
            }
        </>
    );
};

export default PropertiesList;
