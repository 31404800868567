import React, { useState } from "react";
import html2canvas from "html2canvas";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import styles from "./successModal.module.sass";
import QRCode from "qrcode.react";
import { toast, ToastContainer } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";

const SuccessModal = ({
    className,
    paymentObject,
    visible,
    setVisible,
    setShowAddPaymentLink
}) => {
    const downloadQR = async () => {
        const input = document.getElementById("qr-gen");
        const canvas = await html2canvas(input, {
            scale: 3
        });
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${paymentObject?.id}-qr.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div
            className={cn(styles.deleteModal, className, {
                [styles.active]: visible,
            })}
        >
            <ToastContainer />
            <div className={styles.body}>
                <div className={styles.top}>
                    <button
                        className={cn("close", styles.close)}
                        onClick={() => {
                            setShowAddPaymentLink ?
                                setShowAddPaymentLink(false) :
                                setVisible(false)
                        }}
                    >
                        <Icon name="close" size="20" />
                    </button>
                </div>
                <div className={styles.titleContainer}>
                    <span className={styles.title}>Use this link and QR code to get paid</span>
                </div>
                <div className={styles.container}>
                    <span className={styles.name}>Name</span>
                    <span className={styles.value}>{paymentObject?.name}</span>
                </div>

                <div className={styles.container}>
                    <span className={styles.name}>Price</span>
                    <span className={styles.value}>{paymentObject?.amount}</span>
                </div>

                {/* <div className={styles.container}>
                    <span className={styles.name}>Qty</span>
                    <span className={styles.value}>{paymentObject?.qty || "Not defined"}</span>
                </div> */}

                <div className={styles.container}>
                    <span className={styles.name}>Link</span>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className={styles.link}>{paymentObject?.linkUrl}</div>
                        <div style={{ marginLeft: 5, marginTop: -3, cursor: 'pointer' }} onClick={() => {
                            navigator?.clipboard?.writeText(paymentObject?.linkUrl);
                            toast.success("Payment link copied successfully", toastConfiguration);
                        }}>
                            <img src="/images/copyLink.svg" /></div>
                    </div>
                </div>
                <div style={{
                    backgroundColor: "#625afa",
                    height: 275,
                    width: 235,
                    marginTop: 20,
                    borderRadius: 10,
                    paddingBottom: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div id="qr-gen">
                        <QRCode
                            size={156}

                            style={{
                                height: 192,
                                width: 192,
                                borderRadius: 10,
                                background: "#FFFFFF",
                                padding: 15
                            }}
                            value={paymentObject?.linkUrl}
                        />
                    </div>
                </div>
                <span className={styles.scanPayText} style={{
                    color: "#FFFFFF",
                    position: 'relative',
                    top: -50
                }}>Scan to pay</span>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 40, gap: 12 }}>
                    <button className={cn("button-stroke", styles.button)} onClick={() => {
                        navigator?.clipboard?.writeText(paymentObject?.linkUrl);
                        toast.success("Payment link copied successfully", toastConfiguration);
                    }}>
                        <Icon name="download" size="24" />
                        <span>Copy to clipboard</span>
                    </button>

                    <button className={cn("button", styles.sendBtn)} onClick={downloadQR}>
                        <Icon name="download" size="24" />
                        <span>Download QR code</span>
                    </button>
                </div>
            </div>
            <div className={styles.overlay} onClick={() => {
                setShowAddPaymentLink ?
                    setShowAddPaymentLink(false) :
                    setVisible(false)
            }}></div>
        </div>
    );
};

export default SuccessModal;
