import React, { useEffect, useState } from "react";
import styles from "./CardDetails.module.sass";
import Icon from "../../../../components/Icon";
import Details from "./Details";
import Transactions from "../../../../components/TransactionsCards";
import Card from "../../../../components/Card";
import DeleteModal from "../../../../components/DeleteModal";
import { useHistory } from "react-router";
import cn from "classnames";
import { toast, ToastContainer } from 'react-toastify';
import { gettingPermissionByFeature, toastConfiguration, trackIntercomEvent } from "../../../../utils/utils";
import { cardStatusChange, makeCardAsDefault } from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import TransactionPopup from "../../../Accounting/Reconcile/TransactionPopup";

const CardDetails = ({
  setShowCardDetails,
  setShowNewCard,
  setAddCard,
  setShowCards,
  cardDetail,
  id,
  cardCategories,
  setVisibleCardDetails,
  setIndividualCardDetail
}) => {
  const [colorName, setColorName] = useState("gray");
  const [value, setValue] = useState("2244");
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);
  const [spendingLimit, setSpendingLimit] = useState(cardDetail?.spendingLimit);

  const [showTransactionPopup, setShowTransactionPopup] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(null);

  const [loading, setLoading] = useState(false);

  const [makeDefaultLoading, setMakeDefaultLoading] = useState(false);
  const history = useHistory();

  const cardRights = gettingPermissionByFeature("Card");

  useEffect(() => {
    setSpendingLimit(cardDetail?.spendingLimit);
  }, [cardDetail])

  useEffect(() => {
    if (!showTransactionPopup) {
      setCurrentTransaction(null);
    }
  }, [showTransactionPopup])

  // useEffect(() => {
  //   if (currentTransaction) {
  //     setShowTransactionPopup({
  //       fetchTransactionPayload: true,
  //       ...currentTransaction
  //     });
  //     setLoading(false);
  //   }
  // }, [currentTransaction])

  const saveButtonHandler = () => {
    setShowCards && setShowCards(true);
    setShowNewCard(false);
    setShowCardDetails && setShowCardDetails(false);
    setAddCard(false);
    setVisibleCardDetails && setVisibleCardDetails(false)
  }

  const statusChangeHandler = async (status) => {
    setStatusChangeLoading(true);
    const { data } = await cardStatusChange({
      id: cardDetail?.id,
      status
    })

    if (data) {
      setIndividualCardDetail(data?.card);
      let toastMessage = "Card Activated Successfully."
      if (status === "inactive") {
        toastMessage = "Card Freezed Successfully.";
        trackIntercomEvent('card-freezed');
      }

      toast.success(toastMessage, toastConfiguration);
    }
    setStatusChangeLoading(false);
  }

  const makeDefaultHandler = async () => {
    setMakeDefaultLoading(true);
    const { data } = await makeCardAsDefault({
      id
    })

    if (data) {
      cardDetail.isDefaultCard = true;
      setIndividualCardDetail(cardDetail);
      toast.success("Card marked as default", toastConfiguration);
    } else {
      toast.error("Error in making card as defualt", toastConfiguration);
    }

    setMakeDefaultLoading(false);
  }

  return (
    <>
      {loading && (
        <div className={styles.spinner}>
          <Spinner color={"gray"} size="48" />
        </div>
      )}
      <div className={styles.row}
        style={cardRights === 2 ? { pointerEvents: 'none' } : {}}
      >
        <ToastContainer />
        <div className={styles.col12}>
          <div className={styles.title}>
            <h5
              className={styles.backBtn}
              style={{ pointerEvents: 'auto' }}
              onClick={() => {
                history.push('/cards')
                // setShowNewCard(false);
                // setShowCardDetails && setShowCardDetails(false);
                // setVisibleCardDetails && setVisibleCardDetails(false)
                // setAddCard(false);
              }}
            >
              <Icon name="arrow-left" size="18" />
              <span style={{ marginLeft: 6 }}>Back to Cards</span>
            </h5>
          </div>
        </div>
        <div className={styles.col12}>
          <Details
            setColorName={setColorName}
            setValue={setValue}
            setSpendingLimit={setSpendingLimit}
            colorName={colorName}
            cardRights={cardRights}
            value={value}
            cardDetail={cardDetail}
            statusChangeHandler={statusChangeHandler}
            makeDefaultLoading={makeDefaultLoading}
            makeDefaultHandler={makeDefaultHandler}
            statusChangeLoading={statusChangeLoading}
            spendingLimit={spendingLimit}
            setIndividualCardDetail={setIndividualCardDetail}
            cardCategories={cardCategories}
          />
        </div>
        <div className={styles.col12}>
          <Transactions
            id={id}
            onClickHandler={(id, x) => {
              setLoading(true);

              setCurrentTransaction({
                fetchTransactionPayload: true,
                ...x
              });

              setShowTransactionPopup({
                fetchTransactionPayload: true,
                ...x
              });

            }}
          />
        </div>
        <TransactionPopup
          showTransactionPopup={showTransactionPopup}
          setShowTransactionPopup={setShowTransactionPopup}
          currentTransactionObject={currentTransaction}
          setLoading={setLoading}
          setCurrentTransactionObject={setCurrentTransaction}
        />
      </div>
    </>
  );
};

export default CardDetails;
