import React, { useEffect, useState } from "react";
import Dropdown from "../../../../../../components/Dropdown";
import Search from "../../SearchProduct";
import cn from "classnames";
import styles from "./DetailsCustomer.module.sass";
import TextInput from "../../../../../../components/TextInput";
import { NavLink, useHistory } from "react-router-dom";
import Icon from "../../../../../../components/Icon";
import CreateNewProduct from "../../../../Invoices/NewInvoice/Modals/CreateNewProduct";
import Card from "../../../../../../components/Card";
import Lines from "../../Lines/Lines";
import DatePicker from "react-datepicker";
import Checkbox from "../../../../../../components/Checkbox";
import DateRangeInput from "../../../../../../components/DateRangeInput";
import { toast, ToastContainer } from "react-toastify";
import { toastConfiguration, transactionDateFormatter } from "../../../../../../utils/utils";
import DropdownWithValue from "../../../../../../components/Dropdown_c";
import { AddSubscriptionLine } from "../../../../../../utils/apiCallHanlder";
import OutsideClickHandler from "react-outside-click-handler";
import Spinner from "../../../../../../utils/spinner";
import Image from "../../../../../../components/Image";
import AlternativeAvatar from "../../../../../../components/AlternativeAvatar";
import DateInput from "../../../../../../components/DateInput";

const DetailsCustomer = ({
  className,
  setShowActionBtn,
  setShowDetails,
  setShowSummary,
  selectedCustomerObject,
  getProducts,
  products,
  lines,
  setLines,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  durations,
  setDurations,
  paymentMethods,
  activeCardPaymentType,
  isSubscriptionSent,
  activeAchPaymentType,
  setActiveAchPaymentType,
  setActiveCardPaymentType,
  memo,
  setMemo,
  intervals,
  showProduct,
  setShowProduct,
  paymentMethodsToSend,
  subscription,
  lineId,
  setLineId,
  id,
  schedulePaymentClickHandler,
  addSubscriptionLoading,
  todayDate,
  setTodayDate,
  dateSelector,
  setDateSelector,
  foreverDate,
  cancelSubscription,
  setForeverDate,
  endDateSelector,
  setEndDateSelector,
}) => {
  const [addAnotherProduct, setAddAnotherProduct] = useState(false);
  const [showAddAnotherProductBtn, setShowAddAnotherProductBtn] = useState(true);

  const [visible, setVisible] = useState(false);
  const [productName, setProductName] = useState("");
  const [searchString, setSearchString] = useState("");

  const [updateState, setUpdateState] = useState(false);

  const [showNewProductModal, setShowNewProductModal] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [addLoading, setAddLoading] = useState(false);

  const [oustsideVisible, setOutsideVisible] = useState(false);

  const history = useHistory();

  useEffect(async () => {
    setSearchLoading(true);
    if (visible) {
      await getProducts(searchString);
    }
    setSearchLoading(false);
  }, [visible]);

  const productSelectHandler = async (product) => {
    const alreadyAddedProduct = containsObject(product, lines);

    if (alreadyAddedProduct) {
      toast.error("Product already added", toastConfiguration);
    } else {
      if (subscription?.id) {
        setAddLoading(true);
        const { data, error } = await AddSubscriptionLine({
          id: subscription?.id,
          customerId: subscription?.customerId,
          productId: product?.id,
          priceId: product?.priceId,
          priceTypeValue: product?.priceTypeValue,
          recurringType: product?.recurringType,
          name: product?.name,
          quantity: 1,
        });
        if (data) {
          const lineObject = {
            lineId: data?.subscriptionLine?.id,
            priceTypeValue: product?.priceTypeValue,
            recurringType: product?.recurringType,
            productId: product?.id,
            priceId: product?.priceId,
            pricingModel: product?.pricingModel,
            name: product?.name,
            quantity: 1,
            description: product?.description,
            isCustomerAdjustQuantity: false,
            priceValue: product?.priceValue,
            price: product?.price,
          };
          lines.push(lineObject);
          setLineId((prevLineId) => prevLineId + 1);
        } else {
          toast.error(error || "Error in adding product", toastConfiguration);
        }
        setAddLoading(false);
      } else {
        const lineObject = {
          lineId: lineId + 1,
          priceTypeValue: product?.priceTypeValue,
          recurringType: product?.recurringType,
          productId: product?.id,
          priceId: product?.priceId,
          pricingModel: product?.pricingModel,
          name: product?.name,
          quantity: 1,
          description: product?.description,
          isCustomerAdjustQuantity: false,
          priceValue: product?.priceValue,
        };
        lines.push(lineObject);
        setLineId((prevLineId) => prevLineId + 1);
      }
    }

    setVisible(false);
    setProductName("");
    setShowProduct(true);
    setAddAnotherProduct(false);
    setShowAddAnotherProductBtn(true);
    setShowNewProductModal(false);
    setShowActionBtn(true);
  };

  const containsObject = (obj, list) => {
    var i;
    for (i = 0; i < list?.length; i++) {
      if (list[i]?.productId === obj?.id) {
        return true;
      }
    }

    return false;
  };

  const memoChangeHandler = (e) => {
    setMemo(e.target.value);
  };

  return (
    <div className={styles.detailsWrap}>
      <ToastContainer />
      <Card
        className={cn(styles.card, className)}
        title="Subscription Details"
        border
        /* classTitle="title-blue" */
      >
        <div className={styles.customerHead}>
          <label className={styles.customerLabel}>Customer</label>

          <div className={styles.infoWrapper}>
            <div className={styles.left}>
              {selectedCustomerObject?.imageUrl ? (
                <Image
                  src={selectedCustomerObject?.imageUrl}
                  srcDark={selectedCustomerObject?.imageUrl}
                  className={styles.pic}
                />
              ) : (
                <AlternativeAvatar
                  name={selectedCustomerObject?.name || selectedCustomerObject?.email}
                  className={"customer-info"}
                />
              )}

              {/* <button
                onClick={() => {
                  setShowAccountInfos(false);
                  setShowActionBtn(false);
                  setShowDetails();
                }}
                className={styles.deleteBtn}
              >
                <Icon
                  name="trash"
                  size="20"
                  fill="#FF4900"
                />
              </button> */}
              {!subscription?.id ? (
                <button
                  onClick={() => {
                    setShowActionBtn(false);
                    setShowDetails();
                    setShowSummary(false);
                  }}
                  className={styles.deleteBtn}
                >
                  <Icon name={"trash"} size="20" fill="#FF4900" />
                </button>
              ) : (
                <OutsideClickHandler onOutsideClick={() => setOutsideVisible(false)}>
                  <div
                    className={cn(styles.dotsBtn, className, {
                      [styles.active]: oustsideVisible,
                    })}
                  >
                    <div className={styles.head}>
                      <button
                        className={cn(styles.btn)}
                        onClick={() => setOutsideVisible(!oustsideVisible)}
                      >
                        <Icon name="dots" size="32" />
                      </button>
                    </div>
                    <div className={styles.actionBody}>
                      <button
                        className={styles.actionItem}
                        onClick={() => history.push(`/contacts/${subscription?.customerId}`)}
                      >
                        <Icon name="profile-circle" size="24" />
                        View Customer
                      </button>
                    </div>
                  </div>
                </OutsideClickHandler>
              )}
            </div>
            <div className={styles.infoWrap}>
              <div className={styles.info}>
                <p className={styles.label}>Name</p>
                <p className={styles.txt}>{selectedCustomerObject?.name}</p>
              </div>
              <div className={styles.info}>
                <p className={styles.label}>Email</p>
                <p className={styles.txt}>{selectedCustomerObject?.email}</p>
              </div>
              <div className={styles.info}>
                <p className={styles.label}>Mobile</p>
                <p className={styles.txt}>{selectedCustomerObject?.phone}</p>
              </div>
            </div>
          </div>
          {/* <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: 'space-between',
            marginTop: 8
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
              <img
                src={selectedCustomerObject?.imageUrl || "/images/content/avatar.jpg"}
                alt="customer-image"
                className={styles.customerImage}
              />
              <h3 className={styles.name}>{selectedCustomerObject?.name}</h3>
            </div>
            {!subscription?.id ?
              <button
                onClick={() => {
                  setShowActionBtn(false);
                  setShowDetails();
                  setShowSummary(false)
                }}
                className={styles.deleteButton}
              >
                <Icon name={"trash"} size="20" fill="#FF4900" />
              </button>
              :
              <OutsideClickHandler onOutsideClick={() => setOutsideVisible(false)}>
                <div
                  className={cn(styles.dotsBtn, className, {
                    [styles.active]: oustsideVisible,
                  })}
                  style={{
                    marginRight: 15,
                    width: '30px',
                    height: '30px',
                    background: "#EFEFEF",
                    borderRadius: '50%',
                    textAlign: 'center',
                    paddingTop: 2
                  }}
                >
                  <div className={styles.head}>
                    <button
                      className={cn(styles.btn)}
                      onClick={() => setOutsideVisible(!oustsideVisible)}
                    >
                      <Icon name="dots" />
                    </button>
                  </div>
                  <div className={styles.actionBody}>
                    <button
                      className={styles.actionItem}
                      onClick={() => history.push(`/contacts/${subscription?.customerId}`)}
                    >
                      <Icon name="profile-circle" size="24" />
                      View Customer
                    </button>
                  </div>
                </div>
              </OutsideClickHandler>
            }
          </div> */}
        </div>
        {!showProduct ? (
          <div style={{ marginTop: 32 }}>
            <label className={styles.customerLabel}>Products</label>
            <Search
              className={styles.search}
              inputPlaceholder="Search or add new Product"
              addNewItem={() => setShowNewProductModal(true)}
              addNewBtnTxt="Create new Product"
              visible={visible}
              productName={productName}
              setVisible={setVisible}
              getProducts={getProducts}
              productSelectHandler={productSelectHandler}
              searchNames={products}
              searchString={searchString}
              setSearchString={setSearchString}
              addLoading={addLoading}
              searchLoading={searchLoading}
              setSearchLoading={setSearchLoading}
              showDetails={() => {
                setShowProduct(true);
                setShowActionBtn(true);
              }}
            />
            <CreateNewProduct
              showNewProductModal={showNewProductModal}
              searchString={searchString}
              setShowNewProductModal={setShowNewProductModal}
              productSelectHandler={productSelectHandler}
              showSubscription={true}
              showProduct={() => {
                setShowProduct(true);
                setShowNewProductModal(false);
                setShowActionBtn(true);
              }}
            />
          </div>
        ) : (
          <div style={{ marginTop: 32 }}>
            <div className={styles.detailsTxt}>Products</div>
            {lines?.map((x, index) => (
              <div key={index}>
                <Lines
                  x={x}
                  index={index}
                  lines={lines}
                  setLines={setLines}
                  setLineId={setLineId}
                  lineId={lineId}
                  subscription={subscription}
                  setShowActionBtn={setShowActionBtn}
                />
              </div>
            ))}
            {showAddAnotherProductBtn ? (
              <button
                onClick={() => {
                  setAddAnotherProduct(true);
                  setShowAddAnotherProductBtn(false);
                }}
                className={styles.addProductBtn}
              >
                Add another product
              </button>
            ) : null}
            {addAnotherProduct ? (
              <>
                <Search
                  className={styles.search}
                  inputPlaceholder="Search or add new Product"
                  addNewBtnTxt="Create new Product"
                  visible={visible}
                  addNewItem={() => setShowNewProductModal(true)}
                  productName={productName}
                  setVisible={setVisible}
                  addLoading={addLoading}
                  getProducts={getProducts}
                  productSelectHandler={productSelectHandler}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  searchLoading={searchLoading}
                  setSearchLoading={setSearchLoading}
                  searchNames={products}
                  showDetails={() => {
                    setShowProduct(true);
                    setShowActionBtn(true);
                  }}
                />
                <CreateNewProduct
                  showNewProductModal={showNewProductModal}
                  searchString={searchString}
                  showSubscription={true}
                  setShowNewProductModal={setShowNewProductModal}
                  productSelectHandler={productSelectHandler}
                  showProduct={() => {
                    setShowProduct(true);
                    setShowNewProductModal(false);
                    setShowActionBtn(true);
                  }}
                />
              </>
            ) : null}
          </div>
        )}
        <div
          style={
            subscription?.id
              ? {
                  pointerEvents: "none",
                }
              : {}
          }
        >
          <>
            <div className={styles.wrap} style={{ zIndex: 8, position: "relative" }}>
              <h4 className={styles.label}>Start date</h4>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Checkbox
                  value={todayDate}
                  onChange={() => {
                    setStartDate(new Date());
                    setTodayDate(!todayDate);
                    setDateSelector(!dateSelector);
                  }}
                />
                <label className={styles.dateLabel}>Today</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 12,
                  alignItems: "center",
                }}
              >
                <Checkbox
                  value={dateSelector}
                  onChange={() => {
                    setTodayDate(!todayDate);
                    setDateSelector(!dateSelector);
                  }}
                />
                <label className={styles.dateLabel}>Trial until date</label>
              </div>
              <div
                style={{
                  width: "180px",
                  marginLeft: -12,
                  position: "relative",
                  zIndex: 10000,
                  marginTop: "13px",
                }}
                onClick={() => {}}
              >
                {dateSelector && (
                  <DateInput
                    classInputValue={styles.inputValue}
                    classInput={styles.dateRangeInput}
                    icon="calendar"
                    setComponentStartDate={setStartDate}
                    componentStartDate={startDate}
                    className={styles.dateInput}
                    hideText={true}
                    dateform={true}
                    allowFuture={true}
                    redeemText={false}
                    portalClassInput={styles.input}
                    dateInputId="dateInputId"
                    zIndex={12}
                  />
                )}
              </div>
            </div>

            <div className={styles.wrap}>
              <h4 className={styles.label}>End date</h4>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Checkbox
                  value={foreverDate}
                  onChange={() => {
                    setEndDate(null);
                    setForeverDate(!foreverDate);
                    setEndDateSelector(!endDateSelector);
                  }}
                />
                <label className={styles.dateLabel}>Forever</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 12,
                  alignItems: "center",
                }}
              >
                <Checkbox
                  value={endDateSelector}
                  onChange={() => {
                    setEndDate(new Date());
                    setForeverDate(!foreverDate);
                    setEndDateSelector(!endDateSelector);
                  }}
                />
                <label className={styles.dateLabel}>Select Date</label>
              </div>
              <div style={{ width: "180px", marginLeft: -12, marginTop: "13px" }} id="dateSelector">
                {endDateSelector && (
                  <DateInput
                    classInputValue={styles.inputValue}
                    classInput={styles.dateRangeInput}
                    icon="calendar"
                    dateform={true}
                    setComponentStartDate={setEndDate}
                    componentStartDate={endDate}
                    className={styles.dateInput}
                    redeemText={false}
                    hideText={true}
                    allowFuture={true}
                    portalClassInput={styles.input}
                    // classInputValue={styles.inputValue}
                    dateInputId="dateInput Id"
                  />
                )}
              </div>
            </div>
            {/* <div className={styles.wrap}>
              <h4 className={styles.label}>Start billing cycle on</h4>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={durations}
                setValue={setDurations}
                options={intervals}
                small
              />
              <p className={styles.settings}>
                This is also when the next invoice will be generated
              </p>
            </div> */}
            {/* <div className={styles.wrap}>
              <h4 className={styles.label}>Payments Type</h4>
              <div
                className={styles.paymentTypeWrap}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {paymentMethods.map((item, i) => {
                  const index = paymentMethodsToSend.indexOf(item?.value);
                  let isSelected = false;
                  if (index > -1) {
                    isSelected = true;
                  }
                  return (
                    <div
                      key={i}
                      className={cn(styles.cardPayment, {
                        [styles.active]: isSelected,
                      })}
                    >
                      <Checkbox
                        value={isSelected}
                        onChange={() => {
                          const index = paymentMethodsToSend.indexOf(
                            item?.value
                          );
                          if (index > -1) {
                            paymentMethodsToSend.splice(index, 1);
                          } else {
                            paymentMethodsToSend.push(item?.value);
                          }
                          setUpdateState(!updateState);
                        }}
                      />
                      <span>{item.display}</span>
                    </div>
                  );
                })}
              </div>
            </div> */}
            <div className={styles.wrap}>
              <div className={styles.label}>Memo</div>
              <TextInput onChange={memoChangeHandler} value={memo} />
            </div>

            {(id || isSubscriptionSent) ? null : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    background: "#272B30",
                    opacity: "0.25",
                    borderRadius: "1px",
                    height: "1px",
                    marginTop: 16,
                  }}
                ></div>
                <button
                  onClick={schedulePaymentClickHandler}
                  className={cn("button", styles.sendBtn)}
                  style={{
                    width: "174px",
                    marginTop: 16,
                  }}
                >
                  {addSubscriptionLoading ? (
                    <Spinner size="24" color="white" />
                  ) : (
                    <>
                      <span>Start subscription</span>
                    </>
                  )}
                </button>
              </div>
            )}
            {cancelSubscription && cancelSubscription}
          </>
        </div>
      </Card>
    </div>
  );
};

export default DetailsCustomer;
