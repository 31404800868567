import React, { useReducer, useState } from "react";
import styles from "./NewEmailForm.module.sass";
import Modal from "../../../../components/Modal";
import uuid from "react-uuid";
import TextInput from "../../../../components/TextInput";
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import Trix from "trix";
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";
import Dropdown from "../../../../components/Dropdown";
import { useEffect } from "react";
import Spinner from "../../../../utils/spinner";
import { addEmailStudio, updateEmailTemplate } from "../../../../utils/apiCallHanlder";
import { ToastContainer, toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import { useHistory } from "react-router";
import { getAllEmailsStudio } from "../../../../utils/apiCallHanlder";

const tokenList = [
  {
    name: "Clients Name",
    token: "{clients_name}",
  },
  {
    name: "Clients First Name",
    token: "{clients_first_name}",
  },
  {
    name: "Clients Phone",
    token: "{clients_phone}",
  },
  {
    name: "Clients Address",
    token: "{clients_address}",
  },
  {
    name: "Clients Email",
    token: "{clients_email}",
  },
  {
    name: "Link to Client Portal",
    token: "{link_to_client_portal}",
  },
  {
    name: "Current Date",
    token: "{current_date}",
  },
];

const emailTemplates = ["None", "Inquiry Response", "Invoice Reminder", "Payment Received"];
const subjects = [
  "Thank you so much for your inquiry!",
  "I’m just following-up on an unpaid invoice",
  "I have received your payment.",
];

const defaultTemplates = ["Confirmation Template", "Reminder Template", "Receipt Template"];

const defaultTemplatesDetails = [
  "Choose a template",
  "Booking Confirmations - Anytime a session is booked an email will automatically be sent to the client with this template.",
  "Booking Reminders - A session reminder email will be sent automatically after the set amount of days you set.",
  "Receipts - Once an invoice is paid the client will automatically receive a receipt with this template.",
];

const NewEmailForm = ({
  visible,
  setVisible,
  CreateEmail,
  setEmails,
  getEmailFunction,
  setSinglePageStatus,
  id,
}) => {
  const [token, setToken] = useState(tokenList.map((x) => x.name)[0]);
  const [mblTokenIndex, setMblTokenIndex] = useState(0);
  const [emailTemplate, setEmailTemplate] = useState(emailTemplates[0]);
  const [defaultTemplate, setDefaultTemplate] = useState(defaultTemplatesDetails[0]);
  const [showAddReminderDay, setShowAddReminderDay] = useState(false);

  const [fetchingData, setFetchingData] = useState(true);

  const [addEmailLoading, setAddEmailLoading] = useState(false);
  const [value, setValue] = useState("");
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
    emailName: "",
    emailTemplate: emailTemplate,
    subject: "",
    trixEditorValue: "",
    defaultTemplate: defaultTemplate,
    reminderDay: "",
  });

  const history = useHistory();

  useEffect(async () => {
    if (id) {
      setFetchingData(true);
      const { data } = await getAllEmailsStudio({
        emailTemplateId: id,
      });
      if (data) {
        const template = data.emailTemplates[0];
        if (template) {
          setUserInput({
            emailName: template.name,
            emailTemplate: template.emailTemplate,
            subject: template.subject,
            trixEditorValue: template.body,
          });
          setEmailTemplate(template.emailTemplate);
        }
      }
      setFetchingData(false);
    } else {
      setFetchingData(false);
    }
  }, [id]);

  const handleChange = (e) => {
    setUserInput({ [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trixEditorValue = document.querySelector("trix-editor[input='newEmail']").innerHTML;
    //validations
    if (userInput?.emailName === "" || userInput?.emailName?.trim() === "") {
      toast.error("Name of email template is required", toastConfiguration);
      return;
    }

    if (userInput?.subject === "" || userInput?.subject?.trim() === "") {
      toast.error("Subject of email template is required", toastConfiguration);
      return;
    }

    if (value === "") {
      toast.error("please add content", toastConfiguration);
      return;
    }
    setAddEmailLoading(true);
    const { data } = await addEmailStudio({
      name: userInput.emailName,
      subject: userInput.subject,
      body: trixEditorValue,
      automation: "",
      emailTemplate: userInput.emailTemplate,
    });

    setAddEmailLoading(false);
    if (data) {
      //setVisible(false);
      toast.success("Successfully created email template", toastConfiguration);
      history.push("/studio/2");
      setUserInput({ emailName: "", subject: "" });
    } else {
      toast.error("Error in creating email template", toastConfiguration);
    }
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();

    //validations
    if (userInput?.emailName === "" || userInput?.emailName?.trim() === "") {
      toast.error("Name of email template is required", toastConfiguration);
      return;
    }

    if (userInput?.subject === "" || userInput?.subject?.trim() === "") {
      toast.error("Subject of email template is required", toastConfiguration);
      return;
    }

    setAddEmailLoading(true);

    const { data } = await updateEmailTemplate({
      id: id,
      name: userInput.emailName,
      subject: userInput.subject,
      body: userInput.trixEditorValue,
      automation: "",
      emailTemplate: emailTemplate,
    });
    if (data) {
      toast.success("Successfully updated email template", toastConfiguration);
      history.push("/studio/2");
    } else {
      toast.error("Error in updating email template", toastConfiguration);
    }

    setAddEmailLoading(false);
  };

  const handleTrixInputChange = (e, newValue) => {
    setValue(newValue);
  };

  const toolbarActions = [
    "bold",
    "italic",
    "strike",
    "link",
    "heading1",
    "quote",
    "bullet",
    "number",
    "centered",
    "undo",
    "redo",
  ];

  const handleToken = (e) => {
    const tokenValue = e.target.getAttribute("data-value");
    const trixEditor = document.querySelector("trix-editor[input='newEmail']");

    let trixEditorValue = trixEditor.innerHTML;
    if (trixEditor.editor && trixEditor.editor.insertString) {
      trixEditor.editor.insertString(tokenValue);
    } else {
      trixEditorValue = trixEditorValue + tokenValue;
    }
    return trixEditorValue;
  };

  useEffect(() => {
    const tokenValue = tokenList.map((x) => x.token)[mblTokenIndex];
    const trixEditor = document.querySelector("trix-editor");
    const addToken = () => {
      if (trixEditor === null) {
        return null;
      } else {
        let trixEditorValue = trixEditor.innerHTML;
        if (trixEditor.editor && trixEditor.editor.insertString) {
          trixEditor.editor.insertString(tokenValue);
        } else {
          trixEditorValue = trixEditorValue + tokenValue;
        }
        return trixEditorValue;
      }
    };
    addToken();
  }, [mblTokenIndex]);

  useEffect(
    () =>
      emailTemplate === emailTemplates[0]
        ? setUserInput({ subject: "", emailTemplate: emailTemplates[0] })
        : emailTemplate === emailTemplates[1]
          ? setUserInput({
            subject: subjects[0],
            emailTemplate: emailTemplates[1],
          })
          : emailTemplate === emailTemplates[2]
            ? setUserInput({
              subject: subjects[1],
              emailTemplate: emailTemplates[2],
            })
            : emailTemplate === emailTemplates[3]
              ? setUserInput({
                subject: subjects[2],
                emailTemplate: emailTemplates[3],
              })
              : null,
    [emailTemplate]
  );

  useEffect(
    () =>
      defaultTemplate === defaultTemplatesDetails[0]
        ? (setShowAddReminderDay(false), setUserInput({ defaultTemplate: defaultTemplates[0] }))
        : defaultTemplate === defaultTemplatesDetails[1]
          ? (setShowAddReminderDay(false), setUserInput({ defaultTemplate: defaultTemplates[1] }))
          : defaultTemplate === defaultTemplatesDetails[2]
            ? (setShowAddReminderDay(true), setUserInput({ defaultTemplate: defaultTemplates[2] }))
            : defaultTemplate === defaultTemplatesDetails[3]
              ? (setShowAddReminderDay(false), setUserInput({ defaultTemplate: defaultTemplates[3] }))
              : null,
    [defaultTemplate]
  );

  return (
    <>
      {fetchingData ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Spinner size="64" color="gray" />
        </div>
      ) : (
        <>
          <ToastContainer />

          <div className={styles.title}>
            <h5
              className={styles.backBtn}
              onClick={() => {
                setUserInput({ emailName: "", subject: "" });
                //setVisible(false);
                setSinglePageStatus(false);
                history.push("/studio/2");
              }}
            >
              <Icon name="arrow_backward" size="18" />
              Back to CRM
            </h5>
          </div>
          <form className={styles.formWrap} onSubmit={id ? handleSubmitUpdate : handleSubmit} noValidate>
            <Card
              /* classTitle="title-blue" */
              title="Email Template"
              className={styles.card}
              border
            >
              <TextInput
                className={styles.input}
                label="Name of Template"
                onChange={handleChange}
                name="emailName"
                id="emailName"
                type="text"
                value={userInput.emailName}
                required
              />

              <TextInput
                className={styles.input}
                label="Subject"
                onChange={handleChange}
                name="subject"
                id="subject"
                type="text"
                value={userInput.subject}
                required
              />

              {/* <div className={styles.detailsWrap}>
                <h3 className={styles.title}>Message</h3>
                <div className={styles.tokenMblWrap}>
                  <Dropdown
                    options={tokenList.map((x) => x.name)}
                    value={token}
                    setValue={setToken}
                    handleChangeIndex={(index) => setMblTokenIndex(index)}
                  />
                </div>
                <div className={styles.tokenWrap}>
                  {tokenList.map((x, index) => (
                    <div
                      data-value={x.token}
                      key={index}
                      className={styles.token}
                      onClick={handleToken}
                    >
                      {x.name}
                    </div>
                  ))}
                </div>
              </div>

              <Dropdown
                className={styles.input}
                value={emailTemplate}
                setValue={setEmailTemplate}
                options={emailTemplates}
                label="Template"
              /> */}

              <button className="button" style={{ marginTop: 16 }}>
                {addEmailLoading ? (
                  <Spinner size="24" color="white" />
                ) : (
                  <>{id ? <>Update</> : <>Save</>}</>
                )}
              </button>
            </Card>
            <Card className={styles.card} border>
              <div className={styles.detailsWrap}>
                <ReactTrixRTEToolbar
                  toolbarActions={toolbarActions}
                  toolbarId="react-trix-rte-editor"
                />
                <ReactTrixRTEInput
                  id="newEmail"
                  toolbarId="react-trix-rte-editor"
                  onChange={handleTrixInputChange}
                  placeholder="Enter Content"
                  defaultValue={id && userInput.trixEditorValue}
                />
              </div>
            </Card>
          </form>
        </>
      )}
    </>
  );
};

export default NewEmailForm;
