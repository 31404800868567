import React, { useState, useEffect } from "react";
import styles from "./QuotesOverview.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Table from "./Table";
import Icon from "../../../../components/Icon";
import NewQuote from "../NewQuote";

import { subscriptions } from "../../../../mocks/subscriptions";
import { getSubscriptionsByStatus } from "../../../../utils/apiCallHanlder";
import { useHistory } from "react-router";
import PaymentsHeader from "../../PaymentsHeader";

const QuoteOverview = ({ activeIndex, currentPage }) => {
  const [visible, setVisible] = useState(true);
  const [currentSubscriptionsCheckCount, setCurrentSubscriptionsCheckCount] =
    useState(0);
  const [currentSubscriptionsAction, setCurrentSubscriptionsAction] =
    useState(true);
  const [scheduledSubscriptionsCheckCount, setScheduledSubscriptionsCheckCount] =
    useState(0);
  const [scheduledSubscriptionsAction, setScheduledSubscriptionsAction] = useState(true);
  const [cancelledSubscriptionsCheckCount, setCancelledSubscriptionsCheckCount] = useState(0);
  const [cancelledSubscriptionsAction, setCancelledSubscriptionsAction] = useState(true);
  const [actionBtnValue, setActionBtnValue] = useState(1);
  const [show, setShow] = useState(false);

  const [subscriptionId, setSubscriptionId] = useState(null);

  const history = useHistory();


  /* Current subscriptions handling */
  const currentSubscriptionsLength = Object.keys(
    subscriptions.filter((x) => x.subscriptionsType === "Current")
  ).length;

  const handleCurrentSubscriptions = (e) => {
    var ariaStatus = e.target.getAttribute("aria-Checked");
    if (ariaStatus === "true") {
      setCurrentSubscriptionsAction(true);
      setCurrentSubscriptionsCheckCount(0);
    }
    if (ariaStatus === "false") {
      setCurrentSubscriptionsAction(false);
      setCurrentSubscriptionsCheckCount(currentSubscriptionsLength);
    }
    if (ariaStatus === "mixed") {
      setCurrentSubscriptionsAction(false);
      setCurrentSubscriptionsCheckCount(currentSubscriptionsLength);
    }
  };

  const handleCurrentSubscriptionsSingle = (e) => {
    if (e.target.checked) {
      setCurrentSubscriptionsCheckCount(currentSubscriptionsCheckCount + 1);
    }
    if (!e.target.checked) {
      setCurrentSubscriptionsCheckCount(currentSubscriptionsCheckCount - 1);
    }
  };

  /* Scheduled subscriptions handling */
  const scheduledSubscriptionsLength = Object.keys(
    subscriptions.filter((x) => x.subscriptionsType === "Scheduled")
  ).length;

  const handleScheduledSubscriptions = (e) => {
    var ariaStatus = e.target.getAttribute("aria-Checked");
    if (ariaStatus === "true") {
      setScheduledSubscriptionsAction(true);
      setScheduledSubscriptionsCheckCount(0);
    }
    if (ariaStatus === "false") {
      setScheduledSubscriptionsAction(false);
      setScheduledSubscriptionsCheckCount(scheduledSubscriptionsLength);
    }
    if (ariaStatus === "mixed") {
      setScheduledSubscriptionsAction(false);
      setScheduledSubscriptionsCheckCount(scheduledSubscriptionsLength);
    }
  };

  const handleScheduledSubscriptionsSingle = (e) => {
    if (e.target.checked) {
      setScheduledSubscriptionsCheckCount(scheduledSubscriptionsCheckCount + 1);
    }
    if (!e.target.checked) {
      setScheduledSubscriptionsCheckCount(scheduledSubscriptionsCheckCount - 1);
    }
  };

  /* Cancelled subscriptions handling */
  const cancelledSubscriptionsLength = Object.keys(
    subscriptions.filter((x) => x.subscriptionsType === "Cancelled")
  ).length;

  const handleCancelledSubscriptions = (e) => {
    var ariaStatus = e.target.getAttribute("aria-Checked");
    if (ariaStatus === "true") {
      setCancelledSubscriptionsAction(true);
      setCancelledSubscriptionsCheckCount(0);
    }
    if (ariaStatus === "false") {
      setCancelledSubscriptionsAction(false);
      setCancelledSubscriptionsCheckCount(cancelledSubscriptionsLength);
    }
    if (ariaStatus === "mixed") {
      setCancelledSubscriptionsAction(false);
      setCancelledSubscriptionsCheckCount(cancelledSubscriptionsLength);
    }
  };

  const handleCancelledSubscriptionsSingle = (e) => {
    if (e.target.checked) {
      setCancelledSubscriptionsCheckCount(cancelledSubscriptionsCheckCount + 1);
    }
    if (!e.target.checked) {
      setCancelledSubscriptionsCheckCount(cancelledSubscriptionsCheckCount - 1);
    }
  };



  const handleEditProduct = () => {
    setVisible(false);
  };

  useEffect(() => {

    if (currentSubscriptionsCheckCount === 0) {
      setCurrentSubscriptionsAction(true);
    } else {
      setCurrentSubscriptionsAction(false);
    }

    if (scheduledSubscriptionsCheckCount === 0) {
      setScheduledSubscriptionsAction(true);
    } else {
      setScheduledSubscriptionsAction(false);
    }

    if (cancelledSubscriptionsCheckCount === 0) {
      setCancelledSubscriptionsAction(true);
    } else {
      setCancelledSubscriptionsAction(false);
    }
  }, [
    currentSubscriptionsCheckCount,
    scheduledSubscriptionsCheckCount,
    cancelledSubscriptionsCheckCount,
  ]);


  return (
    <>
      {visible ? (
        <>
          <Card
            className={styles.card}
            title="Get Paid"
            border
            /* classTitle={cn("title-blue", styles.title)} */
            classCardHead={cn(styles.head, { [styles.hidden]: show })}
            head={
              <>
                {actionBtnValue === 1 ? (
                  currentSubscriptionsAction ? (
                    <button
                      onClick={() => {
                        history.push("/quote/add");
                      }}
                      className={cn("button", styles.addProductBtn)}
                    >
                      Start new quote
                    </button>
                  ) : (
                    <button
                      onClick={() => setVisible(false)}
                      className={cn(
                        "button-stroke-red",
                        styles.deleteMarkedBtn
                      )}
                    >
                      Delete Marked
                    </button>
                  )
                ) : null}
                {actionBtnValue === 2 ? (
                  scheduledSubscriptionsAction ? (
                    <button
                      onClick={() => setVisible(false)}
                      className={cn("button", styles.addProductBtn)}
                    >
                      Add new
                    </button>
                  ) : (
                    <button
                      onClick={() => setVisible(false)}
                      className={cn(
                        "button-stroke-red",
                        styles.deleteMarkedBtn
                      )}
                    >
                      Delete Marked
                    </button>
                  )
                ) : null}
                {actionBtnValue === 3 ? (
                  cancelledSubscriptionsAction ? (
                    <button
                      onClick={() => setVisible(false)}
                      className={cn("button", styles.addProductBtn)}
                    >
                      Add new
                    </button>
                  ) : (
                    <button
                      onClick={() => setVisible(false)}
                      className={cn(
                        "button-stroke-red",
                        styles.deleteMarkedBtn
                      )}
                    >
                      Delete Marked
                    </button>
                  )
                ) : null}

              </>
            }
          >
            <div style={{ marginTop: 20}}>
              <PaymentsHeader
                currentPage={currentPage}
              />
            </div>
            <div className={cn(styles.row, { [styles.flex]: show })}>
              <Table
                className={styles.table}
                activeTable={show}
                setActiveTable={setShow}
                activeIndexx={activeIndex}
                setSubscriptionId={setSubscriptionId}
                setVisible={setVisible}
                editAction={
                  <button
                    className={styles.actionItem}
                    onClick={(id) => {
                      setVisible(false);
                    }}
                  >
                    <Icon name="edit" size="24" />
                    Update Subscription
                  </button>
                }
                handleCurrentSubscriptions={handleCurrentSubscriptions}
                handleCurrentSubscriptionsSingle={handleCurrentSubscriptionsSingle}
                handleScheduledSubscriptions={handleScheduledSubscriptions}
                handleScheduledSubscriptionsSingle={handleScheduledSubscriptionsSingle}
                handleCancelledSubscriptions={handleCancelledSubscriptions}
                handleCancelledSubscriptionsSingle={handleCancelledSubscriptionsSingle}
                editProduct={handleEditProduct}
                actionBtnHandle={(e) => setActionBtnValue(e)}
              />
            </div>
          </Card>
        </>
      ) : (
        <NewQuote setSubscriptions={() => setVisible(true)} id={subscriptionId} />
      )}
    </>
  );
};

export default QuoteOverview;
