import React, { useEffect, useState } from "react";
import Card from "../../../components/Card";
import cn from "classnames";
import Dropdown from "../../../components/Dropdown/index";
import ProfileInformation from "./ProfileInformation/ProfileInformation";
import AccountInformation from "./Account";
import styles from "./Tabs.module.sass";
import Icon from "../../../components/Icon";
import DeleteAccount from "./Account/DeleteAccount";
import AddTeamModal from "./team/AddNew";
import Payment from "./Payment";
import Team from "./team";
import Accounts from "./Accounts";
import { useHistory } from "react-router";
import Billing from "./Billing";
import Tax from "./Tax";
import { toast, ToastContainer } from "react-toastify";
import {
  gettingPermissionByFeature,
  logoutHandler,
  toastConfiguration,
  trackIntercomEvent,
} from "../../../utils/utils";
import AccountDeleteModal from "../../../components/DeleteAccountModal";
import { CloseAccount } from "../../../utils/apiCallHanlder";
import DeleteModal from "../../../components/DeleteModal";
import Spinner from "../../../utils/spinner";
import Settings from "./Settings";
import LargeNav from "../../../components/LargeNav";
import ChartOfAccounts from "../../Accounting/ChartOfAccounts";
import Integration from "./Integration";

let tabs = ["Profile", "Accounts"];
let SettingStatus = [
  {
    title: "Profile",
  },
  {
    title: "Accounts",
  },
  {
    title: "Chart of Accounts",
  },
];

const Setting = ({
  visibletax,
  setvisibletax,
  activeIndex,
  setChangeProfile,
  isFeatureChanged,
  setIsFeatureChanged,
  changeProfile,
  openModal,
  setChangeAccountingStatus,
  changeAccountingStatus,
  isNewHeader,
  setChangeBusinessLogo,
  changeBuisnessLogo,
}) => {
  const [visible, setVisible] = useState(false);
  const [mobileTab, setMobileTab] = useState(tabs[0]);
  const [showAccountDeleteSuccessModal, setShowAccountDeleteSuccessModal] =
    useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isNewHeader) {
      setVisible(true);
    }
  }, [isNewHeader]);

  const history = useHistory();

  const taxRight = gettingPermissionByFeature("TaxSettings");
  const teamInviteRight = gettingPermissionByFeature("TeamInvite");
  const businessInfoRight = gettingPermissionByFeature("BusinessInfo");
  const billingRight = gettingPermissionByFeature("Billing");

  const compnaydeleteRights = gettingPermissionByFeature("CompanyDelete");

  useEffect(() => {
    tabs = ["Profile", "Accounts"];
    SettingStatus = [
      {
        title: "Profile",
      },
      {
        title: "Accounts",
      }
    ];

    if (taxRight !== 0) {
      tabs.splice(4, 0, "Tax");
      SettingStatus.splice(4, 0, {
        title: "Tax",
      });
    }
    if (teamInviteRight !== 0) {
      tabs.splice(2, 0, "Team");
      SettingStatus.splice(2, 0, {
        title: "Team",
      });
    }
    if (true) { //businessInfoRight !== 0) {
      tabs.splice(1, 0, "Business Info");
      SettingStatus.splice(1, 0, {
        title: "Business Info",
      });
    }
    if (billingRight !== 0) {
      tabs.splice(5, 0, "Billing");
      SettingStatus.splice(5, 0, {
        title: "Billing",
      });
    }

    tabs.splice(tabs.length - 1, 0, "Integration");
    SettingStatus.splice(tabs.length - 1, 0, {
      title: "Integration",
    });

    setIsLoaded(true);
  }, []);

  const tabsWithComponents = {
    Profile: (
      <ProfileInformation
        setChangeProfile={setChangeProfile}
        changeProfile={changeProfile}
      />
    ),
    "Business Info": (
      <AccountInformation
        setChangeBusinessLogo={setChangeBusinessLogo}
        changeBuisnessLogo={changeBuisnessLogo}
        setChangeProfile={setChangeProfile}
        changeProfile={changeProfile}

      />
    ),
    Team: <Team isNewHeader={isNewHeader} />,
    // "Payment": <Payment openModal={openModal} />,
    Tax: <Tax visibletax={visibletax} setvisibletax={setvisibletax} />,
    Billing: (
      <Billing
        isFeatureChanged={isFeatureChanged}
        setIsFeatureChanged={setIsFeatureChanged}
      />
    ),
    Accounts: (
      <Accounts
        setChangeAccountingStatus={setChangeAccountingStatus}
        changeAccountingStatus={changeAccountingStatus}
        openModal={openModal}
      />
    ),
    "Integration": <Integration />,
    // "Settings": <Settings setChangeAccountingStatus={setChangeAccountingStatus} changeAccountingStatus={changeAccountingStatus}/>
  };

  const accountDeleteClickHandler = async () => {
    setDeleteLoading(true);
    const { data } = await CloseAccount({});
    if (data) {
      setVisibleDelete(false);
      setShowAccountDeleteSuccessModal(true);
      trackIntercomEvent("account-own-delete");
    } else {
      toast.error("Error in deleting account", toastConfiguration);
    }
    setDeleteLoading(false);
  };

  const onConfirm = () => {
    logoutHandler(history);
  };
  return (
    <>
      {isLoaded && (
        <>
          {visible ? (
            <AddTeamModal visible={visible} setVisible={setVisible} />
          ) : (
            <>
              <ToastContainer />
              <AccountDeleteModal
                visible={showAccountDeleteSuccessModal}
                onConfirm={onConfirm}
              />
              <Card
                className={styles.card}
                /* classTitle="title-blue" */
                title='Settings'
                classCardHead={styles.cardHead}
                border
                head={
                  activeIndex === "Team" ? (
                    <div>
                      <button
                        onClick={() => setVisible(true)}
                        className={cn("button", styles.button)}
                      >
                        <span>Invite new team member</span>
                      </button>
                    </div>
                  ) : null
                }
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <LargeNav
                      tabOptions={SettingStatus}
                      activeTab={activeIndex}
                      value='title'
                      onClick={(x) => {
                        history.push(`/settings/${x.title}`);
                      }}
                      category='title'
                    />

                    <div className={styles.mobileNav}>
                      <Dropdown
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        value={mobileTab}
                        handleChange={(value) => {
                          history.push(`/settings/${value}`);
                        }}
                        setValue={setMobileTab}
                        options={tabs}
                        small
                      />
                    </div>
                  </div>
                  {Object.keys(tabsWithComponents).map((item, index) => {
                    if (item === activeIndex) {
                      return <div key={index}>{tabsWithComponents[item]}</div>;
                    }
                  })}
                </div>
              </Card>

              <DeleteModal
                visibleDelete={visibleDelete}
                setVisibleDelete={setVisibleDelete}
                deleteBtn={
                  <button
                    className='button'
                    style={{ width: 150, height: 50 }}
                    onClick={accountDeleteClickHandler}
                  >
                    {deleteLoading ? (
                      <Spinner loading={deleteLoading} size={30} />
                    ) : (
                      "Yes I'm sure!"
                    )}
                  </button>
                }
                button={true}
              />

              {
                activeIndex === "Business Info" &&
                compnaydeleteRights === 1 && (
                  // || (activeIndex === "Accounts" && cashAccountTab) ?
                  <DeleteAccount
                    onClick={() => setVisibleDelete(true)}
                    className={styles.deleteMainAccount}
                    setVisible={setVisibleDelete}
                  //deleteLoading={deleteLoading}
                  />
                )
                // : null
              }
            </>
          )}
        </>
      )}
    </>
  );
};

export default Setting;
