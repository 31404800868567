import cn from 'classnames';
import Card from '../../components/Card';
import Spinner from '../../utils/spinner';
import styles from './LendersPortal.module.sass'
import Icon from '../../components/Icon';
import { useParams, withRouter, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { applyForLoanFromLender, individualLenderDetail } from '../../utils/apiCallHanlder';
import { ToastContainer, toast } from 'react-toastify';
import { camelCaseToWords, toastConfiguration } from '../../utils/utils';
import DeleteModal from '../../components/DeleteModal';
import SaveSuccess from '../../components/SaveSuccess';

const LendersPortal = (props) => {
    const history = useHistory();
    const { id, propertyId } = useParams();
    const status = props.location?.state?.status

    const [loading, setLoading] = useState(true);
    const [lenderDetail, setLenderDetail] = useState({});
    const [visibleDelete, setVisibleDelete] = useState(false);

    const [selectedLoan, setSelectedLoan] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);
    const [hideButton, setHideButton] = useState(false);

    useEffect(async () => {
        if (id && propertyId) {
            const { data } = await individualLenderDetail({
                lenderId: id
            })
            if (data) {
                setSelectedLoan(data?.lenderLoanTypes ? data?.lenderLoanTypes[0] : {})
                setLenderDetail(data);
            }
        }
        setLoading(false);
    }, [id])

    const applyForLoan = async () => {
        const { data, error } = await applyForLoanFromLender({
            lenderId: id,
            propertyId: propertyId
        })

        if (data) {
            setHideButton(true);
            setVisibleDelete(false);
            setShowSuccess(true);
        } else {
            toast.error(error || "Failed to apply for loan", toastConfiguration);
        }
    }

    const GetLoanTypeKeys = (selectedLoan) => {
        let keys = [];
        let objKeys = Object?.keys(selectedLoan || {})
        if (objKeys) {
            for (let index = 0; index < objKeys.length; index++) {
                const element = objKeys[index];
                switch (element) {
                    case 'minLoan':
                    case 'maxLoan':
                        if (selectedLoan[element] > 0) {
                            keys.push({ name: element, icon: 'dollar' })
                        }
                        break;

                    case 'interestRate':
                    case 'constructionFinancing':
                    case 'loanToPrice':
                    case 'loanToCost':
                    case 'loanToArv':
                        keys.push({ name: element, icon: 'percentage' })
                        break;
                    case 'originationFee':
                        keys.push({ name: element, icon: 'grid' })
                        break;
                    case 'term':
                        keys.push({ name: element, icon: 'calendar' })
                        break;
                    case 'minimumFico':
                    case 'minimumDscr':
                        keys.push({ name: element, icon: 'clock' })
                        break;
                    case 'instantTermSheet':
                    case 'shortTermRentals':
                        keys.push({ name: element, icon: 'home' })
                        break;
                    default:
                        break;
                }
            }

        }
        return keys;
    };
    return (
        <>
            <SaveSuccess
                showSuccessModal={showSuccess}
                text={"Application sent to lender successfully !!!"}
                onClose={() => setShowSuccess(false)}
                onConfirm={() => setShowSuccess(false)}
            />
            <ToastContainer />
            <span
                className={styles.backBtn}
                onClick={() => {
                    history.push({
                        pathname: `/projects/${propertyId}`,
                        state: { showLenders: true }
                    })
                }}
            >
                <Icon name="arrow_backward" size="18" />
                Back
            </span>
            <Card
                className={styles.card}
                title='Lender Portal'
                border
                classCardHead={cn(styles.head, { [styles.hidden]: false })}
                head={
                    <>
                        {(!hideButton && status) && (
                            <div className={styles.buttonContainer}>
                                {(status?.toLowerCase() === "apply" || status?.toLowerCase() === "requiredetails") ? (
                                    <DeleteModal
                                        button={
                                            <button className={cn('button', styles.deleteButton)}
                                                onClick={() => setVisibleDelete(true)}>
                                                {status?.toLowerCase() === "apply" ? "Apply for loan" :
                                                    status?.toLowerCase() === "requiredetails" ? "Send details" : ""
                                                }
                                            </button>
                                        }
                                        visibleDelete={visibleDelete}
                                        setVisibleDelete={setVisibleDelete}
                                        btnClass=" "
                                        onClickHandler={applyForLoan}
                                        id={id}
                                        title="Send loan Application"
                                        subTitle={`You are about to send loan application to ${lenderDetail?.lender?.name}`}
                                        confirmationButtonText="Send now"
                                        imgSrc={"/SuccessTick.png"}
                                    />
                                ) :
                                    <button className={cn('button', styles.status)} style={{
                                        backgroundColor: status?.toLowerCase() === 'offer recieved' ? '#FFA96B' : '#464542'
                                    }}>{status}</button>
                                }
                            </div>
                        )}
                    </>
                }
            >
                <div className={styles.portalWrapper}>
                    {loading ?
                        <div className={styles.spinner}>
                            <Spinner size="48" color="gray" />
                        </div>
                        :
                        <>
                            <div className={styles.imageWrapper}>
                                <img src={lenderDetail?.lender?.headerImageUrl} alt="cover" className={styles.coverImage} />
                                <img src={lenderDetail?.lender?.logoImageUrl} alt="profile" className={styles.profileImage} />
                            </div>
                            <div className={styles.lenderName}>{lenderDetail?.lender?.name}</div>
                            <div className={styles.website}>{lenderDetail?.lender?.website}</div>
                            <div className={styles.header}>About us</div>
                            <div className={styles.about}>{lenderDetail?.lender?.aboutUs}</div>

                            {lenderDetail?.lenderPropertyTypes?.length > 0 &&
                                <div className={styles.header}>Type of properties we finance</div>
                            }

                            <div className={styles.iconContainer}>
                                {lenderDetail?.lenderPropertyTypes?.map((item, index) => {
                                    return <div className={styles?.singleIconContainer} key={index}>
                                        <div className={styles.icon} style={{ backgroundColor: "#FF4900" }}>
                                            <Icon name={item?.icon?.toLowerCase()} size="40" viewBox="0 0 40 40" fill={"#FFFDF9"} />
                                        </div>
                                        <span>{item?.display}</span>
                                    </div>
                                })}
                            </div>

                            {lenderDetail?.lenderLoanTypes?.length > 0 &&
                                <div className={styles.header}>Types of loan we offer</div>
                            }

                            <div className={styles.iconContainer}>
                                {lenderDetail?.lenderLoanTypes?.map((item, index) => {
                                    return <div className={styles?.singleIconContainer} key={index} onClick={() => {
                                        setSelectedLoan(item);
                                    }}>
                                        <div className={styles.icon} style={{
                                            backgroundColor: selectedLoan?.name === item?.name ? "#FF4900" : "rgb(233, 231, 224)"
                                        }}>
                                            <Icon name={item?.name?.replace(/\s/g, '')?.toLowerCase()} size="40" viewBox="0 0 40 40" fill={"#FFFDF9"} />
                                        </div>
                                        <span>{item?.name}</span>
                                    </div>
                                })}
                            </div>



                            <div className={styles.detailsContainer}>
                                <div className={styles.row}>
                                    {GetLoanTypeKeys(selectedLoan).map((item, index) => {
                                        return selectedLoan[item.name] && <div className={styles.singleDetail} key={index}>
                                            <div>
                                                <Icon name={item.icon || 'lightning'} size="25" fill="#FF4900" />
                                                <span className={styles.headerWithMargin}>{camelCaseToWords(item.name)}</span>
                                            </div>
                                            <span className={styles.detail}>{selectedLoan[item.name]?.toString() || "-"}</span>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Card>
        </>
    )
}

export default withRouter(LendersPortal);