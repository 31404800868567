import React, { useState } from "react";
import cn from "classnames";
import styles from "./TableInfo.module.sass";
import Row from "../Row";
import DeleteModal from "../../../../../../components/DeleteModal";
import Spinner from "../../../../../../utils/spinner";
import { removeDraftInvoice, voidInvoice } from "../../../../../../utils/apiCallHanlder";
import Icon from "../../../../../../components/Icon";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router";
import { toastConfiguration } from "../../../../../../utils/utils";
import Dropdown from "../../../../../../components/Dropdown";
import Image from "../../../../../../components/Image";

import {
  Checkbox,
  TriStateCheckbox,
  TriStateContext,
} from "react-tri-state-checkbox";
import "react-tri-state-checkbox/styles/checkbox.css";

const TableInfo = ({
  className,
  invoiceId,
  setInvoiceData,
  invoices,
  newData,
  activeIndex,
  handleInvoices,
  handleInvoicesSingle,
  allInvoiceType,
  isInvoiceType,
  editAction,
  editInvoice,
  setNeedNewData,
  activeType,
  invoiceTypes,
  lastId,
  needNewData,
  setInvoicedData,
  setActiveType,
  invoicesData
}) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const history = useHistory();

  const deleteHandler = async (invoiceId, isVoiding) => {
    setDeleteLoading(true);

    const res = isVoiding ? await voidInvoice({ invoiceId }) : await removeDraftInvoice({ invoiceId });
    const { data } = res;
    if (data) {
      toast.success(`Invoice ${isVoiding ? 'voided' : 'deleted'} successfully`, toastConfiguration);
      history.push(`/payments/tabs/${activeIndex}`);
      setInvoiceData([]);
      setNeedNewData(!newData);
    } else {
      toast.error(`Error in ${isVoiding ? 'voiding' : 'deleting'} Invoice`, toastConfiguration);
    }

    setDeleteLoading(false);
  };

  return (
    <>
      <TriStateContext controls={invoiceId}>
        <ToastContainer />
        <div className={cn(styles.wrapper, className)}>
          <div className={cn(styles.table)}>
            <div className={cn(styles.row)}>
              <div className={styles.editOverlayHead}></div>
              {/* <div className={styles.col}> */}
              {/* <TriStateCheckbox
                  onClick={handleInvoices}
                  className={styles.triHead}
                ></TriStateCheckbox> */}
              {/* </div> */}
              <div style={{ marginTop: 24 }}></div>
              <div className={styles.col}>Customer</div>
              <div className={styles.col}>Amount</div>
              <div className={styles.col} style={{ width: 150 }}>
                <div className={styles.nav}>
                  <Dropdown
                    className={styles.dropdown}
                    classDropdownHead={styles.dropdownHead}
                    value={activeType}
                    //bodyStyles={{ height: 260, overflowY: 'hidden' }}
                    onChange={(value) => {
                      const index = invoiceTypes?.indexOf(value);
                      lastId = "";
                      history.push(`/payments/tabs/${index + 1}`);
                      // setActiveIndex(index + 1);
                      // setActiveType(x)
                      // actionBtnHandle(index + 1);
                      setNeedNewData(!needNewData);
                      setInvoicedData([]);
                    }}
                    setValue={setActiveType}
                    options={invoiceTypes}
                    small
                  />
                </div>
              </div>
              <div className={styles.col}>Created</div>
              <div className={styles.col}>Due</div>
              <div className={styles.col}>ID</div>
              <div className={styles.col}></div>
            </div>
            {invoicesData.length > 0 ? (
              invoices.map((x, index) =>
                allInvoiceType || x.invoiceType === isInvoiceType ? (
                  <Row
                    item={x}
                    key={index}
                    setInvoiceData={setInvoiceData}
                    activeIndex={activeIndex}
                    setNeedNewData={setNeedNewData}
                    editInvoice={editInvoice}
                    newData={newData}
                    setActiveType={setActiveType}
                    // singleCheck={
                    //   <Checkbox
                    //     onClick={handleInvoicesSingle}
                    //     className={cn(styles.triCheckbox)}
                    //     id={x.id}
                    //   />
                    // }
                    editAction={editAction && editAction}
                    deleteAction={
                      <DeleteModal
                        icon={
                          <Icon
                            className={styles.btnIcon}
                            fill="#FF4900"
                            name="trash"
                            size="20"
                          />
                        }
                        deleteBtn={
                          <button
                            className={cn(styles.btnClass)}
                            style={{
                              width: 150,
                              border: "1px solid gray",
                              textAlign: "center",
                              color: "black",
                              cursor: "pointer",
                            }}
                          >
                            {deleteLoading ? (
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Spinner
                                  loading={deleteLoading}
                                  size="24"
                                  color="gray"
                                />
                              </span>
                            ) : (
                              <div
                                style={{ width: "100%" }}
                                onClick={() => deleteHandler(x.id)}
                              >
                                <span> Yes I'm sure!</span>
                              </div>
                            )}
                          </button>
                        }
                        className={styles.deleteBtn}
                        btnClass={cn(styles.btnClass)}
                      />
                      
                      // <DeleteModal

                      // deleteBtn={
                      //   <span
                      //   className={cn(styles.btnClass)}
                      //     onClick={deleteHandler}
                      //     style={{ width: 150 }}
                      //   >
                      //     {deleteLoading ?
                      //       <Spinner loading={deleteLoading} size="24" color="white" />
                      //       :
                      //       "Yes I'm sure!"
                      //     }

                      //   </button>
                      // }
                      // />
                    }
                    deleteHandler={deleteHandler}
                  />
                ) : null
              )
            ) : null}
          </div>
          {invoicesData.length > 0 ? null : (
            <div className={styles.foot}>
              <Image
                className={styles.pic}
                src="/images/icons/empty-black.png"
                srcDark="/images/icons/empty-white.png"
                alt="Core"
              />
            </div>
          )}
        </div>
      </TriStateContext>
    </>
  );
};

export default TableInfo;
