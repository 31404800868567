import React, { useState } from "react";
import styles from "./CustomerOverview.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Table from "./Table";
import MainCustomerContainer from '../index';
import { Redirect } from "react-router";
import Icon from "../../../../components/Icon";
import PaymentsHeader from "../../PaymentsHeader";

// import { customers } from "../../../mocks/customers";

const CustomerList = ({
  visibility,
  showAddPaymentLink,
  setShowAddPaymentLink,
  currentPage
}) => {
  const [visible, setVisible] = useState(true);
  const [customerId, setCustomerId] = useState(undefined);
  const [showMainContainer, setShowMainContainer] = useState(false);
  const [actionCustomer, setActionCustomer] = useState(true);
  const [show, setShow] = useState(false);
  const [checkCount, setCheckCount] = useState(0);
  const [isAdd, setIsAdd] = useState(false);
  const [deleteIdArray, setDeleteIdArray] = useState([]);

  const handleEditCustomer = (id) => {
    setVisible(false);
    setCustomerId(id);
  }

  return (
    <>
      {showMainContainer ? <MainCustomerContainer />
        :
        visible ? (
          <>
            <Card
              className={styles.card}
              title="Get Paid"
              border
              /* classTitle={cn("title-blue", styles.title)} */
              classCardHead={cn(styles.head, { [styles.hidden]: show })}
              head={
                <>
                  <button
                    onClick={() => {
                      setShowAddPaymentLink(true);
                    }}
                    className={cn("button", styles.addCustomerBtn)}
                  >
                    Add new payment link
                  </button>
                </>
              }
            >
              <div style={{ marginTop: 20 , marginBottom: 20 }}>
                <PaymentsHeader
                  currentPage={currentPage}
                />
              </div>
              <div className={cn(styles.row, { [styles.flex]: show })}>
                <Table
                  className={styles.table}
                  activeTable={show}
                  setActiveTable={setShow}
                  setShowMainContainer={setShowMainContainer}
                  setVisible={setVisible}
                  setCustomerId={setCustomerId}
                  editCustomer={handleEditCustomer}
                  visibility={visibility}
                  setVisibility={setVisible}
                  setActionCustomer={setActionCustomer}
                  showAddPaymentLink={showAddPaymentLink}
                  setShowAddPaymentLink={setShowAddPaymentLink}
                  setCheckCount={setCheckCount}
                  checkCount={checkCount}
                  deleteIdArray={deleteIdArray}
                  noCustomerButtonClickHandler={() => {
                    setVisible(false);
                    setIsAdd(true);
                  }}
                  setDeleteIdArray={setDeleteIdArray}
                />
              </div>
            </Card>
          </>
        ) : (
          isAdd ? null
            // <EditNewCustomer isAdd={isAdd} />
            : <Redirect to={`/contacts/${customerId}`} />
        )
      }
    </>
  );
};

export default CustomerList;
