import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { reverifyHandler } from "../../../utils/utils";
import styles from "./HomeHeader.module.sass";
import Spinner from "../../../utils/spinner";
import cn from "classnames";
import Card from "../../../components/Card";
import { updateTodoDone } from "../../../utils/apiCallHanlder";

const HomeHeader = ({ className, todo, linkExistingBankAccountHandler }) => {
  const [arrayToMap, setArrayToMap] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [verifyIdentityVisible, setVerifyIdentityVisible] = useState(false);
  const [verifyIdentityLastError, setVerifyIdentityLastError] = useState("");
  const [verifyLink, setVerifyLink] = useState("");
  const [linkLoading, setLinkLoading] = useState(false);
  const history = useHistory();


  const actionCard = [
    {
      name: "todoConnectStripe",
      title: "Add stripe account",
      description: "Sign up for a Stripe Account or link your existing account to the platform",
      showCard: true,
      onClick: async () => {
        await updateTodoDone({ fieldName: 'isStripeConnected' });
        reverifyHandler(setIsLoading)
      },
      isStripeConnected: true
    },
    {
      name: "todoLinkBankAccount",
      title: "Add bank account",
      description: "Sync an existing bank account to the platform to start categorising transactions",
      showCard: true,
      onClick: async () => {
        await updateTodoDone({ fieldName: 'addBankAccountDone' });
        linkExistingBankAccountHandler(setIsLoading)
      },
      addBankAccountDone: true
    },
    {
      name: "todoBookTransaction",
      title: "Book a transaction",
      description: "Choose a transaction and book it in your accounting ledger",
      showCard: true,
      onClick: async () => {
        await updateTodoDone({ fieldName: 'bookTransactionDone' });
        history.push("/accounting");
      },
      bookTransactionDone: true,
    },
    {
      name: "todoSendInvoice",
      title: "Send an invoice",
      description: "Getting paid is why you started your business right? Let’s send your first invoice",
      showCard: true,
      onClick: async () => {
        await updateTodoDone({ fieldName: 'sendInvoiceDone' });
        history.push("/payments");
      },
      sendInvoiceDone: true
    },
    {
      name: "todoBranding",
      title: "Branding",
      description: "Ensure you stand out from your competitors. Set up your branding. ",
      showCard: true,
      onClick: async () => {
        await updateTodoDone({ fieldName: 'brandingDone' });
        history.push("/settings/Business%20Info");
      },
      brandingDone: true
    }
  ];

  useEffect(() => {

    let newArray = [];
    const company = JSON.parse(localStorage.getItem("company"));

    for (let j = 0; j < actionCard.length; j++) {
      if (actionCard[j].isStripeConnected) {
        actionCard[j].showCard = !todo.isStripeConnected;
      }
      else if (actionCard[j].sendInvoiceDone) {
        actionCard[j].showCard = !todo.sendInvoiceDone;
      }
      else if (actionCard[j].bookTransactionDone) {
        if(company.country === "US"){
          actionCard[j].showCard = !todo.bookTransactionDone;
        }
        else {
          actionCard[j].showCard = false;
        }
      }
      else if (actionCard[j].addBankAccountDone) {
        if(company.country === "US"){
          actionCard[j].showCard = !todo.addBankAccountDone;
        }
        else {
          actionCard[j].showCard = false;
        }
      }
      else if (actionCard[j].brandingDone) {
        actionCard[j].showCard = !todo.brandingDone;
      }
      else {
        actionCard[j].showCard = false;
      }

      if (actionCard[j].showCard) {
        newArray.push(actionCard[j]);
      }
    }

    setArrayToMap([...newArray]);
  }, [])

  const common = {
    height: 72,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  }

  return (
    <Card
      className={cn(styles.card, className)}
      title={"To do"}
      id='TodoList'
      isDashboard={true}
      classCardHead={styles.cardHead}
      comingFromDashboard={true}
      border
    >
      <div className={styles.homeHeader}>
        {isLoading ?
          <>
            <div style={{
              backgroundColor: 'lightgray',
              position: 'fixed',
              height: '100vh',
              width: '100vw',
              opacity: 0.6,
              zIndex: 15,
              top: 0,
              left: 0
            }}>
            </div>
            <div style={{
              position: 'fixed',
              zIndex: 20,
              left: "50%",
              top: '50%',
            }}>
              <Spinner color="gray" size="64" />
            </div>
          </>
          :
          null
        }
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 12,
          width: '100%'
        }}>
          {arrayToMap.map((x, index) => {
            return <div
              className={styles.singleCard}
              key={index}

              style={{
                cursor: 'pointer',
                backgroundColor: 'transparent',
                border: '3px solid lightgray',
                ...common
              }}
            >
              <p className={styles.title}>{x.title}</p>
              <p className={styles.description}>{x.description}</p>
              <div className={styles.buttonWrapper}>
                <button
                  onClick={() => { x.onClick() }}
                  className={cn("button", styles.addProductBtn)}
                >
                  {linkLoading ?
                    <Spinner size="24" color="white" />
                    :
                    "Let's go"
                  }
                </button>
              </div>
            </div>
          })}
        </div>

      </div>
    </Card>
  );
};

export default HomeHeader;
