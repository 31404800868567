import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Card from "../../../../../components/Card";
import TableInfo from "./TableInfo";
import Dropdown from "../../../../../components/Dropdown";
import Settings from "../Settings";
import Icon from "../../../../../components/Icon";
import Filters from "../../../../../components/Filters";
import { getInvoices } from "../../../../../utils/apiCallHanlder";
import Spinner from "../../../../../utils/spinner";
import { ToastContainer } from "react-toastify";
import { useHistory } from "react-router";

// data
import { invoices } from "../../../../../mocks/invoices";
import Image from "../../../../../components/Image";
import LargeNav from "../../../../../components/LargeNav";
const invoiceTypes = ["All Invoices", "Scheduled", "Draft", "Outstanding", "Past Due", "Paid", "Void"];

const invoiceTypesDB = ["", "scheduled", "draft", "open", "due", "paid" , "void"];

let lastId = "";

const Table = ({
  handleAllInvoice,
  activeIndexx,
  handleAllInvoicesSingle,
  handleScheduledInvoice,
  handleScheduledInvoicesSingle,
  handleDraftInvoice,
  handleDraftInvoicesSingle,
  handleOutstandingInvoice,
  handleOutstandingInvoicesSingle,
  handlePastDueInvoice,
  handlePastDueInvoicesSingle,
  handlePaidInvoice,
  handlePaidInvoicesSingle,
  actionBtnHandle,
  editInvoice,
}) => {
  if (!activeIndexx) {
    activeIndexx = 1;
  }
  const [activeType, setActiveType] = useState(invoiceTypes[activeIndexx - 1]);
  const [startLoading, setStartLoading] = useState(true);
  const [invoicesData, setInvoicedData] = useState([]);
  const [hasMore, setHasMore] = useState([]);
  const [needNewData, setNeedNewData] = useState(false);
  const history = useHistory();

  // useEffect(() => {
  //   setActiveIndex(invoiceTypes.indexOf(activeType) + 1);
  // }, [activeType]);

  useEffect(async () => {
    if (invoicesData.length < 1) {
      lastId = "";
    }
    await getInvoicesByStatus(invoiceTypesDB[activeIndexx - 1]);
  }, [needNewData]);

  const getInvoicesByStatus = async (status) => {
    setStartLoading(true);
    const { data } = await getInvoices(status, {
      PageSize: 30,
      status: status,
      nextPageInfo: lastId,
    });
    if (data) {
      let filteredInvoices;
      // if (data?.invoices[0]?.status === "draft") {
      //   filteredInvoices = data?.invoices?.filter((invoice) => invoice?.status === "draft");
      // } else {
      filteredInvoices = data?.invoices;
      // }
      setHasMore(data?.isNextPageExist);
      setInvoicedData(invoicesData.concat(filteredInvoices));
      lastId = data?.nextPageInfo;
    }
    setStartLoading(false);
  };

  const allInvoiceId = invoices.map((x) => x.id);

  return (
    <>
      <ToastContainer />
      <Card className={styles.card}>
        <div>
          <div className={styles.nav}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={activeType}
              onChange={(value) => {
                const index = invoiceTypes?.indexOf(value);
                lastId = "";
                history.push(`/payments/tabs/${index + 1}`);
                // setActiveIndex(index + 1);
                // setActiveType(x)
                // actionBtnHandle(index + 1);
                setNeedNewData(!needNewData);
                setInvoicedData([]);
              }}
              setValue={setActiveType}
              options={invoiceTypes}
              small
            />
            {/* <Filters
              className={styles.filters}
              title="Showing 9 of 32 Invoices"
              iconHead={
                <Icon className={styles.filterBtn} name="adjustments" size="24" />
              }
            >
              <Settings />
            </Filters>
            <button className={styles.downloadBtn}>
              <Icon name="download" size="24" />
            </button> */}
          </div>
        </div>
        {startLoading && invoicesData.length < 1 ? (
          <div className={styles.spinner} style={{ height: window.outerHeight - 450 }}>
            <Spinner loading={startLoading} size={50} color={"gray"} />
          </div>
        ) : (
          <>
            <>
              <div>
                <TableInfo
                  invoices={invoicesData}
                  invoiceId={allInvoiceId}
                  handleInvoices={handleAllInvoice}
                  handleInvoicesSingle={handleAllInvoicesSingle}
                  allInvoiceType
                  newData={needNewData}
                  setInvoiceData={setInvoicedData}
                  activeIndex={activeIndexx}
                  setNeedNewData={setNeedNewData}
                  editInvoice={editInvoice}
                  activeType={activeType}
                  invoiceTypes={invoiceTypes}
                  lastId={lastId}
                  needNewData={needNewData}
                  setInvoicedData={setInvoicedData}
                  setActiveType={setActiveType}
                  invoicesData={invoicesData}
                />
              </div>
              {hasMore ? (
                <div className={styles.foot}>
                  <button
                    className={cn("button-stroke-red button-small", styles.button)}
                    onClick={() => getInvoicesByStatus(invoiceTypesDB[activeIndexx - 1])}
                    style={{ width: 200 }}
                  >
                    {startLoading ? (
                      <Spinner loading={startLoading} size={20} color={"gray"} />
                    ) : (
                      <>
                        <span>See More Invoices</span>
                        <Icon name="arrow-next" size="20"></Icon>
                      </>
                    )}
                  </button>
                </div>
              ) : null}
            </>
          </>
        )}
      </Card>
    </>
  );
};

export default Table;
