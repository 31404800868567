import React, { useState } from "react";
import cn from "classnames";
import styles from "./AddNew.module.sass";
import Icon from "../../../../../components/Icon";
import Item from "../../../Item";
import Dropdown from "../../../../../components/Dropdown_c";
import TextInput from "../../../../../components/TextInput";
import {
  emailHandleChange,
  getBaseURLOfCurrentApp,
  trackIntercomEvent,
} from "../../../../../utils/utils";
import { teamInviteMember } from "../../../../../utils/apiCallHanlder";
import Spinner from "../../../../../utils/spinner";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router";
import { toastConfiguration } from "../../../../../utils/utils";
import { teamEmailDesc } from "../../../../../utils/descriptions";

let haveDropdownsData = false;
let roles = [""];

const DeleteModal = ({
  className,
  children,
  visible,
  setVisible,
  redirectedURL,
}) => {
  const history = useHistory();

  if (!haveDropdownsData) {
    const accountLookupData = JSON.parse(localStorage.getItem("lookupData"));
    let data = null;
    if (accountLookupData) {
      data = accountLookupData?.data;
      roles = data?.Roles;
      haveDropdownsData = true;
    }
  }

  const [isLoading, setIsLoading] = useState(false);

  const [role, setRole] = useState(roles[0]?.value);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [serverError, setServerError] = useState("");
  const [redirectURL, setRedirectURL] = useState(redirectedURL || null);

  const addTeamMemberHandler = async () => {
    if (email === "") {
      setEmailError("Please fill out Email Field");
    } else if (emailError === "Email is invalid") {
      setServerError(emailError);
    } else {
      const newTeamMemberPayload = {
        roleid: role,
        email: email,
        returnUrl: getBaseURLOfCurrentApp() + "/invite-team",
      };
      if (isLoading) {
        return;
      }
      setIsLoading(true);

      const { data, error } = await teamInviteMember(newTeamMemberPayload);
      if (data) {
        trackIntercomEvent("teammember-invite");
        toast.success(
          "Invite has been sent on given email address successfully",
          toastConfiguration
        );
        //setRedirectURL(data?.linkUrl);
      } else {
        toast.error(
          "Error in sending invite to given email address",
          toastConfiguration
        );
        setServerError(error);
      }

      setIsLoading(false);
      setVisible(false);
    }
  };

  return (
    <div
      className={cn(styles.deleteModal, className, {
        [styles.active]: visible,
      })}
    >
      <ToastContainer />
      <div className={styles.body}>
        <div className={styles.top}>
          <button
            className={cn("close", styles.close)}
            onClick={() => setVisible(false)}
          >
            <Icon name='close' size='20' />
          </button>
        </div>
        <div>
          {/* {redirectURL ?
                        <div className={styles.field}
                            style={{ marginTop: 40, pointerEvents: 'none' }}
                        >
                            <div style={{ border: '1px solid black', padding: 15, textAlign: 'center' }}>
                                <span style={{ color: 'green' }}>Invite has been sent on given email address !!!</span>
                            </div>
                        </div>
                        : */}
          <Item
            className={cn(styles.card, className)}
            title='Invite Team Member'
            /* classTitle="title-blue" */
          >
            <div className={styles.fieldset}>
              <div className={styles.field} style={{ position: "relative" }}>
                <TextInput
                  style={
                    email === "" || emailError
                      ? {
                          backgroundColor: "#FFBC9925",
                          borderColor: "#FFBC9925",
                        }
                      : null
                  }
                  label='Email'
                  name='email'
                  type='email'
                  value={email}
                  onChange={(event) => {
                    setServerError("");
                    emailHandleChange(event, setEmailError, setEmail);
                  }}
                  /* tooltip={teamEmailDesc} */
                  required
                />
                {emailError ? (
                  <Icon
                    name='warning'
                    className={styles.warningIcon}
                    size='24'
                  />
                ) : (
                  ""
                )}
                {emailError !== "" && emailError !== "Email is invalid" ? (
                  <div className={styles.errorContainer}>
                    <span className={styles.errorMessage}>{emailError}</span>
                  </div>
                ) : null}
              </div>
              <div className={styles.row}>
                <div className={styles.field}>
                  <div
                    className={styles.fieldLabel}
                    style={{ marginBottom: 10 }}
                  >
                    Type
                  </div>
                  <Dropdown
                    className={styles.dropdown}
                    classDropdownHead={styles.dropdownHead}
                    value={role}
                    setValue={setRole}
                    options={roles}
                  />
                </div>
              </div>
              <div className={styles.actionBtn}>
                <button
                  onClick={addTeamMemberHandler}
                  className={cn("button", styles.button)}
                  style={{ width: "100%" }}
                >
                  {isLoading ? (
                    <Spinner loading={isLoading} size={15} />
                  ) : (
                    <>
                      <Icon name='arrow-right' size='24' />
                      <span>Send</span>
                    </>
                  )}
                </button>
                {serverError !== "" ? (
                  <div className={styles.errorContainer}>
                    <span className={styles.errorMessage}>{serverError}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </Item>
          {/* } */}
        </div>
      </div>
      <div className={styles.overlay} onClick={() => setVisible(false)}></div>
    </div>
  );
};

export default DeleteModal;
