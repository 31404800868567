import React, { useState } from "react";
import Card from "../../../components/Card";
import styles from "./AddNewCard.module.sass";
import Dropdown from "../../../components/Dropdown";
import Checkbox from "../../../components/Checkbox";
import cn from "classnames";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";

const cardHolders = ["Myself", "Other"];
const shippingCountries = ["United Kingdom", "United States"];
const shippingStates = ["State", "State 2"];

const AddNewCard = ({ setShowCard, setAddNewCard }) => {
  const [cardHolder, setCardHolder] = useState(cardHolders[0]);
  const [shippingCountry, setShippingCountry] = useState(shippingCountries[0]);
  const [shippingState, setShippingState] = useState(shippingStates[0]);
  const [isPhysical, setIsPhysical] = useState(false);
  const [isVirtual, setIsVirtual] = useState(true);
  return (
    <>
      <div className={styles.title}>
        <h4 className={styles.backBtn} onClick={() => setAddNewCard(false)}>
          Cards <Icon name='arrow-next' size='14' />
        </h4>
        <p>Add new card</p>
      </div>
      <Card title='Add new card' /* classTitle="title-blue" */>
        <div className={styles.row}>
          <div className={styles.col}>
            <Dropdown
              label='Card Holder'
              value={cardHolder}
              setValue={setCardHolder}
              options={cardHolders}
              className={styles.dropdown}
            />
            <div className={styles.singleCard}>
              <p className={styles.label}>Type</p>
              <div className={styles.flex}>
                <Checkbox
                  onChange={() => {
                    setIsVirtual(!isVirtual);
                    setIsPhysical(false);
                  }}
                  value={isVirtual}
                />
                <div className={styles.right}>
                  <h3>Virtual</h3>
                  <p>You can use virtual cards instantly.</p>
                </div>
              </div>
              <div className={styles.flex}>
                <Checkbox
                  onChange={() => {
                    setIsPhysical(!isPhysical);
                    setIsVirtual(false);
                  }}
                  value={isPhysical}
                />
                <div className={styles.right}>
                  <h3>Physical</h3>
                  <p>We ship physical card to you.</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            {isPhysical ? (
              <>
                <TextInput
                  label='Shipping Address'
                  placeholder='Address Line 1'
                  className={styles.input}
                />
                <TextInput
                  placeholder='Address Line 2'
                  className={styles.input}
                />
                <Dropdown
                  value={shippingCountry}
                  setValue={setShippingCountry}
                  options={shippingCountries}
                  className={styles.input}
                />
              </>
            ) : null}
          </div>
          <div className={styles.col}>
            {isPhysical ? (
              <>
                <TextInput
                  placeholder='City'
                  className={cn(styles.input, styles.city)}
                />
                <Dropdown
                  value={shippingState}
                  setValue={setShippingState}
                  options={shippingStates}
                  className={styles.input}
                />
              </>
            ) : null}
          </div>
        </div>
      </Card>
      <Card className={styles.actionCard}>
        <button
          onClick={() => {
            setAddNewCard(false);
            setShowCard(true);
          }}
          className={cn("button-stroke", styles.button)}
        >
          <Icon name='arrow-left' size='24' />
          <span>Cancel</span>
        </button>
        <button
          onClick={() => {
            setAddNewCard(false);
            setShowCard(true);
          }}
          className={cn("button", styles.button)}
        >
          <Icon name='payment' size='24' />
          <span>Order Card</span>
        </button>
      </Card>
    </>
  );
};

export default AddNewCard;
