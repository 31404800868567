import { useEffect, useState } from 'react'
import {
    addProspect,
    getVentureCapitalPipeline,
    removeProspect,
    udpateVentureCapitalColumnsAcrossLane,
    udpateVentureCapitalColumnsInSameLane,
    updateProspect
} from '../../../../utils/apiCallHanlder';
import Spinner from '../../../../utils/spinner';
import Styles from './Pipeline.module.sass'
import TextInput from '../../../../components/TextInput';
import Card from '../../../../components/Card';
import Modal from '../../../../components/Modal';
import ReactPhoneInput from "react-phone-input-2";
import Dropdown from '../../../../components/Dropdown';
import { toastConfiguration } from '../../../../utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import DeleteModal from '../../../../components/DeleteModal';
import Icon from '../../../../components/Icon';
import { format } from "react-phone-input-auto-format";

import Board from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'

let pipelinesData = [];
const inputStyle = {
    position: "relative",
    fontSize: "14px",
    letterSpacing: ".01rem",
    marginTop: "0px !important",
    marginBottom: " 0px !important",
    paddingLeft: "48px",
    marginLeft: "0px",
    background: "#F4F4F4",
    border: "none",
    borderRadius: "10px",
    lineHeight: "25px",
    height: "45px",
    width: "100%",
    outline: "none",
};

const CardComp = (data) => {
    return <div className={Styles.cardContainer}
        onClick={data?.onClick}
        onChange={data?.onChange}
    >
        {/* <div className={Styles.cardColumnContainer}> */}
        <div className={Styles.cardRow}>
            <div className={Styles.title}>
                <Icon name="profile-circle" size="18" fill={"#464542"} />
            </div>
            <div className={Styles.value}>{data?.title}</div>
        </div>
        <div className={Styles.cardRow}>
            <div className={Styles.title}>
                <Icon name="mail" size="18" fill={"#464542"} />
            </div>
            <div
                className={data?.description ? Styles.valueHyperlink : Styles.value}
                onClick={(e) => {
                    if (data?.description) {
                        e.stopPropagation()
                        window.location.href = `mailto:${data?.description}`;
                    }
                }}
            >
                {data?.description || "-"}
            </div>
        </div>
        <div className={Styles.cardRow}>
            <div className={Styles.title}>
                <Icon name="phone" size="18" fill={"#464542"} />
            </div>
            <div
                className={data?.mobile?.length > 4 ? Styles.valueHyperlink : Styles.value}
                onClick={(e) => {
                    if (data?.mobile?.length > 4) {
                        e.stopPropagation()
                        window.location.href = `tel:${data?.mobile}`;
                    }
                }}
            >
                {data?.mobile?.length > 4 ? format(data?.mobile || '') : '-'}
            </div>
        </div>
        {/* </div> */}
    </div >
}

const LaneHeader = (data) => {
    return (
        <div className={Styles.headerRow}>
            <div className={Styles.headerTitle}>{data?.title}</div>
        </div>
    )
}

const Pipeline = ({
    setShowAddProspect,
    showAddProspect
}) => {
    const [loading, setLoading] = useState(true);
    const [addLoading, setAddLoading] = useState(false);
    const [stages, setStages] = useState([]);

    const [dealName, setDealName] = useState("");
    const [email, setEamil] = useState("");
    const [mobile, setMobile] = useState("");
    const [stage, setStage] = useState("");

    const [dealId, setDealId] = useState(undefined);
    const [visibleDelete, setVisibleDelete] = useState(false);

    useEffect(() => {
        getListData();
    }, [])

    const getListData = async () => {
        pipelinesData = { columns: [] }
        setLoading(true);
        const { data } = await getVentureCapitalPipeline({});
        if (data) {
            const calculatedData = [];
            const refinedStages = data?.columns?.map(item => item?.title);
            setStages(refinedStages);
            setStage(refinedStages[0])

            data?.columns?.map(column => {
                const columnCards = [];
                data?.list?.map(list => {
                    if (list?.pipelineColumnId === column?.pipelineColumnId) {
                        columnCards.push({
                            id: list?.pipelineProspectId,
                            title: list?.name,
                            description: list?.email,
                            label: `$${list?.ltv}`,
                            hideCardDeleteIcon: true,
                            pipelineColumnType: list?.pipelineColumnType,
                            mobile: list?.phone,
                            ltv: list?.ltv,
                            onClick: () => cardClickHandler(list?.pipelineProspectId, column?.pipelineColumnId)
                        });
                    }
                })
                calculatedData.push({
                    id: column?.pipelineColumnId,
                    title: `${column?.title} ${column?.probability}%`,
                    label: `$${column?.totalPercentageLTV}`,
                    name: column?.title,
                    pipelineColumnType: column?.columnType,
                    cards: [...columnCards]
                })
            })

            pipelinesData = { columns: calculatedData };
        }
        setLoading(false);
    }

    const handleDragEnd = (board, cardDetails, source, destination) => {
        const sourceLaneId = source?.fromColumnId;
        const targetLaneId = destination?.toColumnId;
        const position = destination?.toPosition;
        const cardId = cardDetails?.id;

        if (sourceLaneId === targetLaneId) {
            handleSameLineMovement(sourceLaneId, cardId, position);
        } else {
            handleAcrossLineMovement(cardId, targetLaneId, cardDetails, position, sourceLaneId);
        }
    }

    const handleSameLineMovement = async (laneId, cardId, currentPosition) => {
        setLoading(true);
        pipelinesData?.columns?.map?.(async (lane) => {
            if (lane?.id === laneId) {
                const card = lane?.cards?.filter(item => item?.id === cardId)
                const laneCardsWithoutTargetCard = lane?.cards?.filter(item => item?.id !== cardId)
                laneCardsWithoutTargetCard?.splice(currentPosition, 0, card[0]);
                lane.cards = laneCardsWithoutTargetCard;

                const laneOrder = laneCardsWithoutTargetCard?.map(item => item?.id);
                await udpateVentureCapitalColumnsInSameLane({ ids: laneOrder });
            }
        })
        setLoading(false)
    }

    const handleAcrossLineMovement = async (cardId, targetLaneId, cardDetails, position, sourceLaneId) => {
        setLoading(true);
        pipelinesData?.columns?.map((lane) => {
            if (lane?.id === sourceLaneId) {
                lane?.cards?.filter(item => item?.id !== cardId);
            } else if (lane?.id === targetLaneId) {
                lane?.cards?.splice(position, 0, cardDetails);
            }
        })

        await udpateVentureCapitalColumnsAcrossLane({
            pipelineProspectId: cardId,
            pipelineColumnId: targetLaneId,
            pipelineColumnType: cardDetails?.pipelineColumnType,
            newOrder: position
        })

        await getListData();
        setLoading(false);
    }

    const addDealCloseHandler = () => {
        setShowAddProspect(false);
        setDealName("");
        setEamil("");
        setMobile("");
        setDealId(undefined);
        setStage(stages[0])
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const saveButtonClickHandler = async () => {
        //validations
        if (!dealName || dealName?.trim() === "") {
            toast.error("Prospect name is required", toastConfiguration);
            return;
        }

        if (!email || email?.trim() === "") {
            toast.error("Email is required", toastConfiguration);
            return;
        }

        if (email && !isValidEmail(email)) {
            toast.error("Email is invalid", toastConfiguration);
            return;
        }

        setAddLoading(true);
        let pipeline = pipelinesData?.columns?.filter(item => item?.name === stage)
        pipeline = pipeline[0] ? pipeline[0] : []

        const payload = {
            name: dealName,
            email: email,
            phone: mobile,
            pipelineColumnId: pipeline?.id,
            pipelineColumnType: pipeline?.pipelineColumnType
        }

        const { data } = dealId ? await updateProspect({
            ...payload,
            pipelineProspectId: dealId
        }) :
            await addProspect(payload);

        if (data) {
            toast.success("Prospect added successfully", toastConfiguration);
            addDealCloseHandler();
            await getListData();
        } else {
            toast.error("Error in adding prospect", toastConfiguration);
        }

        setAddLoading(false);
    }

    const cardClickHandler = (cardId, laneId) => {
        pipelinesData?.columns?.map(lane => {
            if (lane.id === laneId) {
                lane?.cards?.map(card => {
                    if (card?.id === cardId) {
                        setDealId(card?.id);
                        setDealName(card?.title);
                        setEamil(card?.description);
                        setMobile(card?.mobile);
                        setStage(lane?.name)
                    }
                })
            }
        })

        setShowAddProspect(true);
    }

    const deleteDealHandler = async () => {
        const { data } = await removeProspect(dealId, {});
        if (data) {
            toast.success("Prospect deleted Successfully", toastConfiguration);
            setVisibleDelete(false);
            addDealCloseHandler();
            await getListData();
        } else {
            toast.error("Error in deleting prospect", toastConfiguration);
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal
                visible={showAddProspect}
                outerClassName={Styles.modalOuter}
                onClose={addDealCloseHandler}
                hideCloseButton={true}
                children={
                    <Card
                        title={"Deal"}
                        head={
                            <div style={{ display: 'flex', gap: 12 }}>
                                {dealId ?
                                    <>
                                        <DeleteModal
                                            id={dealId}
                                            onClickHandler={deleteDealHandler}
                                            visibleDelete={visibleDelete}
                                            setVisibleDelete={setVisibleDelete}
                                            icon={
                                                <Icon
                                                    className={Styles.btnIcon}
                                                    fill='#FF4900'
                                                    name='trash'
                                                    size='20'
                                                />
                                            }
                                            className={Styles.deleteBtn}
                                        />
                                    </>
                                    :
                                    <button className='button-stroke-red' onClick={addDealCloseHandler}>Cancel</button>
                                }
                                <button className='button' onClick={saveButtonClickHandler}>
                                    {addLoading ?
                                        <Spinner size="24" color="white" />
                                        :
                                        dealId ? "Update" : "Save"
                                    }
                                </button>
                            </div>
                        }
                        className={Styles.cardHeader}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
                            <div style={{ display: 'flex', gap: 24, marginTop: 24 }}>
                                <TextInput
                                    onChange={(event) => setDealName(event.target.value)}
                                    classInput={Styles.input}
                                    label='Name'
                                    name='name'
                                    type='text'
                                    value={dealName}
                                    required
                                />
                                <TextInput
                                    onChange={(event) => setEamil(event.target.value)}
                                    classInput={Styles.input}
                                    label='Email'
                                    name='name'
                                    type='text'
                                    value={email}
                                    required
                                />
                                <div className={Styles.field}>
                                    <div className={Styles.fieldLabel} style={{ marginBottom: 5 }}>Mobile</div>
                                    <div className={Styles.mobileField}>
                                        <ReactPhoneInput
                                            inputExtraProps={{
                                                name: "phone",
                                                required: true,
                                                autoFocus: true,
                                            }}
                                            buttonStyle={{ border: "none" }}
                                            containerStyle={{
                                                border: '2px solid #C7C5BF',
                                                marginTop: 2
                                            }}
                                            inputStyle={inputStyle}
                                            country={"us"}
                                            countryCodeEditable={false}
                                            value={mobile?.indexOf('+') > -1 ? mobile : '+' + mobile}
                                            onChange={(value) => setMobile(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: 24 }}>
                                <div className={Styles.field}>
                                    <Dropdown
                                        label={"Stage"}
                                        className={Styles.dropdown}
                                        classDropdownHead={Styles.dropdownHead}
                                        value={stage}
                                        setValue={setStage}
                                        options={stages || []}
                                        width={"100%"}
                                        bodyStyles={{ height: 150, overflow: 'auto' }}
                                    />
                                </div>
                            </div>
                        </div>

                    </Card>
                }
            />
            <>
                {loading && (
                    <div className={Styles.spinnerContainer}>
                        <Spinner size="48" color="gray" />
                    </div>
                )}
                {pipelinesData?.columns?.length > 0 && !loading && (
                    <Card className={Styles.pipelineCard}>
                        <Board
                            initialBoard={pipelinesData}
                            onCardDragEnd={handleDragEnd}
                            style={{ backgroundColor: 'transparent', height: 'max-content' }}
                            laneStyle={{ backgroundColor: 'transparent' }}
                            renderCard={CardComp}
                            renderColumnHeader={LaneHeader}
                            disableColumnDrag={true}
                        />
                    </Card>
                )}
            </>
        </>
    )
}

export default Pipeline;