import React, { useState } from "react";
import styles from "./Contract.module.sass";
import NoContract from "./NoContract";
import NewContractForm from "./NewContractForm";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import SingleContract from "./SingleContract";
import { useEffect } from "react";
import { getAllContracts, updateContract } from "../../../utils/apiCallHanlder";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../utils/spinner";
import cn from 'classnames';
import UpdateContractForm from "./UpdateContractForm";
import { toastConfiguration } from "../../../utils/utils";
import { useReducer } from "react";

let page = 1;

const Contract = ({
  visible,
  setVisible,
  setIsUpdateVisible,
  isUpdateVisible,
  userInput,
  setUserInput,
  startLoading,
  setStartLoading,
  x,
  isNew,
  setX,
  isComingFromChat
}) => {
  const [showContract, setShowContract] = useState(false);

  const [contracts, setContracts] = useState([]);
  const [isNextPageExists, setIsNextPageExists] = useState(false);
  const [updateContractLoading, setUpdateContractLoading] = useState(false);

  const [currentId, setCurrentId] = useState(-1);

  useEffect(async () => {
    await getContractFunction(1);
  }, [])

  const getContractFunction = async (currentPage) => {
    setStartLoading(true);
    const { data } = await getAllContracts({
      pageSize: 30,
      page: currentPage
    });
    if (data) {
      setIsNextPageExists(data?.isNextPageExist);
      if (currentPage === 1) {
        setContracts(data?.contracts)
      } else {
        setContracts(contracts.concat(data?.contracts))
      }
    }
    setStartLoading(false);
  }

  const create = (newContract) => {
    setContracts([...contracts, newContract]);
  };

  const remove = (id) => {
    setContracts(contracts.filter((contract) => contract.id !== id));
  };

  const update = (updateContract) => {
    const updateContracts = contracts.map((contract) => {
      if (contract.id === updateContract.id) {
        return {
          ...contract,
          contractName: updateContract.contractName,
          trixEditorValue: updateContract.trixEditorValue,
        };
      }
      return contract;
    });
    setContracts(updateContracts);
  };

  useEffect(() => {
    contracts.length === 0 ? setShowContract(false) : setShowContract(true);
  }, [contracts]);

  const handleTrixInputChange = (e, newValue) => {
    setUserInput({ trixEditorValue: newValue })
  };

  const handleChange = (e) => {
    setUserInput({ [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userInput.contractName === "" || userInput.contractName.trim() === "") {
      toast.error("Name is required", toastConfiguration);
      return;
    }

    setUpdateContractLoading(true);

    const { data } = await updateContract({
      id: x?.id,
      name: userInput.contractName,
      description: userInput?.trixEditorValue
    })

    if (data) {
      setUserInput({
        contractName: null,
        trixEditorValue: null
      })
      setX(null);
      toast.success("Successfully updated contract", toastConfiguration)

      if (!isComingFromChat) {
        setIsUpdateVisible(false);
        setContracts([]);
        await getContractFunction(1);
      }
    } else {
      toast.error("Error in updating contract", toastConfiguration)
    }
    setUpdateContractLoading(false);
  };

  return (
    <div className={styles.contract}>
      <ToastContainer />
      {visible ?
        <NewContractForm
          visible={visible}
          setVisible={setVisible}
          CreateContract={create}
          getContractFunction={getContractFunction}
          setContracts={setContracts}
          onFinish={(data) => {
            const contract = data?.contractTemplate;
            setVisible(false);
            setX(contract);
          }}
        />
        :
        isUpdateVisible ?
          <UpdateContractForm
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            userInput={userInput}
            setUserInput={setUserInput}
            updateContractLoading={updateContractLoading}
            handleTrixInputChange={handleTrixInputChange}
          />
          :
          <>
            <button className={cn("button", styles.addNewBtn)} onClick={() => setVisible(true)}>
              Create new contract
            </button>
            <ToastContainer />
            {startLoading && contracts?.length < 1 ?
              <div style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <Spinner size="64" color="gray" />
              </div>
              :
              <>
                {!showContract ? (
                  <NoContract onClick={() => setVisible(true)} />
                ) : (
                  <div>
                    <div className={styles.contractWrap}>
                      <div className={styles.contractHead}>
                        <p>Name</p>
                        <p></p>
                      </div>
                      {contracts.map((item, index) => {
                        return <SingleContract
                          x={item}
                          setX={setX}
                          remove={remove}
                          update={update}
                          key={index}
                          getContractFunction={getContractFunction}
                          setContracts={setContracts}
                          isUpdate={isUpdateVisible}
                          setIsUpdate={setIsUpdateVisible}
                          currentId={currentId}
                          setCurrentId={setCurrentId}
                          setUpdateContractLoading={setUpdateContractLoading}
                        />
                      })}
                      {isNextPageExists ?
                        <div className={styles.foot}>
                          <button className={cn("button-stroke-red button-small", styles.button)}
                            style={{ width: 250 }}
                            onClick={async () => {
                              if (!startLoading) {
                                page = page + 1;
                                await getContractFunction(page);
                              }
                            }}>
                            {startLoading ?
                              <Spinner size="24" color="gray" />
                              :
                              <>
                                <span>See More Contracts</span>
                                <Icon name="arrow-next" size="20"></Icon>
                              </>
                            }
                          </button>
                        </div>
                        :
                        null
                      }
                    </div>
                  </div>
                )}
              </>
            }
          </>
      }
    </div>
  );
};

export default Contract;
