import React, { useEffect, useState } from "react";
import Dropdown from "../../../../../../components/Dropdown_c";
import styles from "./DetailsCustomer.module.sass";
import Spinner from "../../../../../../utils/spinner";
import { toastConfiguration } from "../../../../../../utils/utils";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const TaxEntry = ({
  item,
  index,
  taxRatesList,
  taxList,
  settaxvalue,
  taxvalue,
  saveTaxRateHandler,
  taxIds,
  settaxIds,
}) => {
  const [currentValue, setCurrentValue] = useState("");
  const [loading, setloading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (item?.name) {
      setCurrentValue(item);
    }
  }, [item])

  const handleChangeHandler = async (value, item) => {
    if (value === "-1") {
      const newentry = { id: "", index: taxvalue + 1 };
      taxList.push(newentry);
      settaxvalue(taxvalue + 1);
      return;
    }

    if(value === "1"){
      history.push("/settings/Tax")
      return;
    }

    if (value !== "1" && value !== "-1") {
      if (taxIds?.includes(value)) {
        if (!currentValue) {
          toast.error("This tax is already selected.", toastConfiguration);
        }
        return;
      }

      if (currentValue) {
        const index = taxIds?.indexOf(currentValue?.id);
        if (index >= 0) {
          taxIds.splice(index, 1)
        }
      }

      setCurrentValue(item);
      taxIds.push(value);
      setloading(true);
      await saveTaxRateHandler(taxIds);
      setloading(false);
      settaxIds(taxIds);

    }
  };

  return (
    <>
      <div style={{ width: "100%", marginTop: "10px" }}>
        <h4 className={styles.label}>Tax {index > 0 && index + 1}</h4>
        <div className={styles.TaxRateWrap} key={index}>
          <div className={styles.TaxRateDropdown}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              setValue={() => { }}
              handleChange={handleChangeHandler}
              options={taxRatesList}
              small
              loading={loading}
              value={"Choose Tax Rate"}
              dontShowOptions={true}
            />

            <div className={styles.selectTaxRateWrap}>
              <span>{currentValue ? currentValue?.name?.length > 14 ?
                currentValue?.name?.slice(0, 14) + "..." : currentValue?.name
                : "Select"}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxEntry;
