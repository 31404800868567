import React, { createRef, useEffect, useRef } from "react";
import styles from "./QuestionnaireDisplay.module.sass";
import $ from "jquery";
import Icon from "../../../components/Icon";
import { directQuestionnaireGetDetail, directQuestionnaireSubmit } from "../../../utils/apiCallHanlder";
import { toast, ToastContainer } from "react-toastify";
import { toastConfiguration } from "../../../utils/utils";
import { useState } from "react";
import { useHistory } from "react-router";
import cn from "classnames";
import Spinner from "../../../utils/spinner";
import Modal from "../../../components/Modal";
import Card from "../../../components/Card";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");
require('formBuilder/dist/form-render.min.js')
let formRender = null;

const QuestionnaireDisplay = () => {

  const history = useHistory();

  const [contactQuestionnaire, setContactQuestionnaire] = useState(null)
  const [dataForm, setDataForm] = useState(null)
  const [contactSignatures, setContactSignatures] = useState([])
  const [showSignHere, setShowSignHere] = useState(false)
  const [signature, setSignature] = useState("")
  const [loading, setLoading] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)
  const [showText, setShowText] = useState(false)
  const [text, setText] = useState("")
  const [showPopup, setShowPopup] = useState(false);
  const [buttonClicked, setButtonClicked] = useState("");
  const fb = useRef();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const tokenId = params.get("token") || params.get("tokenid");

  var options = {
    dataType: "json",
    disableFields: [
      "autocomplete",
      "file",
      "hidden",
      "date",
      "button",
      "checkbox-group",
      "header",
      "number",
      "paragraph",
      "radio-group",
      "select",
      "text",
      "textarea",
    ],
    formData: [
      {
        type: "header",
        subtype: "h3",
        label: "Questionnaire",
      },
      {
        type: "paragraph",
        subtype: "p",
        label:
          "Please fill out the info below and hit submit. it will alllow me to prepare better for our meeting. ",
      },
      {
        type: "paragraph",
        subtype: "p",
        label:
          "If you have any questions at all, please email me at user@email.com",
      },
      {
        type: "text",
        label: "Name",
        className: "form-control",
        required: true,
      },
      {
        type: "text",
        label: "Email",
        className: "form-control",
        required: true,
      },
      {
        type: "text",
        label: "Phone",
        className: "form-control",
        required: true,
      },
      {
        type: "header",
        subtype: "h3",
        label: "Session Information",
      },
      {
        type: "date",
        label: "Session Date",
        className: "form-control",
        name: "date-1676692761668",
        required: true,
      },
      {
        type: "radio-group",
        label: "Where will the session take place?",
        name: "radio-group-1676692608763",
        values: [
          {
            label: "Option 1",
            value: "option-1",
          },
          {
            label: "Option 2",
            value: "option-2",
          },
          {
            label: "Option 3",
            value: "option-3",
          },
        ],
      },
      {
        type: "textarea",
        label: "Who will be attending this session?",
        className: "form-control",
        required: true,
      },
      {
        type: "button",
        subtype: "submit",
        label: "Submit",
        className: "form-control",
      },
    ],
  };

  useEffect(async () => {
    if (tokenId) {
      const { code, data, error, message } = await directQuestionnaireGetDetail({}, tokenId);
      if (code === 200 && data) {
        setContactQuestionnaire(data.contactQuestionnaire);
        if (data.contactQuestionnaire.answeredOn) {
          setText(` You have successfully submitted the questionnaire. If you have any questions you can contact us.`)
          setShowText(true);
        }
        let _dataForm = data.contactQuestionnaire.answeredForm || data.contactQuestionnaire.questionForm;
        if (_dataForm)
          _dataForm = JSON.parse(_dataForm);

        let options2 = {
          formData: _dataForm,
          dataType: 'json'
        }
        $(fb.current).formRender(options2)
        setDataForm(_dataForm);
        //formRender =  
      }
      else {
        toast.error(error, toastConfiguration)
        alert(error);
        history.push('/');
      }

    }
    else {
      alert('Questionnair token not found');
      toast.error('Questionnair token not found', toastConfiguration)
      history.push('/');
    }
    setLoading(false);
  }, [])

  const onSubmitHanlder = async (isCompleted) => {
    
    var response = window.JSON.stringify($(fb.current).formRender("userData"));

    const formValues = JSON.parse(response);

    for (let i = 0; i < formValues?.length; i++) {
      if (formValues[i]?.label === "Email") {
        if (!/\S+@\S+\.\S+/.test(formValues[i]?.userData[0])) {
          toast.error("please provide valid email address", toastConfiguration);
          setShowPopup(false);
          return;
        }
      }
    }

    setSaveLoading(true);

    const { data, error } = await directQuestionnaireSubmit({
      response,
      isCompleted
    }, tokenId);
    if (data) {
      if (isCompleted) {
        setShowText(true);
        setText(" You have successfully submitted the questionnaire. If you have any questions you can contact us.");
        toast.success("Your answer has been submit.", toastConfiguration);
      } else {
        toast.success("Your answer has been submit, you still can edit your response", toastConfiguration);
      }
      setShowPopup(false);
      window.scrollTo(0, 0);
    } else {
      toast.error(error || "Error in sending answer", toastConfiguration);
    }

    //e.preventDefault();
    setSaveLoading(false);
  };

  return (
    <>
      <ToastContainer></ToastContainer>

      <Modal
        visible={showPopup}
        outerClassName={styles.outerModal}
        onClose={() => setShowPopup(false)}
        children={
          <div style={{
            marginTop: 64
          }}>
            <div style={{
              backgroundColor: 'rgb(210 249 210)',
              padding: 12,
              borderRadius: 8,
            }}>
              <span style={{
                color: 'green'
              }}>Your questionnaire has been saved successfully, if you're finished click complete, otherwise click dismiss.</span>
            </div>
            <div style={{
              marginTop: 24,
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <button
                className={cn("button-stroke", styles.addBtn)}
                onClick={() => {
                  setButtonClicked('dismiss');
                  onSubmitHanlder(false)
                }}
                style={{ width: '49%' }}
              >
                {saveLoading && buttonClicked === "dismiss" ?
                  <Spinner size="24" color="gray" />
                  :
                  <>
                    Dismiss
                  </>
                }
              </button>
              <button
                className={cn("button-stroke", styles.addBtn)}
                onClick={async () => {
                  setButtonClicked('complete');
                  await onSubmitHanlder(true)
                }}
                style={{ width: '49%' }}
              >
                {saveLoading && buttonClicked === "complete" ?
                  <Spinner size="24" color="gray" />
                  :
                  <>
                    Complete
                  </>
                }
              </button>
            </div>
          </div>
        }
      />

      {loading ?
        <div style={{
          position: 'absolute',
          left: 'calc(50% - 36px)',
          top: 'calc(50% - 36px'
        }}>
          <Spinner size={64} color="gray" />
        </div>
        :
        showText &&
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '10%'
        }}>
          <div style={{
            width: "max-content",
            backgroundColor: 'rgb(210 249 210)',
            display: 'flex',
            alignItems: 'center',
            minHeight: 100,
            paddingLeft: 36,
            paddingRight: 36,
            borderRadius: 8,
            wordBreak: 'break-all',
          }}>
            <span style={{ color: 'green' }}>
              <b>&#x2713; Thankyou!</b>{text}
            </span>

          </div>
        </div>
      }
      <div style={{
        visibility: showText || loading ? 'hidden' : 'visible',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 36,
        overflowY: 'scroll',
        width: "100%"
      }}>
        <Card
          className={styles.card}
          title={"Questionnaire Form"}
          /* classTitle={cn("title-blue", styles.title)} */
        >
          <form onSubmit={(e) => {
            e.preventDefault();
            setShowPopup(true)
          }}>
            <div className={styles.customerHtmlQuestionerWrap}>
              <div id="fbEditor" ref={fb} />
            </div>
          </form>
        </Card>
      </div>
    </>
  );
};

export default QuestionnaireDisplay;
