import React, { useState } from "react";
import styles from "./Search.module.sass";
import cn from "classnames";
import Icon from "../../../../../components/Icon";
import Spinner from "../../../../../utils/spinner";
import { Redirect } from "react-router";

let typingTimer;
let doneTypingInterval = 300;

const Search = ({
  className,
  productName,
  detailsTxt,
  inputPlaceholder,
  addNewItem,
  addNewBtnTxt,
  searchNames,
  searchString,
  setSearchString,
  setShowAddAnotherProductBtn,
  addLoading,
  getProducts,
  setAddAnotherProduct,
  productSelectHandler,
  visible,
  setVisible,
  searchLoading,
  setSearchLoading,
}) => {
  const handleChange = async (event) => {
    setSearchLoading(true);
    await getProducts(searchString);
    setSearchLoading(false);
  };

  return (
    <>
      <div className={styles.detailsTxt}>{detailsTxt && detailsTxt}</div>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
        style={{ position: "relative", zIndex: "unset" }}
      >
        <div className={styles.head}>
          <button className={styles.start}>
            <Icon name='search' size='24' />
          </button>
          <input
            className={styles.input}
            type='text'
            value={searchString}
            placeholder={productName !== "" ? productName : inputPlaceholder}
            onKeyUp={() => {
              clearTimeout(typingTimer);
              typingTimer = setTimeout(handleChange, doneTypingInterval);
            }}
            onKeyDown={() => {
              clearTimeout(typingTimer);
            }}
            onChange={(event) => setSearchString(event?.target?.value)}
            onClick={async () => {
              setVisible(true);
            }}
          />
          <button
            className={styles.close}
            onClick={() => {
              setShowAddAnotherProductBtn && setShowAddAnotherProductBtn(true);
              setAddAnotherProduct && setAddAnotherProduct(false);
              setVisible && setVisible(false);
              setSearchString && setSearchString("");
            }}
          >
            {addLoading ? (
              <Spinner loading={addLoading} size={"24"} color={"blue"} />
            ) : (
              <Icon name='close-circle' size='24' />
            )}
          </button>
        </div>
        <div className={styles.body}>
          {searchLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: 70,
                alignItems: "center",
              }}
            >
              <Spinner loading={searchLoading} color={"blue"} size={"24"} />
            </div>
          ) : (
            <>
              <button onClick={addNewItem} className={styles.addCustomerBtn}>
                <Icon name='plus-circle' size='24' />
                {searchString !== "" ? "Add " + searchString : addNewBtnTxt}
              </button>

              {searchNames.length > 0 ? (
                <>
                  <div className={styles.detailsTxt}>
                    {searchString !== "" ? "Search Results" : "Recent"}
                  </div>
                  <div
                    style={
                      searchNames.length > 4
                        ? { height: 200, overflowY: "auto" }
                        : null
                    }
                  >
                    {searchNames.map((x, index) => (
                      <div key={index}>
                        <button
                          onClick={() => productSelectHandler(x)}
                          className={styles.customerBtn}
                        >
                          {x.name} &nbsp; &nbsp;
                          <span className={styles.price}>
                            {x?.recurringType
                              ? "Per " + x?.recurringType + " " + x.price
                              : x.price}
                          </span>
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Search;
