import React, { useState } from "react";
import styles from "./Search.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import {
  searchCOAList,
  searchCouterParty,
} from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { useHistory } from "react-router";
import { useEffect } from "react";

let typingTimer;
let doneTypingInterval = 600;
let count = 0;

const Search = ({
  className,
  inputClassName,
  inputPlaceholder,
  setCounterPartyName,
  counterPartyName,
  showDetails,
  setIsNew,
  isNew,
}) => {
  const [visible, setVisible] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(false);

  const [searchResponse, setSearchResponse] = useState([]);
  const [currentlySelectedName, setCurrentlySelectedName] = useState(
    counterPartyName || ""
  );
  const router = useHistory();

  useEffect(async () => {
    await getSearchResult("");
  }, []);

  const getSearchResult = async () => {
    setLoading(true);
    const { data } = await searchCouterParty(searchString);
    if (data) {
      count = 0;
      const list = data?.counterPartyList;
      for (let i = 0; i < list?.length; i++) {
        count = count + list[0]?.list?.length;
      }
      setSearchResponse(list);
    }
    setLoading(false);
  };

  return (
    <>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
      >
        <div className={styles.head}>
          <button className={styles.start}>
            <Icon name='search' size='24' />
          </button>
          <button
            className={styles.direction}
            onClick={() => setVisible(false)}
          >
            <Icon name='arrow-left' size='24' />
          </button>
          <input
            className={cn(styles.input, inputClassName)}
            type='text'
            autoComplete='nope'
            placeholder={inputPlaceholder}
            onClick={() => setVisible(true)}
            onFocus={async () => {
              if (!searchString) {
                await getSearchResult("");
              }
            }}
            value={searchString || currentlySelectedName}
            onChange={(e) => {
              if (e?.target?.value?.length < 40) {
                setSearchString(e.target.value);
                setCurrentlySelectedName("");
                if (!e.target.value) {
                  setIsNew(false);
                }
              }
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                getSearchResult();
              } else {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(getSearchResult, doneTypingInterval);
              }
            }}
            onKeyDown={() => {
              clearTimeout(typingTimer);
            }}
          />
          {isNew ? <div className={styles.newText}>NEW</div> : null}
          <button
            className={styles.close}
            onClick={() => {
              setVisible(false);
              setCounterPartyName("");
              setSearchString("");
              setSearchResponse([])
            }}
          >
            {loading ? (
              <Spinner loading={loading} size={24} color='gray' />
            ) : (
              <Icon name='close-circle' size='24' />
            )}
          </button>
        </div>
        <div className={styles.body}>
          {searchResponse?.length > 0 ? (
            <div style={count > 2 ? { height: 200, overflowY: "scroll" } : {}}>
              {searchResponse?.map((item, index) => {
                return (
                  <div key={index}>
                    <div className={styles.detailsTxt}>{item?.type}</div>
                    <div>
                      {item?.list.map((x, i) => (
                        <div key={i}>
                          <button
                            onClick={() => {
                              setSearchString("");
                              setCurrentlySelectedName(x.name);
                              showDetails(x);
                              setVisible(false);
                              setIsNew(false);
                            }}
                            className={styles.customerBtn}
                          >
                            {x.name}
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : searchString === "" ? (
            <label
              className={styles.customerBtn}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              Type to search contacts
            </label>
          ) : (
            <label
              className={styles.customerBtn}
              style={{
                display: "flex",
                marginTop: 20,
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setSearchString("");
                setCurrentlySelectedName(searchString);
                setCounterPartyName(searchString);
                setVisible(false);
                setIsNew(true);
              }}
            >
              <Icon name='add' size='24' />
              <label style={{ marginLeft: 8, cursor: "pointer" }}>
                Add {searchString}
              </label>
            </label>
          )}
        </div>
      </div>
    </>
  );
};

export default Search;
