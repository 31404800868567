import { useState } from "react";
import { use100vh } from "react-div-100vh";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Icon from "../../components/Icon";
import Image from "../../components/Image";
import { externalSignIn, switchCompany } from "../../utils/apiCallHanlder";
import Spinner from "../../utils/spinner";
import {
  getCurrentUser,
  handleLoginSignUpResponse,
  logoutHandler,
  toastConfiguration,
} from "../../utils/utils";
import withUserAuth from "../../utils/withUserAuth";
import styles from "./MultiCompany.module.sass";

// const companyDetails = [
//   {
//     logo: "https://images.unsplash.com/photo-1553835973-dec43bfddbeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
//     name: "Company Name LLC",
//     role: "Admin",
//   },
//   {
//     logo: "https://images.unsplash.com/photo-1602934445884-da0fa1c9d3b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=958&q=80",
//     name: "Company 2 LLC",
//     role: {
//       rolename: "staff",
//     },
//   },
// ];

const MultiStore = () => {
  const heightWindow = use100vh();
  const history = useHistory();
  let user = getCurrentUser();
  let hasMultiStore = localStorage.getItem("hasMultiStore");
  let userCompaniesVal = localStorage.getItem("userCompanies");
  let currentCompany = JSON.parse(localStorage.getItem("company") || {});


  if (!userCompaniesVal || userCompaniesVal == "undefined") {
    userCompaniesVal = "[]";
  }
  let userCompanies = JSON.parse(userCompaniesVal);


  const [companyList, setCompanyList] = useState(userCompanies || [])
  const [loading, setLoading] = useState(false)


  const SwitchToStore = async (company) => {

    let companyId = company.id || company.companyid;

    if (currentCompany?.companyid == companyId && companyId > 0) {
      history.push('/');
    }
    else {
      setCompanyList(list => list.map((item, i) => // Array.protptype.map creates new array
        (item.id || item.companyid) === (companyId)
          ? {                               // new object reference for updated item
            ...item,                        // shallow copy previous item state
            loading: true             // overwrite property being updated
          }
          : {                               // new object reference for updated item
            ...item,                        // shallow copy previous item state
            loading: false             // overwrite property being updated
          }
      ));

      setLoading(true);
      const signInResponse = await switchCompany(company.id || company.companyid);
      const { code, data, message } = signInResponse;
      if (code === 200 && data) {

        const customHistory = { push: (path) => { } };
        logoutHandler(customHistory);

        localStorage.setItem("stripeConnection", data.isConnected);
        localStorage.setItem("isPending", data.isPending);
        localStorage.setItem("isTestMode", data.istestmode);

        localStorage.setItem(
          "connectStatus",
          JSON.stringify(data?.connectStatus)
        );
        localStorage.setItem("currencySymbol", data.currencySymbol || "$");
        localStorage.setItem("currency", data.currency || "USD");
        handleLoginSignUpResponse(data, history, false);
        setLoading(false);
        return;
      } else {
        toast.error(message, toastConfiguration);
        setLoading(false);
      }
    }
  };

  const NewSignUp = async () => {
    history.push({
      pathname: "/sign-up-details",
      state: { openingBusinessAccount: true },
    });
  };

  return (
    <div className={styles.wrapper}>
      {loading &&
        <>
          <div className={styles.cover}></div>
          <div className={styles.loader}>
            <Spinner size="48" color="white" />
          </div>
        </>
      }
      {/* <div className={styles.info}>
        <Link className={styles.link} to="/">
          Back
        </Link>
      </div> */}
      <ToastContainer />
      <div className={styles.chooseCompanyWrap}>
        <button className={styles.close} onClick={() => {
          history.push("/")
        }}>
          <Icon name="close" size="20" />
        </button>
        <div className={styles.brandLogo}>
          <Image
            src={"/images/logo-dark.png"}
            srcDark={"/images/logo-light.png"}
            className={styles.logo}
          />
        </div>
        <div className={styles.companyWrap}>
          {companyList && companyList.length > 0 && (
            <>
              <p className={styles.label}>Choose a company</p>

              <div className={styles.companyScrollView}>
                {companyList.map((x, index) => (
                  <div
                    className={styles.company}
                    onClick={async () => await SwitchToStore(x)}
                    style={currentCompany?.companyid === x?.companyid ? {
                      backgroundColor: 'lightgray',
                      paddingTop: 10,
                      paddingBottom: 10,
                      pointerEvents: 'none',
                      paddingLeft: 10
                    } : { paddingLeft: 10 }}
                  >
                    <img
                      src={
                        x?.logoUrl
                          ? x?.logoUrl
                          : "/images/content/placeholderUser.svg"
                      }
                      alt="logo"
                      className={styles.imageLogo}
                    />
                    <div className={styles.right}>
                      <div className={styles.name}>{x.name}
                      </div>
                      <div className={styles.position}>{x.role?.rolename}</div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          <button className={styles.addNewBtn} onClick={NewSignUp}>
            <Icon name="add" size="32" />
            Signup a new company
          </button>
        </div>
      </div>
    </div>
  );
};

export default withUserAuth(MultiStore);
