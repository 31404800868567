import React, { useEffect, useState } from "react";
import Card from "../../../components/Card";
import cn from 'classnames';
import Icon from '../../../components/Icon'
import Modal from "../../../components/Modal";
import styles from "./prompts.module.sass";
import RowStyles from '../../Projects/ProjectOverview/Row/Row.module.sass'
import TextInput from "../../../components/TextInput";
import { addPrompt, deletePrompt, getPromptsList, updatePrompt } from "../../../utils/apiCallHanlder";
import Spinner from "../../../utils/spinner";
import { ToastContainer, toast } from "react-toastify";
import { toastConfiguration } from "../../../utils/utils";
import DeleteModal from "../../../components/DeleteModal";
import Search from "./Search";
import useDarkMode from "use-dark-mode";

let page = 1;
const Prompts = ({ setActiveTab, clickPredefinedPrompt }) => {
    const darkMode = useDarkMode(false);

    const [loading, setLoading] = useState(true);
    const [visibleModal, setVisibleModal] = useState(false);
    const [name, setName] = useState('');
    const [prompt, setPrompt] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [promptsList, setPromptsList] = useState([]);

    const [isNextPageExists, setIsNextPageExists] = useState(false);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [searchStr, setSearchStr] = useState('');

    useEffect(() => {
        getPrompts();
    }, [])

    const getPrompts = async (string) => {
        page === 1 ? setLoading(true) : setIsNextPageLoading(true)
        const { data } = await getPromptsList({
            pageSize: 30,
            page: page,
            searchString: string || searchStr || ''
        })

        if (data) {
            page === 1 ? setPromptsList(data?.promptList) :
                setPromptsList(promptsList?.concat(data?.promptList))
            setIsNextPageExists(data?.isNextPageExist)
        }

        page === 1 ? setLoading(false) : setIsNextPageLoading(false)
    }

    const savePrompt = async () => {
        if (saveLoading) return;
        setSaveLoading(true);

        const payload = {
            companyPromptId: selectedId || undefined,
            name,
            prompt
        }

        const { data, error } = selectedId ? await updatePrompt(payload) : await addPrompt(payload);
        if (data) {
            page = 1;
            toast.success("Prompt saved successfully", toastConfiguration);
            getPrompts();
            modalCloseHandler();
        } else {
            toast.error(error || "Error in saving prompt", toastConfiguration);
        }

        setSaveLoading(false);
    }

    const modalCloseHandler = () => {
        setSelectedId(null)
        setName('');
        setPrompt('');
        setVisibleModal(false);
    }

    const itemClickHandler = (item) => {
        setName(item.name);
        setPrompt(item.prompt);
        setSelectedId(item.companyPromptId);
        setVisibleModal(true);
    }

    const deletePromptHandler = async (id) => {
        const { data, error } = await deletePrompt(id);
        if (data) {
            page = 1;
            toast.success("Prompt deleted successfully", toastConfiguration);
            getPrompts();
        } else {
            toast.error(error || "Error in deleting Prompt", toastConfiguration);
        }
    }

    const getSearchResult = () => {
        page = 1;
        getPrompts();
    }

    const resetSearch = () => {
        page = 1;
        setSearchStr("");
        getPrompts("");
    }

    return (
        <>
            <div className={styles.title}>
                <h5
                    className={styles.backBtn}
                    onClick={() => setActiveTab(0)}
                >
                    <Icon name="arrow_backward" size="18" />
                    Back
                </h5>
            </div>
            <Modal
                outerClassName={styles.outer}
                visible={visibleModal}
                onClose={modalCloseHandler}
            >
                <Card title={`${selectedId ? 'Update' : 'Add'} Prompt`}>
                    <TextInput
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <div className={styles.fieldLabel}>Prompt</div>
                    <textarea
                        label="Prompt"
                        rows={4}
                        value={prompt}
                        className={styles.promptInput}
                        onChange={(e) => setPrompt(e.target.value)}
                    />
                    <button className='button' onClick={savePrompt} style={{ marginTop: 16, width: 84 }}>
                        {saveLoading ?
                            <Spinner size="16" color="white" />
                            :
                            "Save"
                        }
                    </button>
                </Card>
            </Modal>
            <Card
                className={styles.card}
                title="Prompt"
                head={<button className='button' onClick={() => setVisibleModal(true)}>
                    Add New
                </button>}
            >
                <Search
                    getPrompts={getPrompts}
                    setSearchString={setSearchStr}
                    searchString={searchStr}
                    getSearchResult={getSearchResult}
                    resetSearch={resetSearch}
                />
                <div className={styles.table}>
                    {loading ?
                        <div className={styles.spinner}>
                            <Spinner size={36} color={'gray'} />
                        </div>
                        :
                        <>
                            <div className={styles.row}>
                                <div className={styles.col} style={{ width: 0, padding: 0 }}></div>
                                <div className={styles.col} style={{ paddingLeft: 0 }}>Name</div>
                                <div className={styles.col}>Text</div>
                                <div className={styles.col}></div>
                            </div>
                            {promptsList?.map((item, index) => {
                                return <div
                                    key={index}
                                    className={cn(
                                        RowStyles.row,
                                        styles.pointer
                                    )}
                                    onClick={() => {
                                        clickPredefinedPrompt(item.prompt)
                                        setActiveTab(0);
                                    }}
                                >
                                    <div className={RowStyles.editOverlay} style={{ width: 0 }}></div>
                                    <div className={RowStyles.col} style={{ paddingLeft: 0 }}>
                                        <span className={RowStyles.typeTag}>Name:</span>
                                        <div className={styles.detail}>{item?.name || "-"}</div>
                                    </div>
                                    <div className={RowStyles.col}>
                                        <span className={RowStyles.typeTag}>Text:</span>
                                        <div className={styles.detail}>{item?.prompt || "-"}</div>
                                    </div>
                                    <div className={cn(RowStyles.col, styles.options)} onClick={e => e.stopPropagation()}>
                                        <div onClick={() => itemClickHandler(item)}>
                                            <Icon name="note" size="24" fill={darkMode?.value ? "#464542" : "#1A1D1F"} />
                                        </div>
                                        <DeleteModal
                                            btnTxt={<Icon name="trash" size="24" fill="#FF4900" />}
                                            btnClass=' '
                                            onClickHandler={deletePromptHandler}
                                            id={item.companyPromptId}
                                        />
                                    </div>
                                </div>
                            })}
                        </>
                    }
                </div>
                {isNextPageExists ? (
                    <div className={styles.foot}>
                        <button
                            className={cn("button-stroke-red button-small", styles.button)}
                            onClick={() => {
                                page += 1;
                                getPrompts();
                            }}
                            style={{ width: 200 }}
                        >
                            {isNextPageLoading ? (
                                <Spinner size={20} color={"white"} />
                            ) : (
                                <>
                                    <span>See More Prompts</span>
                                    <Icon name='arrow-next' size='20'></Icon>
                                </>
                            )}
                        </button>
                    </div>
                ) : null}
            </Card>
        </>
    );
};

export default Prompts;
