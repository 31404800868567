import React, { useEffect, useState } from "react";
import Card from "../../../../components/Card";
import Icon from "../../../../components/Icon";
import Modal from "../../../../components/Modal";
import styles from "./AccountInfo.module.sass";
import TextInput from "../../../../components/TextInput";
import { addNewCOA, getCreateAccountMetaData } from "../../../../utils/apiCallHanlder";
import Dropdown from "../../../../components/Dropdown";
import TaxRateDropDown from "../../../../components/TaxRateDropdown";
import COATYPEDropDown from "../../../../components/COATYPE";
import Spinner from "../../../../utils/spinner";
import { toast, ToastContainer } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import Search from "./Search";

const predefinedNames = ["Travel Expenses"]

const AccountInfo = ({
  visible,
  setVisible,
  needNewData,
  setNeedNewData,
  COAList,
  setCOAList,
  setSearchString,
  searchString,
}) => {
  const [detailType, setDetailType] = useState([]);
  const [detailId, setDetailId] = useState(null);
  const [taxRates, setTaxRates] = useState([]);
  const [taxId, setTaxId] = useState(null);
  const [type, setTypes] = useState([]);
  const [coaOptions, setCoaOptions] = useState([]);
  const [typeId, setTypeId] = useState(null);
  const [detailTypeDB, setDetailsTypeDB] = useState("");

  const [visibleSearch, setVisibleSearch] = useState(false);
  const [searchStringIn, setSearchStringIn] = useState(searchString || "");

  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [startLoading, setStartLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [subTypes, setSubTypes] = useState([]);

  const getMetaData = async (id) => {
    const { data } = await getCreateAccountMetaData(id ? { coaId: id } : {});
    if (data) {
      const minifiedTypes = data?.type?.map(item => item.name);

      setCoaOptions(data?.type);
      setDetailType(data?.detailType);
      setTaxRates(data?.taxRates);
      setTypes(minifiedTypes);
      setTaxId(data?.taxRates[0]?.companyTaxId);

      if (id) {
        setCode(data?.account?.code);
        setDescription(data?.account?.description);
        setName(data?.account?.name);
        setTypeId(data?.account?.coa2Id);
        setDetailsTypeDB(data?.account?.detailType);
      } else {
        setDetailsTypeDB(data?.detailType[0]?.name);

        const expenseType = data?.type?.filter(item => item.name === "Expenses");
        setSubTypes(expenseType);
        setName(expenseType[0]?.children[0]?.id);
        setTypeId(expenseType[0]?.name);

        setCode("");
        setDescription("");
      }
    }
    setStartLoading(false);
  };

  const saveClickHanlder = async () => {
    setSaveLoading(true);
    const { data, error } = await addNewCOA({
      code: Math.floor(1000 + Math.random() * 9000),
      name: typeId,
      coa2Id: name,
      // detailType: detailTypeDB,
      // description: description,
      // companyTaxId: taxId,
      debit: 0.0,
      credit: 0.0,
      balance: 0.0,
    });
    if (data) {
      toast.success("Account Created Successfully", toastConfiguration);
      setCOAList([]);
      setNeedNewData(!needNewData);
      setVisible(false);
      setCode(null);
      setDescription(null);
      setName(null);
      setSearchStringIn("");
      setSearchString("");
    } else {
      toast.error(error || "Error in adding account", toastConfiguration);
    }

    setSaveLoading(false);
  };
  useEffect(() => {
    if (visible && !searchString) {
      getMetaData(null);
    }
  }, [visible]);

  return (
    <div>
      <ToastContainer />
      <Modal
        visible={visible}
        id="modal-coa"
        onClose={() => {
          setVisible(false);
          setVisibleSearch(false);
          setCode(null);
          setDescription(null);
          setName(null);
        }}
        outerClassName={styles.modal}
        children={
          <Card
            /* classTitle="title-blue" */
            title="Add new account"
            className={styles.card}
            head={
              <button className="button" onClick={saveClickHanlder}>
                {saveLoading ? (
                  <Spinner loading={saveLoading} size="16" color="white" />
                ) : (
                  <>Save</>
                )}
              </button>
            }
          >
            <div className={styles.row}>
              {startLoading ? (
                <div className={styles.spinner}>
                  <Spinner loading={startLoading} size="24" color="gray" />
                </div>
              ) : (
                <>
                  <div className={styles.col}>
                    <div className={styles.fieldLabel}>Type</div>
                    <Dropdown
                      className={styles.dropdown}
                      classDropdownHead={styles.dropdownHead}
                      bodyStyles={{ width: "100%" }}
                      handleChange={(value) => {
                        const filteredSubTypes = coaOptions?.filter(item => item?.name === value);
                        if (filteredSubTypes?.length > 0) {
                          setSubTypes(filteredSubTypes);
                          setName(filteredSubTypes[0]?.children[0]?.id)
                        }
                      }}
                      value={typeId}
                      setValue={setTypeId}
                      options={type}
                    />
                  </div>
                  {/* <div className={styles.col}>
                      <TextInput
                        label="Number"
                        placeholder="1078"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </div> */}
                  <div className={styles.col}>
                    <COATYPEDropDown
                      label="Name"
                      className={styles.dropdown}
                      classDropdownHead={styles.dropdownHead}
                      value={name}
                      setValue={setName}
                      options={subTypes}
                      hideHeader
                    />
                  </div>
                  {/* <div className={styles.col}>
                      <div className={styles.fieldLabel}>Details Type</div>
                      <Dropdown
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        value={detailTypeDB}
                        setValue={setDetailsTypeDB}
                        options={detailType}
                      />
                    </div>
                    <div className={styles.col}>
                      <div className={styles.fieldLabel}>Tax Rates</div>
                      <TaxRateDropDown
                        className={styles.dropdown}
                        classDropdownHead={styles.dropdownHead}
                        value={taxId}
                        setValue={setTaxId}
                        options={taxRates}
                      />
                    </div>
                    <div className={styles.col}>
                      <TextInput
                        label="Description"
                        placeholder="Cash Management"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div> */}
                </>
              )}
            </div>
          </Card>
        }
      />
    </div>
  );
};

export default AccountInfo;
