import React, { useEffect, useState } from "react";
import Card from "../../../../components/Card";
import Modal from "../../../../components/Modal";
import TextInput from "../../../../components/TextInput";
import Dropdown from "../../../../components/COATYPE";
import SimpleDropdown from "../../../../components/Dropdown";
import styles from "./TransactionPopup.module.sass";
import documentTableStyles from '../../Documents/Table/Table.module.sass';
import documentRowStyles from '../../Documents/Table/Row/Row.module.sass';
import Icon from "../../../../components/Icon";
import { useDropzone } from "react-dropzone";
import DropdownCategory from "../../../../components/DropdownCategory";
import cn from "classnames";
import {
  accountingDocumentAttacher,
  bookTransaction,
  cancelTransaction,
  deleteTransactionAttachment,
  getAccountingDocuments,
  getDataOfPopup,
  getTaxRate,
  returnProxyGetUrl,
  saveDataOfPopup,
  searchCOAList,
  uploadFile,
} from "../../../../utils/apiCallHanlder";
import {
  dateFormatter,
  GetFileNameFromPath,
  gettingPermissionByFeature,
  isAccountingEnabled,
  toastConfiguration,
  toTitleCase,
} from "../../../../utils/utils";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../../utils/spinner";
import Search from "../SearchAccounts";

import SearchCounterParty from "../SearchContacts";
import Image from "../../../../components/Image";
import AlternativeAvatar from "../../../../components/AlternativeAvatar";
import Checkbox from "../../../../components/Checkbox";

const imageWRTExtension = {
  common: "/images/content/image.png",
  xlsx: "/images/content/xlsx.png",
  xls: "/images/content/xlsx.png",
  pdf: "/images/content/pdf.png",
};

const categories = {
  Software: [
    {
      text: "IT",
      value: "IT",
      display: "IT",
    },
    {
      text: "Software",
      value: "Software",
      display: "Software",
    },
    {
      text: "Saas",
      value: "Saas",
      display: "Saas",
    },
  ],
  Travel: [
    {
      text: "Hotel",
      value: "Hotel",
      display: "Hotel",
    },
    {
      text: "Taxi",
      value: "Taxi",
      display: "Taxi",
    },
    {
      text: "Train",
      value: "Train",
      display: "Train",
    },
  ],
  Entertainment: [
    {
      text: "Resturant",
      value: "Resturant",
      display: "Resturant",
    },
    {
      text: "Gift",
      value: "Gift",
      display: "Gift",
    },
  ],
  Others: [
    {
      text: "Other",
      value: "Other",
      display: "Other",
    },
  ],
};

let pageNo = 1;
let transactionPayload = null;
let typingTimer;
let doneTypingInterval = 600;

const TransactionPopup = ({
  showTransactionPopup,
  setShowTransactionPopup,
  props,
  taxRates,
  setLoading,
  getUpdatedTransactions,
  currentTransactionObject,
  setCurrentTransactionObject,
  getAccountSwitchTransactions,
  activeIndex,
}) => {
  let transactionRight = gettingPermissionByFeature("CanDoTrasnsactionBook");

  const [initialLoading, setInitialLoading] = useState(
    activeIndex === 2 ? true : false
  );
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [files, setFiles] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [newTransactionStart, setNewTransactionStart] = useState(0);

  const [categoryName, setCategoryName] = useState(
    categories.Software[0].value
  );

  const [counterPartyId, setCounterPartyId] = useState("");
  const [counterPartyName, setCounterPartyName] = useState("");
  const [coaId, setCOAId] = useState("");
  const [selectedTaxRate, setSelectedTaxRate] = useState(null);

  const [documentsModal, setDocumentModal] = useState(null);
  const [getDocumentsLoading, setGetDocumentsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [hasMoreDocuments, setHasMoreDocuments] = useState(false);
  const [moreDocumentsLoading, setGetMoreDocumentsLoading] = useState(false);
  const [attachLoading, setAttachLoading] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [attachmentLink, setAttachmentLink] = useState("");
  const [searchString, setSearchString] = useState("");

  const [accountName, setAccountName] = useState("");
  const [description, setDescription] = useState("");

  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [bookLoading, setBookLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [showBookingDetails, setShowBookingDetails] = useState(
    isAccountingEnabled() == true
  );

  const [imageUrl, setImageUrl] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  const [transactionAttachments, setTransactionAttachments] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [blobUrlToDisplay, setBlobUrl] = useState(false);

  const [stripeBalanceDetails, setStripeBalanceDetails] = useState(null);
  const [isBlobLoading, setIsBlobLoading] = useState(false);

  const [expenseCOAId, setExpenseCOAId] = useState(null);
  const [revenueCOAId, setRevenueCOAId] = useState(null);
  const [expenseCOAName, setExpenseCOAName] = useState(false);
  const [revenueCOAName, setRevenueCOAName] = useState(false);

  const [type, setType] = useState("");
  const [coaTypes, setCoaTypes] = useState([]);
  const [coaOptions, setCoaOptions] = useState([]);
  const [subTypes, setSubTypes] = useState([]);

  transactionPayload = currentTransactionObject || null;

  useEffect(async () => {
    if (showTransactionPopup) {
      setInitialLoading(true);
      let fetchTransactionPayload = false;
      if (currentTransactionObject.fetchTransactionPayload) {
        fetchTransactionPayload = true;
      }

      const types = await getCOAOptions();
      const { data } = await getDataOfPopup(
        {
          id:
            currentTransactionObject?.stripeTransactionId ||
            currentTransactionObject?.id,
        },
        true,
        fetchTransactionPayload
      );
      if (data) {
        const tx = data?.transaction;
        const selectedCOA = types?.filter(item => item?.children?.filter(i => i.id === tx.companyCoaccountId)?.length > 0);

        if (selectedCOA?.length > 0) {
          setType(selectedCOA[0]?.name);
          setSubTypes(selectedCOA);
        }

        tx.companyCoaccountId && setCOAId(tx.companyCoaccountId);
        setCounterPartyId(tx?.contactId);
        setCounterPartyName(tx?.contactName);
        setImageUrl(tx?.contactImageUrl);
        setEmail(tx?.contactEmail);
        setMobile(tx?.contactMobile);

        setStripeBalanceDetails(data?.stripeBalanceDetails);
        setExpenseCOAId(data?.stripeBalanceDetails?.expenseCompanyCOAccountId);
        setRevenueCOAId(data?.stripeBalanceDetails?.incomeCompanyCOAccountId);
        setExpenseCOAName(data?.stripeBalanceDetails?.expenseCompanyCOAccountName);
        setRevenueCOAName(data?.stripeBalanceDetails?.incomeCompanyCOAccountName);


        if (attachmentLink) {
          getBlobUrl(attachmentLink, false, data?.stripeBalanceDetails);
        } else if (tx?.attachments?.length > 0) {
          getBlobUrl(tx?.attachments?.length > 0 ? tx?.attachments[0] : null, false, data?.stripeBalanceDetails);
        }

        setAccountName(tx?.companyCoaccountName);
        setDescription(tx?.notes || data?.stripeBalanceDetails?.description);
        setCategoryName(tx?.categoryName || categories.Software[0].value);



        if (
          data?.transactionPayload?.accountId == "stripebalance" ||
          currentTransactionObject?.accountId == "stripebalance" ||
          !isAccountingEnabled()
        ) {
          transactionRight = 0;
        }
        let showBookingDetails2 = isAccountingEnabled() == true;

        if (data?.stripeBalanceDetails) {
          if (isPayoutTransaction(data?.stripeBalanceDetails)) {
            showBookingDetails2 = false;
          }
        }
        setShowBookingDetails(showBookingDetails2);

        if (fetchTransactionPayload) {
          transactionPayload = {
            ...data.transaction,
            ...data?.transactionPayload,
          };
        } else {
          transactionPayload = {
            ...data.transaction,
            ...currentTransactionObject,
            accountingStatus: data.transaction.accountingStatus || currentTransactionObject.accountingStatus
          };
        }
        setCurrentTransactionObject(transactionPayload);
      }
      setInitialLoading(false);
      setLoading && setLoading(false);
      setSaveLoading(false);
      setBookLoading(false);
      setCancelLoading(false);
    }
  }, [showTransactionPopup, newTransactionStart]);

  const getCOAOptions = async () => {
    const { data } = await searchCOAList('');
    if (data) {
      const minifiedTypes = data?.list?.map(item => item.name);
      const expensesOptions = data?.list?.filter(item => item.name === "Expenses") || [];

      setCoaOptions(data?.list);
      setCoaTypes(minifiedTypes);
      setSubTypes(expensesOptions);
      setType(expensesOptions[0]?.name);
      setCOAId(expensesOptions[0]?.children[0]?.id);
    }

    return (data?.list || [])
  }

  const { getRootProps } = useDropzone({
    noDragEventsBubbling: true,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        imageUploadHandler(acceptedFiles[0]);
      }
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const saveButtonClickHandler = async (transactionId) => {
    if (saveLoading) {
      return;
    }
    setSaveLoading(true);

    let selectedCounterPartyId = counterPartyId;
    if (isNew) {
      selectedCounterPartyId = null;
    }

    const signInResponse = await saveDataOfPopup({
      id: transactionId,

      contactId: selectedCounterPartyId,
      DoCreateCounterParty: isNew ? true : false,
      contactName: counterPartyName,
      contactImageUrl: imageUrl || "",
      categoryName: categoryName,
      contactEmail: email || "",
      contactMobile: mobile || "",

      coaId: coaId || 0,
      companyTaxId: selectedTaxRate || 0,

      notes: description,
      attachments: transactionAttachments,
      data: transactionPayload,
    });

    const { data, message, error } = signInResponse;
    if (data) {
      toast && toast.success(message, toastConfiguration);
      await ResetOnSave();
    } else {
      toast.error(error, toastConfiguration);
    }

    setSaveLoading(false);
  };

  const bookButtonClickHandler = async (transactionId, payloadId) => {
    let stripeTransactionId = transactionId || payloadId;
    let id = transactionId || payloadId;

    if (isNaN(id)) {
      id = 0;
    }

    if (!coaId && !stripeBalanceDetails?.isCharge) {
      toast.error("Please select any chart of account", toastConfiguration);
      return;
    }

    if (isNaN(coaId)) {
      toast.error("Please select any chart of account", toastConfiguration);
      return;
    }
    
    if (bookLoading) {
      return;
    }
    setBookLoading(true);

    const { data, message, error } = await bookTransaction({
      id,
      stripeTransactionId,
      CounterPartyId: counterPartyId,
      DoCreateCounterParty: isNew ? true : false,
      CounterPartyName: counterPartyName,
      coaId: coaId,
      description: description,
      companyTaxId: 0,
      attachments: transactionAttachments,
      CounterPartyEmail: email,
      CounterPartyMobile: mobile,
      CounterPartyImageUrl: imageUrl,
      transactionDate: currentTransactionObject?.transactionDate || currentTransactionObject?.created,
      accountId: currentTransactionObject?.accountId,
      accountType: currentTransactionObject?.accountType,
      incomeCoaId: stripeBalanceDetails ? revenueCOAId : undefined,
      expenseCoaId: stripeBalanceDetails ? expenseCOAId : undefined
    });
    if (data) {
      toast && toast.success(message, toastConfiguration);
      await ResetOnSave();
    } else {
      toast.error("Error in booking transaction: " + error, toastConfiguration);
    }
    setBookLoading(false);
  };

  const cancelButtonClickHandler = async (transactionId) => {
    if (cancelLoading) {
      return;
    }
    setCancelLoading(true);
    const { data, message, error } = await cancelTransaction({
      id: transactionId,
    });
    if (data) {
      await ResetOnSave();
      toast && toast.success(message, toastConfiguration);
    } else {
      toast.error("Error in cancel bookkeeping", toastConfiguration);
    }
    setCancelLoading(false);
  };

  const ResetOnSave = async () => {
    setDescription("");
    setTransactionAttachments([]);

    setIsNew(false);
    setSaveLoading(false);
    setBlobUrl("");
    setTransactionAttachments([]);
    setShowTransactionPopup(false);
    setLoading && setLoading(true);
    getAccountSwitchTransactions && await getAccountSwitchTransactions();
    getUpdatedTransactions && await getUpdatedTransactions(null, false, []);
    setLoading && setLoading(false);
  };

  const imageUploadHandler = async (file) => {
    setImageUploadLoading(true);
    setSelectedImage(file);
    const formData = new FormData();
    formData.append("", file);
    const { data, error } = await uploadFile(formData);

    if (data) {
      const imageUrl = data?.file?.url;

      if (imageUrl) {
        getBlobUrl(imageUrl, true);
      }
    }
    else {
      toast.error(error || "Error in uploading file", toastConfiguration);
    }
    setImageUploadLoading(false);
  };

  const getAccountStatus = (status) => {
    if (status == "Booked") return <p className={styles.booked}> {status}</p>;
    else return <p className={styles.notBooked}> Not booked</p>;
  };

  const deleteImageHandler = async (e, attachmentLink) => {
    setDeleteLoading(true);
    e.stopPropagation();
    setTransactionAttachments(
      transactionAttachments.filter(function (item) {
        return item !== attachmentLink;
      })
    );
    let data = { ...transactionPayload, attachments: [] };
    transactionPayload = data;
    setDeleteLoading(false);
  };

  const getBlobUrl = async (url, doConcat, _stripeBalanceDetails) => {
    const fileNameObj = GetFileNameFromPath(url, true);
    if (fileNameObj?.extension === "pdf" || (fileNameObj && _stripeBalanceDetails?.isCharge)) {
      setIsBlobLoading(true);
      let downLoadUrl = url;
      if (fileNameObj?.extension !== "pdf" && _stripeBalanceDetails?.isCharge) {
        downLoadUrl = returnProxyGetUrl(downLoadUrl);
      }
      var logoVideoXhr = new XMLHttpRequest();
      logoVideoXhr.open("GET", downLoadUrl, true);
      logoVideoXhr.responseType = "arraybuffer";

      logoVideoXhr.send();

      logoVideoXhr.onload = function (oEvent) {
        var blob = new Blob([oEvent.target.response], {
          type: "application/pdf",
        });
        var blobURL = URL.createObjectURL(blob);
        setBlobUrl(blobURL);
        doConcat ? setTransactionAttachments([...(transactionAttachments || []), url]) :
          setTransactionAttachments([url]);
        setAttachmentLink("");
        setIsBlobLoading(false)
      }
    } else {
      doConcat ? setTransactionAttachments([...(transactionAttachments || []), url]) :
        setTransactionAttachments([url]);
    }
  };

  const getDocuments = async (pageNumber = 1, search = "") => {
    pageNumber === 1 && setGetDocumentsLoading(true);
    const { data } = await getAccountingDocuments({
      page: pageNumber,
      pageSize: 10,
      searchString: search,
      onlyUnattached: true
    })

    if (data) {
      if (pageNumber === 1) {
        setDocuments(data?.documents)
      } else {
        setDocuments([...(documents || []), ...(data?.documents || [])])
      }
      setHasMoreDocuments(data?.hasNextPage);
    }
    pageNumber === 1 && setGetDocumentsLoading(false);
  }

  const onChangeHandler = (id, link) => {
    if (selectedDocument?.includes(id)) {
      setSelectedDocument([]);
      setAttachmentLink("");
    } else {
      setSelectedDocument([id])
      setAttachmentLink(link);
    }
  }

  const attachHandler = async () => {
    if (selectedDocument?.length < 1) {
      toast.error('Please select at least one document', toastConfiguration);
      return;
    }
    setAttachLoading(true);
    const { data } = await accountingDocumentAttacher({
      transactionIds: [
        currentTransactionObject?.stripeTransactionId ||
        currentTransactionObject?.id
      ],
      attachmentLink: attachmentLink,
      docId: selectedDocument[0]
    });

    if (data) {
      toast.success("Transaction(s) attached to document successfully", toastConfiguration);
      setDocumentModal(false);
      setShowTransactionPopup(true)
    } else {
      toast.error("Error in attaching transaction(s) to document", toastConfiguration);
    }

    setAttachLoading(false);
  }

  const getSearchResult = () => {
    getDocuments(1, searchString);
  }

  const showBookedOrCancelledTransaction = (currentTx, stripeTx) => {
    if (stripeTx && stripeTx.isAllowedForBooking && stripeTx.isCharge) {
      return true;
    }
    else if (stripeTx && stripeTx.isAllowedForBooking && stripeTx.isPayout) {
      return true;
    }
    else if (stripeTx && stripeTx.isAllowedForBooking == false) {
      return false;
    }
    return currentTx?.accountType !== "Stripe Balance";
  }

  const isChargeTransaction = (stripeTx) => {
    if (stripeTx) {
      if (stripeTx.isCharge) {
        return true;
      }
    }
    return false;
  };

  const isPayoutTransaction = (stripeTx) => {
    if (stripeTx) {
      if (stripeTx.isPayout) {
        return true;
      }
    }
    return false;
  };
  const showRelatedTransaction = (item) => {
    setDescription("");
    setIsNew(false);
    setSaveLoading(false);
    setBlobUrl("");
    setTransactionAttachments([]);
    setAttachmentLink("");

    setCurrentTransactionObject(item);
    setNewTransactionStart((newTransactionStart ?? 0) + 1);
  };
  return (
    <>
      <ToastContainer />
      <>
        <Modal
          visible={showTransactionPopup}
          onClose={() => {
            setBlobUrl("");
            setTransactionAttachments([]);
            setShowTransactionPopup(false);
          }}
          outerClassName={styles.modalOuter}
          children={
            <>
              {initialLoading ?
                <div className={styles.loader}>
                  <Spinner size='48' color='gray' />
                </div>
                :
                <div className={styles.row}>
                  {showBookingDetails && (
                    <div className={styles.col}>
                      <Card
                        title={stripeBalanceDetails?.isCharge ? 'Stripe Payment' : 'Details'}
                        /* classTitle="title-blue" */
                        className={styles.card}
                      >
                        <div >
                          <div
                            style={{
                              pointerEvents:
                                (currentTransactionObject?.accountingStatus ===
                                  "Booked" || currentTransactionObject?.accountingStatus ===
                                  "Confirmed")
                                  ? "none"
                                  : "unset",
                            }}
                          >
                            {stripeBalanceDetails?.isCharge ?
                              <div className={styles.metaWrapper} style={{
                                marginTop: 24,
                                pointerEvents:
                                  currentTransactionObject?.accountingStatus !==
                                    "Confirmed"
                                    ? "unset"
                                    : "none",
                              }}>
                                <div className={styles.singleMeta} style={{ borderBottom: 'unset' }}>
                                  <p className={styles.label}>Income Category</p>
                                  <Search
                                    className={styles.search}
                                    inputPlaceholder='Choose the account'
                                    showDetails={(x) => setRevenueCOAId(x?.id)}
                                    accountName={revenueCOAName}
                                  />
                                </div>

                                <div className={styles.singleMeta} style={{ borderBottom: 'unset' }}>
                                  <p className={styles.label}>Expense Category</p>
                                  <Search
                                    className={styles.search}
                                    inputPlaceholder='Choose the account'
                                    showDetails={(x) => setExpenseCOAId(x?.id)}
                                    accountName={expenseCOAName}
                                  />
                                </div>

                                <div className={styles.singleMeta}>
                                  <p className={styles.label}>Description</p>
                                  <p className={styles.text}>
                                    {description || "-"}
                                  </p>
                                </div>
                              </div> :
                              <>
                                <div
                                  className={styles.fieldWrap}
                                  style={{ marginTop: 8 }}
                                >
                                  {transactionRight === 0 ? (
                                    <DropdownCategory
                                      label={"Category"}
                                      className={styles.dropdown}
                                      classDropdownHead={styles.dropdownHead}
                                      value={categoryName}
                                      showSearch={"true"}
                                      setValue={setCategoryName}
                                      options={categories}
                                      taxcodes={categories}
                                    />
                                  ) : (
                                    <>
                                      <SimpleDropdown
                                        label={"Type"}
                                        className={styles.dropdown}
                                        classDropdownHead={styles.dropdownHead}
                                        value={type}
                                        setValue={setType}
                                        options={coaTypes}
                                        handleChange={(value) => {
                                          const filteredSubTypes = coaOptions?.filter(item => item?.name === value);
                                          if (filteredSubTypes?.length > 0) {
                                            setSubTypes(filteredSubTypes);
                                            setCOAId(filteredSubTypes[0]?.children[0]?.id)
                                          }
                                        }}
                                        bodyStyles={{ width: '100%' }}
                                      />
                                      <div style={{ marginTop: 12 }}>
                                        <Dropdown
                                          label="Chart of Account"
                                          className={styles.dropdown}
                                          classDropdownHead={styles.dropdownHead}
                                          value={coaId}
                                          setValue={setCOAId}
                                          options={subTypes}
                                          hideHeader
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div style={{ marginBottom: 8 }}>
                                  {counterPartyId || email || counterPartyName ? (
                                    <><div className={styles.fieldLabel}>Contact</div>
                                    <div className={styles.infoWrapper}>

                                      <div className={styles.left}>
                                        {imageUrl ? (
                                          <Image
                                            src={
                                              imageUrl || "/images/content/avatar.jpg"
                                            }
                                            srcDark={
                                              imageUrl || "/images/content/avatar.jpg"
                                            }
                                            className={styles.pic}
                                          />
                                        ) : (
                                          <AlternativeAvatar
                                            name={counterPartyName}
                                            className={"customer-info"}
                                          />
                                        )}
                                        <button
                                          onClick={() => {
                                            setImmediate("");
                                            setCounterPartyId(null);
                                            setCounterPartyName(null);
                                            setEmail(null);
                                            setMobile(null);
                                            setIsNew(false);
                                          }}
                                          className={styles.deleteBtn}
                                        >
                                          <Icon
                                            name='trash'
                                            size='20'
                                            fill='#FF4900'
                                          />
                                        </button>
                                      </div>
                                      <div className={styles.infoWrap}>
                                        <div className={styles.info}>
                                          <p className={styles.label}>Name</p>
                                          <p className={styles.txt}>
                                            {counterPartyName}
                                          </p>
                                        </div>
                                        <div className={styles.info}>
                                          <p className={styles.label}>Email</p>
                                          <p className={styles.txt}>{email || ""}</p>
                                        </div>
                                        <div className={styles.info}>
                                          <p className={styles.label}>Mobile</p>
                                          <p className={styles.txt}>{mobile || ""}</p>
                                        </div>
                                      </div>
                                    </div>
                                    </> ) : (
                                    <div className={styles.fieldWrap}>
                                      <div className={styles.fieldLabel}>
                                        Contact
                                      </div>
                                      <SearchCounterParty
                                        className={styles.search}
                                        counterPartyName={counterPartyName}
                                        inputPlaceholder='Name of contact'
                                        setIsNew={setIsNew}
                                        setCounterPartyName={setCounterPartyName}
                                        isNew={isNew}
                                        showDetails={(x) => {
                                          setImageUrl(x?.imageUrl);
                                          setEmail(x?.email);
                                          setMobile(x?.mobile);
                                          setCounterPartyId(x?.id);
                                          setCounterPartyName(x?.name);
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className={styles.fieldWrap}>
                                  <div className={styles.fieldLabel}>Description</div>
                                  <TextInput
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                  />
                                </div>
                              </>
                            }
                          </div>


                          {(currentTransactionObject?.accountingStatus ===
                            "Booked" || currentTransactionObject?.accountingStatus ===
                            "Confirmed") &&
                            transactionRight === 1 &&
                            showBookedOrCancelledTransaction(currentTransactionObject, stripeBalanceDetails) && (
                              <div
                                style={{
                                  marginTop: 11,
                                  pointerEvents: "none !important",
                                }}
                              >
                                <button
                                  className='button-stroke-red'
                                  onClick={async () => {
                                    if (cancelLoading) {
                                      return;
                                    }
                                    setCancelLoading(true);
                                    await cancelButtonClickHandler(
                                      currentTransactionObject?.stripeTransactionId ||
                                      currentTransactionObject?.id ||
                                      transactionPayload.id
                                    );
                                  }}
                                  style={{ width: 250 }}
                                >
                                  {cancelLoading ? (
                                    <Spinner size='24' color='gray' />
                                  ) : (
                                    <>

                                      {(stripeBalanceDetails && stripeBalanceDetails.isCharge) ? 'Cancel Confirmation' : 'Cancel Bookkeeping Entry'}
                                    </>
                                  )}
                                </button>
                              </div>
                            )}

                          <>
                            {/* {(currentTransactionObject?.accountingStatus !==
                              "Booked" ||
                              currentTransactionObject?.accountType ===
                              "Stripe Balance") && (
                                <div>
                                  <button
                                    className='button'
                                    onClick={() =>
                                      saveButtonClickHandler(
                                        currentTransactionObject?.stripeTransactionId ||
                                        transactionPayload.id
                                      )
                                    }
                                    style={{
                                      backgroundColor: "#490000",
                                    }}
                                  >
                                    {saveLoading ? (
                                      <Spinner size='24' color='white' />
                                    ) : (
                                      <>Save</>
                                    )}
                                  </button>
                                </div>
                              )} */}
                            {currentTransactionObject?.accountingStatus !==
                              "Booked" &&
                              currentTransactionObject?.accountingStatus !==
                              "Confirmed" &&
                              showBookedOrCancelledTransaction(currentTransactionObject, stripeBalanceDetails) &&
                              transactionRight === 1 && (
                                <div style={{ marginTop: 10 }}>
                                  <button
                                    className='button'
                                    onClick={() => {
                                      bookButtonClickHandler(
                                        currentTransactionObject?.stripeTransactionId ??
                                        currentTransactionObject?.id,
                                        transactionPayload?.stripeTransactionId ??
                                        transactionPayload?.id
                                      );
                                    }}
                                    style={{ width: 180 }}
                                  >
                                    {bookLoading ? (
                                      <Spinner size='24' color='white' />
                                    ) : (
                                      <>
                                        {(stripeBalanceDetails && stripeBalanceDetails.isCharge) ? 'Confirm' : 'Book Transaction'}
                                      </>
                                    )}
                                  </button>
                                </div>
                              )}
                          </>

                        </div>
                      </Card>
                    </div>
                  )}
                  <div className={cn(styles.col, isPayoutTransaction(stripeBalanceDetails) ? styles.col2 : '')}>
                    {isPayoutTransaction(stripeBalanceDetails) && <Card
                      title='Stripe Payout'
                      className={styles.card}
                    >
                      <div className={styles.metaWrapper}>
                        <div className={styles.singleMeta}>
                          <p className={styles.label}>To/From</p>
                          <p className={styles.text}>
                            {currentTransactionObject?.description || "-"}{" "}
                          </p>
                        </div>
                        <div className={styles.singleMeta}>
                          <p className={styles.label}>Gross</p>
                          <p className={styles.text}>
                            {currentTransactionObject?.amountF ||
                              currentTransactionObject?.amount}
                          </p>
                        </div>
                        <div className={styles.singleMeta}>
                          <p className={styles.label}>Fee</p>
                          <p className={styles.text}>
                            {transactionPayload?.feeF || currentTransactionObject?.fee || "$0.00"}{" "}
                          </p>
                        </div>
                        <div className={styles.singleMeta}>
                          <p className={styles.label}>Total</p>
                          <p className={styles.text}>
                            {transactionPayload?.netF ||
                              currentTransactionObject?.amountF ||
                              currentTransactionObject?.amount} {" "}
                          </p>
                        </div>
                        <div className={styles.singleMeta}>
                          <p className={styles.label}>Date</p>
                          <p className={styles.text}>
                            {(currentTransactionObject?.transactionDate ||
                              currentTransactionObject?.created) ?
                              dateFormatter(
                                currentTransactionObject?.transactionDate ||
                                currentTransactionObject?.created
                              ) : "-"}
                          </p>
                        </div>
                        <div className={styles.singleMeta}>
                          <p className={styles.label}>Payout Account</p>
                          <p className={styles.text}>
                            {stripeBalanceDetails?.bankAccountName || "-"} {" "}
                          </p>
                        </div>
                        <div className={styles.singleMeta}>
                          <p className={styles.label}>Payout ID</p>
                          <p className={styles.text}>
                            {currentTransactionObject?.stripeTransactionId ||
                              currentTransactionObject?.id}
                          </p>
                        </div>

                        {stripeBalanceDetails?.linkedAccountingStatus && <div className={styles.singleMeta}>
                          <p className={styles.label}>Accounting Status</p>
                          <p className={styles.text}>
                            {stripeBalanceDetails?.linkedAccountingStatus}
                          </p>
                        </div>}

                        {stripeBalanceDetails?.linkedAccountingStatus ===
                          "Booked" &&
                          transactionRight === 1 &&
                          showBookedOrCancelledTransaction(currentTransactionObject, stripeBalanceDetails) && (
                            <div
                              style={{
                                marginTop: 11,
                                pointerEvents: "none !important",
                              }}
                            >
                              <button
                                className='button-stroke-red'
                                onClick={async () => {
                                  if (cancelLoading) {
                                    return;
                                  }
                                  setCancelLoading(true);
                                  await cancelButtonClickHandler(
                                    currentTransactionObject?.stripeTransactionId ||
                                    currentTransactionObject?.id ||
                                    transactionPayload.id
                                  );
                                }}
                                style={{ width: 250 }}
                              >
                                {cancelLoading ? (
                                  <Spinner size='24' color='gray' />
                                ) : (
                                  <>
                                    Cancel Bookkeeping Entry
                                  </>
                                )}
                              </button>
                            </div>
                          )}
                        {stripeBalanceDetails?.linkedAccountingStatus !==
                          "Booked" &&
                          showBookedOrCancelledTransaction(currentTransactionObject, stripeBalanceDetails) &&
                          transactionRight === 1 && (
                            <div style={{ marginTop: 10 }}>
                              <button
                                className='button'
                                onClick={() => {
                                  bookButtonClickHandler(
                                    currentTransactionObject?.stripeTransactionId ??
                                    currentTransactionObject?.id,
                                    transactionPayload?.stripeTransactionId ??
                                    transactionPayload?.id
                                  );
                                }}
                                style={{ width: 180 }}
                              >
                                {bookLoading ? (
                                  <Spinner size='24' color='white' />
                                ) : (
                                  <>
                                    Book Transaction
                                  </>
                                )}
                              </button>
                            </div>
                          )}

                      </div>
                    </Card>}

                    {!isPayoutTransaction(stripeBalanceDetails) && <Card
                      title='Metadata'
                      /* classTitle="title-red" */
                      className={styles.card}
                    >
                      <div className={styles.metaWrapper}>
                        {!isChargeTransaction(stripeBalanceDetails) && <>
                          <div className={styles.singleMeta}>
                            <p className={styles.label}>Transaction ID</p>
                            <p className={styles.text}>
                              {currentTransactionObject?.stripeTransactionId ||
                                currentTransactionObject?.id}
                            </p>
                          </div>
                          <div className={styles.singleMeta}>
                            <p className={styles.label}>Transaction Date</p>
                            <p className={styles.text}>
                              {(currentTransactionObject?.transactionDate ||
                                currentTransactionObject?.created) ?
                                dateFormatter(
                                  currentTransactionObject?.transactionDate ||
                                  currentTransactionObject?.created
                                ) : "-"}
                            </p>
                          </div></>}
                        {stripeBalanceDetails?.isCharge && stripeBalanceDetails?.from && (
                          <div className={styles.singleMeta}>
                            <p className={styles.label}>From</p>
                            <p className={styles.text}>
                              {stripeBalanceDetails?.from}
                            </p>
                          </div>
                        )}

                        <div className={styles.singleMeta}>
                          <p className={styles.label}>Amount</p>
                          <p className={styles.text}>
                            {currentTransactionObject?.amountF ||
                              currentTransactionObject?.amount}
                          </p>
                        </div>

                        {stripeBalanceDetails?.isCharge && (
                          <>
                            <div className={styles.singleMeta}>
                              <p className={styles.label}>Fee</p>
                              <p className={styles.text}>
                                {transactionPayload?.feeF || currentTransactionObject?.fee || "-"}{" "}
                              </p>
                            </div>

                            <div className={styles.singleMeta}>
                              <p className={styles.label}>Net</p>
                              <p className={styles.text}>
                                {transactionPayload?.netF}{" "}
                              </p>
                            </div>
                            {stripeBalanceDetails?.sentOn && <div className={styles.singleMeta}>
                              <p className={styles.label}>Date Sent</p>
                              <p className={styles.text}>
                                {dateFormatter(
                                  stripeBalanceDetails?.sentOn
                                ) ?? "-"}
                              </p>
                            </div>}
                            {stripeBalanceDetails?.paidOn && <div className={styles.singleMeta}>
                              <p className={styles.label}>Date Received</p>
                              <p className={styles.text}>
                                {dateFormatter(
                                  stripeBalanceDetails?.paidOn
                                ) ?? "-"}
                              </p>
                            </div>}
                            {stripeBalanceDetails?.paymentMethod && <div className={styles.singleMeta}>
                              <p className={styles.label}>Payment Method</p>
                              <p className={styles.text}>
                                {stripeBalanceDetails?.paymentMethod}
                              </p>
                            </div>}
                          </>
                        )}
                        {isChargeTransaction(stripeBalanceDetails) && <>
                          <div className={styles.singleMeta}>
                            <p className={styles.label}>Transaction ID</p>
                            <p className={styles.text}>
                              {currentTransactionObject?.stripeTransactionId ||
                                currentTransactionObject?.id}
                            </p>
                          </div>
                        </>}


                        {!isChargeTransaction(stripeBalanceDetails) && <>

                          {stripeBalanceDetails?.from ? (<></>) :
                            (<div className={styles.singleMeta}>
                              <p className={styles.label}>To/From</p>
                              <p className={styles.text}>
                                {currentTransactionObject?.description || "-"}{" "}
                              </p>
                            </div>)}
                          <div className={styles.singleMeta}>
                            <p className={styles.label}>Account</p>
                            <p className={styles.text}>
                              {currentTransactionObject?.accountName ||
                                currentTransactionObject?.accountType ||
                                currentTransactionObject?.transactionDetail || "-"}
                            </p>
                          </div>


                          {true ? (
                            <>
                              {currentTransactionObject?.accountType && (
                                <div className={styles.singleMeta}>
                                  <p className={styles.label}>Type</p>
                                  <p className={styles.text}>
                                    {toTitleCase(
                                      currentTransactionObject?.accountType
                                    )}
                                  </p>
                                </div>
                              )}

                              {transactionPayload?.authorizationMethod && (
                                <div className={styles.singleMeta}>
                                  <p className={styles.label}>
                                    Authorization method
                                  </p>
                                  <p className={styles.text}>
                                    {toTitleCase(
                                      transactionPayload?.authorizationMethod
                                    )}
                                  </p>
                                </div>
                              )}
                              {transactionPayload?.status && (
                                <div className={styles.singleMeta}>
                                  <p className={styles.label}>
                                    Transaction Status{" "}
                                  </p>
                                  <p className={styles.text}>
                                    {toTitleCase(transactionPayload?.status)}
                                  </p>
                                </div>
                              )}

                              {transactionPayload?.cardTreasuryIssuingAuthStatus && (
                                <div className={styles.singleMeta}>
                                  <p className={styles.label}>
                                    Treasury Issuing Authorization Status{" "}
                                  </p>
                                  <p className={styles.text}>
                                    {toTitleCase(
                                      transactionPayload?.cardTreasuryIssuingAuthStatus
                                    )}
                                  </p>
                                </div>
                              )}

                              {transactionPayload?.cardTreasuryReceivedDebitStatus && (
                                <div className={styles.singleMeta}>
                                  <p className={styles.label}>
                                    Treasury Received Debit Status{" "}
                                  </p>
                                  <p className={styles.text}>
                                    {toTitleCase(
                                      transactionPayload?.cardTreasuryReceivedDebitStatus
                                    )}
                                  </p>
                                </div>
                              )}

                              {transactionPayload?.statementDescriptor && (
                                <div className={styles.singleMeta}>
                                  <p className={styles.label}>
                                    Statement Descriptor{" "}
                                  </p>
                                  <p className={styles.text}>
                                    {toTitleCase(
                                      transactionPayload?.statementDescriptor
                                    )}
                                  </p>
                                </div>
                              )}

                              {transactionPayload?.merchantCountry && (
                                <div className={styles.singleMeta}>
                                  <p className={styles.label}>Merchant Country </p>
                                  <p className={styles.text}>
                                    {transactionPayload?.merchantCountry}
                                  </p>
                                </div>
                              )}

                              {transactionPayload?.merchantCountry && (
                                <div className={styles.singleMeta}>
                                  <p className={styles.label}>Merchant Currency </p>
                                  <p className={styles.text}>
                                    {transactionPayload?.merchantCurrency?.toUpperCase()}
                                  </p>
                                </div>
                              )}
                            </>
                          ) : null}
                        </>
                        }
                      </div>
                    </Card>}
                  </div>
                  <div className={cn(styles.col, isPayoutTransaction(stripeBalanceDetails) ? styles.col2 : '')}>
                    {isPayoutTransaction(stripeBalanceDetails) && <Card
                      title='Related Payments'
                      className={styles.card}>
                      {stripeBalanceDetails?.relatedTransactions?.length > 0 && (
                        <div style={{ marginTop: 16 }}>
                          {stripeBalanceDetails?.relatedTransactions?.map((item, index) => {
                            return <div className={styles.infoWrapper} style={{ cursor: 'pointer' }} onClick={() => showRelatedTransaction(item)} key={index}>
                              <div className={styles.infoWrap}>
                                <div className={styles.info}>
                                  <p className={styles.label}>Gross Amount</p>
                                  <p className={styles.txt}>{item?.amount || "-"}</p>
                                </div>
                                {item?.customerName && <div className={styles.info}>
                                  <p className={styles.label}>Customer</p>
                                  <p className={styles.txt}>
                                    {item?.customerName}
                                  </p>
                                </div>}
                                <div className={styles.info}>
                                  <p className={styles.label}>Invoice</p>
                                  <p className={styles.txt}>
                                    {item?.invoiceNumber || item?.description || "-"}
                                  </p>
                                </div>
                                <div className={styles.info}>
                                  <p className={styles.label}>Date</p>
                                  <p className={styles.txt}>{item?.created ? dateFormatter(item?.created) : "-"}</p>
                                </div>
                                <div className={styles.info}>
                                  <p className={styles.label}>ID</p>
                                  <p className={styles.txt}>{item?.id}</p>
                                </div>
                                {item?.accountingStatus && <div className={styles.info}>
                                  <p className={styles.label}>Status</p>
                                  <p className={styles.txt}>{item?.accountingStatus}</p>
                                </div>}
                              </div>
                            </div>
                          })}
                        </div>
                      )}
                      {stripeBalanceDetails?.warning && <span className={styles.warning}>{stripeBalanceDetails?.warning}</span>}
                    </Card>}
                    {!isPayoutTransaction(stripeBalanceDetails) && <Card
                      title='Attachment'
                      /* classTitle="title-yellow" */
                      className={styles.card}
                    >
                      {imageUploadLoading ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div style={{ width: "70%" }}>
                            {selectedImage?.name?.slice(0, 20)}...
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "30%",
                            }}
                          >
                            <Spinner
                              size='24px'
                              color='gray'
                              loading={imageUploadLoading}
                            />
                          </div>
                        </div>
                      ) : isBlobLoading ?
                        <div>
                          Pdf Loading ...
                        </div> :
                        transactionAttachments < 1 ? (
                          <div>
                            {currentTransactionObject?.accountingStatus ===
                              "Booked" || currentTransactionObject?.accountingStatus ===
                              "Confirmed" ? (
                              ""
                            ) : (
                              <>
                                <div {...getRootProps({ className: "dropzone" })}>
                                  <div className={styles.dropzoneWrap}>
                                    <p className={styles.dropzone}>
                                      {" "}
                                      <Icon name='file-add' size='72' />
                                      <span>
                                        Drag and drop here or click to upload
                                        (PDF/JPG/PNG)
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                  <button
                                    className={"button"}
                                    onClick={() => {
                                      setSelectedDocument([]);
                                      setShowTransactionPopup(false);
                                      setDocumentModal(true);
                                      getDocuments();
                                    }}
                                  >
                                    {false ?
                                      <Spinner size="24" color="white" />
                                      :
                                      "Attach Existing Document"
                                    }
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        ) : null}

                      <div className={styles.imgInput}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {transactionAttachments?.map((x, index) => {
                            const fileNameObj = GetFileNameFromPath(x, true);
                            return (
                              </* onClick={(event) => {
                            event.stopPropagation();
                            //setShowPreviewModal(true);
                            //setPreviewImage(x);
                            //setFileNameObject(fileNameObj);
                            
                          }} */
                              /* style={{ marginLeft: 10, marginTop: 10 }} */>
                                <div className={styles.selectedImageWrap}>
                                  {(fileNameObj.extension === "pdf" || stripeBalanceDetails?.isCharge) ?
                                    <>
                                      {(currentTransactionObject?.attachmentCount ||
                                        transactionAttachments?.length > 0) && blobUrlToDisplay &&
                                        <iframe
                                          src={blobUrlToDisplay + "#toolbar=0"}
                                          height={300}
                                          width={250}
                                          style={{ pointerEvents: "auto" }}
                                        />
                                      }
                                      {!currentTransactionObject?.attachmentCount && !blobUrlToDisplay && files[0]?.preview &&
                                        <iframe
                                          src={files[0]?.preview + "#toolbar=0"}
                                          height={300}
                                          width={250}
                                          style={{ pointerEvents: "auto" }}
                                        />
                                      }

                                    </>
                                    : (
                                      <img
                                        className={styles.selectedImage}
                                        src={
                                          fileNameObj.extension === "png" ||
                                            fileNameObj.extension === "gif" ||
                                            fileNameObj.extension === "jpg" ||
                                            fileNameObj.extension === "jpeg"
                                            ? x
                                            : imageWRTExtension[
                                            fileNameObj.extension
                                            ] || imageWRTExtension["common"]
                                        }
                                        hideZoom
                                      />
                                    )}
                                  {activeIndex !== 2 && (
                                    <div className={styles.action}>
                                      {deleteLoading ? (
                                        <>
                                          <div className={styles.modal}>
                                            <div
                                              style={{
                                                position: "fixed",
                                                left: "50%",
                                                top: "45%",
                                              }}
                                            >
                                              <Spinner size='48' color='gray' />
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {/* <button>
                                        <Icon name="size" size="24" />
                                      </button> */}
                                          <a
                                            href={x}
                                            download={
                                              fileNameObj?.fileName +
                                              fileNameObj?.extension
                                            }
                                            style={{
                                              cursor: "pointer",
                                              marginRight: 8,
                                            }}
                                          >
                                            <Icon name='download' size='24' />
                                          </a>
                                          {(currentTransactionObject?.accountingStatus ===
                                            "Booked" || stripeBalanceDetails?.isCharge) ? (
                                            ""
                                          ) : (
                                            <button
                                              className={styles.dropImgDelete}
                                              onClick={async (e) => {
                                                deleteImageHandler(e, x);
                                              }}
                                            >
                                              <Icon name='trash' size='24' />
                                            </button>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>

                      {/* <Image
                    src="https://via.placeholder.com/319x530.png"
                    srcDark="https://via.placeholder.com/319x530.png"
                    className={styles.image}
                  />  <div className={styles.attachmentButtons}>
                    <button className={cn("button button-stroke", styles.button)}>
                      <Icon name="expand" size="24" />
                      Expand
                    </button>
                    <button className={cn("button button-stroke", styles.button)}>
                      <Icon name="download" size="24" />
                      Download
                    </button>
                    <button className="button button-stroke-red">
                      <Icon name="trash" size="24" />
                      Delete
                    </button>
                  </div> */}
                    </Card>}
                  </div>
                </div>
              }
            </>
          }
        />
        <Modal
          outerClassName={styles.outer}
          visible={documentsModal}
          onClose={() => {
            setDocumentModal(false);
            setShowTransactionPopup(true);
          }}
          children={
            <Card
              className={cn(styles.card)}
              classCardHead={styles.head}
              dangerTitle={`Attach document to Transaction`
                // <span style="font-size: 16px">(${currentTransactionObject?.stripeTransactionId ||
                // currentTransactionObject?.id}
                // )</span>
              }
            >
              <div className={documentTableStyles.documentWrapper} style={{
                height: 305,
                overflowY: "auto",
                paddingLeft: 21
              }}>
                <input
                  className={styles.input}
                  type="text"
                  placeholder={"Search Documents"}
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      getSearchResult();
                    } else {
                      clearTimeout(typingTimer);
                      typingTimer = setTimeout(getSearchResult, doneTypingInterval);
                    }
                  }}
                  onKeyDown={() => {
                    clearTimeout(typingTimer);
                  }}
                />
                {getDocumentsLoading ?
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Spinner size="36" color="gray" />
                  </div>
                  :
                  <div className={documentTableStyles.table}>
                    <div className={documentTableStyles.row}>
                      <div className={styles.editOverlayHead}></div>
                      <div className={documentTableStyles.col}></div>
                      <div className={documentTableStyles.col}>ID</div>
                      <div className={documentTableStyles.col}>Document Name</div>
                      <div className={documentTableStyles.col}>Uploaded</div>
                      <div className={documentTableStyles.col}>Uploaded By</div>
                      {/* <div className={documentTableStyles.col}>Status</div> */}
                      <div className={documentTableStyles.col}>Type</div>
                    </div>
                    {documents?.map((item, index) => (
                      <div
                        key={index}
                        className={cn(
                          documentRowStyles.row,
                          // { [styles.selected]: activeId === item.documentFileId },
                          // { [styles.active]: activeTable }
                        )}
                        onClick={() => onChangeHandler(item?.documentFileId, item?.attachmentLink)}
                        style={{ cursor: 'pointer' }}
                      >
                        <div className={documentRowStyles.editOverlay}></div>
                        <div className={documentRowStyles.col} onClick={(e) => e.stopPropagation()}>
                          <Checkbox
                            onChange={() => onChangeHandler(item?.documentFileId, item?.attachmentLink)}
                            value={selectedDocument?.includes(item?.documentFileId)}
                          />
                        </div>
                        <div className={documentRowStyles.col}>
                          <div className={documentRowStyles.title}>Document Id</div>
                          <div className={cn(documentRowStyles.documentName)}>{item?.documentFileId}</div>
                        </div>
                        <div className={documentRowStyles.col} style={{ maxWidth: 200, width: 200, wordWrap: 'break-word' }}>
                          <div className={documentRowStyles.title}>Document Name</div>
                          <div className={cn(documentRowStyles.documentName)}>{item.displayName}</div>
                        </div>
                        <div className={documentRowStyles.col}>
                          <span className={documentRowStyles.title}>Uploaded</span>
                          <div className={documentRowStyles.uploaded}>{dateFormatter(item.uploadedOn)}</div>
                        </div>
                        <div className={documentRowStyles.col}>
                          <span className={documentRowStyles.title}>Uploaded By</span>
                          <div className={documentRowStyles.uploaded}>{item?.uploadedBy}</div>
                        </div>
                        {/* <div className={documentRowStyles.col}>
                            <span className={documentRowStyles.title}>Status</span>
                            <div className={documentRowStyles.status}>{item.status}</div>
                          </div> */}
                        <div className={documentRowStyles.col}>
                          <span className={documentRowStyles.title}>Type</span>
                          <div className={documentRowStyles.status}>{item?.documentType}</div>
                        </div>
                      </div>
                    ))}

                  </div>
                }
                {hasMoreDocuments && (
                  <div style={{
                    marginTop: 12,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                    <button
                      className={"button-stroke button-small"}
                      style={{ width: 120 }}
                      onClick={async () => {
                        setGetMoreDocumentsLoading(true);
                        pageNo = pageNo + 1;
                        await getDocuments(pageNo, searchString)
                        setGetMoreDocumentsLoading(false);
                      }}
                    >
                      {moreDocumentsLoading ?
                        <Spinner size="24" color="gray" />
                        :
                        "Load More"
                      }
                    </button>
                  </div>
                )}
              </div>
              {!getDocumentsLoading && (
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  marginTop: 12
                }}>
                  <button
                    className={"button"}
                    onClick={attachHandler}
                  >
                    {attachLoading ?
                      <Spinner size="24" color="white" />
                      :
                      "Attach"
                    }
                  </button>
                </div>
              )}
            </Card>
          }
        />
      </>

    </>
  );
};
export default TransactionPopup;
