/* global Stripe */
import React, { useEffect, useState } from "react";
import styles from "./Home.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import MyCards from "./MyCards";
import Transactions from "../../components/Transactions";
import withUserAuth from "../../utils/withUserAuth";
import {
  createLinkAccountSession,
  getCardDetails,
  getReportsOverview,
  getTaxRate,
  lookupData,
} from "../../utils/apiCallHanlder";
import Card from "../../components/Card";
import { getCompanyIsStandardStripe, getCurrentUser, isUserLoggedIn, toastConfiguration, transactionDateFormatter } from "../../utils/utils";
import cn from 'classnames';
import {
  GetDashboardInitialData,
  GetDashboardNextPage,
} from "../../utils/apiCallHanlder";

import Balance from "./balance";
import Spinner from "../../utils/spinner";
import { ToastContainer, toast } from "react-toastify";
import MoneyIn from "./MoneyIn";
import MoneyOut from "./MoneyOut";
import HomeHeader from "./HomeHeader";
import Accounting from "../Accounting";
import { withRouter } from "react-router-dom";

let isAccountingEnabled = false;
let isStudioEnabled = false;
let user = null;
let connect = 1;

const Home = (props) => {
  const openLinkAccount = props.location?.state?.openLinkAccount;

  const [accounts, setAccounts] = useState([]);
  const [overviewAccounts, setOverviewAccounts] = useState([]);
  const [graph, setGraph] = useState([]);
  const [loading, setLoading] = useState(true);

  const [transactions, setTransactions] = useState([]);
  const [lastMonthTransactions, setLastMonthTransactions] = useState([]);
  const [isNextPageExist, setIsNextPageExist] = useState(false);
  const [todo, setTodo] = useState({});

  const [nextPageInfo, setNextPageInfo] = useState({});
  const [bankInfoLoading, setBankInfoLoading] = useState(true);

  const [defCurrencyCode, setDefCurrencyCode] = useState("");
  const [startDt, setStartDt] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDt, setEndDt] = useState(new Date());
  const [reportsData, setReportsData] = useState(null);
  const [selectedAccId, setSelectedAccId] = useState("");
  const [loadingForDetailData, setLoadingForDetailData] = useState(false);

  const [width, setWidth] = useState("unset");


  useEffect(async () => {
    if (openLinkAccount) linkExistingBankAccountHandler();
    user = getCurrentUser();
    const connectStatus = JSON.parse(localStorage.getItem("connectStatus"));
    connect = connectStatus?.status;
    isAccountingEnabled = localStorage.getItem("isAccountingEnabled") === "true" ? true : false;
    isStudioEnabled = localStorage.getItem("isStudioEnabled") === "true" ? true : false;

    const response = await loadInitialData(true);
    setLoading(false);

    setWidth("max-content");

    if (!response?.skipNextCall) {
      setLoadingForDetailData(true);
      await loadInitialData(false);
      setLoadingForDetailData(false);
    }

    if (!localStorage.getItem("lookupData") && isUserLoggedIn()) {
      const { data } = await lookupData();
      if (data) {
        localStorage.setItem("lookupData", JSON.stringify(data));
      }
    }
  }, []);

  const loadInitialData = async (isFirstLoad) => {
    if (isFirstLoad) {
      const dbReportsData = await getReportsOverview({
        fromDate: transactionDateFormatter(new Date()),
        toDate: transactionDateFormatter(new Date()),
      });


      setReportsData(dbReportsData?.data);
    }

    const { data } = await GetDashboardInitialData({
      FromDate: transactionDateFormatter(startDt),
      ToDate: transactionDateFormatter(endDt),
      isFirstLoad,
    });

    if (data) {
      let filteredAccounts = [];
      if (connect !== 1) {
        filteredAccounts = data?.accounts?.filter(item => item?.id !== "stripebalance")
        setOverviewAccounts(
          prepareAccountForOverview(filteredAccounts, data.defaultAccountCurrency)
        );
      } else {
        setOverviewAccounts(
          prepareAccountForOverview(data?.accounts, data.defaultAccountCurrency)
        );
      }

      setAccounts(filteredAccounts);
      setTransactions(data.transactions);
      setLastMonthTransactions(data.transactions);

      setDefCurrencyCode(data.defaultAccountCurrency);
      setIsNextPageExist(data.isNextPageExist);
      setNextPageInfo(data.nextPageInfo);
      setSelectedAccId(data.accounts[0]?.id);
      setTodo(data.todo);
    }

    return data;
  };

  const prepareAccountForOverview = (accountsFromApi, defCurrency) => {
    if (!accountsFromApi || !defCurrency) return accountsFromApi;

    if (accountsFromApi?.length === 2) {
      accountsFromApi.shift();
    }

    defCurrency = defCurrency.toLowerCase();
    var allBalance = 0;
    var allAccountIdx = -1;
    for (let index = 0; index < accountsFromApi.length; ++index) {
      const accountInfo = accountsFromApi[index];
      if (accountInfo.id === "All") {
        allAccountIdx = index;
        continue;
      }

      if (accountInfo.balances && accountInfo.balances[defCurrency]) {
        accountInfo.displayBalance = accountInfo.balances[defCurrency];
        allBalance += accountInfo.balances[defCurrency];
      } else {
        accountInfo.displayBalance = 0;
      }
    }

    if (allAccountIdx >= 0) {
      accountsFromApi[allAccountIdx].displayBalance = allBalance;
    }
    return accountsFromApi;
  };

  const initialLoadTransactions = async (
    selectedAccountId,
    startDate,
    endDate
  ) => {
    const { data } = await GetDashboardNextPage({
      SelectedAccountId: selectedAccountId,
      FromDate: transactionDateFormatter(startDate),
      ToDate: transactionDateFormatter(endDate),
      NextPageInfo: null,
    });

    if (data) {
      setTransactions(data.transactions);
      setIsNextPageExist(data?.isNextPageExist);
      setNextPageInfo(data.nextPageInfo);
    }
  };

  const customNextPageLoad = async () => {
    const { data } = await GetDashboardNextPage({
      SelectedAccountId: selectedAccId,
      FromDate: transactionDateFormatter(startDt),
      ToDate: transactionDateFormatter(endDt),
      NextPageInfo: nextPageInfo,
    });

    if (data) {
      setTransactions([...transactions, ...data.transactions]);
      setIsNextPageExist(data?.isNextPageExist);
      setNextPageInfo(data.nextPageInfo);
    }
  };

  const onAccountChanged = (startdt, enddt, acc) => {
    setSelectedAccId(acc);
    setEndDt(enddt);
    setStartDt(startdt);
    initialLoadTransactions(acc, startdt, enddt);
  };

  const onDateRangeChanged = (startdt, enddt, acc) => {
    setSelectedAccId(acc);
    setEndDt(enddt);
    setStartDt(startdt);
    initialLoadTransactions(acc, startdt, enddt);
  };

  const linkExistingBankAccountHandler = async (setLinkLoading) => {
    const { data, error } = await createLinkAccountSession();

    if (data) {
      var stripe = Stripe(data?.stripe_pk, {
        stripeAccount: data.stripeAccountId
      });
      stripe.collectFinancialConnectionsAccounts({
        clientSecret: data?.session?.clientSecret
      })
        .then(async function (result) {
          //result.financialConnectionsSession.accounts[0].id
          if (result.error) {
            // Inform the customer that there was an error.
            toast.error(result.error.message || "Failed to add linked account", toastConfiguration);
            // Handle next step based on length of accounts array
          } else if (result.financialConnectionsSession.accounts.length === 0) {
            toast.error("No accounts were linked", toastConfiguration);
          } else {
            window?.location?.reload();
          }
        }).catch(() => {
          toast.error(error, toastConfiguration);
        })
    }
    else {
      toast.error(error, toastConfiguration);
    }
  }

  return (
    <>
      {loading ? (
        <div className={styles.spinner}>
          <Spinner loading={loading} color='gray' size={50} />
        </div>
      ) : (
        <>
          <>
            {todo.showData &&
              <div className={styles.col12}>
                <HomeHeader
                  className={styles.card}
                  todo={todo}
                  linkExistingBankAccountHandler={linkExistingBankAccountHandler}
                ></HomeHeader>
              </div>
            }
            {reportsData && (
              <div style={{ marginTop: 12, marginBottom: 24 }}>
                <Accounting value={"Accounting"} showOverviewOnly={true} reportsData={reportsData} />
              </div>
            )}
            <div className={styles.col12}>
              <Overview
                className={styles.card}
                loadingForDetailData={loadingForDetailData}
                accounts={overviewAccounts}
                transactions={transactions}
                graph={graph}
                setGraph={setGraph}
                setNextPageInfo={setNextPageInfo}
                nextPageInfo={nextPageInfo}
                onAccountChanged={onAccountChanged}
                onDateRangeChanged={onDateRangeChanged}
                defCurrencyCode={defCurrencyCode}
                width={width}
                setWidth={setWidth}
                user={user}
              />
            </div>
            <div className={styles.row} style={{ gap: "2%" }}>
              <div className={styles.col6}>
                <MoneyIn
                  lastMonthTransactions={lastMonthTransactions}
                  linkExistingBankAccountHandler={linkExistingBankAccountHandler}
                />
              </div>
              <div className={styles.col6}>
                <MoneyOut
                  lastMonthTransactions={lastMonthTransactions}
                  linkExistingBankAccountHandler={linkExistingBankAccountHandler}
                />
              </div>
            </div>
            {reportsData && (
              <div style={{ marginTop: -12, marginBottom: 24 }}>
                <Accounting value={"Accounting"} showOverviewOnly={false} reportsData={reportsData} />
              </div>
            )}
          </>

          <TooltipGlodal />
        </>
      )
      }
    </>
  );
};

export default withUserAuth(withRouter(Home));
