import React, { useReducer, useState } from "react";
import styles from "./Contracts.module.sass";
import TextInput from "../../../components/TextInput";
import Card from "../../../components/Card";
import cn from "classnames";
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { toastConfiguration } from "../../../utils/utils";
import { addContract, createAIContract, getAllContracts, getAllDropdownContracts } from "../../../utils/apiCallHanlder";
import Spinner from "../../../utils/spinner";
import Icon from "../../../components/Icon";
import Dropdown from "../../../components/Dropdown_c";
import { useHistory } from "react-router-dom";

const tokenList = [
    {
        name: "Name",
        token: "{contact_name}",
    },
    {
        name: "Mobile",
        token: "{contact_phone}",
    },
    {
        name: "Email",
        token: "{contact_email}",
    },
    {
        name: "Address",
        token: "{contact_address}",
    },

    {
        name: "Date",
        token: "{current_date}",
    },
];

const Contracts = ({ setActiveTab }) => {
    const history = useHistory();

    const [template, setTemplate] = useState(null);
    const [options, setOptions] = useState([]);
    const [contractLoading, setContractLoading] = useState(false);
    const [showCreateNew, setShowCreateNew] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const [value, setValue] = useState("");

    const [addContractLoading, setAddContractLoading] = useState(false);
    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
        prompt: "",
        trixEditorValue: "",
    });

    useEffect(() => {
        getDropdownTemplates();
        const trixEditor = document.querySelector("trix-editor");
        trixEditor.style.minHeight = "65vh";
    }, []);


    const getDropdownTemplates = async () => {
        setContractLoading(true);
        const { data } = await getAllDropdownContracts('');
        if (data) {
            data?.contracts?.unshift({
                name: 'Choose',
                id: 'choose'
            })
            setTemplate(data?.contracts[0]?.id)
            setOptions(data?.contracts)
        }
        setContractLoading(false);
    }

    const handleChange = (e) => {
        setUserInput({ [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (addContractLoading) return;
        if (userInput.prompt === "" || userInput.prompt.trim() === "") {
            toast.error("Prompt is required", toastConfiguration);
            return;
        }

        setAddContractLoading(true);
        setValue("");
        setUserInput({ trixEditorValue: "" });
        const trixEditor = document.querySelector("trix-editor");
        if (trixEditor.editor) {
            trixEditor.innerHTML = "";
        }

        const { data } = await createAIContract({
            name: userInput.prompt
        });

        if (data) {
            toast.success("Successfully added prompt", toastConfiguration);

            history.push({
                pathname: `/studio/5`,
                state: { isNewContract: true, comingFromChat: true, contract: data?.contract },
            });
        } else {
            toast.error("Error in adding contract", toastConfiguration);
        }

        setAddContractLoading(false);
    };

    const toolbarActions = [
        "bold",
        "italic",
        "strike",
        "link",
        "heading1",
        "quote",
        "bullet",
        "number",
        "centered",
        "undo",
        "redo",
    ];

    const createTemplateHandler = async () => {
        if (createLoading) return;
        if (userInput.prompt === "" || userInput.prompt.trim() === "") {
            toast.error("Prompt is required", toastConfiguration);
            return;
        }

        if (value === "" || value?.trim() === "") {
            toast.error("Please add content", toastConfiguration);
            return;
        }
        setCreateLoading(true);

        const { data } = await addContract({
            name: userInput.prompt,
            description: value,
        });

        if (data) {
            toast.success("Successfully added contract", toastConfiguration);
            getDropdownTemplates();
            setShowCreateNew(false);
        } else {
            toast.error("Error in adding contract", toastConfiguration);
        }

        setUserInput({ prompt: "" });
        setCreateLoading(false);
    };

    const handleTrixInputChange = (e, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className={styles.title}>
                <h5
                    className={styles.backBtn}
                    onClick={() => {
                        if (showCreateNew) setShowCreateNew(false)
                        else setActiveTab(0)
                    }}
                >
                    <Icon name="arrow_backward" size="18" />
                    Back
                </h5>
            </div>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Card title={"Contract"} border classTitle={styles.title}>
                        {showCreateNew ?
                            <form onSubmit={handleSubmit} noValidate>
                                <TextInput
                                    className={styles.input}
                                    label="Prompt"
                                    onChange={handleChange}
                                    name="prompt"
                                    id="prompt"
                                    type="text"
                                    value={userInput.prompt}
                                    required
                                />

                                <button
                                    className={cn("button-stroke", styles.sendBtn)}
                                    style={{ marginTop: 16, marginRight: 8, width: 100 }}
                                    type="submit"
                                >
                                    {addContractLoading ? <Spinner size="16" color="gray" /> : <>Generate</>}
                                </button>
                                {/* <button
                                    className={cn("button", styles.sendBtn)}
                                    onClick={createTemplateHandler}
                                    type="button"
                                >
                                    {createLoading ? <Spinner size="16" color="white" /> : <>Create</>}
                                </button> */}
                            </form>
                            :
                            <Dropdown
                                label="Template"
                                classDropdownLabel={styles.classLabel}
                                loading={contractLoading}
                                className={styles.dropdown}
                                classDropdownHead={styles.dropdownHead}
                                value={template}
                                setValue={setTemplate}
                                options={options}
                                handleChange={(id) => {
                                    history.push({
                                        pathname: `/studio/5`,
                                        state: { isUpdatedContract: true, comingFromChat: true, contract: id },
                                    });
                                }}
                                createNewHandler={() => {
                                    history.push({
                                        pathname: `/studio/5`,
                                        state: { isNewContract: true, comingFromChat: true },
                                    });
                                }}
                                showAddNew
                            />
                        }
                    </Card>
                </div>
                <div className={styles.col}>
                    <Card border>
                        <ReactTrixRTEToolbar toolbarActions={toolbarActions} toolbarId="react-trix-rte-editor" />
                        <ReactTrixRTEInput
                            toolbarId="react-trix-rte-editor"
                            onChange={handleTrixInputChange}
                            placeholder="Enter Content"
                            defaultValue={userInput?.trixEditorValue}
                        />
                    </Card>
                </div>
            </div>
        </>
    );
};

export default Contracts;
