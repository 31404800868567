import React, { useState } from "react";
import styles from "./Pricing.module.sass";
import TextInput from "../../../../components/TextInput";
import cn from "classnames";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Checkbox from "../../../../components/Checkbox";
import Tooltip from "../../../../components/Tooltip";
import Card from "../../../../components/Card";
import Dropdown from "../../../../components/Dropdown_c";
import { getCurrencyFromLocalStorage } from "../../../../utils/utils";
import { useEffect } from "react";

const Pricing = ({
  className,
  priceModel,
  recurringType,
  productCurrency,
  selectCurrency,
  setSelectCurrency,
  selectPricingModel,
  setSelectPricingModel,
  userChoose,
  setUserChoose,
  oneTime,
  setOneTime,
  subscriptions,
  setSubscriptions,
  selectPeriod,
  setSelectPeriod,
  productPrice,
  includeTax,
  setIncludeTax,
  setProductPrice,
  setValueHandler,
  priceError,
  setPriceError,
  archived,
  showOneTime,
  showSubscription,
  comingFromInvoice,
  showBilling,
  cost,
  margin,
  setCost,
  setMargin
}) => {

  useEffect(() => {
    const productPriceNum = parseInt(productPrice || "0");
    const costPriceNum = parseInt(cost || "0");

    if (productPriceNum && costPriceNum) {
      setMargin((((productPriceNum - costPriceNum) / productPriceNum) * 100))
    } else {
      setMargin(null);
    }
  },[productPrice,cost])

  const handleSubscriptions = () => {
    setSubscriptions(!subscriptions);
    setOneTime(!oneTime);
  };

  const handleOneTime = () => {
    setSubscriptions(!subscriptions);
    setOneTime(!oneTime);
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title='Pricing'
      /* classTitle="title-yellow" */
      >
        <div className={styles.row}>
          <div
            className={styles.col}
            style={archived ? { pointerEvents: "none" } : null}
          >
            <div
              className={styles.field}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <span className={styles.fieldLabel}>Pricing Model</span>
              <Dropdown
                className={styles.dropdown}
                //tooltip="Select standard price if you charge same price for each unit. If you would like to have customer choose their own price (for instance for tipping) choose “Customer chooses their own price”"
                classDropdownHead={styles.dropdownHead}
                value={selectPricingModel}
                setValue={setSelectPricingModel}
                options={priceModel}
                handleChange={(e) =>
                  e === "customer_choose"
                    ? setUserChoose(true)
                    : setUserChoose(false)
                }
              />
              {userChoose ? (
                <Dropdown
                  className={cn(styles.dropdown, styles.userChooseDrop)}
                  classDropdownHead={styles.dropdownHead}
                  value={selectCurrency}
                  setValue={setSelectCurrency}
                  options={productCurrency}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          {!userChoose ? (
            <>
              {/* {(showOneTime || showSubscription) && comingFromInvoice ? (
                showSubscription && (
                  <div
                    className={styles.col}
                    style={archived ? { pointerEvents: "none" } : null}
                  >
                    <div className={styles.field}>
                      <div className={styles.fieldLabel}>
                        <span>Billing Period</span>
                      </div>
                    </div>
                    <>
                      {subscriptions ? (
                        <Dropdown
                          className={styles.userChooseDrop}
                          classDropdownHead={styles.dropdownHead}
                          value={selectPeriod}
                          setValue={setSelectPeriod}
                          options={recurringType}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                )
              ) : ( */}
                <div
                  className={styles.col}
                  style={archived ? { pointerEvents: "none" } : null}
                >
                  <div className={styles.field}>
                    <div className={styles.fieldLabel}>
                      <span>Billing Period</span>
                    </div>
                  </div>
                  <div className={styles.includeTax}>
                    <Checkbox
                      onChange={handleOneTime}
                      value={oneTime}
                    />
                    <span className={styles.txt}>Regular</span>
                  </div>
                  <>
                    <div className={styles.includeTax}>
                      <Checkbox
                        onChange={handleSubscriptions}
                        value={subscriptions}
                      />
                      <span className={styles.txt}>Subscription</span>
                    </div>
                    {subscriptions ? (
                      <Dropdown
                        className={styles.userChooseDrop}
                        classDropdownHead={styles.dropdownHead}
                        value={selectPeriod}
                        setValue={setSelectPeriod}
                        options={recurringType}
                      />
                    ) : (
                      ""
                    )}
                  </>
                </div>
              {/* )} */}
            </>
          ) : (
            ""
          )}
        </div>
        {!userChoose ? (
          <div
            className={styles.row}
            style={archived ? { pointerEvents: "none" } : null}
          >
            <div className={styles.col}>
              <div>
                <div className={styles.priceGroup}>
                  <TextInput
                    style={
                      productPrice === "" || productPrice === null
                        ? {
                          backgroundColor: "#FFBC9925",
                          borderColor: "#FFBC9925",
                          width: 150,
                        }
                        : { width: 150 }
                    }
                    label='Price'
                    //currency={currency}
                    name='price'
                    value={productPrice}
                    onChange={(event) =>
                      setValueHandler(event?.target?.value, setProductPrice)
                    }
                    required
                  />
                  <Dropdown
                    className={styles.dropdown}
                    classDropdownHead={styles.dropdownHead}
                    value={selectCurrency}
                    setValue={setSelectCurrency}
                    options={productCurrency}
                  />
                </div>
                {priceError !== "" ? (
                  <div className={styles.errorContainer}>
                    <span className={styles.errorMessage}>{priceError}</span>
                  </div>
                ) : null}
                {/* <div className={styles.includeTax}>
                  <Checkbox
                    onChange={() => setIncludeTax(!includeTax)}
                    value={includeTax}
                  />
                  <span className={styles.txt}>Include Tax in Price</span>
                   <Tooltip
                    className={styles.tooltip}
                    title="This will be used for calculating automatic tax. Defaults to the default product tax category from your tax settings."
                    icon="info"
                    place="right"
                  /> 
                </div> */}
              </div>
            </div>
            <div className={styles.colCost}>
              <div className={styles.costMarginWrapper}>
                <TextInput
                  style={{ width: 158 }}
                  label='Cost'
                  name='cost'
                  type="number"
                  value={cost}
                  onChange={(event) => {
                    setValueHandler(event?.target?.value, setCost);
                  }}
                  required
                  percentage={true}
                />
                <TextInput
                  style={{ width: 158, pointerEvents: 'none' }}
                  label='Margin'
                  name='margin'
                  text='%'
                  value={(margin || 0)?.toFixed(2)}
                  required
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Pricing;
