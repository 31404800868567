import React, { useEffect, useState } from 'react';
import Modal from '../../../../../components/Modal';
import TextInput from '../../../../../components/TextInput';
import styles from './CashAccount.module.sass';
import cn from 'classnames';
import Icon from '../../../../../components/Icon';
import Card from '../../../../../components/Card';
import Spinner from '../../../../../utils/spinner';
import { toast } from 'react-toastify';
import { toastConfiguration } from '../../../../../utils/utils';
import Dropdown from '../../../../../components/Dropdown';
import { addCashAccount } from '../../../../../utils/apiCallHanlder';
import Switch from '../../../../../components/Switch';

const CashAccount = ({
    showCashAccountModal,
    setShowCashAccountModal,
    setVisible,
    gettingBankAccountDetails,
    id,
    accountNameX,
    bankNameX,
    detail1X,
    detail2X,
    currencyX,
    detail3X,
    detail4X,
    isInternationalX,
    setCurrentlyActive,
    isLinked
}) => {
    const [accountName, setAccountName] = useState(accountNameX || "");
    const [bankName, setBankName] = useState(bankNameX || "default");
    const [routingNumber, setRoutingNumber] = useState(detail2X || "");
    const [accountNumber, setAccountNumber] = useState(detail1X || "");
    const [currency, setCurrency] = useState(currencyX || "USD");

    const [iban, setIban] = useState(detail3X || "");
    const [bic, setBic] = useState(detail4X || "");

    const [isInternational, setIsInternational] = useState(isInternationalX || false);

    const [saveLoading, setSaveLoading] = useState(false);

    useEffect(() => {
        if (showCashAccountModal && !accountNameX) {
            setAccountName("");
            setBankName("");
            setIban("");
            setBic("");
            setAccountNumber("");
            setRoutingNumber("");
            setIsInternational(false);
        } else {
            setAccountName(accountNameX);
            setBankName(bankNameX);
            setIban(detail3X);
            setBic(detail4X);
            setAccountNumber(detail1X);
            setRoutingNumber(detail2X);
            setIsInternational(isInternationalX);
        }
    }, [showCashAccountModal])

    const onClose = () => {
        setShowCashAccountModal(false);
    }

    const setValueHandler = (valueToSet, setter) => {
        setter(valueToSet)
    }

    const containsEmptySpaces = (str) => {
        return !str.replace(/\s/g, '').length
    }

    const saveClickHandler = async () => {
        const re = new RegExp("/\S/");
        if (((!bic || !iban) && isInternational) || !accountName || !routingNumber || !accountNumber) {
            toast.error("Please fill out all input details", toastConfiguration);
            return;
        }

        //checking space
        if (((containsEmptySpaces(bic) || containsEmptySpaces(iban)) && isInternational) || containsEmptySpaces(accountName) || containsEmptySpaces(routingNumber) || containsEmptySpaces(accountNumber)) {
            toast.error("Please fill out all input details", toastConfiguration);
            return;
        }

        if (saveLoading) {
            return;
        }

        setSaveLoading(true);
        const { data, error } = await addCashAccount({
            id: id || '',
            title: accountName,
            bankName: "-",
            detail1: accountNumber,
            detail2: routingNumber,
            detail3: isInternational ? iban : "",
            detail4: isInternational ? bic : "",
            currency: currency,
            isInternational: isInternational
        })
        if (data) {
            toast.success(id ? "Successfully updated account" : "Successfully added account",
                toastConfiguration);
            setCurrentlyActive && setCurrentlyActive(data?.accountDetail?.id)
            setShowCashAccountModal(false);
            setVisible && setVisible(false);
            await gettingBankAccountDetails(false, data?.accountDetail?.id)
        } else {
            toast.error(error || (id ? "Error in updating account" : "Error in adding account"),
                toastConfiguration);
            //setShowCashAccountModal(false);
            setVisible && setVisible(false);
        }
        setSaveLoading(false);
    }

    const isLessThan500 = (value) => {
        if (value?.length < 500) {
            return true;
        }

        return false;
    }

    return (
        <Modal
            visible={showCashAccountModal}
            onClose={onClose}
            outerClassName={styles.outer}
            children={
                <Card
                    title={isLinked ? "Account details" : "Add cash account"}
                /* classTitle={cn("title-blue", styles.title)} */
                >
                    <div className={styles.row}>
                        <div className={styles.field}>
                            <div className={styles.fieldLabel}>Bank Account name</div>
                            <TextInput
                                name="description"
                                value={accountName}
                                onChange={(event) => setValueHandler(event?.target?.value, setAccountName)}
                                type="text"
                                required
                            />
                        </div>
                        {/* <div className={styles.field}>
                            <div className={styles.fieldLabel}>Bank Name</div>
                            <TextInput
                                name="description"
                                value={bankName}
                                onChange={(event) => setValueHandler(event?.target?.value, setBankName)}
                                type="text"
                                required
                            />
                        </div> */}

                    </div>
                    <div className={styles.row}>
                        <div className={styles.field}>
                            <div className={styles.fieldLabel}>Routing number</div>
                            <TextInput
                                name="routing"
                                value={routingNumber}
                                onChange={(event) => {
                                    if (isLessThan500(event.target.value)) {
                                        setValueHandler(event?.target?.value, setRoutingNumber)
                                    }
                                }}
                                type="text"
                                required
                            />
                        </div>
                        <div className={styles.field}>
                            <div className={styles.fieldLabel}>Account number</div>
                            <TextInput
                                name="account"
                                value={accountNumber}
                                onChange={(event) => {
                                    if (isLessThan500(event.target.value)) {
                                        setValueHandler(event?.target?.value, setAccountNumber);
                                    }
                                }}
                                type="text"
                                required
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <Switch
                            value={isInternational}
                            onChange={() => {
                                setIsInternational(!isInternational);
                            }
                            }
                        />
                        <span style={{ marginLeft: 16 }}>
                            International
                        </span>
                    </div>

                    {isInternational && (
                        <>
                            <div className={styles.row}>
                                <div className={styles.field}>
                                    <div className={styles.fieldLabel}>IBAN</div>
                                    <TextInput
                                        name="iban"
                                        value={iban}
                                        onChange={(event) => {
                                            if (isLessThan500(event.target.value)) {
                                                setValueHandler(event?.target?.value, setIban)
                                            }
                                        }}
                                        type="text"
                                        required
                                    />
                                </div>
                                <div className={styles.field}>
                                    <div className={styles.fieldLabel}>BIC</div>
                                    <TextInput
                                        name="bic"
                                        value={bic}
                                        onChange={(event) => {
                                            if (isLessThan500(event.target.value)) {
                                                setValueHandler(event?.target?.value, setBic)
                                            }
                                        }}
                                        type="text"
                                        required
                                    />
                                </div>
                            </div>
                        </>

                    )}
                    {/* <div className={styles.row}>
                        <div className={styles.field}>
                            <div className={styles.fieldLabel}>Currency</div>
                            <Dropdown
                                className={styles.dropdown}
                                classDropdownHead={styles.dropdownHead}
                                value={currency}
                                setValue={setCurrency}
                                options={["USD"]}
                            />
                        </div>
                    </div> */}
                    <button
                        className={cn("button", styles.button)}
                        onClick={saveClickHandler}
                    >
                        {saveLoading ?
                            <Spinner size="24" color="white" />
                            :
                            <>
                                Save
                            </>
                        }
                    </button>
                </Card>
            }
        />
    )
}

export default CashAccount;