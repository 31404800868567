import React, { useState, useEffect } from "react";
import styles from "./AccountDetails.module.sass";
import Icon from "../../../components/Icon";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import DeleteModal from "../../../components/DeleteModal";
import cn from "classnames";
import Transactions from "../../../components/LedgerTransactions";
import { useHistory, useParams } from "react-router";
import { deleteCOAAccount, getCOATransactions, getCreateAccountMetaData, getSingleCOAAccount, pageLoadTransactions, syncFeedAccounts, updateCOAAccount } from "../../../utils/apiCallHanlder";
import { dateFormatter, dateFormatterUTC, toastConfiguration, transactionDateFormatter } from "../../../utils/utils";
import Spinner from "../../../utils/spinner";
import TransactionPopup from "../Reconcile/TransactionPopup";
import { toast } from "react-toastify";
import COATYPEDropDown from "../../../components/COATYPE";
import Dropdown from "../../../components/Dropdown";
import { withRouter } from "react-router";
import ExportPopup from "../../../components/ExportGledgerPopup";
//import TransactionPopup from "../../Transactions/TransactionPopup";

const pageSize = 30;
let page = 1;

const AccountDetails = (props) => {
  const coaId = props.coaId;
  const { id } = useParams();
  const year = props.location?.state?.year;
  const month = props?.location?.state?.month;

  const [showDetails, setShowDetails] = useState(false);
  const [showTransactionSend, setShowTransactionSend] = useState(
    id ? true : false
  );
  const [showAddNewTransactions, setShowAddNewTransactions] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [accountDetailPayload, setAccountDetailPayload] = useState({});
  const [isNextPageExist, setIsNextPageExist] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleExportPopup, setVisibleExportPopup] = useState(false);

  const [isArchived, setIsArchived] = useState(false);
  const [detail, setDetail] = useState({});
  const [number, setNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccountNo, setBankAccountNumber] = useState("");
  const [type, setType] = useState("");
  const [detailsType, setDetailsType] = useState("");
  const [description, setDescription] = useState("");
  const [debit, setDebit] = useState("");
  const [credit, setCredit] = useState("");
  const [balance, setBalance] = useState("");
  const [options, setOptions] = useState([]);

  const [startLoading, setStartLoading] = useState(true);
  const [syncLoading, setSyncLoading] = useState(false);

  const [currentTransactionObject, setCurrentTransactionObject] = useState([]);
  const [currentTransactionId, setCurrentTransactionId] = useState(null);

  const [showTransactionPopup, setShowTransactionPopup] = useState(false);

  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
  const [endDate, setEndDate] = useState(new Date());

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [coa2Id, setCoa2Id] = useState("");
  const [bankTypes, setBankTypes] = useState([]);
  const [coaOptions, setCoaOptions] = useState([]);
  const [subTypes, setSubTypes] = useState([]);

  const [accountType, setAccountType] = useState("");

  const history = useHistory();

  useEffect(async () => {
    if (coaId) {
      await getCOAAccount();
    }
  }, [coaId])


  useEffect(() => {
    if (!showTransactionPopup) {
      setCurrentTransactionId(null);
      setCurrentTransactionObject(null);
    }
  }, [showTransactionPopup])

  useEffect(() => {
    if (currentTransactionId) {
      setCurrentTransactionObject({
        fetchTransactionPayload: true,
        id: currentTransactionId

      });
      setShowTransactionPopup(true);
    }
  }, [currentTransactionId])

  const getCOAAccount = async () => {
    setStartLoading(true);
    const metaData = await getCreateAccountMetaData(coaId ? { coaId: coaId } : {});
    if (metaData?.data) {
      const minifiedTypes = metaData?.data?.type?.map(item => item.name);

      setCoaOptions(metaData?.data?.type);
      setOptions(metaData?.data?.detailType);
      setBankTypes(minifiedTypes);
    }
    const { data } = await getSingleCOAAccount(coaId);
    if (data) {
      const dataDetail = data?.detail;
      const minifiedTypes = metaData?.data?.type?.filter(item => item?.children?.filter(i => i.id === dataDetail?.coa2Id)?.length > 0);
      setSubTypes(minifiedTypes);

      setCoa2Id(dataDetail?.coa2Id)
      setDetail(data?.detail);
      setNumber(dataDetail?.code);
      setBankAccountNumber(dataDetail?.bankAccountNo)
      setBankName(dataDetail?.bankName);
      setAccountName(dataDetail?.coa2Id);
      setAccountType(dataDetail?.type)
      setType(minifiedTypes?.length > 0 ? minifiedTypes[0]?.name : dataDetail?.coa1Name);
      setDetailsType(dataDetail?.detailType);
      setIsArchived(dataDetail?.isArchived);
      setDescription(dataDetail?.description);
      setDebit(dataDetail?.debit);
      setCredit(dataDetail?.credit);
      setBalance(dataDetail?.balance);
    }
    setStartLoading(false);
  }

  useEffect(async () => {
    if (coaId) {
      setLoading(true);
      if (year && month) {
        const startCOA = new Date(year, month - 1, 1);
        const endCOA = new Date(year, month, 0);

        setStartDate(startCOA);
        setEndDate(endCOA);
        await getUpdatedTransactions(startCOA, endCOA);
      } else if (year) {
        const today = new Date();
        const startCOA = new Date(year);
        let endCOA = new Date(year, 11, 31);

        if (year === today?.getFullYear()?.toString()) {
          endCOA = today;
        }

        setStartDate(startCOA);
        setEndDate(endCOA);
        await getUpdatedTransactions(startCOA, endCOA);
      } else {
        await getUpdatedTransactions();
      }

      setLoading(false)
    }
  }, [coaId]);

  const getUpdatedTransactions = async (start, end, pageNo, previousTransactions) => {
    if (pageNo) {
      page = page + 1;
    }
    const { data } = await getCOATransactions({
      coaId,
      page: page || 1,
      pageSize,
      fromDate: dateFormatter(start || startDate),
      toDate: dateFormatter(end || endDate)
    }, coaId);
    if (data) {
      previousTransactions ? setTransactions(previousTransactions.concat(data.list)) :
        setTransactions(data?.list);
      setIsNextPageExist(data?.isNextPageExist);
    }
  }

  const refreshFeedAccount = async () => {
    if (!syncLoading) {
      setSyncLoading(true);
      const { data } = await syncFeedAccounts({}, coaId);
      if (data) {
        getCOAAccount();
        setLoading(true);
        await getUpdatedTransactions();
        setLoading(false)
      }
      setSyncLoading(false);
    }
  }

  const deleteCoaHandler = async () => {
    setDeleteLoading(true);
    const { data, error } = await deleteCOAAccount(coaId, {})
    if (data) {
      history.push("/accounting/COA")
      toast.success("COA account deleted successfully", toastConfiguration);
    } else {
      toast.error(error || "Error in deleting COA Account", toastConfiguration);
    }
    setDeleteLoading(false);
  }

  const editCOAHandler = async () => {
    setEditLoading(true);
    const { data, error } = await updateCOAAccount({
      name: type,
      description: description,
      id: coaId,
      coa2Id: accountName,
      detailType: detailsType,
      code: number
    })
    if (data) {
      toast.success("COA account updated successfully", toastConfiguration);
    } else {
      toast.error(error || "Error in updating COA Account", toastConfiguration);
    }
    setEditLoading(false);
  }

  return (
    <>
      <div className={styles.title} onClick={() => history.push("/accounting/COA")}>
        <h5
          className={styles.backBtn}
        >
          <Icon name="arrow_backward" size="14" />
        </h5>
        <p style={{ marginLeft: 8, marginTop: 2, cursor: 'pointer' }}>Back to categories</p>
      </div>
      <ExportPopup
        visible={visibleExportPopup}
        setVisible={setVisibleExportPopup}
        startDate={transactionDateFormatter(startDate)}
        endDate={transactionDateFormatter(endDate)}
        accountData={{ coaId }}
      ></ExportPopup>

      <Card
        /* classTitle="title-blue" */
        title="Account Information"
        className={styles.card}
        border
        head={
          <>
            <button
              className={"button-stroke-red"}
              style={{ marginRight: 12 }}
              onClick={() => { setVisibleExportPopup(true) }}>
              Export

            </button>
            {!isArchived && <>
              <DeleteModal
                className={styles.deleteBtn}
                btnTxt="Delete"
                deleteBtn={
                  <button
                    className="button"
                    style={{ width: 150 }}
                    onClick={deleteCoaHandler}
                  >
                    {deleteLoading ?
                      <Spinner size="24" color="white" loading={deleteLoading} />
                      :
                      "Yes I'm sure!"
                    }
                  </button>
                }
              />
              <button
                className={"button"}
                style={{ marginLeft: 12 }}
                onClick={editCOAHandler}
              >
                {editLoading ?
                  <Spinner size="24" color="gray" />
                  :
                  "Save"
                }
              </button>
            </>}

          </>
        }
      >
        {startLoading ?
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner loading={startLoading} size="42" color="gray" />
          </div>
          :
          <>
            <div className={styles.row} style={{ pointerEvents: 'none' }}>
              <div className={styles.col} style={{ pointerEvents: 'auto' }}>
                <div className={styles.fieldLabel}>Type</div>
                <Dropdown
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={type}
                  setValue={setType}
                  options={bankTypes}
                  handleChange={(value) => {
                    const filteredSubTypes = coaOptions?.filter(item => item?.name === value);
                    if (filteredSubTypes?.length > 0) {
                      setSubTypes(filteredSubTypes);
                      setAccountName(filteredSubTypes[0]?.children[0]?.id)
                    }
                  }}
                  bodyStyles={{ width: '100%' }}
                />
              </div>
              <div className={styles.col} style={{ pointerEvents: 'auto' }}>
                <COATYPEDropDown
                  label="Name"
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={accountName}
                  setValue={setAccountName}
                  options={subTypes}
                  hideHeader
                />
              </div>
              <div className={styles.col} style={{ pointerEvents: 'auto' }}>
                <TextInput
                  label="Number"
                  value={number}
                  classInput={styles.activeInputClass}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </div>
            </div>
            {/* {accountType?.toLowerCase() === "bank" ?
              <div className={styles.row} style={{ pointerEvents: 'none', marginTop: 20, marginBottom: 20 }}>
                <div className={styles.col}>
                  <TextInput
                    label="Bank Name"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                  />
                </div>
                <div className={styles.col}>
                  <TextInput
                    label="Bank Account Number"
                    value={bankAccountNo}
                    onChange={(e) => setBankAccountNumber(e.target.value)}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className={styles.fieldLabel}>Bank Feed Last Synced On</div>
                  <div style={{
                    color: 'gray',
                    marginTop: 5,
                    display: 'flex',
                    flexDirection: 'row'
                  }}>
                    {(detail?.maxBankFeedDate && dateFormatterUTC(detail?.maxBankFeedDate)) || '(Sync now)'}
                    <div style={{
                      marginLeft: 10,
                      marginTop: -2,
                      cursor: 'pointer',
                      pointerEvents: 'auto'
                    }}
                      onClick={refreshFeedAccount}
                    >
                      {syncLoading ?
                        <Spinner size="24" color="gray" />
                        :
                        <Icon name="refresh" size={16} viewBox={"0 0 24 24"} />
                      }
                    </div>
                  </div>
                </div>
              </div>
              :
              null
            } */}
            <div className={styles.row} style={{
              marginTop: 24, marginBottom: 20, pointerEvents: 'none'
            }}>
              {/* <div className={styles.col} style={{ pointerEvents: 'auto' }}>
                <div className={styles.fieldLabel}>Details Type</div>
                <Dropdown
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={detailsType}
                  setValue={setDetailsType}
                  options={options}
                />
              </div>
              <div className={styles.col} style={{ pointerEvents: 'auto' }}>
                <TextInput
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div> */}
              <div className={styles.col}>
                <TextInput
                  label="Debit"
                  value={debit}
                  onChange={(e) => setDebit(e.target.value)}
                />
              </div>
              <div className={styles.col}>
                <TextInput
                  label="Credit"
                  value={credit}
                  onChange={(e) => setCredit(e.target.value)}
                />
              </div>
              <div className={styles.col}>
                <TextInput
                  label="Balance"
                  value={balance}
                  onChange={(e) => setBalance(e.target.value)}
                />
              </div>
            </div>
          </>
        }
      </Card>
      <Transactions
        setShowDetails={setShowDetails}
        transactions={transactions}
        accountDetailPayload={accountDetailPayload}
        setAccountDetailPayload={setAccountDetailPayload}
        accounts={accounts}
        page={page}
        isNextPageExist={isNextPageExist}
        setTransactions={setTransactions}
        setIsNextPageExist={setIsNextPageExist}
        getUpdatedTransactions={getUpdatedTransactions}
        showCalender=" "
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
        loading={loading}
        setLoading={setLoading}
        showDate={true}
        isGLedger={true}
        onClickHandler={(id) => {
          setCurrentTransactionId(id);
        }}
      />
      <TransactionPopup
        showTransactionPopup={showTransactionPopup}
        setShowTransactionPopup={setShowTransactionPopup}
        currentTransactionObject={currentTransactionObject}
        setCurrentTransactionObject={setCurrentTransactionObject}
      ></TransactionPopup>
      {/* <TransactionPopup
        showTransactionPopup={showTransactionPopup}
        setShowTransactionPopup={setShowTransactionPopup}
      /> */}
    </>
  );
};

export default withRouter(AccountDetails);
