import React, { useEffect, useState, useMemo, useCallback } from "react";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import TextInput from "../../../../components/TextInput";
import styles from "./Account.module.sass";
import Card from "../../../../components/Card";
import Dropdown from "../../../../components/Dropdown";
import Image from "../../../../components/Image";
import {
  uploadProductImage,
  getAccountDetail,
  updateAccount,
  uploadTokenedImage,
} from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { toastConfiguration } from "../../../../utils/utils";
import ErrorModal from "../../../../components/ErrorModal";
import { setCountryFromList } from "../../../../utils/utils";
import Select from "react-select";
import countryList from "react-select-country-list";
import Modal from "../../../../components/Modal";
import ReactPhoneInput from "react-phone-input-2";
import {
  emailHandleChange,
} from "../../../../utils/utils";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import DateInput from "../../../../components/DateInput";
import { useDropzone } from "react-dropzone";

const inputStyle = {
  position: "relative",
  fontSize: "14px",
  letterSpacing: ".01rem",
  marginTop: "0px !important",
  marginBottom: " 0px !important",
  paddingLeft: "48px",
  marginLeft: "0px",
  background: "#F4F4F4",
  border: "none",
  borderRadius: "10px",
  lineHeight: "25px",
  height: "45px",
  width: "100%",
  outline: "none",
  color: "#464542",
  fontWeight: 600,
};

let countries = [""];
let states = [""];
let haveDropdownsData = false;
const typeOptions = ["Partnership", " C-Corp", "S-Corp", "LLC", "Trust", "Sole Proprietorship"];

const Account = ({ token, continueClickHandler,  setChangeProfile, changeProfile, skipClickHanlder, setChangeBusinessLogo, changeBuisnessLogo, isComingFromSignup }) => {
  const history = useHistory();

  if (!haveDropdownsData) {
    const accountLookupData = JSON.parse(localStorage.getItem("lookupData"));
    let data = null;
    if (accountLookupData) {
      data = accountLookupData?.data;
      countries = data?.Countries;
      states = data?.US_States;
      haveDropdownsData = true;
    }
  }

  const [tz, setTz] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [country, setCountry] = useState("US");
  const [state, setStates] = useState(states[0]?.value);

  const [imageUrl, setImageUrl] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [selectedCoverImage, setSelectedCoverImage] = useState(null);
  const [businessName, setBusinessName] = useState("");
  const [legalBusinessName, setLegalBusinessName] = useState("");
  const [showUpdatePhonePopup, setShowUpdatePhonePopup] = useState(false);
  const [showUpdateEmailPopup, setShowUpdateEmailPopup] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [type, setType] = useState(typeOptions[0]);
  const [date, setDate] = useState(new Date());

  const [selectedImage, setSelectedImage] = useState(null);
  const [description, setDescription] = useState("");

  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [zip, setZip] = useState("");
  const [taxNumber, setTaxNumber] = useState("");

  const [startLoading, setStartLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [showMainContainer, setShowMainContainer] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [displayCountry, setDisplayCountry] = useState({
    value: "US",
    label: "United States",
  });
  const [isStripedVerified, setIsStripedVerified] = useState(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    const formData = new FormData();

    acceptedFiles.map((x) => {
      formData.append('files', x);
    })

    console.log(acceptedFiles);

  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  let options = useMemo(() => countryList().getData(), []);
  
  options = options.filter((item) => item.value !== "US");
  options.unshift({ value: "US", label: "United States" });
  setCountryFromList(country, displayCountry, setDisplayCountry, options);

  useEffect(async () => {
    const stripeVerified = true; //stripeConnectionStatus();
    setIsStripedVerified(stripeVerified)

    if (isComingFromSignup) {
      setStartLoading(false);
      return;
    }

    if (stripeVerified) {
      const { data } = await getAccountDetail();

      if (data) {
        const { account } = data;
        // console.log("account", account)
        setBusinessName(account?.businessName);
        setLegalBusinessName(account?.companyName);
        setAddressLine1(account?.addressLine1);
        setAddressLine2(account?.addressLine2);
        setCity(account?.addressCity);
        setImageUrl(account?.imageUrl);
        setCoverImage(account?.headerImageUrl)
        setCountry(account?.addressCountry);
        setStates(account?.addressState);
        setPhone(account?.phone);
        setEmail(account?.email);
        setIsEmailVerified(account?.isEmailVerified);
        setIsPhoneVerified(account?.isPhoneVerified);
        setZip(account?.addressPostalCode);
        setTaxNumber(account?.taxNumber);
        setDescription(account?.description);
        setType(account?.businessType || typeOptions[0]);
        setDate(new Date(account?.incorporationDate || new Date()));

        if(account?.addressCountry){
          setDisplayCountry(null);
          setCountryFromList(country, displayCountry, setDisplayCountry, options);
        }
      }
    }
    setStartLoading(false);
  }, []);

  const AccountSaveHandler = async () => {
    const accountPayload = {
      businessName: businessName,
      companyName: legalBusinessName,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      addressCity: city,
      addressCountry: country,
      addressState: state,
      imageUrl: imageUrl,
      addressPostalCode: zip,
      taxNumber: taxNumber,
      phone: phone,
      headerImageUrl: coverImage,
      description: description,
      businessType: type,
      incorporationDate: date,
      email: email
    };
    if (loading) {
      return;
    }
    setLoading(true);
    const { data, error} = await updateAccount(accountPayload);
    if (data) {
      setServerError(null);
      toast.success(
        "Account Information Saved Successfulluy",
        toastConfiguration
      );
      const company = JSON.parse(localStorage.getItem("company"));
      const userCompanies = JSON.parse(localStorage.getItem("userCompanies"));
      if (!imageUrl) {
        if (company && userCompanies) {
          for (let i = 0; i < userCompanies?.length; i++) {
            if (userCompanies[i]?.name === businessName) {
              userCompanies[i].logoUrl = "";
            }
          }
          company.logoUrl = "";
          localStorage.setItem("userCompanies", JSON.stringify(userCompanies))
          localStorage.setItem("company", JSON.stringify(company))
        }
        setChangeBusinessLogo(!changeBuisnessLogo);
      }
      if (company && company.name !== data?.account?.businessName) {
        company.name = data?.account?.businessName;
        localStorage.setItem("company", JSON.stringify(company))
        setChangeBusinessLogo(!changeBuisnessLogo);
        setChangeProfile(!changeProfile);
      }
      //setShowMainContainer(true);
    } else {
      toast.error(error, toastConfiguration);
      setServerError(error);
    }

    setLoading(false);
  };

  const imageUploadHandler = async (imageFile) => {
    setImageUploading(true);
    setSelectedImage(imageFile);
    const formData = new FormData();
    formData.append("", imageFile);
    const uploadImageResponse = token ? await uploadTokenedImage(formData, token) : await uploadProductImage(formData);
    const { data, error } = uploadImageResponse;
    if (data) {
      setImageUrl(data.image.url);

      const company = JSON.parse(localStorage.getItem("company"));
      const userCompanies = JSON.parse(localStorage.getItem("userCompanies"));
      if (company && userCompanies) {
        for (let i = 0; i < userCompanies?.length; i++) {
          if (userCompanies[i]?.name === businessName) {
            userCompanies[i].logoUrl = data.image.url;
          }
        }
        company.logoUrl = data.image.url;
        localStorage.setItem("company", JSON.stringify(company))
        localStorage.setItem("userCompanies", JSON.stringify(userCompanies))
      }

      setChangeBusinessLogo && setChangeBusinessLogo(!changeBuisnessLogo);
      toast.success("Image uploaded successfully", toastConfiguration);
    } else {
      toast.error(error || "Image upload operation failed", toastConfiguration);
    }
    setImageUploading(false);
  };

  const coverImageUploadHandler = async (imageFile) => {
    setImageUploading(true);
    setSelectedCoverImage(imageFile);
    const formData = new FormData();
    formData.append("", imageFile);
    const uploadImageResponse = token ? await uploadTokenedImage(formData, token) : await uploadProductImage(formData);
    const { data, error } = uploadImageResponse;
    if (data) {
      setCoverImage(data.image.url);
      toast.success("Image uploaded successfully", toastConfiguration);
    } else {
      toast.error(error || "Image upload operation failed", toastConfiguration);
    }
    setImageUploading(false);
  }

  const handleOnChange = (value) => {
    setPhone(value);
  };

  return (
    <>
      {!isStripedVerified ? (
        <ErrorModal visibility={true} />
      ) : // showMainContainer ?
        //     <MainContainer />
        //     :
        startLoading ? (
          <div
            className={styles.spinner}
            style={{ height: window.outerHeight - 250 }}
          >
            <Spinner loading={startLoading} size={150} color={"gray"} />
          </div>
        ) : (
          <div className={styles.mainContainer}>
            <ToastContainer />
            <div className={styles.profileContainer}>
              <div style={{ marginTop: 16, position: 'relative' }}>
                {/* Cover Image */}
                {coverImage || selectedCoverImage ?
                  <div style={{ position: 'relative' }}>
                    <img src={selectedCoverImage
                      ? URL.createObjectURL(selectedCoverImage)
                      : coverImage} alt="cover" className={styles.coverImage}
                      onError={() => {
                        setSelectedCoverImage(null);
                        setCoverImage(null);
                      }}
                    />
                    <div className={styles.coverClose}>
                      <button
                        onClick={() => {
                          setSelectedCoverImage(null);
                          setCoverImage("");
                        }}
                      >
                        <Icon name='close' size='16' />
                      </button>
                    </div>
                  </div>
                  :
                  <div
                    className={cn(styles.imgInputCover, styles.coverImage)}
                  >
                    <label
                      className={styles.inputLabelCover}
                      htmlFor='fileInputCover'
                      style={{ cursor: "pointer" }}
                    >
                      <Icon name='add' />
                    </label>
                    <input
                      type='file'
                      className={styles.input}
                      name='myImage'
                      id='fileInputCover'
                      onChange={(event) =>
                        coverImageUploadHandler(event.target.files[0])
                      }
                    />
                  </div>
                }

                {selectedImage || imageUrl ? (
                  <div className={styles.selectedImageWrap}>
                    <Image
                      className={styles.selectedImage}
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : imageUrl
                      }
                      srcDark={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : imageUrl
                      }
                      onError={() => {
                        setSelectedImage(null);
                        setImageUrl(null);
                      }}
                    />
                    <div className={styles.action}>
                      <button
                        onClick={() => {
                          setSelectedImage(null);
                          setImageUrl("");
                        }}
                      >
                        <Icon name='close' size='16' />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    className={styles.imgInput}
                    style={{ width: 100, height: 100, marginBottom: 20 }}
                  >
                    <label
                      className={cn(
                        selectedImage ? styles.inputLabelImg : styles.inputLabel
                      )}
                      htmlFor='fileInput'
                      style={{ cursor: "pointer" }}
                    >
                      <Icon name='add' />
                    </label>
                    <input
                      type='file'
                      className={styles.input}
                      name='myImage'
                      id='fileInput'
                      onChange={(event) =>
                        imageUploadHandler(event.target.files[0])
                      }
                    />
                  </div>
                )}
              </div>

              <div className={styles.row} style={{ marginTop: 20 }}>
                <div className={styles.field}>
                  <TextInput
                    label='Business Name (What your client see)'
                    name='Business-name'
                    type='text'
                    value={businessName}
                    onChange={(event) => setBusinessName(event?.target?.value)}
                    //tooltip={businessNameDesc}
                    required
                  />
                </div>
                <div className={styles.field}>
                  <TextInput
                    label='Legal Business Name (What authorities see)'
                    name='Legal-Business-name'
                    type='text'
                    value={legalBusinessName}
                    onChange={(event) =>
                      setLegalBusinessName(event?.target?.value)
                    }
                    //tooltip={businessNameDesc}
                    required
                  />
                </div>
                <div className={styles.field}>
                  <Dropdown
                    label="Type"
                    className={styles.dropdown}
                    classDropdownHead={styles.dropdownHead}
                    value={type}
                    setValue={setType}
                    options={typeOptions || []}
                    bodyStyles={{ width: '100%' }}
                  />
                </div>
              </div>
              <div className={styles.billingWrapper}>
                <div className={styles.row}>
                  <div className={styles.field}>
                    <div className={styles.fieldLabel}>Country</div>
                    <Select
                      options={options}
                      className={cn(styles.dropdown, "drop", styles.drop)}
                      value={displayCountry}
                      onChange={(item) => {
                        setAddressLine1('');
                        setCountry(item?.value);
                        setDisplayCountry(item);
                      }}
                    />
                    {/* <Dropdown
                                            className={styles.dropdown}
                                            classDropdownHead={styles.dropdownHead}
                                            value={country}
                                            setValue={setCountry}
                                            options={countries}
                                        /> */}
                  </div>
                  <div className={styles.field} style={{ position: 'relative' }}>
                    <div className={styles.title} style={{ marginBottom: 5 }}>Address</div>
                    <GooglePlacesAutocomplete
                      autocompletionRequest={{
                        types: ["geocode"],
                        componentRestrictions: { country: [country] },

                      }}
                      selectProps={{
                        placeholder: addressLine1 ? addressLine1 : "",
                        formatOptionLabel: ({ label, description }) => (
                          <div>
                            <span>{label}</span>
                            <span>{description}</span>
                          </div>
                        ),
                        styles: {
                          input: (provided) => ({
                            ...provided,
                            width: "33%",
                            height: "40px"
                          }),
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: '#F4F4F4',
                            border: '2px solid #C7C5BF',
                            color: '#46455A',
                            borderRadius: '0px',
                            height: '48px',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: '#464542'
                          })
                        },

                        addressLine1,
                        onChange: (e) => {
                          setAddressLine1(e.label);
                        },
                        //defaultInputValue: addressLine1,
                      }}
                    />
                    <button
                      className={styles.close}
                      onClick={() => {
                        setAddressLine1('');
                        const divToClear = document.getElementsByClassName(" css-1uccc91-singleValue")[0];

                        console.log(divToClear)
                        if (divToClear) divToClear.innerHTML = "";
                      }}
                    >
                      <Icon name="close-circle" size="24" />
                    </button>
                  </div>
                  {/* <div className={styles.field}>
                    <TextInput
                      name='addressline1'
                      type='text'
                      value={addressLine1}
                      onChange={(event) => setAddressLine1(event?.target?.value)}
                      //tooltip="Maximum 100 characters. No HTML or emoji allowed"
                      required
                      label='Address'
                    />
                  </div> */}
                  {/* <div className={styles.field}>
                                        <TextInput
                                            className={styles.inputNo}
                                            name="addressline2"
                                            type="text"
                                            value={addressLine2}
                                            onChange={(event) => setAddressLine2(event?.target?.value)}
                                            required
                                        />
                                    </div> */}
                  {/* <div className={styles.field}>
                    <TextInput
                      name='description'
                      label='City'
                      value={city}
                      onChange={(event) => setCity(event?.target?.value)}
                      type='text'
                      required
                    />
                  </div> */}
                  <div className={styles.field}>
                    <TextInput
                      className={styles.inputNo}
                      label='Company ID'
                      name='tax-id'
                      type='text'
                      value={taxNumber}
                      onChange={(event) => setTaxNumber(event?.target?.value)}
                      required
                    />
                  </div>
                  {/* <div className={styles.field}>
                    {country === "US" ? (
                      <Dropdown
                        classDropdownHead={cn(styles.dropdownHead, styles.drop)}
                        classOptionText={styles.classOptionText}
                        value={state}
                        setValue={setStates}
                        options={states}
                        label='State'
                      />
                    ) : (
                      <TextInput
                        name='state'
                        label='State'
                        type='text'
                        required
                      />
                    )}
                  </div>
                  <div className={styles.field}>
                    <TextInput
                      className={styles.inputNo}
                      label='ZIP'
                      name='zip'
                      type='text'
                      value={zip}
                      onChange={(event) => setZip(event?.target?.value)}
                      required
                    />
                  </div> */}

                </div>

                <div className={styles.billingWrapper}>
                  <div className={styles.row}>
                    {/* <div className={styles.field}>
                    <div className={styles.fieldLabel}>Phone Verification</div>
                    <div className={styles.subtitle}>{phone}</div>
                    <div className={styles.verifiedBox}>
                      {isPhoneVerified ? (
                        <div className={styles.verified}>Verified</div>
                      ) : (
                        <div className={styles.notVerified}>Not Verified</div>
                      )}
                      <button
                        onClick={setShowUpdatePhonePopup}
                        className={styles.update}
                      >
                        Update
                      </button>
                    </div>
                  </div> */}
                    {/* <div className={styles.field}>
                    <div className={styles.fieldLabel}>Email Verification</div>
                    <div className={styles.subtitle}>{email}</div>
                    <div className={styles.verifiedBox}>
                      {isEmailVerified ? (
                        <div className={styles.verified}>Verified</div>
                      ) : (
                        <div className={styles.notVerified}>Not Verified</div>
                      )}
                      <button
                        onClick={setShowUpdateEmailPopup}
                        className={styles.update}
                      >
                        Update
                      </button>
                    </div>
                  </div> */}
                    <div
                      className={styles.field}
                      style={{ position: "relative" }}
                    >
                      <TextInput
                        // style={
                        //   email === "" || emailError
                        //     ? {
                        //       backgroundColor: "#FFBC9925",
                        //       borderColor: "#FFBC9925",
                        //     }
                        //     : null
                        // }
                        label={(isEmailVerified || isComingFromSignup) ? "Business Email" : "Email (not verified)"}
                        name='Business Email'
                        type='email'
                        value={email}
                        onChange={(event) => {
                          setServerError("");
                          emailHandleChange(event, setEmailError, setEmail);
                        }}
                        required
                      />
                      {/* {emailError ? (
                        <Icon
                          name='warning'
                          className={styles.warningIcon}
                          size='24'
                        />
                      ) : (
                        ""
                      )} */}
                    </div>
                    <div
                      className={styles.field}
                      style={{ position: "relative" }}
                    >
                      <div className={styles.fieldLabel}>Business Mobile</div>
                      <div
                        className={styles.mobileField}
                        style={{ border: "2px solid #C7C5BF" }}
                      >
                        <ReactPhoneInput
                          inputExtraProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                          buttonStyle={{ border: "none" }}
                          inputStyle={inputStyle}
                          country={"us"}
                          countryCodeEditable={false}
                          value={phone}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                    {/* <div className={styles.field}>
                    <div className={styles.fieldLabel}>Time Zone</div>
                      <TimezoneSelect
                        value={tz}
                        onChange={setTz}
                        labelStyle="abbrev"
                      />
                    
                  </div> */}

                    <div className={styles.field}>
                      <DateInput
                        label={"Date of incorportation"}
                        className={styles.dateInput}
                        allowFuture={true}
                        portalClassInput={styles.inputDate}
                        classInputValue={styles.inputValue}
                        dateInputId='dateInputId'
                        componentStartDate={date}
                        setComponentStartDate={setDate}
                      />
                    </div>
                  </div>
                  {!isComingFromSignup && (
                    <div className={styles.field}>
                      <TextInput
                        name='Description'
                        type='text'
                        value={description}
                        onChange={(event) => setDescription(event?.target?.value)}
                        required
                        label='Description (Optional)'
                      />
                    </div>
                  )}
                </div>

                {isComingFromSignup && (
                  <div className={styles.buttonWrapper}>
                    <button className="button" onClick={() => continueClickHandler(
                      businessName,
                      legalBusinessName,
                      addressLine1,
                      country,
                      imageUrl,
                      email,
                      phone,
                      coverImage,
                      type,
                      date,
                      taxNumber,
                      emailError
                    )}>Continue</button>
                    <button className="button-stroke-red" onClick={skipClickHanlder}>Skip</button>
                  </div>
                )}

                {!isComingFromSignup && (
                  <div className={styles.actionCard}>
                    <button
                      className={cn("button", styles.button)}
                      onClick={async () => {
                        if (imageUploading) {
                          toast.error("Image is upoading...", toastConfiguration);
                          return;
                        }
                        AccountSaveHandler();
                      }}
                    >
                      {loading ? (
                        <Spinner loading={loading} size={15} />
                      ) : (
                        <>
                          <span>Save</span>
                        </>
                      )}
                    </button>
                  </div>
                )}
                {serverError !== "" ? (
                  <div
                    className={styles.errorContainer}
                    style={{ marginLeft: 25 }}
                  >
                    <span className={styles.errorMessage}>{serverError}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div >
        )}
      {/* update phone popup */}
      <Modal
        visible={showUpdatePhonePopup}
        onClose={() => setShowUpdatePhonePopup(false)}
        children={
          <Card
            /* classTitle="title-secondary-03" */
            title='Update Mobile'
            className={styles.card}
          >
            <div className={styles.field}>
              <TextInput label='Current Mobile' value={phone} />
            </div>
            <div className={styles.field} style={{ position: "relative" }}>
              <div className={styles.fieldLabel}>New Mobile</div>
              <div className={styles.mobileField}>
                <ReactPhoneInput
                  inputExtraProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  buttonStyle={{ border: "none" }}
                  inputStyle={inputStyle}
                  country={"us"}
                  countryCodeEditable={false}
                  value={phone}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <button
              style={{ marginTop: "12px" }}
              className='button'
              onClick={() => setShowUpdatePhonePopup(false)}
            >
              Save
            </button>
          </Card>
        }
      />
      {/* update Email popup */}
      <Modal
        visible={showUpdateEmailPopup}
        onClose={() => setShowUpdateEmailPopup(false)}
        children={
          <Card
            /* classTitle="title-secondary-03" */
            title='Update Email'
            className={styles.card}
          >
            <div className={styles.field}>
              <TextInput label='Current Email' value={email} />
            </div>
            <div className={styles.field} style={{ position: "relative" }}>
              <TextInput
                style={
                  email === "" || emailError
                    ? {
                      backgroundColor: "#FFBC9925",
                      borderColor: "#FFBC9925",
                    }
                    : null
                }
                label={isEmailVerified ? "Email" : "Email (not verified)"}
                name='email'
                type='email'
                value={email}
                onChange={(event) => {
                  setServerError("");
                  emailHandleChange(event, setEmailError, setEmail);
                }}
                required
              />
              {emailError ? (
                <Icon name='warning' className={styles.warningIcon} size='24' />
              ) : (
                ""
              )}
            </div>
            <button
              style={{ marginTop: "12px" }}
              className='button'
              onClick={() => setShowUpdateEmailPopup(false)}
            >
              Save
            </button>
          </Card>
        }
      />
    </>
  );
};

export default Account;
