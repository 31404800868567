import Card from "../../../components/Card";
import LargeNav from "../../../components/LargeNav";
import cn from 'classnames';
import styles from './EditNewProject.module.sass';
import { useEffect, useState } from "react";
import Details from "./Details";
import Lenders from "./Lenders";
import { getSingleProperty, propertyLendersList } from "../../../utils/apiCallHanlder";
import Spinner from "../../../utils/spinner";
import Documents from "./Documents";
import Messages from "./Messages";

const ChatAPI = require("twilio-chat");
const tabs = ["Details", "Loan", "Documents", "Messages"]
let client = null;

const EditNewProject = ({ id, getPropertiesData, setShowAddProject, showLendersTab }) => {
    const [selectedTab, setSelectedTab] = useState(showLendersTab ? tabs[1] : tabs[0]);
    const [showDetails, setShowDetails] = useState(showLendersTab ? false : true);
    const [showLenders, setShowLenders] = useState(showLendersTab ? true : false);
    const [showDocuments, setShowDocuments] = useState(false);

    const [checkCount, setCheckCount] = useState(0);
    const [propertyName, setPropertyName] = useState(null);
    const [deleteIdArray, setDeleteIdArray] = useState([]);
    const [retrievalLoading, setRetrievalLoading] = useState(id ? true : false);
    const [lendersLoading, setLendersLoading] = useState(id ? true : false);
    const [property, setProperty] = useState(null);
    const [propertyLenders, setPropertyLenders] = useState([]);

    const [matchedLendersCount, setMatchedLendersCount] = useState(0);
    const [showAllLenders, setShowAllLenders] = useState(false);
    const [channelToCreate, setChannelToCreate] = useState(null);

    useEffect(async () => {
        const chatApiToken = localStorage.getItem("chatAPiToken");
        client = new ChatAPI.Client(chatApiToken, { logLevel: 'info' });

        if (id) {
            setRetrievalLoading(true);
            const { data } = await getSingleProperty({
                propertyId: id
            });
            if (data) {
                setProperty(data?.property);
                await getLendersData(false);
            }
            setRetrievalLoading(false);
        }
    }, [id]);

    const getLendersData = async (showAll) => {
        setLendersLoading(true);
        const lenders = await propertyLendersList({
            propertyId: id,
            showAll: showAll || false
        })
        if (lenders?.data) {
            setMatchedLendersCount(lenders?.data?.matchedCount);
            setPropertyLenders(lenders?.data?.propertyLenders);
        }
        setLendersLoading(false);
    }

    return (
        <Card
            className={styles.card}
            title={propertyName ? propertyName : 'New Property'}
            border
            classCardHead={cn(styles.head, { [styles.hidden]: false })}
            head={
                <>
                    {showLenders && checkCount > 0 && (
                        <div className={styles.buttonContainer}>
                            <button className='button-stroke-red' onClick={() => { }}>
                                Delete Marked
                            </button>
                        </div>
                    )}
                </>
            }
        >
            {retrievalLoading ?
                <div className={styles.spinner}>
                    <Spinner size="48" color="gray" />
                </div>
                :
                <>
                    {id && (
                        <LargeNav
                            tabOptions={tabs}
                            activeTab={tabs?.indexOf(selectedTab)}
                            onClick={async (x) => {
                                setChannelToCreate(null);
                                setSelectedTab(x);

                                if (x === tabs[0]) {
                                    setShowDetails(true);
                                    setShowLenders(false);
                                    setShowDocuments(false);
                                    return;
                                }
                                if (x === tabs[1]) {
                                    setShowDetails(false);
                                    setShowLenders(true);
                                    setShowDocuments(false);
                                    return;
                                }
                                if (x === tabs[2]) {
                                    setShowDetails(false);
                                    setShowLenders(false);
                                    setShowDocuments(true);
                                    return;
                                }

                                setShowDetails(false);
                                setShowLenders(false);
                                setShowDocuments(false);
                            }}
                            onlyLarge
                        />
                    )}

                    {showDetails && <Details
                        getPropertiesData={getPropertiesData}
                        setShowAddProject={setShowAddProject}
                        property={property}
                        id={id}
                        onSaveHandler={() => {
                            setSelectedTab(tabs[1]);
                            setShowDetails(false);
                            setShowLenders(true)
                        }}
                        setProperty={setProperty}
                        setName={setPropertyName}
                        name={propertyName}
                        matchedLendersCount={matchedLendersCount}
                    />}
                    {showLenders && <Lenders
                        checkCount={checkCount}
                        setCheckCount={setCheckCount}
                        deleteIdArray={deleteIdArray}
                        setDeleteIdArray={setDeleteIdArray}
                        lendersLoading={lendersLoading}
                        propertyLenders={propertyLenders}
                        id={id}
                        property={property}
                        getLendersData={getLendersData}
                        setShowAllLenders={setShowAllLenders}
                        showAllLenders={showAllLenders}
                        matchedLendersCount={matchedLendersCount}
                        setProperty={setProperty}
                        setSelectedTab={setSelectedTab}
                        setShowLenders={setShowLenders}
                        setChannelToCreate={setChannelToCreate}
                    />}
                    {showDocuments && <Documents propertyId={id} />}
                    {selectedTab === tabs[3] && <Messages client={client} id={id} channelToCreate={channelToCreate} />}
                </>
            }
        </Card>
    );
};

export default EditNewProject;
